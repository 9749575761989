import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isUserImpersonating } from '@boldpenguin/sdk';
import { UntilDestroy } from '@ngneat/until-destroy';
import { select } from '@ngrx/store';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  selectIntercomEnabled,
  selectUserName,
} from 'src/app/core/services/bp-sdk-store-service/bp-sdk-store.selectors';
import { BpSdkStoreService } from 'src/app/core/services/bp-sdk-store-service/bp-sdk-store.service';
import {
  IntercomInternalLabels,
  IntercomService,
} from 'src/app/core/services/intercom-service/intercom.service';
import { ThemeService } from 'src/app/core/services/theme-service/theme.service';
import { externalUrls, localStorageKeys } from 'src/app/shared/constants';

@UntilDestroy()
@Component({
  selector: 'app-top-navigation-toolbar',
  templateUrl: './top-navigation-toolbar.component.html',
  styleUrls: ['./top-navigation-toolbar.component.scss'],
})
export class TopNavigationToolbarComponent implements OnInit {
  @Input() suppressNavMenu: boolean;
  @Input() isSideNavOpen = false;
  @Input() isAlertBannerPresent = false;
  @Output() toggleSideNav: EventEmitter<void> = new EventEmitter();

  readonly JiraCreateBugPage = externalUrls.JiraCreateBugPage;
  intercomLabels = IntercomInternalLabels; // For setting the data-intercom-target attribute
  intercomActive = false;
  loggedInUserIsImpersonating = false;
  orderOfSelectedNav = 0;
  nameOfUserBeingImpersonated$: Observable<string>;
  intercomEnabled$: Observable<boolean>;
  logoPath$: Observable<string>;

  constructor(
    private bpSdkStoreService: BpSdkStoreService,
    private localStorageService: LocalStorageService,
    private intercomService: IntercomService,
    private themeService: ThemeService,
  ) {}

  ngOnInit(): void {
    this.logoPath$ = this.themeService.getThemeLogoPath$();
    this.nameOfUserBeingImpersonated$ = this.bpSdkStoreService.state$.pipe(
      select(selectUserName),
      map((n) => n ?? ''),
    );

    this.bpSdkStoreService.state$
      .pipe(select(isUserImpersonating))
      .subscribe((isImpersonating: boolean) => {
        if (isImpersonating) {
          this.localStorageService.store(localStorageKeys.impersonated, 'true');
          this.loggedInUserIsImpersonating = true;
        } else if (
          this.localStorageService.retrieve(localStorageKeys.impersonated)
        ) {
          this.loggedInUserIsImpersonating = true;
        }
      });

    this.intercomService.onHide(this.onHideIntercom.bind(this));
    this.intercomService.onShow(this.onShowIntercom.bind(this));

    this.intercomEnabled$ = this.bpSdkStoreService.sdkReadyState$.pipe(
      select(selectIntercomEnabled),
    );
  }

  sideNavClicked() {
    this.toggleSideNav.emit();
  }

  onHideIntercom() {
    this.intercomActive = false;
  }

  onShowIntercom() {
    this.intercomActive = true;
  }
}
