<nav class="nav-body">
  <ng-container *ngFor="let menuItem of mainMenuItems$ | async">
    <app-navigation-menu-item [item]="menuItem"></app-navigation-menu-item>
  </ng-container>

  <hr class="divider" />

  <ng-container *ngFor="let menuItem of settingsMenuItems$ | async">
    <app-navigation-menu-item [item]="menuItem"></app-navigation-menu-item>
  </ng-container>

  <emperor-nav-button
    *ngIf="intercomEnabled$ | async"
    class="nav-menu-item provide-feedback"
    [highlight]="false"
    [stopPropagation]="false"
    [attr.data-intercom-target]="intercomLabels.NAV_MENU_FEEDBACK"
  >
    <emperor-icon name="user_chat_2" class="nav-icon"></emperor-icon>
    Feedback & FAQ
  </emperor-nav-button>

  <!-- Copyright -->
  <div class="copyright-info">
    <!-- Powered By Pengs -->
    <bp-sdk-footer></bp-sdk-footer>
    <div>©<app-year></app-year> Bold Penguin • All rights reserved</div>
    <div>
      <a href="https://www.boldpenguin.com/terms">Terms & Conditions</a>
      •
      <a href="https://www.boldpenguin.com/privacy-policy">Privacy Policy</a>
    </div>
  </div>
</nav>
