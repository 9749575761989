import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer';
import { localStorageKeys } from 'src/app/shared/constants';
import packageInfo from '../../../../../package.json';
import type LogRocket from 'logrocket';

let logRocket: typeof LogRocket;
if (environment.logRocketTag) {
  logRocket = require('logrocket');
}

declare global {
  interface Window {
    Cypress?: unknown;
  }
}

export interface ILogRocketService {
  init(): void;
  identify(userId: string, userTraits: { [key: string]: string }): void;
  track(eventDescription: string): void;
}

@Injectable({
  providedIn: 'root',
})
export class LogRocketService implements ILogRocketService {
  private _logRocket: typeof LogRocket;

  private readonly privateFieldNames = [
    'value',
    'password',
    'access_token',
    'refresh_token',
    'producer_code',
    'ssn',
    'fein',

    // ledger payment methods
    'last4',
    'exp_month',
    'exp_year',

    // ledger invoices
    'hosted_invoice_url',
    'invoice_pdf',
  ];
  private readonly fuzzySanitizers = LogrocketFuzzySanitizer.setup([
    ...this.privateFieldNames,
  ]);

  /**
   * Initialize the LogRocket library. Should only be called once,
   * and must be called before any events are tracked
   *
   */
  init(): void {
    // Short circuit for test runs
    if (localStorage.getItem(localStorageKeys.disableLogRocket)) {
      return;
    }

    if (!!window?.Cypress) {
      return;
    }

    if (environment.logRocketTag && logRocket) {
      this._logRocket = logRocket;

      const logRocketOptions: any = {
        network: {
          requestSanitizer: this._requestSanitizer,
          responseSanitizer: this._responseSanitizer,
        },
        release: packageInfo.version,
      };

      if (environment.sanitizeLogRocket) {
        logRocketOptions.dom = {
          textSanitizer: true,
          inputSanitizer: true,
        };
      }

      this._logRocket.init(
        `qszyqd/terminal-${environment.logRocketTag}`,
        logRocketOptions,
      );
    }
  }

  /**
   * Identify a user to LogRocket
   *
   * @param userId A unique user ID to pass to LogRocket, otherwise sessions will show up as anonymous
   * @param traits Optional object containing key value pairs to include with user
   */
  identify(userId: string, traits?: { [key: string]: string }): void {
    if (this._logRocket) {
      this._logRocket.identify(userId, traits);
    }
  }

  /**
   * Track an event in LogRocket
   *
   * @param eventDescription String event name to track in LogRocket
   */
  track(eventDescription: string): void {
    if (this._logRocket) {
      this._logRocket.track(eventDescription);
    }
  }

  get sessionUrl(): string | null {
    return this._logRocket?.sessionURL;
  }

  private _requestSanitizer = (request: any): any =>
    this.fuzzySanitizers.requestSanitizer(this._networkSanitizer(request));

  private _responseSanitizer = (response: any): any =>
    this.fuzzySanitizers.responseSanitizer(this._networkSanitizer(response));

  private _networkSanitizer = (networkRequest: any): any => {
    delete networkRequest.headers.Authorization;
    delete networkRequest.headers.authorization;

    if (networkRequest?.url?.includes('bank_account')) {
      networkRequest.body = null;
    }

    return networkRequest;
  };
}
