import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppRoutingModule } from '../app-routing/app-routing.module';
import { ApplicationCountEffects } from './store/application-count/application-count.effects';
import { BankAccountEffects } from './store/bank-account/bank-account.effects';
import { ComplianceFormEffects } from './store/compliance-form/compliance-form.effects';
import {
  CoreFeatureStateKey,
  CoreReducerMap,
} from './store/core-feature-reducer';
import { EnrollmentSelfServiceRegistrationEffects } from './store/enrollment-self-service-registration/enrollment-self-service-registration.effects';
import { EnrollmentsEffects } from './store/enrollments/enrollments.effects';
import { ExchangeStatusEffects } from './store/exchange-status/exchange-status.effects';
import { HeaderAlertsEffects } from './store/header-alerts/header-alerts.effects';
import { ProductRankingEffects } from './store/product-ranking/product-ranking.effects';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    MatSnackBarModule,
    HttpClientModule,
    StoreModule.forFeature(CoreFeatureStateKey, CoreReducerMap),
    EffectsModule.forFeature([
      ApplicationCountEffects,
      BankAccountEffects,
      HeaderAlertsEffects,
      EnrollmentsEffects,
      ComplianceFormEffects,
      EnrollmentSelfServiceRegistrationEffects,
      ExchangeStatusEffects,
      ProductRankingEffects,
    ]),
  ],
  providers: [],
  exports: [RouterModule],
})
export class CoreModule {}
