import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { EmperorUIButtonTypes } from '@boldpenguin/emperor-presentational';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LocalStorageService } from 'ngx-webstorage';
import {
  MixpanelEventType,
  MixpanelService,
} from 'src/app/core/services/mixpanel-service/mixpanel.service';

export const compatibilityBannerLocalStorage = 'compat-banner-dismissed';

@UntilDestroy()
@Component({
  selector: 'app-compatibility-banner',
  templateUrl: './compatibility-banner.component.html',
  styleUrls: ['./compatibility-banner.component.scss'],
})
export class CompatibilityBannerComponent implements OnInit {
  isDismissed = false;
  isMobileSized = false;
  emperorTextButton = EmperorUIButtonTypes.Text;

  constructor(
    private mixpanelService: MixpanelService,
    private breakpointObserver: BreakpointObserver,
    private localSt: LocalStorageService,
  ) {}

  ngOnInit() {
    this.checkBannerVisibility();

    this.localSt
      .observe(compatibilityBannerLocalStorage)
      .pipe(untilDestroyed(this))
      .subscribe((bannerIsDismissed: boolean) => {
        this.isDismissed = bannerIsDismissed;
      });
  }

  checkBannerVisibility() {
    this.isMobileSized =
      this.breakpointObserver.isMatched('(max-width: 719px)');
  }

  dismiss() {
    this.localSt.store(compatibilityBannerLocalStorage, true);
    this.isDismissed = true;
    // Track the reason why it was shown when dismissing (mobile/ie11/etc)
    this.mixpanelService.track(
      MixpanelEventType.COMPATIBILITY_BANNER_DISMISSED,
      { isMobileSized: this.isMobileSized },
    );
  }
}
