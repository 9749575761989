import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
  transform(
    phoneNumber: string,
    shouldMask: boolean = false,
    stripFormatting: boolean = false,
  ): string {
    const strippedNumber = !!phoneNumber ? phoneNumber.replace(/\D/g, '') : '';
    if (!phoneNumber || !strippedNumber.length) {
      return '';
    }

    if (stripFormatting) {
      return strippedNumber;
    } else if (shouldMask) {
      return this.transform(phoneNumber.replace(/\d/g, '5'));
    } else {
      return this.formatNumber(strippedNumber);
    }
  }

  private formatNumber(strippedNumber: string): string {
    let formattedPhone = '';
    if (strippedNumber.charAt(0) === '1' && strippedNumber.length >= 11) {
      // Trim off the country code
      formattedPhone = `(${strippedNumber.substr(1)}`;
    } else {
      formattedPhone = `(${strippedNumber}`;
    }

    if (formattedPhone.length > 4) {
      formattedPhone = `${formattedPhone.substr(0, 4)}) ${formattedPhone.substr(
        4,
      )}`;
    }
    if (formattedPhone.length > 9) {
      formattedPhone = `${formattedPhone.substr(0, 9)}-${formattedPhone.substr(
        9,
      )}`;
    }
    return formattedPhone;
  }
}
