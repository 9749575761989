import { createAction, props } from '@ngrx/store';
import { IApplicationCountResponse } from '../../models/application-count-state.model';

export const GetApplicationCounts = createAction(
  '[ApplicationCounts] - Get Application Counts',
);

export const GetApplicationCountsInternal = createAction(
  '[ApplicationCounts] - Get Application Counts Internal',
);

export const GetApplicationCountsSuccess = createAction(
  '[ApplicationCounts] - Get Application Counts Success',
  props<{ counts: IApplicationCountResponse }>(),
);

export const GetApplicationCountsError = createAction(
  '[ApplicationCounts] - Get Application Counts Error',
  props<{ error: Error }>(),
);
