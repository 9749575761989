// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { BpEnvironmentName } from '@boldpenguin/sdk';

export const defaultEnvironment = {
  production: false,
  envTag: 'local',
  sdkEnvTag: BpEnvironmentName.Development,
  isUatEnv: false,

  apiBaseUri: 'http://localhost:8080',
  apiAuthUri: 'http://localhost:3000',
  ledgerUri: 'http://localhost:3004',
  wsBaseUri: 'ws://localhost:8080/cable',
  magellanBaseUri: 'http://localhost:8000',
  calendlyOnboardingKickoffUrl:
    'https://calendly.com/d/3d8-6cy-43r/test-onboarding-calendly-embed-round-robin',
  clientId: 'terminal-test-id',
  googleApiKey: 'AIzaSyAMH3B_OCvJzwl-x_d_5QgK14lDLSYMSVc',
  mixpanelProjectKey: '78227e15fafb2e45df0fb0b67f793d2e',

  // intercom
  intercomAppId: 'hfr8cui8',
  recommendedCarriersProductTourId: 439977,

  bpSdkDebugging: false,
  stripePublishableKey: 'pk_test_hv6tVRFJiGkXCNny0YEqmP0F',

  //log rocket
  logRocketTag: 'dev-ny7ud',
  sanitizeLogRocket: false,

  allowAccountSignup: true,

  sendMixpanelEvents: false,
  logMixpanelEvents: false, // Don't be too noisy
  downloadEIT: true,
  showAcordFormsCTA: true,

  // Feature Flags
  notesEnabled: false,
  enrollmentsFiltersFlag: true,
  showResetComplianceForms: false,
  calendlySignupFlow: false,
  supportsGeminiUploads: true,

  enableReportsNavigationLink: true,

  // For unit test purpose only
  unitTestingFeature: true,

  // Sentry
  sentryTracesSampleRate: 0.1,
  sentryInstrumentNavigation: true,
  sentryInstrumentPageLoad: true,
  sentryMarkBackgroundSpans: true,
  sentryEnableLongTask: true,

  zapierPageId: 'clq48q8lw002dke453h4d6q8e',

  // Reporting
  shouldLoadQuicksightDashboard: false,

  /**
   * T3ZB0JL2: 10% discount
   * OCGJEE71: 20% discount
   * PEMOWQCM: 100% discount
   */
  discounts: {
    agency_collective: { code: 'OCGJEE71', title: 'Agency Collective' },
    asnoa: { code: 'OCGJEE71', title: 'ASNOA' },
    biberk_sub: { code: 'OCGJEE71', title: 'BiBERK' },
    biberk: { code: 'T3ZB0JL2', title: 'BiBERK' },
    coterie_sub: { code: 'OCGJEE71', title: 'Coterie' },
    coterie: { code: 'T3ZB0JL2', title: 'Coterie' },
    hub: { code: 'PEMOWQCM', title: 'HUB' },
    isu: { code: 'T3ZB0JL2', title: 'ISU' },
    pin: { code: 'T3ZB0JL2', title: 'Partners Insurance Network' },
    acuity: { code: 'T3ZB0JL2', title: 'Acuity' },
    chubb: { code: 'T3ZB0JL2', title: 'Chubb' },
    pathpoint: { code: 'T3ZB0JL2', title: 'Pathpoint' },
  },

  plans: {
    agency_collective: {
      code: 'OCGJEE71',
      title: 'Agency Collective',
    },
    insurance_agency_network: {
      code: 'T3ZB0JL2',
      title: 'Insurance Agency Network',
    },
    partners_insurance_network: {
      code: 'T3ZB0JL2',
      title: 'Partners Insurance Network',
    },
    keystone: {
      code: 'OCGJEE71',
      title: 'Keystone',
    },
    travelers: {
      code: 'OCGJEE71',
      title: 'Travelers',
    },
    westfield: {
      code: 'T3ZB0JL2',
      title: 'Westfield',
    },
  },
};
