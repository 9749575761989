import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ComplianceFormQuestionGroup } from 'src/app/core/models/compliance-form.model';
import { IEnrollment } from 'src/app/core/models/enrollments.model';
import {
  selectActivatingEnrollmentDirectAppointments,
  selectAllEnrollments,
  selectEnrollmentsHaveBeenLoaded,
  selectUpdatingEnrollmentSubAppointments,
} from 'src/app/core/store/enrollments/enrollments.selectors';
import {
  businessEntityTypeId,
  queuedDirectAppointmentEnrollmentsId,
  queuedSubAppointmentEnrollmentsId,
  skipInstantEnrollmentDefaultForIds,
} from 'src/app/shared/models/shared-compliance-form-questions';
import { isMainComplianceFormComplete } from 'src/app/shared/utilities/compliance-form-utilities';
import {
  IWelcomeStep,
  WelcomeStep,
  enrollmentSubdomainCollection,
} from '../welcome.model';
import {
  IWelcomeState,
  welcomeAdapter,
  welcomeFeatureStateKey,
} from './welcome.reducer';

const { selectEntities, selectAll } = welcomeAdapter.getSelectors();

export const selectWelcomeFeature = createFeatureSelector<IWelcomeState>(
  welcomeFeatureStateKey,
);

export const selectSteps = createSelector(selectWelcomeFeature, selectAll);

export const selectUISteps = createSelector(
  selectWelcomeFeature,
  ({ sidebarSteps }) => sidebarSteps,
);

export const selectStepEntities = createSelector(
  selectWelcomeFeature,
  selectEntities,
);

export const selectHasPaymentsStep = createSelector(
  selectStepEntities,
  (stepEntities) => !!stepEntities[WelcomeStep.PAYMENTS],
);

const selectCurrentStepId = createSelector(
  selectWelcomeFeature,
  (state) => state.currentStepId,
);

export const selectIsSubAppointmentPagePresent = createSelector(
  selectWelcomeFeature,
  (state) => state.ids.includes(WelcomeStep.SUB_APPOINTMENTS),
);

export const selectCurrentStep = createSelector(
  selectStepEntities,
  selectCurrentStepId,
  (entities, currentStepId): IWelcomeStep =>
    entities[currentStepId] as IWelcomeStep,
);

export const selectComplianceFormData = createSelector(
  selectWelcomeFeature,
  (state) => state.complianceFormData,
);

export const selectComplianceFormDataLoaded = createSelector(
  selectWelcomeFeature,
  (state) => state.complianceFormDataLoaded,
);

export const selectPendingDirectAppointmentEnrollmentIds = createSelector(
  selectComplianceFormData,
  (state) =>
    ((state?.data || {})[queuedDirectAppointmentEnrollmentsId] as string[]) ||
    [],
);

export const selectPendingSubAppointmentEnrollmentIds = createSelector(
  selectComplianceFormData,
  (state) =>
    ((state?.data || {})[queuedSubAppointmentEnrollmentsId] as string[]) || [],
);

export const selectComplianceFormBusinessEntityType = createSelector(
  selectComplianceFormData,
  (state) => ((state?.data || {})[businessEntityTypeId] as string) || '',
);

export const selectCarrierSubdomainsForPendingSubAppointmentsWithAdditionalQuestions =
  createSelector(
    selectPendingSubAppointmentEnrollmentIds,
    selectAllEnrollments,
    (pendingSubApptIds, enrollments): ComplianceFormQuestionGroup[] => {
      const pendingEnrollments: IEnrollment[] = [];
      pendingSubApptIds.forEach((pendingId) => {
        const pendingEnrollment = enrollments.find((x) => x.id === pendingId);
        pendingEnrollments.push(pendingEnrollment as IEnrollment);
      });
      return pendingEnrollments
        .filter((enrollment) =>
          enrollmentSubdomainCollection.includes(
            enrollment?.carrier_subdomain as ComplianceFormQuestionGroup,
          ),
        )
        .map(
          (enrollment) =>
            enrollment.carrier_subdomain as ComplianceFormQuestionGroup,
        );
    },
  );

export const selectCarrierSubdomainsForPendingSubAppointmentEnrollments =
  createSelector(
    selectPendingSubAppointmentEnrollmentIds,
    selectAllEnrollments,
    (pendingSubApptIds, enrollments): string[] => {
      const pendingEnrollments: IEnrollment[] = [];
      pendingSubApptIds.forEach((pendingId) => {
        const pendingEnrollment = enrollments.find((x) => x.id === pendingId);
        pendingEnrollments.push(pendingEnrollment as IEnrollment);
      });
      return pendingEnrollments.map(
        (enrollment) => enrollment.carrier_subdomain,
      );
    },
  );

export const selectSkipInstantEnrollmentSubAppointmentEnrollmentIds =
  createSelector(
    selectComplianceFormData,
    (state) =>
      (state?.data?.[skipInstantEnrollmentDefaultForIds] as string[]) || [],
  );

export const selectComplianceFormCompleted = createSelector(
  selectWelcomeFeature,
  (state) =>
    isMainComplianceFormComplete(
      state.complianceFormData?.completed_question_groups ?? [],
    ),
);

export const selectEoDocument = createSelector(
  selectWelcomeFeature,
  (state) => state.errorsAndOmissionsDocument || null,
);

export const selectEoDocumentIsUploading = createSelector(
  selectWelcomeFeature,
  (state) => state.errorsAndOmissionsDocumentUploading,
);

export const selectEoDocumentUploadError = createSelector(
  selectWelcomeFeature,
  (state) => state.errorsAndOmissionsDocumentError || null,
);

export const selectUpdatingData = createSelector(
  selectWelcomeFeature,
  (state) =>
    state.errorsAndOmissionsDocumentUploading || state.complianceFormSaving,
);

export const selectCompletedComplianceQuestionSets = createSelector(
  selectWelcomeFeature,
  (state) => state.complianceFormData?.completed_question_groups ?? [],
);

export const selectIncompleteCarrierQuestionGroups = createSelector(
  selectCompletedComplianceQuestionSets,
  (completedQuestionGroups) =>
    enrollmentSubdomainCollection.filter(
      (enrollmentSubdomainQuestionGroup) =>
        !completedQuestionGroups.includes(enrollmentSubdomainQuestionGroup),
    ),
);

export const selectIsWelcomeEnrollmentsMakingRequest = createSelector(
  selectUpdatingEnrollmentSubAppointments,
  selectActivatingEnrollmentDirectAppointments,
  selectUpdatingData,
  (updatingEnrollment, activatingEnrollment, updatingWelcomeData) =>
    [updatingEnrollment, activatingEnrollment, updatingWelcomeData].some(
      (inProgress) => inProgress,
    ),
);

export const selectIsComplianceEnrollmentDataLoaded = createSelector(
  selectEnrollmentsHaveBeenLoaded,
  selectComplianceFormDataLoaded,
  (enrollmentsLoaded, complianceLoaded) =>
    [enrollmentsLoaded, complianceLoaded].every((loaded) => loaded),
);
