import { AppointmentTypes } from '@boldpenguin/sdk';
import { createSelector } from '@ngrx/store';
import {
  IAvailableEnrollment,
  IEnrollment,
} from 'src/app/core/models/enrollments.model';
import {
  hasSameOfflineName,
  isOptInQuoteFlowCarrier,
} from 'src/app/shared/utilities/carrier-utilities';
import {
  filterByAvailableAppointmentType,
  getUserFacingServerErrors,
  isActiveDirectAppointmentEnrollment,
  isActiveSubAppointmentEnrollment,
} from '../../utilities/enrollments-utilities';
import { selectCoreState } from '../core-feature-selector';
import {
  AvailableEnrollmentsAdapter,
  EnrollmentsAdapter,
} from './enrollments.state';

export const selectEnrollmentsState = createSelector(
  selectCoreState,
  (state) => state.enrollments,
);

export const selectAvailableEnrollmentsState = createSelector(
  selectEnrollmentsState,
  (state) => state.availableEnrollments,
);

export const { selectAll: selectAllEnrollments } =
  EnrollmentsAdapter.getSelectors(selectEnrollmentsState);

export const { selectAll: selectAllAvailableEnrollments } =
  AvailableEnrollmentsAdapter.getSelectors(selectAvailableEnrollmentsState);

export const selectEnrollmentById = (id: string) =>
  createSelector(selectAllEnrollments, (allEnrollments) =>
    allEnrollments.find((e) => e.id === id),
  );

export const selectEnrollmentByCarrierId = (carrierId: string) =>
  createSelector(selectAllEnrollments, (allEnrollments) =>
    allEnrollments.find((e) => e.carrier_id === carrierId),
  );

export const selectLogoPathForEnrollmentBySubdomain = (subdomain: string) =>
  createSelector(
    selectAllEnrollments,
    (allEnrollments) =>
      allEnrollments.find((e) => e.carrier_subdomain === subdomain)
        ?.logo_path ?? '',
  );

export const selectEnrollmentServerErrorsById = (id: string) =>
  createSelector(selectAllEnrollments, (allEnrollments) => {
    const errors: string[] | undefined = allEnrollments
      .filter((e) => e.id === id)
      .map((enrollment) =>
        getUserFacingServerErrors(
          enrollment.serverErrorMessages,
          enrollment.carrier_subdomain,
        ),
      )[0];
    return { errors, hasErrors: !!(errors && errors?.length > 0) };
  });

export const selectActiveEnrollments = createSelector(
  selectAllEnrollments,
  (allEnrollments) => allEnrollments.filter((e) => e.active),
);

export const selectEnrollmentsHaveBeenLoaded = createSelector(
  selectEnrollmentsState,
  (enrollmentsState) => enrollmentsState.enrollmentsHaveBeenLoaded,
);

export const selectHasEnrollmentsError = createSelector(
  selectEnrollmentsState,
  (enrollmentsState) => !!enrollmentsState.enrollmentsError,
);

export const selectHasSuccessfullyLoadedEnrollments = createSelector(
  selectEnrollmentsHaveBeenLoaded,
  selectHasEnrollmentsError,
  (hasLoaded, hasError) => hasLoaded && !hasError,
);

export const selectEnrollmentProducts = createSelector(
  selectAllEnrollments,
  (enrollments) => {
    const products = new Set();
    enrollments.forEach((enrollment) => {
      enrollment.carrier_products?.forEach((product) => {
        products.add(product);
      });
    });

    return Array.from(products.values() as Iterable<string>).sort();
  },
);

export const selectHasAvailableSubAppointments = createSelector(
  selectAllEnrollments,
  (enrollments) =>
    enrollments.some((enrollment) =>
      enrollment.available_appointment_types.includes(AppointmentTypes.Sub),
    ),
);

export const selectLoadedEnrollmentsHaveSubAppointmentsEnabled = createSelector(
  selectHasAvailableSubAppointments,
  selectEnrollmentsHaveBeenLoaded,
  (hasAvailableSubs, loaded) => ({
    hasAvailableSubs,
    loaded,
  }),
);

export const selectDirectAppointmentEnrollments = createSelector(
  selectAllEnrollments,
  (enrollments) =>
    filterByAvailableAppointmentType(
      enrollments,
      AppointmentTypes.Direct,
    ) as IEnrollment[],
);

export const selectNonOptInDirectAppointmentEnrollments = createSelector(
  selectDirectAppointmentEnrollments,
  (enrollments) =>
    enrollments.filter(
      (enrollment) => !isOptInQuoteFlowCarrier(enrollment.carrier_labels),
    ),
);

// Selects online enrollments and offline enrollments
// that don't have an online counterpart
export const selectAdmittedOnlineDirectAppointmentEnrollments = createSelector(
  selectNonOptInDirectAppointmentEnrollments,
  (enrollments) =>
    enrollments.filter((enrollment) => {
      if (enrollment.carrier_online) {
        return true;
      }
      return !enrollments.some((onlineEnrollment) =>
        hasSameOfflineName(
          onlineEnrollment.carrier_subdomain,
          enrollment.carrier_subdomain,
        ),
      );
    }),
);

export const selectActiveNonPendingDirectAppointmentEnrollments =
  createSelector(
    selectAdmittedOnlineDirectAppointmentEnrollments,
    (enrollments) =>
      enrollments.filter(
        (enrollment) =>
          !isActiveSubAppointmentEnrollment(enrollment) &&
          !enrollment.appointment_pending,
      ),
  );

export const selectActiveDirectAppointmentEnrollments = createSelector(
  selectActiveEnrollments,
  (enrollments) =>
    enrollments.filter(
      (enrollment) => enrollment.appointment_type === AppointmentTypes.Direct,
    ),
);

export const selectAdmittedActiveDirectAppointmentEnrollments = createSelector(
  selectActiveDirectAppointmentEnrollments,
  (enrollments) =>
    enrollments.filter(
      (enrollment) => !isOptInQuoteFlowCarrier(enrollment.carrier_labels),
    ),
);

export const selectHasAdmittedActiveDirectAppointmentEnrollments =
  createSelector(
    selectActiveDirectAppointmentEnrollments,
    (enrollments) =>
      !!enrollments.filter(
        (enrollment) => !isOptInQuoteFlowCarrier(enrollment.carrier_labels),
      ).length,
  );

export const selectSubAppointmentEnrollments = createSelector(
  selectAllEnrollments,
  (enrollments: IEnrollment[]) =>
    filterByAvailableAppointmentType(
      enrollments,
      AppointmentTypes.Sub,
    ) as IEnrollment[],
);

export const selectHasSubAppointmentEnrollments = createSelector(
  selectSubAppointmentEnrollments,
  selectEnrollmentsHaveBeenLoaded,
  selectHasEnrollmentsError,
  (subs: IEnrollment[], loaded, error) => ({
    hasSubs: subs.filter((sub) => sub.active).length > 0,
    loaded,
    error,
  }),
);

export const selectActiveOrPreselectedSubAppointmentEnrollments =
  createSelector(selectSubAppointmentEnrollments, (enrollments) =>
    enrollments.filter(
      (enrollment) =>
        !isActiveDirectAppointmentEnrollment(enrollment) &&
        (isActiveSubAppointmentEnrollment(enrollment) ||
          enrollment.preselected),
    ),
  );

export const selectEnrollmentsEligibleForSubAppointments = createSelector(
  selectSubAppointmentEnrollments,
  (enrollments) =>
    enrollments.filter((enrollment) => {
      const hasActiveOrPendingSubAppointment =
        enrollment.active || enrollment.appointment_pending;

      return !hasActiveOrPendingSubAppointment;
    }),
);

export const selectAvailableEnrollments = createSelector(
  selectEnrollmentsState,
  (state) => state.availableEnrollments,
);

export const selectAvailableSubAppointmentChoices = createSelector(
  selectAllAvailableEnrollments,
  (availableEnrollments: IAvailableEnrollment[]) =>
    filterByAvailableAppointmentType(
      availableEnrollments,
      AppointmentTypes.Sub,
    ) as IAvailableEnrollment[],
);

export const selectShowEnrollmentAppointmentDialog = createSelector(
  selectEnrollmentsState,
  (state) => state.showEnrollmentAppointmentDialog,
);

export const selectUpdatingEnrollmentSubAppointments = createSelector(
  selectEnrollmentsState,
  (state) => state.requestingEnrollmentSubappointments,
);

export const selectActivatingEnrollmentDirectAppointments = createSelector(
  selectEnrollmentsState,
  (state) => state.activatingEnrollmentDirectAppointments,
);

export const selectPendingSubAppointmentEnrollments = createSelector(
  selectSubAppointmentEnrollments,
  (subEnrollments) =>
    subEnrollments.filter((e) => e.appointment_pending) as IEnrollment[],
);

export const selectPendingOrActiveSubAppointmentEnrollments = createSelector(
  selectSubAppointmentEnrollments,
  (subEnrollments) =>
    subEnrollments.filter(
      (enrollment) =>
        enrollment.appointment_pending ||
        (enrollment.active &&
          enrollment.appointment_type === AppointmentTypes.Sub),
    ) as IEnrollment[],
);

export const selectInstantActiveSubAppointmentEnrollments = createSelector(
  selectPendingOrActiveSubAppointmentEnrollments,
  (subEnrollments) =>
    subEnrollments.filter(
      (carrier: IEnrollment) => carrier.allow_instant_enrollment,
    ) as IEnrollment[],
);

export const selectManualPendingOrActiveSubAppointmentEnrollments =
  createSelector(
    selectPendingOrActiveSubAppointmentEnrollments,
    (subEnrollments) =>
      subEnrollments.filter(
        (carrier: IEnrollment) => !carrier.allow_instant_enrollment,
      ) as IEnrollment[],
  );
