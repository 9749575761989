import { EmperorUISelectFormChoice } from '@boldpenguin/emperor-form-fields';

export enum BusinessEntityType {
  SoleProp = 'sole_prop',
  CCorp = 'c_corp',
  SCorp = 's_corp',
  Partnership = 'partnership',
  Trust = 'trust',
  LLC = 'llc',
}

// Tax classification values must match forms-engine allowed values
// https://github.com/BoldPenguin/forms-engine/blob/staging/lib/seeds/w9.yml
export const taxClassificationChoices: EmperorUISelectFormChoice[] = [
  {
    id: BusinessEntityType.SoleProp,
    value: BusinessEntityType.SoleProp,
    label: 'Individual / sole proprietor or single member LLC',
  },
  {
    id: BusinessEntityType.CCorp,
    value: BusinessEntityType.CCorp,
    label: 'C Corporation',
  },
  {
    id: BusinessEntityType.SCorp,
    value: BusinessEntityType.SCorp,
    label: 'S Corporation',
  },
  {
    id: BusinessEntityType.Partnership,
    value: BusinessEntityType.Partnership,
    label: 'Partnership',
  },
  {
    id: BusinessEntityType.Trust,
    value: BusinessEntityType.Trust,
    label: 'Trust / Estate',
  },
  {
    id: BusinessEntityType.LLC,
    value: BusinessEntityType.LLC,
    label: 'Limited Liability Company',
  },
];
