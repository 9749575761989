import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { IProductCarrierRanking } from '../../models/product-carrier-ranking';
import { IPolicyCoverageLevel } from '../../models/policy-coverage-level';

export interface ICarrierRankingService {
  /**
   * Retrieves the carrier rankings for each product for the tenant
   */
  getCarrierRankingsForCurrentTenant(): Observable<IProductCarrierRanking[]>;
  /**
   * Updates the carrier rankings with the passed in rankings
   *
   * @param updatedRankings - The new list of rankings
   */
  updateCarrierRankingsForCurrentTenant(
    updatedRankings: IProductCarrierRanking[],
  ): Observable<IProductCarrierRanking[]>;

  getAllPolicyCoverageLevels(): Observable<IPolicyCoverageLevel[]>;

  updateAllPolicyCoverageLevelsForCurrentTenant(
    updatedRankings: IPolicyCoverageLevel[],
  ): Observable<IPolicyCoverageLevel[]>;
}

@Injectable({ providedIn: 'root' })
export class CarrierRankingService implements ICarrierRankingService {
  constructor(private http: HttpClient) {}

  getCarrierRankingsForCurrentTenant(): Observable<IProductCarrierRanking[]> {
    const url = `${environment.apiBaseUri}/carrier_rankings_by_products`;
    return this.http.get<IProductCarrierRanking[]>(url);
  }

  updateCarrierRankingsForCurrentTenant(
    updatedRankings: IProductCarrierRanking[],
  ): Observable<IProductCarrierRanking[]> {
    const url = `${environment.apiBaseUri}/carrier_rankings_by_products`;
    return this.http.post<IProductCarrierRanking[]>(url, {
      rankings: updatedRankings,
    });
  }

  getAllPolicyCoverageLevels(): Observable<IPolicyCoverageLevel[]> {
    const url = `${environment.apiBaseUri}/minimum_coverages`;
    return this.http.get<IPolicyCoverageLevel[]>(url);
  }

  updateAllPolicyCoverageLevelsForCurrentTenant(
    updatedLimits: IPolicyCoverageLevel[],
  ): Observable<IPolicyCoverageLevel[]> {
    const url = `${environment.apiBaseUri}/minimum_coverages`;
    return this.http.post<IPolicyCoverageLevel[]>(url, {
      coverages: updatedLimits,
    });
  }
}
