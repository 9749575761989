import { NaicsDialogChoice } from '../../models/naics-dialog-choice';

export const topChoices: NaicsDialogChoice[] = [
  {
    alias: '561720',
    value: 'Janitorial services',
  },
  {
    alias: '236118',
    value: 'Handyman construction service, residential building',
  },
  {
    alias: '561730',
    value: 'Lawn care services (e.g., fertilizing, mowing, seeding, spraying)',
  },
  { alias: '811111', value: 'General automotive repair shops' },
  {
    alias: '236118',
    value: 'Remodeling and renovating single-family housing',
  },
  { alias: '484220', value: 'Flatbed trucking, local' },
  { alias: '561730', value: 'Landscape care and maintenance services' },
  { alias: '238160', value: 'Roofing contractors' },
  {
    alias: '236118',
    value: 'Home improvement (e.g., adding on, remodeling, renovating)',
  },
  { alias: '238320', value: 'Painting and wallpapering' },
];
