import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import {
  IAnalyticsTrackEventDetail,
  ICoreOptions,
  IUser,
  QuoteRequestRequestTypes,
  SdkStates,
  getViewStateFeatures,
  isFeatureEnabled,
  isSdkConfigured,
  isSdkReady,
  isSdkUserLoaded,
  selectAuthExpiration,
  selectUser,
} from '@boldpenguin/sdk';
import { environment } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  distinctUntilChanged,
  from,
  merge,
  timer,
} from 'rxjs';
import {
  filter,
  first,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import packageInfo from '../../package.json';
import { IExtendedUser } from './core/models/extended-user';
import { AnalyticsService } from './core/services/analytics-service/analytics.service';
import { BpSdkCoreService } from './core/services/bp-sdk-core-service/bp-sdk-core.service';
import { BpSdkStoreService } from './core/services/bp-sdk-store-service/bp-sdk-store.service';
import { ExchangeStatusService } from './core/services/exchange-status/exchange-status.service';
import { HeaderAlert } from './core/services/header-service/header-alerts.service';
import { IntercomService } from './core/services/intercom-service/intercom.service';
import { LogRocketService } from './core/services/log-rocket-service/log-rocket-service';
import {
  MixpanelEventType,
  MixpanelService,
} from './core/services/mixpanel-service/mixpanel.service';
import { Messages } from './core/services/notification-service/notification-messages';
import { NotificationService } from './core/services/notification-service/notification.service';
import { CheckComplianceForm } from './core/store/compliance-form/compliance-form.actions';
import { selectComplianceFormHasBeenChecked } from './core/store/compliance-form/compliance-form.selectors';
import { GetAllEnrollments } from './core/store/enrollments/enrollments.actions';
import {
  selectActiveEnrollments,
  selectEnrollmentsHaveBeenLoaded,
  selectHasEnrollmentsError,
  selectHasSuccessfullyLoadedEnrollments,
} from './core/store/enrollments/enrollments.selectors';
import {
  selectBeforeUnloadHeaderAlerts,
  selectNumberOfHeaderAlerts,
} from './core/store/header-alerts/header-alerts.selectors';
import { Features } from './features/features';
import { NavigationMenuComponent } from './navigation/components/navigation-menu/navigation-menu.component';
import { ProductFeatureType } from './permissions/permissions.model';
import { SetSubdomain } from './root-store/root.actions';
import { selectUrl } from './root-store/state.router';
import { noEnrollmentsDialogData } from './shared/components/warning-dialog/templates/no-enrollments-dialog-data';
import { localStorageKeys } from './shared/constants';
import { getCustomSubdomain } from './shared/utilities/url-utilities';
import { GetOrganizationTenants } from './features/quotes/store/quotes.actions';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild(MatSidenav) sidenav: MatSidenav;
  @ViewChild(NavigationMenuComponent) navMenu: NavigationMenuComponent;

  readonly noEnrollmentsDialogData = noEnrollmentsDialogData;

  complianceFormChecked$: Observable<boolean>;
  topBarHeight$: BehaviorSubject<number> = new BehaviorSubject<number>(64);
  title = 'terminal';
  appVersion = packageInfo.version;
  showEnrollmentsWarningDialog = false;
  isBrowser: boolean;
  isSideNavOpen = false;
  isAlertBannerPresent = false;
  suppressNavMenu$?: Observable<boolean>;

  constructor(
    private router: Router,
    private mixpanelService: MixpanelService,
    private intercomService: IntercomService,
    private bpSdkStoreService: BpSdkStoreService,
    private bpSdkCoreService: BpSdkCoreService,
    private logRocketService: LogRocketService,
    private store: Store,
    private exchangeStatusService: ExchangeStatusService,
    private analyticsService: AnalyticsService,
    private cdRef: ChangeDetectorRef,
    private notificationService: NotificationService,
    @Inject(PLATFORM_ID) platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  get showTopNav(): boolean {
    // TODO: replace this with a more robust solution
    return (
      window.location.href.indexOf(`/${Features.AUTH}/`) === -1 &&
      window.location.href.indexOf(`/${Features.SIGNUP}`) === -1 &&
      window.location.href.indexOf(`/${Features.CONFIRM}`) === -1
    );
  }

  /**
   * Checks if there are HeaderAlerts which prevent the user from leaving the page.
   * If there are `beforeUnload` HeaderAlerts, presents the native browser "Unsaved changes" dialog
   */
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    this.store
      .pipe(select(selectBeforeUnloadHeaderAlerts))
      .pipe(first())
      .subscribe((alerts: HeaderAlert[]) => {
        if (alerts.length > 0) {
          $event.returnValue = false;
        }
      });
  }

  /**
   * Listens for the bpAnalyticsTrack event
   */
  @HostListener('bpAnalyticsTrack', ['$event'])
  onBpAnalyticsTrack(event: CustomEvent<IAnalyticsTrackEventDetail>) {
    this.analyticsService.handleBpAnalyticsTrackEvent(event);
  }

  // TODO: We should find a better place to put our listeners, or at least
  //       a way to put them all in one place
  @HostListener('saveAnswer', ['$event'])
  onAnswerSaved(event: any) {
    this.analyticsService.handleSdkEvent('saveAnswer', event);
  }

  async ngOnInit(): Promise<void> {
    try {
      await this.initBpSdk();
      this.checkForCustomDomain();
      this.initScrollAdjustments();
      this.mixpanelService.init();
      this.mixpanelService.track(MixpanelEventType.TERMINAL_LOADED);
      this.initLoginSuccessSubscriptions();
      this.initTracking();
      this.initSidenavSubscription();
    } catch (e) {
      this.mixpanelService.track(
        MixpanelEventType.TERMINAL_APP_FAILED_TO_INIT,
        { error: e.message || e },
      );
    }
  }

  hideEnrollmentsWarning() {
    this.showEnrollmentsWarningDialog = false;
  }

  private initTracking(): void {
    const sdkUserLoadedState$ = this.bpSdkStoreService.state$.pipe(
      filter(isSdkUserLoaded),
    );
    const userState$ = sdkUserLoadedState$.pipe(
      select(selectUser),
      filter(
        (userInfo): userInfo is IExtendedUser =>
          !!userInfo?.extra?.raw_info?.user_id,
      ),
    );
    const viewStateFeature$ = sdkUserLoadedState$.pipe(
      select(getViewStateFeatures),
      filter((features) => features.isLoaded),
      map((features) => features.list),
    );

    const featuresError$ = sdkUserLoadedState$.pipe(
      select(getViewStateFeatures),
      filter((features) => features.status === SdkStates.Error),
    );

    const enrollmentsError$ = this.store.pipe(
      select(selectHasEnrollmentsError),
      filter(Boolean),
    );

    merge(featuresError$, enrollmentsError$)
      .pipe(
        take(1),
        switchMap(() =>
          this.notificationService
            .error(Messages.ERROR, {
              actionText: 'Refresh Page',
              duration: 0,
            })
            .onAction(),
        ),
        untilDestroyed(this),
      )
      .subscribe(() => {
        window.location.reload();
      });

    combineLatest([userState$, viewStateFeature$])
      .pipe(first(), untilDestroyed(this))
      .subscribe(([userInfo, viewStateFeatures]) => {
        this.store.dispatch(GetAllEnrollments());
        this.setMixpanelUserIdentity(userInfo, viewStateFeatures);
        this.sendIntercomInfo(userInfo);
        this.setSentryUser(userInfo);
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(() => this.intercomService.updateRouteChange());
  }

  private initSidenavSubscription(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        switchMap(() => from(this.sidenav.close())),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private checkForCustomDomain(): void {
    this.store.dispatch(
      SetSubdomain({
        subdomain: getCustomSubdomain(
          window.location.hostname,
          window.location.search,
          environment.envTag,
        ),
      }),
    );
  }

  private initScrollAdjustments(): void {
    const alerts$ = this.store.pipe(select(selectNumberOfHeaderAlerts));

    alerts$.pipe(untilDestroyed(this)).subscribe((numAlerts) => {
      const toolbarHeight = 64;
      this.topBarHeight$.next(toolbarHeight * numAlerts + toolbarHeight);
      this.isAlertBannerPresent = !!numAlerts;
    });
  }

  private initLoginSuccessSubscriptions(): void {
    this.bpSdkStoreService.sdkReadyState$
      .pipe(first(), untilDestroyed(this))
      .subscribe(() => {
        this.exchangeStatusService.init();
      });

    combineLatest([
      this.bpSdkStoreService.sdkReadyState$.pipe(first()),
      this.store.pipe(
        select(selectHasSuccessfullyLoadedEnrollments),
        filter(Boolean),
        first(),
      ),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.store.dispatch(CheckComplianceForm());
        this.store.dispatch(GetOrganizationTenants());
      });

    // Wait to show a loader while we check compliance form
    // until we're signed in. Assumes expired sessions will
    // refresh page.
    this.complianceFormChecked$ = combineLatest([
      this.store.pipe(select(selectComplianceFormHasBeenChecked)),
      this.bpSdkStoreService.state$.pipe(select(isSdkConfigured)),
      this.bpSdkStoreService.state$.pipe(select(isSdkReady)),
    ]).pipe(
      map(([complianceFormHasBeenChecked, sdkConfigured, sdkAuthReady]) => {
        if (sdkConfigured && !sdkAuthReady) {
          return true;
        } else if (sdkAuthReady) {
          return complianceFormHasBeenChecked;
        }
        return false;
      }),
      distinctUntilChanged(),
      tap(() => this.cdRef.detectChanges()),
    );

    this.suppressNavMenu$ = combineLatest([
      this.complianceFormChecked$,
      this.store.pipe(select(selectUrl)),
    ]).pipe(
      map(
        ([complianceFormChecked, url]) =>
          !complianceFormChecked || url?.includes(Features.WELCOME),
      ),
      distinctUntilChanged(),
    );

    // Before we init logrocket, we want to make sure that
    // the user has the related feature flag. Because the SDK has to be ready
    // before the features are loaded, we don't need to check to confirm the SDK is ready.
    // Also, because a user has to be authenticated
    // before feature flags are loaded, we know
    // that the SDK will have the user info, so we can just
    // select it from the store and use it when setting up log rocket
    this.bpSdkStoreService.sdkReadyState$
      .pipe(
        filter((state) => !!state.viewState.features.isLoaded), // we have to do it this way because we don't want to select the features state if we want to use `isFeatureEnabled`
        filter((state) =>
          isFeatureEnabled(state, ProductFeatureType.LogrocketSessions),
        ),
        select(selectUser),
        first(),
        untilDestroyed(this),
      )
      .subscribe((userInfo) => {
        if (userInfo?.extra?.raw_info?.user_id) {
          this.logRocketService.init();
          this.setLogRocketUser(userInfo);
        }
      });

    // When a user session expires, we want to log out so their session
    // remains secure. Guards handle route navigation, HTTP interceptor
    // handles failed network requests (401). This subscription handles
    // an idle page beyond expiration.
    this.bpSdkStoreService.sdkReadyState$
      .pipe(
        select(selectAuthExpiration),
        switchMap((expiresAtMs) => timer(new Date(expiresAtMs))),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.mixpanelService.track(MixpanelEventType.SESSION_EXPIRED);
        this.bpSdkCoreService.logout();
      });
  }

  private async initBpSdk(): Promise<void> {
    await this.bpSdkCoreService.init({
      debug:
        environment.bpSdkDebugging ||
        !!localStorage.getItem(localStorageKeys.sdkDebugging),
      clientId: environment.clientId,
      authUri: environment.apiAuthUri,
      env: environment.sdkEnvTag,
      wsBaseUri: environment.wsBaseUri,
      uri: environment.apiBaseUri,
      googleApiToken: environment.googleApiKey,
      options: {
        quoteStatusFilter: [
          QuoteRequestRequestTypes.completed,
          QuoteRequestRequestTypes.failed,
          QuoteRequestRequestTypes.referral,
        ],
        bcsChoiceLimit: 7,
        enableWebSockets: true,
        enableUserApplicationGuard: true,
        showParentQuestionSetsInNavigation: true,
        enableAddressValidation: true,
        addressValidationOptions: {
          includePartialQuestionCodes: ['_address_'],
          excludePartialQuestionCodes: ['_vehicle_'],
        },
      },
    } as ICoreOptions);

    this.bpSdkStoreService.init();
  }

  private sendIntercomInfo(userInfo: IExtendedUser): void {
    const intercomUserData =
      this.intercomService.convertUserToIntercomUser(userInfo);
    this.intercomService.bootWithUserData(intercomUserData);

    // Get the number of active enrollments to send to intercom
    this.store
      .pipe(
        select(selectEnrollmentsHaveBeenLoaded),
        filter(Boolean),
        first(),
        withLatestFrom(this.store.pipe(select(selectActiveEnrollments))),
        map(([, activeEnrollments]) => activeEnrollments.length),
        untilDestroyed(this),
      )
      .subscribe((activeEnrollmentsCount) => {
        this.intercomService.updateNumberOfEnrollments(activeEnrollmentsCount);
        if (
          activeEnrollmentsCount === 0 &&
          this.router.url.includes('quotes/dashboard')
        ) {
          this.showEnrollmentsWarningDialog = true;
        }
      });
  }

  private setMixpanelUserIdentity(
    userInfoParam: IUser,
    viewStateFeatures: string[],
  ): void {
    const userInfo = userInfoParam.extra.raw_info;
    // If we receive new user info from auth, update the mixpanel to associate
    // all future events with the user id
    this.mixpanelService.identify(userInfo.user_id);

    // Set the user profile from the userInfo
    this.mixpanelService.setUserProfileProperties({
      $email: userInfo.email,
      $first_name: userInfo.first_name || 'Unknown',
      $last_name: userInfo.last_name || 'Unknown',
      tenant_id: userInfo.tenant_id || '',
      tenant_name: userInfo.tenant_name || '',
      completed_steps: userInfo.completed_steps || [],
      plan_sku: userInfo.plan_sku || 'Unknown',
    });

    // Register some super properties so every event has these on them
    this.mixpanelService.registerSuperProperties({
      user_id: userInfo.user_id,
      user_email: userInfo.email,
      tenant_id: userInfo.tenant_id || '',
      tenant_name: userInfo.tenant_name || '',
      plan_sku: userInfo.plan_sku || 'Unknown',
      view_state_features: viewStateFeatures,
    });
  }

  private setSentryUser({ extra: { raw_info } }: IUser): void {
    Sentry.setUser({
      id: raw_info.user_id,
      email: raw_info.email,
      username: `${raw_info.first_name} ${raw_info.last_name}`,
    });
  }

  private setLogRocketUser({ extra: { raw_info } }: IUser): void {
    this.logRocketService.identify(raw_info.user_id, {
      email: raw_info.email,
      username: `${raw_info.first_name} ${raw_info.last_name}`,
    });
  }
}
