import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

// Note: Terminal falls back to `<title>Bold Penguin Terminal</title>` in
// `index.html` if title is not set in route config
@Injectable()
export class TerminalTitleStrategy extends TitleStrategy {
  title = inject(Title);

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title) {
      this.title.setTitle(`${title} - Bold Penguin Terminal`);
    }
  }
}
