import { Directive, ElementRef, HostListener } from '@angular/core';
import { PhoneNumberPipe } from '../../pipes/phone-number/phone.pipe';

@Directive({
  selector: '[appPhoneNumber]',
  providers: [PhoneNumberPipe],
})
export class PhoneNumberDirective {
  inputEl: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private phoneNumber: PhoneNumberPipe,
  ) {}

  @HostListener('keypress', ['$event'])
  onKeyPress(event) {
    // Need to accommodate emperor-text-input component as well
    if (this.elementRef.nativeElement.tagName.toLowerCase() === 'input') {
      this.inputEl = this.elementRef.nativeElement;
    } else {
      this.inputEl = this.elementRef.nativeElement.querySelector(
        'input',
      ) as HTMLInputElement;
    }

    // Stop accepting input after 14 characters
    if (this.inputEl.value.length >= 14) {
      event.preventDefault();
      return;
    } else {
      this.formatPhoneNumber();
    }
  }

  formatPhoneNumber() {
    const formattedValued = this.phoneNumber.transform(this.inputEl.value);
    this.inputEl.value = formattedValued;
  }
}
