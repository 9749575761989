import { createAction, props } from '@ngrx/store';

import { HeaderAlert } from '../../services/header-service/header-alerts.service';
import { HeaderAlertButton } from '../../services/header-service/header-alerts.service';

export const AddHeaderAlert = createAction(
  '[Header Alerts] - ADD_HEADER_ALERT',
  props<{ headerAlert: HeaderAlert }>(),
);

export const CancelHeaderAlert = createAction(
  '[Header Alerts] - CANCEL_HEADER_ALERT',
  props<{ headerAlert: HeaderAlert }>(),
);

export const ConfirmHeaderAlert = createAction(
  '[Header Alerts] - CONFIRM_HEADER_ALERT',
  props<{ headerAlert: HeaderAlert }>(),
);

export const HeaderAlertButtonClick = createAction(
  '[Header Alerts] - HEADER_ALERT_BUTTON_CLICK',
  props<{ alert: HeaderAlert; button: HeaderAlertButton }>(),
);
