import { RouterReducerState, getRouterSelectors } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getLastSegmentFromUrl } from '../features/reports/utilities/url-utilities';

export interface State {
  router: RouterReducerState<any>;
}

export const selectRouter =
  createFeatureSelector<RouterReducerState<any>>('router');

export const {
  selectCurrentRoute, // select the current route
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = getRouterSelectors(selectRouter);

export const selectLastSegmentFromUrl = createSelector(selectUrl, (url) =>
  getLastSegmentFromUrl(url),
);
