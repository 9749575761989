import { CarrierLabel } from '@boldpenguin/sdk';

export const carrierSubdomainMap: { [subdomain: string]: string } = {
  pieoffplatform: 'piecarrier',
};

/**
 * Both "isAdditionalMarketCarrier" and "isOptInQuoteFlowCarrier" are replacing the functionality that was handled in "isAdditionalMarketCarrier".
 * The purpose of breaking the two out is to support different carrier who want to be "opt in" without showing in "Additional Markets".
 * Both function have backward support for "NON_ADMITTED". Doing this insures Pathpoint continues to work.
 *
 * @isAdditionalMarketCarrier should be used when determining if a carrier should be displayed in the "Addition Markets" section in the UI.
 * @isOptInQuoteFlowCarrier should be used when determining if a carrier should go through the "opt in" carrier workflow.
 */

export const isAdditionalMarketCarrier = (
  labels: CarrierLabel[] = [],
): boolean => {
  //TODO: Remove CarrierLabel.NON_ADMITTED after backend work is complete for Pathpoint
  const additionalMarketLabels = [
    CarrierLabel.NON_ADMITTED,
    CarrierLabel.ADDITIONAL_MARKET,
  ];
  return labels.some((label) => additionalMarketLabels.includes(label));
};

export const isOptInQuoteFlowCarrier = (
  labels: CarrierLabel[] = [],
): boolean => {
  //TODO: Remove CarrierLabel.NON_ADMITTED after backend work is complete for Pathpoint
  const additionalMarketLabels = [
    CarrierLabel.NON_ADMITTED,
    CarrierLabel.OPT_IN_QUOTE_FLOW,
  ];
  return labels.some((label) => additionalMarketLabels.includes(label));
};

/**
 * Determines if 2 subdomains are equal
 *
 * @param subdomain1
 * @param offlineSubdomain
 * @returns Returns true if subdomains match, else false
 */
export const hasSameName = (subdomain1, subdomain2): boolean =>
  subdomain1 === subdomain2;

/**
 * Determines if an offline and online subdomain pair overlaps
 *
 * @param onlineSubdomain
 * @param offlineSubdomain
 * @returns Returns true if subdomains overlap, else false
 */
export const hasSameOfflineName = (
  onlineSubdomain,
  offlineSubdomain,
): boolean => {
  if (carrierSubdomainMap[offlineSubdomain]) {
    return carrierSubdomainMap[offlineSubdomain] === onlineSubdomain;
  }
  return `${onlineSubdomain}offplatform` === offlineSubdomain;
};
