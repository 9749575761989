import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Messages } from 'src/app/core/services/notification-service/notification-messages';
import { NotificationService } from 'src/app/core/services/notification-service/notification.service';
import { CarrierRankingService } from '../../services/carrier-ranking/carrier-ranking.service';
import * as ProductRankingActions from './product-ranking.actions';

@Injectable()
export class ProductRankingEffects {
  loadCarrierRankings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductRankingActions.LoadProductRankingsForTenant),
      mergeMap(() =>
        this.carrierRankingService.getCarrierRankingsForCurrentTenant().pipe(
          map((rankings) =>
            // Pull off and fire an action to indicate we loaded the application form for our app
            ProductRankingActions.StoreProductRankingsForTenant({
              rankings,
            }),
          ),
          catchError((e: { error: Error }) =>
            of(ProductRankingActions.LoadProductRankingsForTenantError(e)),
          ),
        ),
      ),
    ),
  );

  updateProductRankings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductRankingActions.UpdateProductRankings),
      mergeMap((action) =>
        this.carrierRankingService
          .updateCarrierRankingsForCurrentTenant(action.rankings)
          .pipe(
            map(() => {
              this.notifService.success(
                Messages.PRODUCT_RANKINGS_UPDATE_SUCCESS,
              );
              return ProductRankingActions.UpdateProductSuccess();
            }),
            catchError((err) => {
              this.notifService.success(
                Messages.PRODUCT_RANKINGS_UPDATE_SUCCESS,
              );
              return of(
                ProductRankingActions.UpdateProductRankingsError({
                  error: err,
                }),
              );
            }),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private carrierRankingService: CarrierRankingService,
    private notifService: NotificationService,
  ) {}
}
