export enum SettingsSubFeatures {
  USERS = 'users',
  CARRIER_APPOINTMENTS = 'carrier-appointments',
  CLIENT_QUOTE_START = 'client-quote-start',
  COMPLIANCE_FORM = 'compliance-form',
  SUB_APPOINTMENT_COMPLIANCE_FORM = 'carrier-appointments/compliance-form',
  // DO NOT CHANGE path below. This is hardcoded in authenticator to send callbacks to partners.
  CARRIER_APPOINTMENTS_CALLBACK = 'carrier-appointments/:enrollment_id/callback',
  CARRIER_SETTINGS = 'carrier-settings',
  RECOMMENDED_CARRIERS = 'recommended-carriers',
}
