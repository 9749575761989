/**
 * These are the buttons/text in the left hand nav of the application dashboard
 */
export enum DashboardNavigationButtons {
  All = 'All',
  New = 'New',
  Open = 'Open',
  Done = 'Done',
  Archive = 'Archive',
}
