<emperor-dialog
  [backdropDismiss]="isFirstTimeWizard"
  [uiDialogSize]="EmperorDialogSize.Medium"
  (close)="handleCancelDialog()"
>
  <emperor-dialog-header
    [title]="
      isFirstTimeWizard
        ? 'Direct carrier connection'
        : 'Add carrier appointment'
    "
    [shouldShowClose]="isFirstTimeWizard"
  ></emperor-dialog-header>

  <!-- Dialog Content -->
  <emperor-card-content [scrollable]="true">
    <ng-container *ngIf="enrollment$ | async as enrollment">
      <div class="carrier-info">
        <!-- Logo -->
        <div *ngIf="enrollment.logo_path as logoUrl" class="logo">
          <app-image [src]="logoUrl" [mixpanelEventType]="logErrorEvent">
          </app-image>
        </div>

        <div>
          <h2 class="name">{{ enrollment.carrier_name }}</h2>
          <div class="products">
            {{ enrollment.carrier_products | productNames | coverageAbbr }}
          </div>
          <!-- Appointment Type Radio -->
          <emperor-radio-button
            *ngIf="
              !isFirstTimeWizard &&
              appointmentOptionsToShow === appointmentOptions.Both
            "
            class="inline-radios appointment-type-radios"
            [choices]="appointmentTypeChoices"
            [formControl]="appointmentType"
            label="Appointment type"
          ></emperor-radio-button>
        </div>
      </div>

      <!-- Direct Appointment Override -->
      <ng-container *ngIf="isDirectAppointment(); else subAppointment">
        <ng-container *ngIf="serverErrorMessages$ | async as errorInfo">
          <div *ngIf="errorInfo.hasErrors" class="server-errors">
            <mat-icon>warning</mat-icon>
            <div *ngFor="let message of errorInfo.errors">
              {{ message }}
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            directOverrides[enrollment.carrier_subdomain] !== undefined;
            else normalDirect
          "
        >
          <div>
            <h3 class="override-sub-header">
              {{ directOverrides[enrollment.carrier_subdomain].subHeader }}
            </h3>
            <div
              [innerHtml]="
                directOverrides[enrollment.carrier_subdomain].dialogBodyHTML
              "
            ></div>
          </div>
        </ng-container>

        <!-- Form fields -->
        <ng-template #normalDirect>
          <form [formGroup]="form">
            <div *ngFor="let metadata of formControlMetadata">
              <emperor-text-input
                [formControlName]="metadata.formControlName"
                [type]="metadata.inputType"
                [label]="metadata.label"
                [hint]="metadata.hint"
                [errorText]="metadata.errorText"
              ></emperor-text-input>
            </div>
          </form>
        </ng-template>
      </ng-container>
      <ng-template #subAppointment>
        <p>
          Bold Penguin is happy to fulfill your sub-appointment request. You
          will receive a confirmation email as soon as your sub-appointment has
          been activated.
        </p>
      </ng-template>
    </ng-container>
  </emperor-card-content>
  <!-- Footer Buttons -->
  <emperor-card-footer
    *ngIf="enrollment$ | async as enrollment"
    class="card-footer"
  >
    <emperor-button
      emperorDialogClose
      class="cancel"
      [uiButtonType]="EmperorUIButtonTypes.Text"
      >CANCEL</emperor-button
    >
    <emperor-button
      (click)="onSubmitClick(enrollment)"
      [disabled]="isSubmitDisabled(enrollment)"
      >{{ getSubmitText(enrollment) | uppercase }}</emperor-button
    >
  </emperor-card-footer>
</emperor-dialog>
