import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-agent-avatar',
  templateUrl: './agent-avatar.component.html',
  styleUrls: ['./agent-avatar.component.scss'],
})
export class AgentAvatarComponent implements OnInit {
  @Input() banner = true;
  @Input() firstName = 'B';
  @Input() lastName = 'P';

  constructor() {}

  get first(): string {
    return !!this.firstName ? this.firstName.substring(0, 1) : 'B';
  }

  get last(): string {
    return !!this.lastName ? this.lastName.substring(0, 1) : 'P';
  }

  ngOnInit(): void {}
}
