import { ScrollingModule } from '@angular/cdk/scrolling';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule,
  NgZone,
} from '@angular/core';

import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SortablejsModule } from 'ngx-sortablejs';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AngularResizeEventModule } from 'angular-resize-event';
import { MarkdownModule } from 'ngx-markdown';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppErrorHandler } from './app.error';
import { CoreModule } from './core/core.module';
import { EnrollmentsService } from './core/services/enrollments-service/enrollments.service';
import { IntercomService } from './core/services/intercom-service/intercom.service';
import { NavigationModule } from './navigation/navigation.module';
import { RootStateEffects } from './root-store/root.effects';
import { ComponentTrackingEffects } from './root-store/component-tracking/component-tracking.effects';
import { RootReducer } from './root-store/root.reducers';
import { KonamiDirective } from './shared/directive/konami/konami.directive';
import { httpInterceptorProviders } from './shared/http-interceptors';
import { SharedModule } from './shared/shared.module';

const provideErrorHandler = (zone: NgZone) => {
  if (environment.production) {
    return new AppErrorHandler(zone);
  } else {
    return new ErrorHandler();
  }
};

const initializeIntercomFactory = (intercomService: IntercomService) => () =>
  intercomService.loadIntercomScript();

@NgModule({
  declarations: [AppComponent, KonamiDirective],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    ScrollingModule,
    NavigationModule,
    MatSidenavModule,
    AngularResizeEventModule,
    NgxWebstorageModule.forRoot(),
    StoreModule.forRoot({ router: routerReducer, root: RootReducer }, {}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([RootStateEffects, ComponentTrackingEffects]),
    SortablejsModule.forRoot({ animation: 150 }),
    BrowserAnimationsModule,
    MarkdownModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [
    { provide: ErrorHandler, useFactory: provideErrorHandler, deps: [NgZone] },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', hideRequiredMarker: true },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeIntercomFactory,
      deps: [IntercomService],
      multi: true,
    },
    EnrollmentsService,
    httpInterceptorProviders,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
