import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { Invoice } from '../models/invoices';
import {
  ILedgerServiceCustomerResponse,
  IPaymentMethodResponse,
} from '../models/payment-methods';
import { PaymentMethod } from '@stripe/stripe-js';
import { IPlansResponse } from '../models/plans';
import { IPromotionCode, IDiscountResponse } from '../models/coupons';
import { IBankAccountResponse } from '../models/bank-account.model';
import {
  IBillingPortalRequest,
  IBillingPortalResponse,
} from '../models/billing-portals';

export interface ILedgerService {
  getInvoices(): Observable<{
    data: Invoice[];
    has_more: boolean;
    url: string;
  }>;
  getPaymentMethods(): Observable<IPaymentMethodResponse>;
  addPaymentMethod(
    paymentMethod: PaymentMethod,
    makeDefault?: boolean,
  ): Observable<string>;
  deletePaymentMethod(paymentMethodId: string): Observable<string>;
  updateDefaultPaymentMethod(id: string): Observable<any>;
  getCustomerDetails(): Observable<ILedgerServiceCustomerResponse>;
  getPlansProducts(): Observable<IPlansResponse>;
  getBillingPortal(
    request: IBillingPortalRequest,
  ): Observable<IBillingPortalResponse>;
  getCoupons(): Observable<IDiscountResponse>;
  getPromotionCode(code: string): Observable<IPromotionCode>;
  addCouponToSubscription(couponId: string): Observable<any>;
  getBankAccount(): Observable<IBankAccountResponse>;
  saveBankAccount(publicToken: string): Observable<void>;
  removeBankAccount(): Observable<void>;
}

@Injectable({
  providedIn: 'root',
})
export class LedgerService implements ILedgerService {
  constructor(private http: HttpClient) {}

  getBillingPortal({ return_url }): Observable<IBillingPortalResponse> {
    const url = `${environment.ledgerUri}/api/billing_portals`;
    return this.http.post<IBillingPortalResponse>(url, {
      return_url,
    });
  }

  getInvoices(): Observable<{
    data: Invoice[];
    has_more: boolean;
    url: string;
  }> {
    const url = `${environment.ledgerUri}/api/invoices`;
    return this.http.get<{ data: Invoice[]; has_more: boolean; url: string }>(
      url,
    );
  }

  getPaymentMethods(): Observable<IPaymentMethodResponse> {
    const url = `${environment.ledgerUri}/api/payment_methods`;
    return this.http.get<IPaymentMethodResponse>(url);
  }

  addPaymentMethod(
    paymentMethod: PaymentMethod,
    makeDefault?: boolean,
  ): Observable<string> {
    const url = `${environment.ledgerUri}/api/payment_methods`;
    return this.http.post(
      url,
      { payment_method_id: paymentMethod.id, mark_default: !!makeDefault },
      { responseType: 'text' },
    );
  }

  deletePaymentMethod(paymentMethodId: string): Observable<string> {
    const url = `${environment.ledgerUri}/api/payment_methods/${paymentMethodId}`;
    return this.http.delete(url, {
      responseType: 'text',
    });
  }

  updateDefaultPaymentMethod(id: string): Observable<any> {
    const url = `${environment.ledgerUri}/api/payment_methods/${id}`;
    return this.http.patch(url, {});
  }

  getCustomerDetails(): Observable<ILedgerServiceCustomerResponse> {
    const url = `${environment.ledgerUri}/api/customer`;
    return this.http.get<ILedgerServiceCustomerResponse>(url);
  }

  getPlansProducts(): Observable<IPlansResponse> {
    const url = `${environment.ledgerUri}/api/prices`;
    return this.http.get<IPlansResponse>(url);
  }

  getCoupons(): Observable<IDiscountResponse> {
    const url = `${environment.ledgerUri}/api/coupons`;
    return this.http.get<IDiscountResponse>(url);
  }

  getPromotionCode(code: string): Observable<IPromotionCode> {
    const url = `${environment.ledgerUri}/api/coupons/${code}`;
    return this.http.get<IPromotionCode>(url);
  }

  addCouponToSubscription(couponId: string): Observable<string> {
    const url = `${environment.ledgerUri}/api/coupons/`;
    return this.http.post(url, { id: couponId }, { responseType: 'text' });
  }

  getBankAccount(): Observable<IBankAccountResponse> {
    const url = `${environment.ledgerUri}/api/customer/bank_account`;
    return this.http.get<IBankAccountResponse>(url);
  }

  saveBankAccount(publicToken): Observable<void> {
    const url = `${environment.ledgerUri}/api/customer/bank_account`;
    return this.http.put<void>(url, { public_token: publicToken });
  }

  removeBankAccount(): Observable<void> {
    const url = `${environment.ledgerUri}/api/customer/bank_account`;
    return this.http.delete<void>(url);
  }
}
