import { ComplianceFormQuestionGroup } from 'src/app/core/models/compliance-form.model';
import { MixpanelEventType } from 'src/app/core/services/mixpanel-service/mixpanel.service';

export enum WelcomeStep {
  DIRECT_APPOINTMENTS = 'direct-appointments',
  SUB_APPOINTMENTS = 'sub-appointments',
  COMPLIANCE_FORM = 'compliance-form',
  AGENCY_INFO = 'agency-info',
  MARKEL_COMPLIANCE = 'markel-compliance',
  STATE_AUTO_COMPLIANCE = 'state-auto-compliance',
  WESTFIELD_COMPLIANCE = 'westfield-compliance',
  EO_UPLOAD = 'eo-upload',
  ACKNOWLEDGEMENT = 'acknowledgement',
  PAYMENTS = 'payments',
  COMPLETED = 'completed',
  APPOINTMENTS_COMPLETED = 'appointments-completed',
}
export interface IWelcomeStep {
  id: WelcomeStep;
  order?: number;
  text?: string;
  children?: IWelcomeStep[];
  uiOnly?: boolean;
  trackingOnly?: boolean;
}

export interface ErrorsAndOmissionsDocument {
  name: string;
  size: number;
}

export const enrollmentSubdomainCollection = [
  ComplianceFormQuestionGroup.MARKEL,
  ComplianceFormQuestionGroup.STATE_AUTO,
  ComplianceFormQuestionGroup.WESTFIELD,
];

export const carrierComplianceSteps = [
  WelcomeStep.MARKEL_COMPLIANCE,
  WelcomeStep.STATE_AUTO_COMPLIANCE,
  WelcomeStep.WESTFIELD_COMPLIANCE,
];

// Steps that are conditionally included
// post-sub-appointments step, if user
// has selected any sub-appointment enrollments

export const agencyInfoStep: Array<IWelcomeStep> = [
  {
    id: WelcomeStep.AGENCY_INFO,
    text: 'Agency & principal information',
  },
];

export const endingEOAcknowledgementSteps: Array<IWelcomeStep> = [
  {
    id: WelcomeStep.EO_UPLOAD,
    text: 'Upload your E&O declaration',
  },
  {
    id: WelcomeStep.ACKNOWLEDGEMENT,
    text: 'Acknowledgment',
  },
];

export const complianceWestfieldStep: IWelcomeStep = {
  id: WelcomeStep.WESTFIELD_COMPLIANCE,
  text: 'Westfield questionnaire',
};

export const complianceMarkelStep: IWelcomeStep = {
  id: WelcomeStep.MARKEL_COMPLIANCE,
  text: 'Markel questionnaire',
};

export const complianceStateAutoStep: IWelcomeStep = {
  id: WelcomeStep.STATE_AUTO_COMPLIANCE,
  text: 'State Auto questionnaire',
};

export const carrierAdditionalQuestionStep = new Map<
  ComplianceFormQuestionGroup,
  IWelcomeStep
>([
  [ComplianceFormQuestionGroup.MARKEL, complianceMarkelStep],
  [ComplianceFormQuestionGroup.STATE_AUTO, complianceStateAutoStep],
  [ComplianceFormQuestionGroup.WESTFIELD, complianceWestfieldStep],
]);

export const carrierAdditionalQuestionTrackingEvent = new Map<
  ComplianceFormQuestionGroup,
  MixpanelEventType
>([
  [
    ComplianceFormQuestionGroup.MARKEL,
    MixpanelEventType.COMPLIANCE_MARKEL_QUESTIONS_ACTIVE,
  ],
  [
    ComplianceFormQuestionGroup.STATE_AUTO,
    MixpanelEventType.WELCOME_STATEAUTO_QUESTIONS_ACTIVE,
  ],
  [
    ComplianceFormQuestionGroup.WESTFIELD,
    MixpanelEventType.COMPLIANCE_WESTFIELD_QUESTIONS_ACTIVE,
  ],
]);
