import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnInit {
  @Input() isFullScreen = false;
  @Input() diameter = 100; // 100 is the default diameter for angular material's spinner
  @Input() value = 5; // Optional, only required if this is a progress-type spinner
  @Input() isProgress?: boolean;

  constructor() {}

  ngOnInit(): void {}
}
