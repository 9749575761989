import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  IAvailableEnrollment,
  IEnrollment,
} from 'src/app/core/models/enrollments.model';

const sortAlphabetically = (
  item1: IAvailableEnrollment | IEnrollment,
  item2: IAvailableEnrollment | IEnrollment,
  sortKey: string,
): number => {
  const nameA = item1[sortKey]?.toUpperCase();
  const nameB = item2[sortKey]?.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  return nameA > nameB ? 1 : 0;
};

export default interface EnrollmentsState extends EntityState<IEnrollment> {
  enrollmentsHaveBeenLoaded: boolean;
  enrollmentsError?: Error;
  availableEnrollments: EntityState<IAvailableEnrollment>;
  requestingEnrollmentSubappointments: boolean;
  requestingEnrollmentSubappointmentsError?: Error;
  activatingEnrollmentDirectAppointments: boolean;
  showEnrollmentAppointmentDialog: boolean;
}

export const enrollmentsStateKey = 'enrollments';

export const EnrollmentsAdapter: EntityAdapter<IEnrollment> =
  createEntityAdapter<IEnrollment>({
    sortComparer: (item1: IEnrollment, item2: IEnrollment) =>
      sortAlphabetically(item1, item2, 'carrier_name'),
  });

export const AvailableEnrollmentsAdapter: EntityAdapter<IAvailableEnrollment> =
  createEntityAdapter<IAvailableEnrollment>({
    selectId: (availableEnrollment: IAvailableEnrollment) =>
      availableEnrollment.carrier_id,
    sortComparer: (item1: IAvailableEnrollment, item2: IAvailableEnrollment) =>
      sortAlphabetically(item1, item2, 'name'),
  });

export const enrollmentsInitialState: EnrollmentsState =
  EnrollmentsAdapter.getInitialState({
    enrollmentsHaveBeenLoaded: false,
    requestingEnrollmentSubappointments: false,
    availableEnrollments: AvailableEnrollmentsAdapter.getInitialState(),
    activatingEnrollmentDirectAppointments: false,
    showEnrollmentAppointmentDialog: false,
  });
