import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  EmperorCardHeaderColor,
  EmperorUIButtonTypes,
} from '@boldpenguin/emperor-presentational';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-explore-exchange-dialog',
  templateUrl: './explore-exchange-dialog.component.html',
  styleUrls: ['./explore-exchange-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExploreExchangeDialogComponent {
  readonly EmperorCardHeaderColor = EmperorCardHeaderColor;
  readonly EmperorUIButtonTypes = EmperorUIButtonTypes;
  readonly supportEmail = `mailto:support@boldpenguin.com?subject=${encodeURIComponent(
    'Learn more about Bold Penguin Exchange',
  )}`;
}
