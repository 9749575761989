<div class="carriers" *ngIf="loaded; else loading">
  <ng-container *ngFor="let carrierFc of controls; trackBy: trackByCarrierId">
    <emperor-checkbox
      *ngIf="carrierFc.carrier"
      class="emperor-ui-full-width"
      [formControl]="carrierFc | formControl"
      [customLabel]="true"
      [ariaLabel]="carrierFc.carrier.name"
      ariaDescribedBy="{{ carrierFc.carrier.carrier_subdomain }}-products {{
        carrierFc.carrier.carrier_subdomain
      }}-messages"
      [attr.data-testid]="
        carrierFc.carrier.carrier_subdomain + '-appointment-choice'
      "
      (click)="trackCheckboxClick(carrierFc.carrier)"
      noSubscript
    >
      <div class="checkbox-header">
        <ng-container
          [ngTemplateOutlet]="
            carrierFc.carrier.logo_url ? carrierLogo : carrierName
          "
          [ngTemplateOutletContext]="{
            name: carrierFc.carrier.name,
            logo: carrierFc.carrier.logo_url
          }"
        ></ng-container>
        <div
          class="products"
          [id]="carrierFc.carrier.carrier_subdomain + '-products'"
        >
          {{ carrierFc.carrier.products | productNames | coverageAbbr }}
        </div>
      </div>
      <ng-container
        *ngIf="(carrierMessages$ | async)?.[carrierFc.carrier.carrier_subdomain] as carrierMessages"
      >
        <div *ngIf="carrierFc.value" class="carrier-information">
          <hr class="divider" />
          <div class="message-container">
            <p
              *ngFor="let carrierMessage of carrierMessages"
              class="message mat-body-2"
              [id]="carrierFc.carrier.carrier_subdomain + '-messages'"
              [innerHTML]="carrierMessage.message"
            ></p>
          </div>
        </div>
      </ng-container>
    </emperor-checkbox>
  </ng-container>
</div>

<!-- TEMPLATES -->
<ng-template #carrierName let-name="name">
  <div id="name" class="name" *ngIf="name" [title]="name">
    {{ name }}
  </div>
</ng-template>

<ng-template #carrierLogo let-name="name" let-logo="logo">
  <app-image
    class="logo"
    [src]="logo"
    [alt]="name"
    [caption]="name"
    [mixpanelEventType]="logoErrorMixpanelEvent"
  >
  </app-image
></ng-template>

<ng-template #loading>
  <emperor-spinner [diameter]="50"></emperor-spinner>
</ng-template>
