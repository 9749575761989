import { SortOrder } from 'src/app/shared/models/sort-order.model';
import { DashboardNavigationButtons } from './application-list-nav-buttons.model';

/**
 * Possible values for app_state in an application_form
 */
export enum FormFilterAppStates {
  All = 'all',
  InComplete = 'incomplete',
  InProgress = 'in_progress',
  Completed = 'completed',
  Quoted = 'quoted',
}

/**
 * Possible app owners that aren't just a user guid
 */
export enum FormFilterGenericAppOwners {
  Unassigned = 'unassigned',
  CurrentUser = 'current_user',
  NotCurrentUser = 'not_current_user',
}

/**
 * Where the app originated from
 */
export enum FormFilterOrigins {
  Exchange = 'exchange',
  None = 'none',
}

/**
 * Disposition indicates an application form is
 * complete and how it resolved.
 *
 * None - no disposition status, e.g. active appliation forms
 * Dispositioned - all application products are dispositioned as either "bound" or "not_bound"
 * Bound - application product either marked as "Bound elsewhere" (old) or bound with a carrier incl "Other" (new)
 * Not Bound - applications product either marked as "Did not bind" (old) or "None" (new)
 */
export enum FormFilterDispositions {
  None = 'none',
  Dispositioned = 'dispositioned',
  Bound = 'bound',
  NotBound = 'not_bound',
}

export interface IFormFilters {
  /**
   * App form Search string
   */
  q: string | null;
  page: number;
  order: SortOrder;
  app_state: FormFilterAppStates[];
  app_owner: FormFilterGenericAppOwners[];
  app_created_at_gt?: string | null;
  archived: boolean;
  disposition?: FormFilterDispositions[];
  include_quick_view?: boolean;
  origin: FormFilterOrigins[];
  app_tenant_ids: string[];
}

/**
 * Represents the default search to grab 'every' application form from the application form search endpoint
 */
export class FormFilters implements IFormFilters {
  q = null;
  page = 1;
  order = SortOrder.Desc;
  app_state = [];
  app_owner = [];
  // TODO: do apply a default disposition once old application dashboard is gone - SG 20210831
  // disposition = [FormFilterDispositions.None];
  archived = false;
  origin = [];
  include_quick_view = true;
  app_tenant_ids = [];

  constructor(init?: Partial<IFormFilters>) {
    Object.assign(this, init);
  }
}

export const NavButtonFilterMap: {
  [key in DashboardNavigationButtons]: Partial<IFormFilters>;
} = {
  [DashboardNavigationButtons.All]: new FormFilters({}),
  [DashboardNavigationButtons.New]: new FormFilters({
    app_owner: [FormFilterGenericAppOwners.Unassigned],
    disposition: [FormFilterDispositions.None],
  }),
  [DashboardNavigationButtons.Open]: new FormFilters({
    app_owner: [
      FormFilterGenericAppOwners.CurrentUser,
      FormFilterGenericAppOwners.NotCurrentUser,
    ],
    disposition: [FormFilterDispositions.None],
  }),
  [DashboardNavigationButtons.Done]: new FormFilters({
    disposition: [FormFilterDispositions.Dispositioned],
  }),
  [DashboardNavigationButtons.Archive]: new FormFilters({
    archived: true,
  }),
};
