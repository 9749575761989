import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  Observable,
  combineLatest,
  concatMap,
  filter,
  from,
  map,
  of,
  toArray,
} from 'rxjs';
import { PermissionsService } from 'src/app/core/services/permissions/permissions.service';
import { selectUrl } from 'src/app/root-store/state.router';
import {
  IMenuItem,
  allMainMenuItems,
  allSettingsMenuItems,
} from '../models/menus';

@Injectable({
  providedIn: 'root',
})
export class MenuItemsService {
  readonly mainMenuItems$: Observable<IMenuItem[]> =
    this.filterMenuItemsByPermission(allMainMenuItems);

  readonly settingsMenuItems$: Observable<IMenuItem[]> =
    this.filterMenuItemsByPermission(allSettingsMenuItems);

  readonly currentSubMenuItem$: Observable<any> = combineLatest([
    this.mainMenuItems$,
    this.settingsMenuItems$,
    this.store.pipe(select(selectUrl)),
  ]).pipe(
    map(([mainMenuItems, settingsMenuItems, currentUrl]) => [
      mainMenuItems.concat(settingsMenuItems),
      currentUrl,
    ]),
    map(([allMenuItems, currentUrl]: [IMenuItem[], string]) => {
      const currentMenuItem = allMenuItems.find(
        (menuItem) =>
          !!menuItem.url &&
          currentUrl.startsWith(`/${menuItem.url?.join('/')}`),
      );
      return currentMenuItem?.subItems;
    }),
  );

  constructor(
    private readonly permissionsService: PermissionsService,
    private readonly store: Store,
  ) {}

  private filterMenuItemsByPermission(
    items: IMenuItem[],
  ): Observable<IMenuItem[]> {
    return from(items).pipe(
      concatMap((item) =>
        this.permissionsService.hasRoutePermission(item).pipe(
          filter(Boolean),
          map(() => item),
        ),
      ),
      concatMap((item) =>
        this.permissionsService.hasFeatureEnabled(item).pipe(
          filter(Boolean),
          map(() => item),
        ),
      ),
      concatMap((item) =>
        this.permissionsService.hasFeatureDisabled(item).pipe(
          filter(Boolean),
          map(() => item),
        ),
      ),
      concatMap((item) =>
        from(this.permissionsService.featureMeetsStoreCondition(item)).pipe(
          filter(Boolean),
          map(() => item),
        ),
      ),
      // NOTE: disabled state is not currently set up for nav links
      concatMap((item) =>
        this.hasRequiredSubscriptionStatus(item).pipe(
          map(
            (isSubscriptionRequirementMet) =>
              (item.disabled = !isSubscriptionRequirementMet),
          ),
          map(() => item),
        ),
      ),
      concatMap((item) =>
        this.filterSubItems(item).pipe(
          map((filteredSubItems) => ({
            ...item,
            subItems: filteredSubItems,
          })),
        ),
      ),
      toArray(),
    );
  }

  private filterSubItems(item: IMenuItem): Observable<IMenuItem[] | undefined> {
    return item.subItems
      ? this.filterMenuItemsByPermission(item.subItems)
      : of(undefined);
  }

  private hasRequiredSubscriptionStatus(item: IMenuItem): Observable<boolean> {
    return this.permissionsService.hasRequiredSubscriptionStatus(
      !!item.subscriptionRequired,
    );
  }
}
