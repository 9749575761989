import { BpEnvironmentName } from '@boldpenguin/sdk';
import { defaultEnvironment } from './environment.default';

export const environment = {
  ...defaultEnvironment,
  production: true,
  envTag: 'prod',
  sdkEnvTag: BpEnvironmentName.Production,
  isUatEnv: false,

  apiBaseUri: 'https://partner-engine.boldpenguin.com',
  apiAuthUri: 'https://boldpenguin-auth.boldpenguin.com', // NOTE Hopefully we won't need this when the SDK gives it to us
  ledgerUri: 'https://ledger.boldpenguin.com',
  magellanBaseUri: 'https://admin.boldpenguin.com',
  calendlyOnboardingKickoffUrl:
    'https://calendly.com/bold-penguin-customer-success/onboarding-kickoff',
  clientId: 'cVpg3vBpfFvf3YivDf4Z3EPvjL1SvGd9',
  wsBaseUri: 'wss://partner-engine.boldpenguin.com/cable',
  googleApiKey: 'AIzaSyCfI3ebafNyFmuyyFpypPCFOBLi8A8fk1Q',
  mixpanelProjectKey: '41becf1286f14411685866762c4bf3a6',

  // intercom
  intercomAppId: 'z36nk6zh',
  recommendedCarriersProductTourId: 455476,

  bpSdkDebugging: false,
  stripePublishableKey: 'pk_live_mbBxfWfUmD3HL04tBvEencVV',
  logRocketTag: 'prod',
  sanitizeLogRocket: true,
  sendMixpanelEvents: true,
  logMixpanelEvents: false,
  sentryTracesSampleRate: 0.01,
  sentryInstrumentNavigation: false,
  sentryInstrumentPageLoad: false,
  sentryMarkBackgroundSpans: false,
  sentryEnableLongTask: false,
  downloadEIT: false,
  // Feature Flags
  showResetComplianceForms: true,
  enableReportsNavigationLink: false,
  calendlySignupFlow: false,
  supportsGeminiUploads: false,

  // Reporting
  shouldLoadQuicksightDashboard: true,

  zapierPageId: 'clrkt8n65000tj34oyp4mibsx',

  discounts: {
    agency_collective: { code: 'U0MDU5VG', title: 'Agency Collective' },
    asnoa: { code: 'HU9IT01T', title: 'ASNOA' },
    biberk_sub: { code: 'TQMJNCEW', title: 'BiBERK' },
    biberk: { code: 'SBL2QR3O', title: 'BiBERK' },
    coterie_sub: { code: 'QBIYSZOS', title: 'Coterie' },
    coterie: { code: 'UYG6KGJ7', title: 'Coterie' },
    hub: { code: 'EDZ9SJHP', title: 'HUB' },
    isu: { code: 'QMELAAOX', title: 'ISU' },
    pin: { code: 'NKZ0DENL', title: 'Partners Insurance Network' },
    acuity: { code: 'DCNFCULT', title: 'Acuity' },
    chubb: { code: 'JJYSNF4N', title: 'Chubb' },
    pathpoint: { code: 'EHNQRXYJ', title: 'Pathpoint' },
  },

  plans: {
    agency_collective: {
      code: 'EUWYGRLZ',
      title: 'Agency Collective',
    },
    insurance_agency_network: {
      code: 'F1WDL72C',
      title: 'Insurance Agency Network',
    },
    partners_insurance_network: {
      code: 'NKZ0DENL',
      title: 'Partners Insurance Network',
    },
    keystone: {
      code: '5AANQW0G',
      title: 'Keystone',
    },
    travelers: {
      code: 'KPEUKHUG',
      title: 'Travelers',
    },
    westfield: {
      code: '9IBVPX1S',
      title: 'Westfield',
    },
  },
};
