import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { IUser } from '@boldpenguin/sdk';
import { environment } from '../../../../environments/environment';
import { IIntercomUser } from '../../models/intercom-user';
import { IExtendedUser } from '../../models/extended-user';

export enum IntercomInternalLabels {
  // Global Header
  NAV_MENU = 'Navigation Menu CTA',
  NAV_MENU_CATEGORY = 'Navigation Menu Category',
  NAV_MENU_FEEDBACK = 'Navigation Menu Feedback CTA',
  PAGE_HEADING = 'Page Heading',
  USER_MENU = 'User Menu',
  INTERNAL_PAGE_HEADING = 'Internal Page Heading',

  // Quotes Dashboard
  QUOTE_CREATE = 'Start Quote',
  QUOTES_SEARCH = 'Search Quotes',
  QUOTES_FILTERS_SMALL_WINDOW = 'Quote Filters (Small Window)',
  QUOTES_FILTERS_LARGE_WINDOW = 'Quote Filters (Large Window)',
  QUOTES_FILTER_STATUS = 'Filter Quotes by Status',
  QUOTES_FILTER_TIME = 'Filter Quotes by Time',
  QUOTES_FILTER_AGENTS = 'Filter Quotes by Agents',
  QUOTES_FILTER_STATUS_ALL = 'Filter Quotes by Status - All',
  QUOTES_FILTER_STATUS_NEW = 'Filter Quotes by Status - New',
  QUOTES_FILTER_STATUS_IN_PROGRESS = 'Filter Quotes by Status - In Progress',
  QUOTES_FILTER_STATUS_COMPLETED = 'Filter Quotes by Status - Completed',
  QUOTES_FILTER_STATUS_ARCHIVED = 'Filter Quotes by Status - Archived',
  QUOTES_IN_PROGRESS = 'In Progress Quotes',
  QUOTES_30_DAY_PROGRESS = '30 Day Progress Quotes',
  QUOTES_UPLOAD_DOCUMENTS = 'Upload documents',

  // Quote Card
  QUOTE_APPLICATION_DETAILS = 'Application details',
  QUOTE_CONTINUE_WITH_APPLICATION = 'Quote Dashboard - Continue application',
  QUOTE_OVERFLOW = '...',
  QUOTE_OVERFLOW_QUICK_VIEW = 'Quick View',
  QUOTE_OVERFLOW_DISPOSITION = 'Disposition',
  QUOTE_OVERFLOW_ARCHIVE = 'Overflow Archive',
  QUOTE_OVERFLOW_ACORD_125 = 'Download ACORD',
  QUOTE_OVERFLOW_ETI = 'Download ETI',
  QUOTE_REASSIGN_TO_ME_AND_CONTINUE = 'Quote Dashboard - Takeover modal opened',
  QUOTE_CARD_CARRIERS = 'Quote Card Eligible Carriers',
  QUOTE_STATUS = 'Quote Status',
  QUOTE_PERCENTAGE_COMPLETE = 'Percentage Complete',

  // Disposition Modal
  DISPOSITION_POLICY_DATE = 'Policy Date',
  DISPOSITION_CARRIER = 'Carrier',
  DISPOSITION_DETAILS = 'Details',
  DISPOSITION_ALL_PRODUCTS = 'All Products',
  DISPOSITION_PRODUCT = 'Product',
  DISPOSITION_DONE = 'Done',

  // Users Page
  USER_TABLE = 'User Table',
  USER_CREATE = 'Add User A',
  USER_CREATE_CANCEL = 'Cancel Add User',
  USER_CREATE_SUBMIT = 'Add User B',
  USER_DEACTIVATE = 'Deactivate User',
  USER_EDIT = 'Edit User',
  USER_EMAIL = 'User Email',
  USER_FIRST_NAME = 'User First Name',
  USER_LAST_NAME = 'User Last Name',
  USER_LINE_ITEM = 'User Line Item',
  USER_OVERFLOW = 'User Overflow Menu',
  USER_PHONE = 'User Phone',
  USER_REACTIVATE = 'Reactivate User',
  USER_RESEND_INVITE = 'Resend User Invite',
  USER_ROLE = 'User Role',
  USER_ACCOUNT_TYPE_TOOLTIP = 'Account Type Tool Tip',
  USER_ADD_EDIT_MODAL = 'User Add Edit Modal',
  USER_ADD_EDIT_MODAL_FIRST_NAME = 'User Add Edit Modal First Name',
  USER_ADD_EDIT_MODAL_LAST_NAME = 'User Add Edit Modal Last Name',
  USER_ADD_EDIT_MODAL_EMAIL = 'User Add Edit Modal Email',
  USER_ADD_EDIT_MODAL_PHONE = 'User Add Edit Modal Phone',
  USER_ADD_EDIT_MODAL_ACCOUNT_TYPE = 'User Add Edit Modal Account Type',
  USER_ADD_EDIT_MODAL_CTA = 'User Add Edit Modal CTA',

  // Appointments Page
  APPOINTMENT_CARRIER_TOGGLE = 'On?',
  APPOINTMENT_CREATE_MODAL = 'Carrier Appt Modal',
  APPOINTMENT_CREATE_CANCEL = 'Cancel Add Appt',
  APPOINTMENT_CARRIER_CARD = 'Carrier Card',
  APPOINTMENT_CREATE_INPUT = 'Carrier Appt',
  APPOINTMENT_CREATE_SUBMIT = 'Add Appt Confirm',
  APPOINTMENT_CREATE_TYPE_DIRECT = 'Appt Type Direct',
  APPOINTMENT_CREATE_TYPE_REQUEST = 'Appt Type Request',
  APPOINTMENTS_FILTER = 'Fltr:',

  CARRIER_SETTINGS_CARRIER_CARD = 'Carrier Setting Carrier Card',

  // Quote Flow
  QUOTE_HELP_BOT = 'quotes-help-bot',
  QUOTE_SAVE_AND_CLOSE = 'Save and Close',

  // Exchange Schedule Page
  EXCHANGE_TIMEZONE = 'Exchange Timezone',
  EXCHANGE_STATUS = 'Exchange Status',
  EXCHANGE_HOURS = 'Exchange Hours',
  EXCHANGE_DAYS = 'Exchange Days',
  EXCHANGE_SCHEDULE = 'Exchange Schedule',
  EXCHANGE_SCHEDULE_UPDATE = 'Exchange Schedule Update',

  // Reports Page
  REPORTS_FILTER_MONTHS = 'Filter by Month',
  REPORTS_FILTER_NAMES = 'Filter by Name',
  REPORTS_DOWNLOAD_CSV = 'Download CSV',
  REPORTS_DATA = 'Report Data',

  // CQS
  CQS_SETTINGS = 'CQS Settings',

  //Storefront
  STOREFRONTS = 'Storefront Index',
  ADD_STOREFRONT = 'Add Storefront',

  // Recommended Carriers
  RECOMMENDED_CARRIERS_HOW_TO_USE = 'How To Use This Page',
  RECOMMENDED_CARRIERS_TIER = 'Tier',
  RECOMMENDED_CARRIERS_CARRIER = 'Carrier',
}
export interface IIntercomService {
  convertUserToIntercomUser(userInfo: IUser): IIntercomUser;
  bootWithUserData(intercomUser: IIntercomUser): void;
  updateUserData(intercomUser: IIntercomUser): void;
  shutdown(): void;
  isUrlForbidden(urlToCheck: string): boolean;
  updateNumberOfEnrollments(numEnrollments: number);
  updateRouteChange(): void;
  onHide(handler: () => void): void;
  onShow(handler: () => void): void;
}

declare global {
  interface Window {
    Intercom?: (command: string, params?: any) => void;
  }
}

@Injectable({
  providedIn: 'root',
})
export class IntercomService implements IIntercomService {
  public forbiddenIntercomRoutes: string[] = [];
  isBooted = false;

  private renderer: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  loadIntercomScript(): Promise<any> {
    return new Promise((resolve) => {
      const script = this.renderer.createElement('script');

      script.onload = resolve;

      script.src = '/assets/scripts/intercom.js';
      this.renderer.appendChild(this.document.head, script);
    });
  }

  isUrlForbidden(urlToCheck: string): boolean {
    return !!this.forbiddenIntercomRoutes.find((forbiddenUrl) =>
      urlToCheck.startsWith(forbiddenUrl),
    );
  }

  convertUserToIntercomUser(userInfo: IExtendedUser): IIntercomUser {
    return {
      user_id: userInfo.extra.raw_info.user_id,
      user_hash: userInfo.extra.raw_info.intercom_hash,
      email: userInfo.extra.raw_info.email,
      name: `${userInfo.extra.raw_info.first_name} ${userInfo.extra.raw_info.last_name}`,
      company: {
        id: userInfo.extra.raw_info.tenant_id,
        name: userInfo.extra.raw_info.tenant_name,
      },
      plan: userInfo.extra.raw_info.plan_sku ?? undefined,
      subscriptionStatus: userInfo.extra.raw_info.sub_status ?? undefined,
      newExperience: true,
    };
  }

  bootWithUserData(intercomUser: IIntercomUser) {
    if (!this.isBooted && window.Intercom) {
      window.Intercom?.('boot', {
        app_id: environment.intercomAppId,
        custom_launcher_selector: '#intercom-launcher',
        ...intercomUser,
      });
      this.isBooted = true;
    }
  }

  updateUserData(intercomUser: IIntercomUser) {
    if (this.isBooted) {
      window.Intercom?.('update', {
        ...intercomUser,
      });
    }
  }

  updateNumberOfEnrollments(numEnrollments: number) {
    if (this.isBooted) {
      window.Intercom?.('update', {
        numActiveEnrollments: numEnrollments,
      });
    }
  }

  updateRouteChange() {
    if (this.isBooted) {
      window.Intercom?.('update', {
        last_request_at: parseInt((new Date().getTime() / 1000) as any, 10),
      });
    }
  }

  triggerProductTour(productTourId: number) {
    if (this.isBooted) {
      window.Intercom?.('startTour', productTourId);
    }
  }

  shutdown() {
    if (this.isBooted) {
      window.Intercom?.('shutdown');
      this.isBooted = false;
    }
  }

  onHide(handler: () => void) {
    window.Intercom?.('onHide', handler);
  }

  onShow(handler: () => void) {
    window.Intercom?.('onShow', handler);
  }
}
