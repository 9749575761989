import { createAction, props } from '@ngrx/store';
import { IBankAccountResponse } from 'src/app/features/billing/models/bank-account.model';
import { CompliancePayload } from '../../models/compliance-form.model';
import { PlaidLinkSuccessMetadata } from '../../models/plaid.model';

export const LinkBankAccountSuccess = createAction(
  '[BankAccount] Link Bank Account Success',
  props<{ publicToken: string }>(),
);

export const LinkMultipleAccountsError = createAction(
  '[BankAccount] Link Multiple Accounts Error',
  props<{ metadata: PlaidLinkSuccessMetadata }>(),
);

export const SaveBankAccountSuccess = createAction(
  '[BankAccount] Save Bank Account Success',
);

export const SaveBankAccountError = createAction(
  '[BankAccount] Save Bank Account Error',
  props<{ error: Error }>(),
);

export const GetBankAccount = createAction('[BankAccount] Get Bank Account');

export const GetBankAccountSuccess = createAction(
  '[BankAccount] Get Bank Account Success',
  props<{ bankAccount: IBankAccountResponse }>(),
);

export const GetBankAccountError = createAction(
  '[BankAccount] Get Bank Account Error',
  props<{ error: Error }>(),
);

export const RemoveBankAccount = createAction(
  '[BankAccount] Remove Bank Account',
);

export const RemoveBankAccountSuccess = createAction(
  '[BankAccount] Remove Bank Account Success',
);

export const RemoveBankAccountError = createAction(
  '[BankAccount] Remove Bank Account Error',
  props<{ error: Error }>(),
);

export const UpdateComplianceFormData = createAction(
  '[BankAccount] Update Compliance Form Data',
  props<{ data: CompliancePayload }>(),
);

export const UpdateComplianceFormDataSuccess = createAction(
  '[BankAccount] Update Compliance Form Data Success',
);

export const UpdateComplianceFormDataError = createAction(
  '[BankAccount] Update Compliance Form Data Error',
  props<{ error: Error }>(),
);
