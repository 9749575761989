import { Injectable } from '@angular/core';
import { selectUser } from '@boldpenguin/sdk';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { map } from 'rxjs/operators';
import { IExtendedUser } from '../core/models/extended-user';
import { BpSdkStoreService } from '../core/services/bp-sdk-store-service/bp-sdk-store.service';
import { IntercomService } from '../core/services/intercom-service/intercom.service';
import {
  MixpanelEventType,
  MixpanelService,
} from '../core/services/mixpanel-service/mixpanel.service';

@Injectable()
export class RootStateEffects {
  updateIntercomRouteOnChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigatedAction),
        map((navigatedAction) => {
          const { url } = navigatedAction.payload.event;
          const routeIsForbidden = this.intercomService.isUrlForbidden(url);
          const user = selectUser(this.bpSdkStoreService.getCurrentState());
          if (routeIsForbidden) {
            this.intercomService.shutdown();
          } else if (!routeIsForbidden && !!user?.extra?.raw_info?.user_id) {
            const intercomUser = this.intercomService.convertUserToIntercomUser(
              user as IExtendedUser,
            );
            this.intercomService.bootWithUserData(intercomUser);
          } else {
            this.intercomService.updateRouteChange();
          }
        }),
      ),
    { dispatch: false },
  );

  trackMixpanelEventsOnRouteChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigatedAction),
        map((navigatedAction) => {
          const { url } = navigatedAction.payload.event;
          this.mixpanelService.track(MixpanelEventType.PAGE_LOADED, {
            urlLoaded: url,
          });
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private intercomService: IntercomService,
    private mixpanelService: MixpanelService,
    private bpSdkStoreService: BpSdkStoreService,
  ) {}
}
