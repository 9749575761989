import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  EmperorCardHeaderColor,
  EmperorUIButtonTypes,
} from '@boldpenguin/emperor-presentational';
import { UntilDestroy } from '@ngneat/until-destroy';

export interface IWarningDialogData {
  title: string;
  body: string;
  actionButtons?: {
    text: string;
    clickedCallback: () => void;
  }[];
}

@UntilDestroy()
@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss'],
})
export class WarningDialogComponent {
  @Input({ required: true }) data: IWarningDialogData;
  @Output() closeDialog: EventEmitter<void> = new EventEmitter();
  @Output() callback: EventEmitter<() => void> = new EventEmitter();

  readonly EmperorUIButtonTypes = EmperorUIButtonTypes;
  readonly EmperorCardHeaderColor = EmperorCardHeaderColor;

  constructor() {}

  handleActionButtonClick(clickedCallback: () => void) {
    this.callback.emit(clickedCallback);
  }

  close() {
    this.closeDialog.emit();
  }
}
