import { Pipe, PipeTransform } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';

@Pipe({
  name: 'formControl',
})
export class FormControlPipe implements PipeTransform {
  transform(control: AbstractControl): FormControl<(typeof control)['value']> {
    if (!(control instanceof FormControl)) {
      throw new Error(
        `${control.constructor.name} is not an instance of FormControl`,
      );
    }
    return control;
  }
}

@Pipe({
  name: 'formGroup',
})
export class FormGroupPipe implements PipeTransform {
  transform(control: AbstractControl): FormGroup<(typeof control)['value']> {
    if (!(control instanceof FormGroup)) {
      throw new Error(
        `${control.constructor.name} is not an instance of FormGroup`,
      );
    }
    return control;
  }
}

@Pipe({
  name: 'formArray',
})
export class FormArrayPipe implements PipeTransform {
  transform(control: AbstractControl): FormArray<(typeof control)['value']> {
    if (!(control instanceof FormArray)) {
      throw new Error(
        `${control.constructor.name} is not an instance of FormArray`,
      );
    }
    return control;
  }
}
