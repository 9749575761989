export enum ExchangeStatus {
  On = 'On',
  Off = 'Off',
}

export interface IExchangeStatus {
  /**
   * Flag for Exchange receive status, false when Exchange is paused
   */
  active: boolean;
  id: number;
  tenant_id: string;
}
