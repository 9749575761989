import { createSelector } from '@ngrx/store';
import { selectCoreState } from '../core-feature-selector';

const selectComplianceFormState = createSelector(
  selectCoreState,
  (state) => state.complianceForm,
);

export const selectComplianceForm = createSelector(
  selectComplianceFormState,
  (state) => state.complianceForm,
);

export const selectComplianceFormHasBeenChecked = createSelector(
  selectComplianceFormState,
  (state) => state.isChecked,
);

export const selectComplianceFormHasBeenLoaded = createSelector(
  selectComplianceFormState,
  (state) => state.isLoaded,
);
