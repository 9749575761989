import { IApplicationFormOwner } from '@boldpenguin/sdk';

export const PROGRAMMATIC_USER_FIRST_NAME = 'programmatic';
export const PROGRAMMATIC_USER_LAST_NAME = 'user';
export const PROGRAMMATIC_USER_FULL_NAME = `${PROGRAMMATIC_USER_FIRST_NAME} ${PROGRAMMATIC_USER_LAST_NAME}`;
export const PROGRAMMATIC_USER_TYPE = 'programmatic';
export const GUEST_USER_TYPE = 'guest_user';

export const quotesDisplayUser = 'Unassigned';
export const reportsDisplayUser = 'Unassigned';

export const isProgrammaticUser = (agentName: string): boolean =>
  agentName.toLowerCase() === PROGRAMMATIC_USER_FULL_NAME;

export const isProgrammaticUserType = (
  owner: IApplicationFormOwner | undefined,
): boolean => owner?.user_type === PROGRAMMATIC_USER_TYPE;

export const isGuestUserType = (
  owner: IApplicationFormOwner | undefined,
): boolean => owner?.user_type === GUEST_USER_TYPE;
