import {
  ApplicationFormStates,
  IApplicationFormQuestionSet,
  ICarrierWithProducts,
  IQuotesState,
  LoadingStates,
  QuestionReferenceType,
  QuoteRequestRequestTypes,
  getAllQuestionSetResources,
  getApplicationForm,
  getApplicationState,
  getCurrentActiveQuestionSet,
  getCurrentActiveQuestionSetId,
  getCurrentCarrierProductBucket,
  getEligibleOnlineCarriersWithProducts,
  getIncompleteQuestionSets,
  getQuestionSets,
  getQuoteId,
  getQuotes,
  hasPermission,
  isFormLoaded,
  selectEnrollments,
  selectUser,
} from '@boldpenguin/sdk';
import { createSelector } from '@ngrx/store';
import {
  ProspectDetails,
  QuoteQuestionCodes,
} from 'src/app/features/quotes/models/quote';
import {
  QuoteRequestIncompleteQuestionSet,
  extractAnswer,
  findAnswerByQuestionAttributes,
  getEligibleCarrierAsQuoteRequest,
} from 'src/app/features/quotes/utilities/quote-utilities';
import { PlanCodes } from 'src/app/shared/models/plan-codes';
import { PriceSkus } from 'src/app/shared/models/price-skus';
import { isAdditionalMarketCarrier } from 'src/app/shared/utilities/carrier-utilities';
import { isAfiSubdomain } from '../../utilities/afi-utilities';
import {
  selectOfflineEligibleCarriersAsQuoteRequests,
  selectOptInRequiredCarriers,
} from './eligible-carriers.selectors';

export const selectUserEmail = createSelector(selectUser, (user) => {
  if (!user) {
    return null;
  }

  return user.info?.email ?? 'no email found';
});

export const selectUserFirstName = createSelector(selectUser, (user) => {
  if (!user) {
    return null;
  }

  return user.info?.first_name ?? 'Unknown';
});

export const selectUserLastName = createSelector(selectUser, (user) => {
  if (!user) {
    return null;
  }

  return user.info?.last_name ?? 'Agent';
});

export const selectUserName = createSelector(
  selectUserFirstName,
  selectUserLastName,
  (firstName: string, lastName: string) => {
    if (!firstName || !lastName) {
      return null;
    }

    return `${firstName} ${lastName}`;
  },
);

export const selectTenantName = createSelector(selectUser, (user) => {
  if (!user) {
    return null;
  }

  return user.extra?.raw_info?.tenant_name ?? 'Unknown Tenant';
});

export const selectTenantId = createSelector(
  selectUser,
  (user) => user?.extra?.raw_info?.tenant_id ?? null,
);

export const selectSubscriptionStatus = createSelector(selectUser, (user) => {
  if (!user) {
    return null;
  }

  return user.extra?.raw_info?.sub_status;
});

export const selectPlanSku = createSelector(selectUser, (user) => {
  let planSku = '';

  /*
   * If the user does not have a plan sku we want to default it to 'legacy'.
   * However, we don't want to return a value for plan sku if the whole user information is null.
   * This logic will only set 'legacy' if just the plan sku is nullable.
   */

  if (user?.extra?.raw_info) {
    planSku = user.extra.raw_info.plan_sku ?? 'legacy';
  }

  return planSku;
});

export const selectPriceSku = createSelector(selectUser, (user) => {
  const rawPriceSku = user?.extra?.raw_info?.price_sku;

  if (!rawPriceSku) {
    return undefined;
  }

  return Object.values(PriceSkus).includes(rawPriceSku as PriceSkus)
    ? (rawPriceSku as PriceSkus)
    : undefined;
});

export const selectProducerAgreementAccepted = createSelector(
  selectUser,
  (user) =>
    !!user?.extra?.raw_info?.accepted_agreements &&
    user.extra.raw_info.accepted_agreements.some(
      // Agreements are saved strings, and a previous version had a typo
      (agreement: string) => /^Producer Agreeme?nt/i.test(agreement),
    ),
);

// Note: these are Tenant-like, but are not a subset of ITenant
export const selectUserTenants = createSelector(
  selectUser,
  (user) => user?.extra?.raw_info?.tenants ?? [],
);

export const selectCurrentActiveQuestionSetCode = createSelector(
  getCurrentActiveQuestionSet,
  (activeQSet) => activeQSet?.question_set.code,
);

/* Quotes Selectors */

export const selectAnswerByQuestionCode = (
  code: QuoteQuestionCodes,
  useAlias: boolean = false,
  referenceType: QuestionReferenceType | undefined = undefined,
  transformToAbbreviated = true,
) =>
  createSelector(getQuestionSets, (qns) =>
    selectByCode(qns, code, useAlias, referenceType, transformToAbbreviated),
  );

/**
 * Returns first answer found either by a partial question code or reference type
 *
 * @param code partial question code
 * @param referenceType optional reference type, ex. business_class
 * @returns IAnswer | undefined
 */
export const selectIAnswerByQuestionCode = (
  code: QuoteQuestionCodes,
  referenceType: QuestionReferenceType | undefined = undefined,
) =>
  createSelector(getQuestionSets, (questionSets) =>
    findAnswerByQuestionAttributes(
      questionSets.flatMap((qs) => qs.answers),
      code,
      referenceType,
    ),
  );

/**
 * Returns first question set containing an answer matched either
 * by a partial question code or reference type
 *
 * @param code partial question code
 * @param refType optional reference type, ex. business_class
 * @returns IApplicationFormQuestionSet | undefined
 */
export const selectQuestionSetByQuestionCode = (
  code: QuoteQuestionCodes,
  reference_type: QuestionReferenceType | undefined = undefined,
) =>
  createSelector(getQuestionSets, (qns) => {
    const appFormQuestionSet: IApplicationFormQuestionSet | undefined =
      qns.find((afqs) =>
        findAnswerByQuestionAttributes(afqs.answers, code, reference_type),
      );
    return appFormQuestionSet;
  });

export const selectByCode = (
  qns: IApplicationFormQuestionSet[],
  targetCode: string,
  useAlias: boolean = false,
  referenceType: QuestionReferenceType | undefined = undefined,
  transformToAbbreviated = true,
) => {
  let currentValue = '';

  qns.forEach((question: IApplicationFormQuestionSet) => {
    // extractAnswer will return undefined if the target answer is not on the question set
    const extracted = extractAnswer(
      question.answers,
      targetCode,
      useAlias,
      referenceType,
      transformToAbbreviated,
    );
    currentValue = extracted === undefined ? currentValue : extracted;
  });

  return currentValue;
};

export const selectAnswerByReferenceType = (
  referenceType: QuestionReferenceType,
  useAlias: boolean = false,
  transformToAbbreviated = true,
) =>
  createSelector(getQuestionSets, (qns) =>
    selectByCode(qns, '', useAlias, referenceType, transformToAbbreviated),
  );

export const selectBusinessName = createSelector(getQuestionSets, (qns) =>
  selectByCode(qns, QuoteQuestionCodes.BusinessName),
);

export const selectCoverageTypes = (transformToAbbreviated: boolean) =>
  selectAnswerByQuestionCode(
    QuoteQuestionCodes.CoverageTypes,
    false,
    QuestionReferenceType.LinesOfBusiness,
    transformToAbbreviated,
  );

export const selectAppetiteData = createSelector(
  selectAnswerByQuestionCode(
    QuoteQuestionCodes.Naics,
    false,
    QuestionReferenceType.BusinessClass,
  ),
  selectCoverageTypes(true),
  selectAnswerByQuestionCode(
    QuoteQuestionCodes.State,
    true,
    QuestionReferenceType.State,
  ),
  (naics, products, stateCode) => ({
    naics,
    products: products.split(',').map((p) => p.trim()),
    stateCode,
  }),
);

/**
 * Selects all carrier question sets that are incomplete
 * and transforms them into the shape of IQuotesState[];
 */
export const selectIncompleteCarrierQuestionSetsAsQuoteRequests =
  createSelector(
    getIncompleteQuestionSets,
    getEligibleOnlineCarriersWithProducts,
    (
      questionSets: IApplicationFormQuestionSet[],
      eligibleCarriers: ICarrierWithProducts[],
    ): IQuotesState[] => {
      const consumedCarrierIds = new Set<string>();

      return questionSets.reduce(
        (acc: IQuotesState[], qs: IApplicationFormQuestionSet) => {
          const carrier = eligibleCarriers.find(
            (c) => c.id === qs.question_set.tenant_id,
          );

          let quoteList = acc;
          if (carrier && !consumedCarrierIds.has(carrier.id)) {
            consumedCarrierIds.add(carrier.id);

            const quoteRequest = getEligibleCarrierAsQuoteRequest(
              carrier,
              QuoteRequestIncompleteQuestionSet,
              null,
            );
            quoteList = [...acc, quoteRequest];
          }

          return quoteList;
        },
        [],
      );
    },
  );

export const selectAllQuotes = createSelector(
  getQuotes,
  selectIncompleteCarrierQuestionSetsAsQuoteRequests,
  selectOptInRequiredCarriers,
  selectOfflineEligibleCarriersAsQuoteRequests,
  (
    onlineQuotes,
    incompleteQuestionSetQuotes,
    additionalMarketCarriers,
    offlineQuotes,
  ) => [
    ...(onlineQuotes || []),
    ...(incompleteQuestionSetQuotes || []),
    ...(additionalMarketCarriers || []),
    ...(offlineQuotes || []),
  ],
);

export const selectProspectData = createSelector(
  selectAnswerByQuestionCode(
    QuoteQuestionCodes.Naics,
    false,
    QuestionReferenceType.BusinessClass,
  ),
  selectAnswerByReferenceType(QuestionReferenceType.BusinessClass, false),
  selectAnswerByQuestionCode(QuoteQuestionCodes.BusinessName),
  selectAnswerByQuestionCode(
    QuoteQuestionCodes.City,
    false,
    QuestionReferenceType.City,
  ),
  selectCoverageTypes(true),
  selectAnswerByQuestionCode(QuoteQuestionCodes.Email),
  selectAnswerByQuestionCode(QuoteQuestionCodes.FirstName),
  getQuoteId,
  selectAnswerByQuestionCode(QuoteQuestionCodes.LastName),
  selectAnswerByQuestionCode(QuoteQuestionCodes.Phone),
  selectAnswerByQuestionCode(
    QuoteQuestionCodes.State,
    false,
    QuestionReferenceType.State,
  ),
  (
    businessClass,
    businessClassByReferenceOnly,
    businessName,
    city,
    coverages,
    email,
    firstName,
    id,
    lastName,
    phone,
    state,
  ): ProspectDetails => ({
    businessClass: businessClass ?? businessClassByReferenceOnly,
    businessName,
    city,
    coverages,
    email,
    firstName,
    id,
    lastName,
    phone,
    state,
  }),
);

/**
 * Selector for only the question sets that actually have answers, but returned in ascending order
 */
export const selectQuestionSetsWithAnswersOrdered = createSelector(
  getQuestionSets,
  (qns) => {
    const filtered = qns.filter((qn) => qn.answers.length > 0);
    filtered.sort((a, b) => a.order - b.order);

    return filtered;
  },
);

/**
 * Selector for getting the last question set id
 */
export const selectLastQuestionSet = createSelector(
  getCurrentActiveQuestionSetId,
  selectQuestionSetsWithAnswersOrdered,
  (id, qns) => {
    let idToReturn = id;
    if (qns.length > 0) {
      idToReturn = qns[qns.length - 1].question_set_id;
    }

    return idToReturn;
  },
);

export const selectFetchingQuotesOnExclusiveBucket = createSelector(
  getApplicationState,
  getCurrentCarrierProductBucket,
  getQuotes,
  (formState, currentBucket, quotes) =>
    formState === ApplicationFormStates.Completed &&
    !!currentBucket?.exclusive &&
    quotes.length > 0 &&
    !quotes.find(
      (q) => q.request_status === QuoteRequestRequestTypes.completed,
    ),
);

export const selectShowQuotesView = createSelector(
  getApplicationState,
  isFormLoaded,
  selectFetchingQuotesOnExclusiveBucket,
  (formState, formLoaded, currentlyFetchingQuotes) =>
    !currentlyFetchingQuotes &&
    (formState === ApplicationFormStates.Completed ||
      formState === ApplicationFormStates.Quoted) &&
    formLoaded,
);

export const selectShowAppTakenOverView = createSelector(
  getApplicationForm,
  isFormLoaded,
  (form, formLoaded) => form.locked && form.taken_over && formLoaded,
);

export const selectShowQuoteTransferredView = createSelector(
  getApplicationForm,
  getApplicationState,
  isFormLoaded,
  (form, formState, formLoaded) =>
    form.locked &&
    !form.taken_over &&
    formState === ApplicationFormStates.InProgress &&
    formLoaded,
);

export const selectShowSendToExchange = createSelector(
  selectUser,
  getApplicationForm,
  getApplicationState,
  (user, appForm, status) =>
    !!user &&
    !!user?.extra?.raw_info.send_to_exchange &&
    appForm.owner_id === user?.extra?.raw_info.user_id &&
    (status === ApplicationFormStates.Completed ||
      status === ApplicationFormStates.Quoted) &&
    hasPermission({
      user,
      model: 'application_forms/application_forms_question_sets',
      action: 'create',
    }),
);

export const selectShowWholesaleWorkflowCTA = createSelector(
  getApplicationForm,
  (appForm) => appForm.features.includes('send_to_ba'),
);

export const selectWholesalerPreferredQuotes = createSelector(
  getApplicationForm,
  (appForm) => appForm.wholesaler_preferred_quotes,
);

export const selectSentToWholesalerAt = createSelector(
  getApplicationForm,
  (appForm) =>
    appForm.sent_to_wholesaler_at || appForm.received_by_wholesaler_at,
);

export const selectSentToWholesaler = createSelector(
  selectSentToWholesalerAt,
  (sentToWholesalerAt) => !!sentToWholesalerAt,
);

export const selectReceivedByWholesaler = createSelector(
  getApplicationForm,
  (appForm) => !!appForm.received_by_wholesaler_at,
);

export const selectAppFormTenant = createSelector(
  getApplicationForm,
  (appForm) => appForm.tenant,
);

export const selectAppFormTenantName = createSelector(
  selectAppFormTenant,
  (tenant) => tenant.name,
);

export const selectIsQuestionSetLoading = (
  applicationFormQuestionSetId: string,
) =>
  createSelector(
    getAllQuestionSetResources,
    (questionSetState) =>
      questionSetState[applicationFormQuestionSetId] === LoadingStates.Loading,
  );

export const selectAdmittedQuotes = createSelector(getQuotes, (quotes) =>
  quotes.filter((quote) => !isAdditionalMarketCarrier(quote.carrier.labels)),
);

export const selectAdditionalMarketQuotes = createSelector(
  getQuotes,
  (quotes) =>
    quotes.filter((quote) => isAdditionalMarketCarrier(quote.carrier.labels)),
);

export const selectIntercomEnabled = createSelector(
  selectPlanSku,
  (planSku) => planSku !== PlanCodes.Enterprise,
);

export const selectIsAfi = createSelector(selectUser, (user): boolean => {
  if (!user) {
    return false;
  }

  const tenantSubdomain = user.extra.raw_info.tenant_subdomain;
  const tenantTemplateSubdomain = user.extra.raw_info.template_subdomain ?? '';

  return (
    isAfiSubdomain(tenantSubdomain) || isAfiSubdomain(tenantTemplateSubdomain)
  );
});

export const selectShowDashboardRightColumn = createSelector(
  selectIsAfi,
  (isAfi) => !isAfi,
);

export const selectActiveAppFormEnrollments = createSelector(
  selectEnrollments,
  (allEnrollments) => allEnrollments.filter((e) => e.active),
);

export const selectComplianceStatus = createSelector(
  selectUser,
  (userInfo) => userInfo?.extra?.raw_info?.compliance_status ?? '',
);
