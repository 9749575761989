<emperor-dialog
  [uiDialogSize]="uiDialogSize"
  class="coverage-search-dialog"
  (close)="closeModal()"
>
  <emperor-dialog-header [title]="title"></emperor-dialog-header>

  <emperor-card-content [scrollable]="true">
    <ng-container *ngIf="coverageDisplayList$ | async as coverageDisplayList">
      <mat-radio-group [(ngModel)]="selectedCoverage">
        <div
          *ngFor="let coverage of coverageDisplayList"
          (click)="save(coverage.abbreviation)"
          (keydown.enter)="save(coverage.abbreviation)"
          (keydown.space)="save(coverage.abbreviation)"
          (keydown.arrowUp)="save(coverage.abbreviation)"
          (keydown.arrowDown)="save(coverage.abbreviation)"
          class="coverage-box"
          role="button"
          [ngClass]="{
            selected: coverage.abbreviation === selectedCoverage
          }"
        >
          <div class="coverage-info">
            <emperor-icon
              name="{{ coverage.icon }}"
              class="coverage-icon"
              aria-hidden="true"
            ></emperor-icon>

            <div class="coverage-name subheader">
              {{ coverage.name }}
              <span class="coverage-abbreviation"
                >({{ coverage.abbreviation }})</span
              >
            </div>
          </div>
          <mat-radio-button
            color="primary"
            [id]="coverage.abbreviation"
            [value]="coverage.abbreviation"
            [checked]="coverage.abbreviation === selectedCoverage"
          ></mat-radio-button>
        </div>
      </mat-radio-group>
    </ng-container>
  </emperor-card-content>

  <emperor-card-footer class="card-footer">
    <ng-content select="[footer-actions]"></ng-content>
  </emperor-card-footer>
</emperor-dialog>
