import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { EnrollmentsService } from '../../services/enrollments-service/enrollments.service';
import { Messages } from '../../services/notification-service/notification-messages';
import { NotificationService } from '../../services/notification-service/notification.service';
import { WindowRef } from '../../services/window-ref/window-ref.service';
import * as EnrollmentSelfServiceRegistrationActions from './enrollment-self-service-registration.actions';

@Injectable()
export class EnrollmentSelfServiceRegistrationEffects {
  registerSelfServiceEnrollment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        EnrollmentSelfServiceRegistrationActions.RegisterSelfServiceEnrollmentAction,
      ),
      switchMap((action) =>
        this.enrollmentsService
          .registerSelfServiceEnrollment(action.enrollment)
          .pipe(
            map(({ signup_redirect_url, already_self_registered }) => {
              if (signup_redirect_url) {
                return EnrollmentSelfServiceRegistrationActions.RegisterSelfServiceEnrollmentRedirect(
                  {
                    url: signup_redirect_url,
                  },
                );
              } else if (already_self_registered) {
                this.notificationService.error(
                  Messages.ALREADY_SELF_SERVICE_REGISTERED,
                  { duration: 10000 },
                );
                return EnrollmentSelfServiceRegistrationActions.RegisterSelfServiceEnrollmentFail(
                  {
                    error: new Error('Account already self registered.'),
                    enrollment: action.enrollment,
                  },
                );
              } else {
                throw new Error('No signup url returned');
              }
            }),
            catchError((error: HttpErrorResponse) => {
              this.notificationService.error(
                Messages.REGISTER_SELF_SERVICE_ENROLLMENT_FAIL,
              );
              return of(
                EnrollmentSelfServiceRegistrationActions.RegisterSelfServiceEnrollmentFail(
                  {
                    error,
                    enrollment: action.enrollment,
                  },
                ),
              );
            }),
          ),
      ),
    ),
  );

  enrollmentCallback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        EnrollmentSelfServiceRegistrationActions.SelfServiceRegistrationCallbackAction,
      ),
      switchMap((action) =>
        this.enrollmentsService
          .selfServiceRegistrationCallback(action.selfServiceCallback)
          .pipe(
            map((response) =>
              EnrollmentSelfServiceRegistrationActions.SelfServiceRegistrationCallbackSuccessAction(
                response,
              ),
            ),
            catchError((error: HttpErrorResponse) => {
              this.notificationService.error(
                Messages.SELF_SERVICE_ENROLLMENT_CALLBACK_FAIL,
              );
              return of(
                EnrollmentSelfServiceRegistrationActions.SelfServiceRegistrationCallbackFailedAction(
                  {
                    error,
                  },
                ),
              );
            }),
          ),
      ),
    ),
  );

  registerRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EnrollmentSelfServiceRegistrationActions.RegisterSelfServiceEnrollmentRedirect,
        ),
        tap(({ url }) => (this.windowRef.nativeWindow.location.href = url)),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private windowRef: WindowRef,
    private enrollmentsService: EnrollmentsService,
    private notificationService: NotificationService,
  ) {}
}
