import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApplicationFormIndex } from '@boldpenguin/sdk';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IFormFilters } from 'src/app/core/models/quote-dashboard-filters';
import { APPLICATIONS_PER_PAGE } from './application-form-search.constants';

@Injectable({
  providedIn: 'root',
})
export class ApplicationFormSearchService {
  constructor(private readonly http: HttpClient) {}

  searchAndFilterApplicationsForTotalCount(
    filters: IFormFilters,
  ): Observable<number> {
    return this.searchAndFilterApplicationsWithTotalCount(filters).pipe(
      map((res) => res.totalCount),
    );
  }

  searchAndFilterApplications(
    filters: IFormFilters,
  ): Observable<IApplicationFormIndex[]> {
    return this.searchAndFilterApplicationsWithTotalCount(filters).pipe(
      map((res) => res.forms),
    );
  }

  private searchAndFilterApplicationsWithTotalCount(
    filters: IFormFilters,
  ): Observable<{ totalCount: number; forms: IApplicationFormIndex[] }> {
    const url = `${environment.apiBaseUri}/application_forms`;

    let params: { [key: string]: string | string[] } = {
      per_page: APPLICATIONS_PER_PAGE.toString(),
      'app_state[]': filters.app_state,
    };

    if (filters.origin.length > 0) {
      params['origin[]'] = filters.origin;
    }
    if (filters.app_owner.length > 0) {
      params['app_owner[]'] = filters.app_owner;
    }
    if (filters.app_tenant_ids.length > 0) {
      params['app_tenant_ids[]'] = filters.app_tenant_ids;
    }

    // Archive is now 'Deleted'
    if (filters.archived) {
      params = { deleted: 'true' };
    }

    // If we have a query string (i.e. the user typed in something)
    // we want to let the backend order the results by relevance, not
    // by created date. So only send the column if we aren't doing a query

    if (!filters.q) {
      params.column = 'created_at';
    }

    // Stringify the rest of the attached filters
    Object.keys(filters)
      .filter(
        (key) =>
          !!filters[key] &&
          key !== 'app_state' &&
          key !== 'origin' &&
          key !== 'app_owner' &&
          key !== 'app_tenant_ids',
      )
      .forEach((key) => {
        params[key] = `${filters[key]}`;
      });
    return this.http
      .get<IApplicationFormIndex[]>(url, { observe: 'response', params })
      .pipe(
        map((res) => ({
          totalCount: +(res.headers.get('x-total') ?? '0'),
          forms: res.body ?? [],
        })),
      );
  }
}
