<button
  #menuTrigger="matMenuTrigger"
  [attr.data-intercom-target]="intercomLabels.USER_MENU"
  [matMenuTriggerFor]="profileMenu"
  mat-button
  class="profile-button"
>
  <div class="mat-button-layout">
    <div class="profile-name">
      <p>
        {{
          agentFirstName$
            | async
            | truncate
              : {
                  start: 0,
                  end: 21,
                  ellipsis: true,
                  summands: [agentLastName$ | async]
                }
        }}
      </p>
      <p class="tenant-name">{{ tenantName$ | async }}</p>
    </div>
    <app-agent-avatar
      [firstName]="(agentFirstName$ | async) ?? ''"
      [lastName]="(agentLastName$ | async) ?? ''"
    ></app-agent-avatar>
  </div>
</button>

<mat-menu #profileMenu="matMenu">
  <app-profile-panel
    [agentFirstName]="(agentFirstName$ | async) ?? ''"
    [agentLastName]="(agentLastName$ | async) ?? ''"
    [agentEmail]="(agentEmail$ | async) ?? ''"
    [canSeeReferralButton]="(canSeeReferralButton$ | async) ?? false"
    (referAFriend)="referAFriend()"
    (logout)="logout()"
  ></app-profile-panel>
</mat-menu>
