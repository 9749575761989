import { createReducer, on } from '@ngrx/store';
import * as RedirectActions from './redirect.actions';

export const redirectStateKey = 'redirect';

export interface RedirectState {
  redirectPath: string;
}

export const redirectInitialState: RedirectState = {
  redirectPath: '',
};

export const RedirectReducer = createReducer(
  redirectInitialState,
  on(
    RedirectActions.addRedirect,
    (state, { path }): RedirectState => ({
      ...state,
      redirectPath: path,
    }),
  ),
);
