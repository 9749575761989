<div
  class="loader-container"
  [ngClass]="{ fixed: isFullScreen, relative: !isFullScreen }"
>
  <mat-progress-spinner
    [mode]="!!isProgress ? 'determinate' : 'indeterminate'"
    [value]="!!value ? value : 1"
    [diameter]="diameter"
  ></mat-progress-spinner>
</div>
