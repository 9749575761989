import { Action, createReducer } from '@ngrx/store';
import { IApplicationCountState } from '../models/application-count-state.model';
import {
  ApplicationCountReducer,
  applicationCountStateKey,
  initialState as applicationCountInitialState,
} from './application-count/application-count.reducer';
import {
  bankAccountInitialState,
  BankAccountReducer,
  BankAccountState,
  bankAccountStateKey,
} from './bank-account/bank-account.reducer';
import { ComplianceFormReducer } from './compliance-form/compliance-form.reducer';
import {
  complianceFormInitialState,
  ComplianceFormState,
  complianceFormStateKey,
} from './compliance-form/compliance-form.state';
import {
  enrollmentSelfServiceRegistrationInitialState,
  EnrollmentSelfServiceRegistrationReducer,
  enrollmentSelfServiceRegistrationStateKey,
} from './enrollment-self-service-registration/enrollment-self-service-registration.reducers';
import EnrollmentSelfServiceRegistrationState from './enrollment-self-service-registration/enrollment-self-service-registration.state';
import { EnrollmentsReducer } from './enrollments/enrollments.reducer';
import EnrollmentsState, {
  enrollmentsInitialState,
  enrollmentsStateKey,
} from './enrollments/enrollments.state';
import {
  ExchangeStatusReducer,
  exchangeStatusStateKey,
  initialState as exchangeStatusInitialState,
} from './exchange-status/exchange-status.reducers';
import ExchangeStatusState from './exchange-status/exchange-status.state';
import {
  HeaderAlertsReducer,
  headerAlertStateKey,
  initialState as HeaderInitialState,
} from './header-alerts/header-alerts.reducer';
import HeaderAlertsState from './header-alerts/header-alerts.state';
import {
  productRankingInitialState,
  ProductRankingReducer,
  productRankingReducerKey,
  ProductRankingState,
} from './product-ranking/product-ranking.reducer';
import {
  redirectInitialState,
  RedirectReducer,
  RedirectState,
  redirectStateKey,
} from './redirect/redirect.reducer';

export const CoreFeatureStateKey = 'core';

export default interface CoreFeatureState {
  [applicationCountStateKey]: IApplicationCountState;
  [headerAlertStateKey]: HeaderAlertsState;
  [enrollmentsStateKey]: EnrollmentsState;
  [enrollmentSelfServiceRegistrationStateKey]: EnrollmentSelfServiceRegistrationState;
  [exchangeStatusStateKey]: ExchangeStatusState;
  [redirectStateKey]: RedirectState;
  [bankAccountStateKey]: BankAccountState;
  [productRankingReducerKey]: ProductRankingState;
  [complianceFormStateKey]: ComplianceFormState;
}

export const coreInitialState = (): CoreFeatureState => ({
  [applicationCountStateKey]: applicationCountInitialState,
  [headerAlertStateKey]: HeaderInitialState,
  [enrollmentsStateKey]: enrollmentsInitialState,
  [enrollmentSelfServiceRegistrationStateKey]:
    enrollmentSelfServiceRegistrationInitialState,
  [exchangeStatusStateKey]: exchangeStatusInitialState,
  [redirectStateKey]: redirectInitialState,
  [bankAccountStateKey]: bankAccountInitialState,
  [productRankingReducerKey]: productRankingInitialState,
  [complianceFormStateKey]: complianceFormInitialState,
});

export const coreFeatureInitialState = coreInitialState();

const reducer = createReducer(coreFeatureInitialState);

export const CoreFeatureReducer = (
  state: CoreFeatureState | undefined,
  action: Action,
) => reducer(state, action);

export const CoreReducerMap = {
  [applicationCountStateKey]: ApplicationCountReducer,
  [headerAlertStateKey]: HeaderAlertsReducer,
  [enrollmentsStateKey]: EnrollmentsReducer,
  [enrollmentSelfServiceRegistrationStateKey]:
    EnrollmentSelfServiceRegistrationReducer,
  [exchangeStatusStateKey]: ExchangeStatusReducer,
  [redirectStateKey]: RedirectReducer,
  [bankAccountStateKey]: BankAccountReducer,
  [productRankingReducerKey]: ProductRankingReducer,
  [complianceFormStateKey]: ComplianceFormReducer,
};
