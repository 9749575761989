import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  EmperorCardHeaderColor,
  EmperorDialogRef,
  EmperorUIButtonTypes,
} from '@boldpenguin/emperor-presentational';
import { take } from 'rxjs';
import {
  MixpanelEventType,
  MixpanelService,
} from 'src/app/core/services/mixpanel-service/mixpanel.service';
import { Messages } from 'src/app/core/services/notification-service/notification-messages';
import { NotificationService } from 'src/app/core/services/notification-service/notification.service';
import { ReferralService } from 'src/app/core/services/referral-service/referral.service';

@Component({
  selector: 'app-referral-dialog',
  templateUrl: './referral-dialog.component.html',
  styleUrls: ['./referral-dialog.component.scss'],
})
export class ReferralDialogComponent implements OnInit {
  referralForm: UntypedFormGroup;

  readonly EmperorUIButtonTypes = EmperorUIButtonTypes;
  readonly EmperorCardHeaderColor = EmperorCardHeaderColor;

  constructor(
    public dialogRef: EmperorDialogRef,
    private formBuilder: UntypedFormBuilder,
    private referralService: ReferralService,
    private notificationService: NotificationService,
    private mixpanelService: MixpanelService,
  ) {}

  ngOnInit(): void {
    this.referralForm = this.formBuilder.group({
      agencyName: ['', [Validators.required, Validators.maxLength(255)]],
      contactName: ['', [Validators.required, Validators.maxLength(255)]],
      phone: [
        '',
        [
          Validators.required,
          Validators.minLength(14),
          Validators.maxLength(14),
        ],
      ],
      email: [
        '',
        [Validators.required, Validators.email, Validators.maxLength(255)],
      ],
    });

    this.dialogRef.afterClosed.pipe(take(1)).subscribe((data) => {
      if (!data?.submitted) {
        this.trackClose();
      }
    });
  }

  trackClose() {
    this.mixpanelService.track(MixpanelEventType.REFER_A_FRIEND_CLOSED);
  }

  onSubmit() {
    const { agencyName, contactName, phone, email } = this.referralForm.value;
    this.referralService
      .referAgency({ name: agencyName, contact: contactName, phone, email })
      .subscribe({
        next: (_result) => {
          this.notificationService.success(Messages.REFERRAL_SENT);
          this.mixpanelService.track(
            MixpanelEventType.REFER_A_FRIEND_SENT_REFERRAL,
          );
          this.dialogRef.close({ submitted: true });
        },
        error: (_err) => {
          this.notificationService.error(Messages.REFERRAL_ERROR);
        },
      });
  }
}
