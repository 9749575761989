import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { getTenantLogo, selectUser } from '@boldpenguin/sdk';
import { environment } from '@environments/environment';
import { select } from '@ngrx/store';
import { LocalStorageService } from 'ngx-webstorage';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  filter,
  firstValueFrom,
  iif,
  map,
  of,
  skipUntil,
  startWith,
  switchMap,
} from 'rxjs';
import { localStorageKeys } from 'src/app/shared/constants';
import { getCustomSubdomain } from 'src/app/shared/utilities/url-utilities';
import { BpSdkStoreService } from '../bp-sdk-store-service/bp-sdk-store.service';
import { AFI_SUBDOMAINS } from '../../utilities/afi-utilities';
import { selectPlanSku } from '../bp-sdk-store-service/bp-sdk-store.selectors';

const themeClasses = ['default', 'theme-afi'] as const;
type ThemeClass = (typeof themeClasses)[number];

const themesByTenantSubdomain = new Map<string, ThemeClass>([
  [AFI_SUBDOMAINS.AFI, 'theme-afi'],
  [AFI_SUBDOMAINS.AMFAM, 'theme-afi'],
]);

const allowCustomLogoByTheme: ThemeClass[] = ['theme-afi'];
const allowCustomLogoByPlanSku = ['darkhorse_agency_network'];

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  readonly bpLogoPath = 'assets/images/logomark_medium_orange_bkg.svg';
  private theme$: BehaviorSubject<ThemeClass | null> = new BehaviorSubject(
    null,
  );

  constructor(
    private bpSdkStore: BpSdkStoreService,
    private localStorageService: LocalStorageService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  initialize(): Promise<void> {
    this.addThemeFromCache();
    this.addThemeFromUriSubdomain();
    return this.setThemeFromTenant();
  }

  getThemeLogoPath$(): Observable<string> {
    const tenantPlanSku$ = this.bpSdkStore.sdkReadyState$.pipe(
      select(selectPlanSku),
      startWith(''),
    );

    return combineLatest([
      tenantPlanSku$,
      this.theme$.pipe(filter(Boolean)),
    ]).pipe(
      switchMap(([planSku, theme]) =>
        iif(
          () =>
            allowCustomLogoByTheme.includes(theme) ||
            allowCustomLogoByPlanSku.includes(planSku),
          this.bpSdkStore.state$.pipe(
            select(getTenantLogo),
            map((logo) => logo ?? this.bpLogoPath),
          ),
          of(this.bpLogoPath),
        ),
      ),
    );
  }

  private async setThemeFromTenant() {
    const user$ = this.bpSdkStore.state$.pipe(select(selectUser));
    const user = await firstValueFrom(user$.pipe(skipUntil(user$)));

    if (user) {
      const tenantSubdomain = user?.extra.raw_info.tenant_subdomain;
      const tenantTemplateSubdomain = user?.extra.raw_info.template_subdomain;
      const theme = this.resolveThemeFromTenant(
        tenantSubdomain,
        tenantTemplateSubdomain,
      );
      if (theme) {
        this.setTheme(theme);
      } else {
        this.removeThemeClasses();
      }
    }
  }

  private resolveThemeFromTenant(
    tenantSubdomain: string,
    templateSubdomain: string | undefined | null,
  ): ThemeClass | undefined {
    let theme = themesByTenantSubdomain.get(tenantSubdomain);
    if (!theme) {
      theme = themesByTenantSubdomain.get(templateSubdomain || '');
    }
    return theme;
  }

  private addThemeFromCache() {
    const cachedTheme = this.localStorageService.retrieve(
      localStorageKeys.theme,
    );
    if (cachedTheme) {
      this.setTheme(cachedTheme);
    }
  }

  private addThemeFromUriSubdomain() {
    const window = this.document.defaultView;
    if (window) {
      const subdomain = getCustomSubdomain(
        window.location.hostname,
        window.location.search,
        environment.envTag,
      );
      const theme = themesByTenantSubdomain.get(subdomain);
      if (theme) {
        this.setTheme(theme);
      }
    }
  }

  private setTheme(theme: ThemeClass) {
    this.theme$.next(theme);
    this.localStorageService.store(localStorageKeys.theme, theme);
    this.document.documentElement.classList.add(theme);
  }

  private removeThemeClasses() {
    this.theme$.next('default');
    themeClasses.forEach((themeClass) => {
      this.localStorageService.clear(localStorageKeys.theme);
      this.document.documentElement.classList.remove(themeClass);
    });
  }
}
