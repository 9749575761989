<div class="welcome-title-banner">
  <ng-container *ngIf="carrierLogo$ | async">
    <div class="image-box">
      <app-image class="logo" [src]="(carrierLogo$ | async) ?? ''"></app-image>
    </div>
  </ng-container>
  <h2 class="welcome-title">Westfield questionnaire</h2>
</div>

<form *ngIf="formGroup" [formGroup]="formGroup">
  <div *ngIf="priorAppointmentRequestControl">
    Has your agency applied for a Westfield sub appointment or direct
    appointment within the last 6 months?
    <emperor-radio-button
      class="westfield-compliance-question"
      [choices]="westfieldComplianceChoices"
      [formControl]="priorAppointmentRequestControl"
    ></emperor-radio-button>
  </div>

  <div *ngIf="minimumOneYearExperienceControl">
    Does your agency principal have at least one year of experience writing P&C
    insurance as the primary product offering?
    <emperor-radio-button
      class="westfield-compliance-question"
      [choices]="westfieldComplianceChoices"
      [formControl]="minimumOneYearExperienceControl"
    ></emperor-radio-button>
  </div>

  <div *ngIf="physicalLocationControl">
    Is the business address you provided a physical brick & mortar location?
    <emperor-radio-button
      class="westfield-compliance-question"
      [choices]="westfieldComplianceChoices"
      [formControl]="physicalLocationControl"
    ></emperor-radio-button>
  </div>

  <div *ngIf="onboardingTrainingControl">
    Would you be willing to attend an onboarding training session prior to
    quoting with Westfield?
    <emperor-radio-button
      class="westfield-compliance-question"
      [choices]="westfieldComplianceChoices"
      [formControl]="onboardingTrainingControl"
    ></emperor-radio-button>
  </div>
</form>
