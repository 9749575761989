import { ProductFeatureType } from '@boldpenguin/emperor-features';
import {
  AppetiteMarkets,
  IApplicationFormQuestionSet,
  ICarrier,
  ICarrierWithProducts,
  IQuoteableCarriersProduct,
  IQuotesState,
  IRealTimeEligibilityCarrier,
  QuoteRequestRequestTypes,
  getAllRTECarriers,
  getEligibleCarriersWithProducts,
  getEligibleOfflineCarriersProducts,
  getEligibleOnlineCarriersProducts,
  getFormId,
  getQuestionSets,
  getViewStateFeatures,
} from '@boldpenguin/sdk';
import { createSelector } from '@ngrx/store';
import {
  QuoteRequestNotOptedIn,
  getEligibleCarrierAsQuoteRequest,
  groupQuotesByProduct,
  showPreOptInEligibleCarrier,
} from 'src/app/features/quotes/utilities/quote-utilities';
import { isAdditionalMarketCarrier } from 'src/app/shared/utilities/carrier-utilities';

const RTEToQuoteRequest = (
  carrier: IRealTimeEligibilityCarrier,
  applicationFormId: string,
): IQuotesState => {
  const allProductsHaveBeenEvaluated = carrier.products.every(
    (product) => product.evaluated,
  );

  const firstProductId = carrier.products.length ? carrier.products[0].id : '';

  return {
    active: true,
    annual_price: '',
    application_form_id: applicationFormId,
    bindable: true,
    calls_to_action: [],
    carrier: {
      name: carrier.name,
      code: carrier.code,
      logo_url: carrier.logo_url,
      labels: carrier.labels,
      id: carrier.id,
    } as ICarrier,
    consumer_selected: true,
    coverage: {
      disclaimer: '',
      limit_and_deductible: [],
      limit_and_deductible_default: [],
    },
    created_at: new Date().toISOString(),
    customizable: false,
    downpayment: '',
    effective_date: null,
    expired: false,
    finished_at: new Date().toISOString(),
    focused: false,
    id: (allProductsHaveBeenEvaluated ? firstProductId : '') as string,
    monthly_price: '',
    number_of_installments: 0,
    online: false,
    order: 0,
    owner_id: '',
    permitted_to_bind: true,
    preferred: false,
    price_details: {
      fees: [],
      new_total_premium: '',
      taxes: [],
      disclaimer_html: null,
      premiums: [],
    },
    products: carrier.products.map((product) => ({
      from_name: null,
      id: product.id as string,
      name: product.name,
    })),
    quote_number: '',
    recommended: false,
    request_status: (allProductsHaveBeenEvaluated
      ? QuoteRequestRequestTypes.referral
      : undefined) as QuoteRequestRequestTypes,
    retrieve_url: '',
    semiannual_price: '',
    selected: false,
    started_at: new Date().toISOString(),
    surcharge: 0,
    tenant_id: '',
    translated_messages: [],
    updated_at: new Date().toISOString(),
    actions: [],
    attachments: [],
    supplier_code: null,
    supplier_name: null,
    supplier_logo_url: null,
    supplier_logo_url_full: null,
  };
};

export const selectOfflineCarriersFromApplicationFormAsQuoteRequests =
  createSelector(
    getAllRTECarriers,
    getFormId,
    (carriers, applicationFormId): IQuotesState[] =>
      carriers?.reduce((offlineCarriers: IQuotesState[], carrier) => {
        if (carrier.eligible && carrier.products.length > 0) {
          const online = carrier.products.filter(
            (p) => p.market === AppetiteMarkets.BP_QUOTABLE && p.evaluated,
          );
          const offline = carrier.products.filter(
            (p) => p.market === AppetiteMarkets.OFFLINE && p.evaluated,
          );
          const hasOfflineProductWithNoOnlineEquivalent = offline.filter(
            (offlineProduct) =>
              !online.find(
                (onlineProduct) => onlineProduct.name === offlineProduct.name,
              ),
          );
          if (hasOfflineProductWithNoOnlineEquivalent.length > 0) {
            offlineCarriers.push(
              RTEToQuoteRequest(
                {
                  ...carrier,
                  products: hasOfflineProductWithNoOnlineEquivalent,
                },
                applicationFormId,
              ),
            );
          }
        }
        return offlineCarriers;
      }, []),
  );

export const selectOfflineEligibleCarriersAsQuoteRequestsLegacy =
  createSelector(
    getEligibleOfflineCarriersProducts,
    getEligibleOnlineCarriersProducts,
    (
      offlineCarriersProducts: IQuoteableCarriersProduct[],
      onlineCarriersProducts: IQuoteableCarriersProduct[],
    ): IQuotesState[] =>
      offlineCarriersProducts
        // Removes offline products where an online equivalent is present.
        // This _might_ eventually move down as a selector from SDK.
        .filter(
          (cp) =>
            !onlineCarriersProducts.some(
              (ocp) =>
                ocp.carrier.id === cp.carrier.id &&
                ocp.product.id === cp.product.id,
            ),
        )
        .map((cp) => ({
          active: true,
          annual_price: '',
          application_form_id: cp.application_form_id,
          bindable: true,
          calls_to_action: [],
          carrier: cp.carrier,
          consumer_selected: true,
          coverage: {
            disclaimer: '',
            limit_and_deductible: [],
            limit_and_deductible_default: [],
          },
          created_at: new Date().toISOString(),
          customizable: false,
          downpayment: '',
          effective_date: null,
          expired: false,
          finished_at: new Date().toISOString(),
          focused: false,
          id: cp.carriers_product_id,
          monthly_price: '',
          number_of_installments: 0,
          online: false,
          order: 0,
          owner_id: '',
          permitted_to_bind: true,
          preferred: false,
          price_details: {
            fees: [],
            new_total_premium: '',
            taxes: [],
            disclaimer_html: null,
            premiums: [],
          },
          products: [{ from_name: null, ...cp.product }],
          quote_number: '',
          recommended: false,
          request_status: QuoteRequestRequestTypes.referral,
          retrieve_url: '',
          semiannual_price: '',
          selected: false,
          started_at: new Date().toISOString(),
          surcharge: 0,
          tenant_id: '',
          translated_messages: [],
          updated_at: new Date().toISOString(),
          actions: [],
          attachments: [],
          quotes: [],
          supplier_code: null,
          supplier_name: null,
          supplier_logo_url: null,
          supplier_logo_url_full: null,
        })),
  );

export const selectOfflineEligibleCarriersAsQuoteRequests = createSelector(
  selectOfflineCarriersFromApplicationFormAsQuoteRequests,
  selectOfflineEligibleCarriersAsQuoteRequestsLegacy,
  getViewStateFeatures,
  (newSelector, legacySelector, viewState) => {
    const hasFeatureEnabled = viewState.list.includes(
      ProductFeatureType.UseRecommendedCarriers,
    );
    return hasFeatureEnabled ? newSelector : legacySelector;
  },
);

export const selectAllOfflineQuotesGroupedByProducts = createSelector(
  selectOfflineEligibleCarriersAsQuoteRequests,
  (offlineQuoteRequests): { [key: string]: Array<IQuotesState> } =>
    groupQuotesByProduct(offlineQuoteRequests),
);

export const selectEligibleAdditionalMarketCarriersWithProducts =
  createSelector(
    getEligibleCarriersWithProducts,
    (carriers: ICarrierWithProducts[]) =>
      carriers.filter((carrier) => isAdditionalMarketCarrier(carrier.labels)),
  );

export const selectOptInRequiredCarriers = createSelector(
  selectEligibleAdditionalMarketCarriersWithProducts,
  getQuestionSets,
  (eligibleCarriers, questionSets: IApplicationFormQuestionSet[]) =>
    eligibleCarriers
      .filter((carrier) => showPreOptInEligibleCarrier(carrier, questionSets))
      .map((carrier) =>
        getEligibleCarrierAsQuoteRequest(carrier, QuoteRequestNotOptedIn),
      ),
);
