import { ComplianceResponse } from '../../models/compliance-form.model';

export const complianceFormStateKey = 'complianceForm';

export interface ComplianceFormState {
  isChecked: boolean;
  isLoaded: boolean;
  complianceForm: ComplianceResponse | null;
}

export const complianceFormInitialState: ComplianceFormState = {
  isChecked: false,
  isLoaded: false,
  complianceForm: null,
};
