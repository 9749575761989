export const getCustomSubdomain = (
  hostname: string | undefined,
  queryParams: string | undefined,
  envTag: string,
): string => {
  const currentHostname = hostname;
  const splitHostname = currentHostname?.split('.') ?? [];
  // Check to see if we are at a *-<baseurl version of the url. If so, we should
  // dispatch it
  let subdomain = getDashSubdomain(splitHostname);

  // Next, check to see if we have a `<subdomain>.<env>.boldpenguin.com` subdomain, or a
  // `<subdomain>.terminal.<env>.boldpenguin.com` or a prod `<subdomain>.terminal.boldpenguin.com` or
  // `<subdomain>.boldpenguin.com`
  if (!subdomain) {
    subdomain = getStandardSubdomain(splitHostname, envTag);
  }

  // If we are running in a canary environment, our custom domain will here be `terminal` since
  // our url scheme is `terminal-PR#.canary.alpha.boldpenguin.com`
  // If we are running in a standard environment, our custom domain will here be `terminal` since
  // our url scheme is `terminal.<env>.boldpenguin.com` or `terminal.boldpenguin.com`
  if (subdomain.toLocaleLowerCase() === 'terminal') {
    subdomain = '';
  }

  // sometimes we need to access enterprise tenants where creating a subdomain is not practical
  // ie. in canary environments. Allow the use of a query parameter in the format of
  // `?subdomain=<subdomain>` as a workaround
  const params = new URLSearchParams(queryParams);
  if (params.has('subdomain')) {
    subdomain = params.get('subdomain') || '';
  }

  return subdomain;
};

const getDashSubdomain = (splitHostname: string[]): string => {
  const domain = splitHostname[0] ?? '';
  const dashSplit = domain.split('-');
  let subdomain = '';
  if (dashSplit.length > 1) {
    subdomain = dashSplit[0];
  }
  return subdomain;
};

const getStandardSubdomain = (
  splitHostname: string[],
  envTag: string,
): string => {
  let subdomain = '';
  const secondDomainPart = splitHostname.length > 1 ? splitHostname[1] : '';
  if (
    !!secondDomainPart &&
    (secondDomainPart.toLocaleLowerCase() === envTag.toLocaleLowerCase() ||
      secondDomainPart.toLocaleLowerCase() === 'boldpenguin' ||
      secondDomainPart.toLocaleLowerCase() === 'terminal')
  ) {
    subdomain = splitHostname[0].toLocaleLowerCase();
  }
  return subdomain;
};
