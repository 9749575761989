import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productNames',
})
export class ProductNamesPipe implements PipeTransform {
  transform(products: string[] = []): { name: string }[] {
    return products.map((product) => ({ name: product }));
  }
}
