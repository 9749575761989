import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
  MixpanelEventType,
  MixpanelService,
} from '../../core/services/mixpanel-service/mixpanel.service';
import {
  AppFormTrackingActions,
  ExchangeConcurrencyLimitIncrement,
  ExchangeDailyLimitIncrement,
} from './component-tracking.actions';

@Injectable()
export class ComponentTrackingEffects {
  trackMixpanelEventsOnIncrementButtonClick$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AppFormTrackingActions.incrementButtonClicked,
          ExchangeConcurrencyLimitIncrement,
          ExchangeDailyLimitIncrement,
        ),
        map((incrementAction) => {
          this.mixpanelService.track(
            MixpanelEventType.COMPONENT_TRACKING_INCREMENT,
            {
              componentName: 'IncrementInputComponent',
              source: incrementAction.source,
              description: incrementAction.description,
            },
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private mixpanelService: MixpanelService,
    private actions$: Actions,
  ) {}
}
