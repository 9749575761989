import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { FileInputService } from 'src/app/core/services/file-input/file-input.service';

@UntilDestroy()
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @ViewChild('fileDropRef') fileDropRef: ElementRef<HTMLInputElement>;
  @Input() multiple = false;
  /**
   * Comma-separated list of file types or extensions based on web standards. Use '*' to accept any file type.
   * See {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers | File type formats}
   */
  @Input() accept = '*';
  /**
   * The number of files which can be uploaded. If multiple is false, this will default to 1
   */
  @Input() maxNumber = Number.MAX_SAFE_INTEGER;
  @Input() reset?: Subject<void>;
  @Input() loading = false;
  @Input() subtext = '';

  @Output() files: EventEmitter<File[]> = new EventEmitter();
  @Output() rejectedFiles: EventEmitter<File[]> = new EventEmitter();

  fileOver = false;
  uploadedFiles: File[] = [];

  constructor(private fileInputService: FileInputService) {}

  @HostListener('drop', ['$event'])
  public onDrop(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.rejectedFiles.emit();

    this.fileOver = false;

    const files = event.dataTransfer?.files ?? ({ length: 0 } as FileList);
    if (files.length > 0) {
      this.handleFiles(files);
    }
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.fileOver = false;
  }

  ngOnInit() {
    if (this.reset) {
      this.reset.pipe(untilDestroyed(this)).subscribe(() => {
        this.uploadedFiles = [];
        this.clearInputValue();
      });
    }
  }

  handleFilesChanged(event: Event): void {
    this.rejectedFiles.emit();
    const target = event.target as HTMLInputElement;
    if (!!target.files) {
      this.handleFiles(target.files);
    }
  }

  handleFiles(fileList: FileList) {
    const validFiles = this.removeFilesWithIncorrectType(fileList);
    if (validFiles.length > 0) {
      const maxFilesAllowed = Math.max(this.maxNumber, 1);
      const numberOfFilesToAllow = this.multiple ? maxFilesAllowed : 1;
      const allowedFiles = validFiles.slice(-1 * numberOfFilesToAllow);

      this.uploadedFiles = allowedFiles;
      this.setNativeInputValue(allowedFiles);
      this.files.emit(allowedFiles);
    }
  }

  clearInputValue() {
    this.setNativeInputValue([]);
  }

  /**
   * @description
   * Sets given Files as value of the native file input
   */
  private setNativeInputValue(files: File[]): void {
    const fileList = this.fileInputService.convertFilesToFileList(files);
    this.fileDropRef.nativeElement.files = fileList;
  }

  private removeFilesWithIncorrectType(files: FileList): File[] {
    const acceptedFileTypes = this.accept.split(',');

    // Keep track of rejected files so that the parent knows about them
    const rejectedFiles: File[] = [];
    Array.from(files).forEach((file) => {
      const acceptable = acceptedFileTypes.some((type) =>
        this.fileInputService.hasAcceptedFileType(file, type),
      );
      if (!acceptable) {
        rejectedFiles.push(file);
      }
    });

    if (rejectedFiles.length) {
      this.rejectedFiles.emit(rejectedFiles);
    }

    return Array.from(files).filter((file) =>
      acceptedFileTypes.some((type) =>
        this.fileInputService.hasAcceptedFileType(file, type),
      ),
    );
  }
}
