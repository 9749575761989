import { Component } from '@angular/core';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectIntercomEnabled } from 'src/app/core/services/bp-sdk-store-service/bp-sdk-store.selectors';
import { BpSdkStoreService } from 'src/app/core/services/bp-sdk-store-service/bp-sdk-store.service';
import { IntercomInternalLabels } from 'src/app/core/services/intercom-service/intercom.service';
import { IMenuItem } from '../../models/menus';
import { MenuItemsService } from '../../services/menu-items.service';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
})
export class NavigationMenuComponent {
  /**
   * For setting the data-intercom-target attribute
   */
  intercomLabels = IntercomInternalLabels;
  /**
   * List of items to show in the primary menu
   */
  mainMenuItems$: Observable<IMenuItem[]> =
    this.menuItemsService.mainMenuItems$;
  /**
   * List of items to show under settings
   */
  settingsMenuItems$: Observable<IMenuItem[]> =
    this.menuItemsService.settingsMenuItems$;

  intercomEnabled$ = this.bpSdkStoreService.sdkReadyState$.pipe(
    select(selectIntercomEnabled),
  );

  constructor(
    private menuItemsService: MenuItemsService,
    private bpSdkStoreService: BpSdkStoreService,
  ) {}
}
