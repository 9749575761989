import { createReducer, on } from '@ngrx/store';
import * as BankAccountActions from './bank-account.actions';

export const bankAccountStateKey = 'bankAccount';

export interface IBankAccount {
  institutionName: string;
  accountName: string;
  maskedAccount: string;
  routing: string;
}
export interface BankAccountState {
  account: null | IBankAccount;
  isLoading: boolean;
}

export const bankAccountInitialState: BankAccountState = {
  account: null,
  isLoading: false,
};

export const BankAccountReducer = createReducer(
  bankAccountInitialState,
  on(
    BankAccountActions.GetBankAccount,
    BankAccountActions.RemoveBankAccount,
    BankAccountActions.LinkBankAccountSuccess,
    (state: BankAccountState): BankAccountState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    BankAccountActions.GetBankAccountSuccess,
    (state: BankAccountState, { bankAccount }): BankAccountState => ({
      ...state,
      isLoading: false,
      account: {
        institutionName: bankAccount.institution_name,
        accountName: bankAccount.account_name,
        maskedAccount: bankAccount.account,
        routing: bankAccount.routing,
      },
    }),
  ),
  on(
    BankAccountActions.RemoveBankAccountSuccess,
    (): BankAccountState => ({
      ...bankAccountInitialState,
    }),
  ),
  on(
    BankAccountActions.GetBankAccountError,
    BankAccountActions.RemoveBankAccountError,
    (state: BankAccountState): BankAccountState => ({
      ...state,
      isLoading: false,
    }),
  ),
);
