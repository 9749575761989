import { Injectable } from '@angular/core';
import {
  ICoreOptions,
  IUser,
  logout as authLogout,
  getToken,
  hasPermission,
  init,
  login,
  loginWithCookie,
  loginWithUsernameAndPassword,
} from '@boldpenguin/sdk';
import { environment } from '@environments/environment';
import { LocalStorageService } from 'ngx-webstorage';
import { localStorageKeys } from 'src/app/shared/constants';
import { IntercomService } from '../intercom-service/intercom.service';

export interface IAdditionalLoginOptions {
  provider?: string;
  inviteToken?: string;
  login_hint?: string;
}

export interface IBpSdkCoreService {
  init(config: ICoreOptions): Promise<ICoreOptions>;
  login(config: ICoreOptions, options?: IAdditionalLoginOptions): void;
  loginWithUsernameAndPassword(
    config: ICoreOptions,
    username: string,
    password: string,
  ): Promise<string | undefined>;
  loginUnified(redirectUri: string): void;
  logout(): Promise<void>;
  hasPermission(data: { user: IUser; model: string; action: string }): boolean;
}

/**
 * One to one wrapper service for SDK core
 */
@Injectable({
  providedIn: 'root',
})
export class BpSdkCoreService implements IBpSdkCoreService {
  constructor(
    private intercomService: IntercomService,
    private localStorageService: LocalStorageService,
  ) {}

  async init(config) {
    return await init(config);
  }

  public login(config: ICoreOptions, options?: IAdditionalLoginOptions): void {
    return login(config, options);
  }

  // TODO: Remove this when removing password reset from terminal
  public loginWithUsernameAndPassword(
    config: ICoreOptions,
    username: string,
    password: string,
  ): Promise<string | undefined> {
    return loginWithUsernameAndPassword(config, username, password);
  }

  public loginUnified(callbackUri: string, subdomain?: string): void {
    const config = {
      authUri: environment.apiAuthUri,
      env: environment.sdkEnvTag,
      callbackUri,
    };
    const options: { login_hint?: string } = {};
    if (subdomain) {
      options.login_hint = subdomain;
    }
    loginWithCookie(config, options);
  }

  public async logout(): Promise<void> {
    this.intercomService.shutdown();
    this.localStorageService.clear(localStorageKeys.impersonated);

    const config = {
      authUri: environment.apiAuthUri,
      env: environment.sdkEnvTag,
      clientId: environment.clientId,
      token: getToken() || undefined,
    };
    await authLogout(config);
    location.href = `${location.origin}/auth/logged-out`;
  }

  public hasPermission(data: {
    user: IUser;
    model: string;
    action: string;
  }): boolean {
    return hasPermission(data);
  }
}
