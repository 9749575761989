import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppointmentTypes } from '@boldpenguin/sdk';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { combineLatest, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ComplianceFormEnrollmentIdService } from 'src/app/core/services/compliance-form-enrollment-id-service/compliance-form-enrollment-id.service';
import { EnrollmentsService } from 'src/app/core/services/enrollments-service/enrollments.service';
import {
  MixpanelEventType,
  MixpanelService,
} from 'src/app/core/services/mixpanel-service/mixpanel.service';
import { Messages } from 'src/app/core/services/notification-service/notification-messages';
import { NotificationService } from 'src/app/core/services/notification-service/notification.service';
import { Features } from 'src/app/features/features';
import * as QuoteActions from 'src/app/features/quotes/store/quotes.actions';
import { SettingsSubFeatures } from 'src/app/features/settings/models/sub-features';
import { selectSignupPlanSku } from 'src/app/features/signup/store/signup.selectors';
import { IEnrollment } from '../../models/enrollments.model';
import { EnrollmentUpdateService } from '../../services/enrollment-update-service/enrollment-update.service';
import { getWelcomeDirectAppointmentActivateErrorMessage } from '../../services/notification-service/dynamic-notification-messages';
import * as EnrollmentsActions from './enrollments.actions';

@Injectable()
export class EnrollmentsEffects {
  getEnrollments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnrollmentsActions.GetAllEnrollments),
      switchMap(() =>
        this.enrollmentsService.getEnrollments().pipe(
          map((enrollments) =>
            EnrollmentsActions.GetAllEnrollmentsSuccess({ enrollments }),
          ),
          catchError((e: { error: Error }) =>
            of(EnrollmentsActions.GetAllEnrollmentsError(e)),
          ),
        ),
      ),
    ),
  );

  toggleEnrollment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnrollmentsActions.UpdateEnrollmentAction),
      tap((action) => {
        const { activeStatus, enrollment } = action;
        this.mixpanelService.track(
          MixpanelEventType.CARRIER_APPOINTMENT_CARRIER_STATUS_CHANGE_REQUESTED,
          {
            carrier: enrollment.carrier_subdomain,
            appointment_type: enrollment.appointment_type || '',
            active: activeStatus,
          },
        );
      }),
      switchMap((action) =>
        this.enrollmentUpdateService
          .updateEnrollment(action.enrollment, action.activeStatus)
          .pipe(
            map((enrollment) =>
              EnrollmentsActions.UpdateEnrollmentSuccessAction({
                enrollment,
              }),
            ),
            catchError((err: HttpErrorResponse) => {
              const errorMessages = this.mapEnrollmentUpdateErrors(err);

              return of(
                EnrollmentsActions.UpdateEnrollmentErrorAction({
                  error: errorMessages,
                  enrollment: action.enrollment,
                }),
              );
            }),
          ),
      ),
    ),
  );

  updateEnrollment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnrollmentsActions.QuoteViewUpdateEnrollmentCredentials),
      switchMap((action) =>
        this.enrollmentUpdateService
          .updateEnrollment(action.enrollment, true)
          .pipe(
            mergeMap((enrollment) => [
              EnrollmentsActions.QuoteViewUpdateEnrollmentCredentialsSuccess({
                enrollment,
              }),
              QuoteActions.ResubmitCarrierQuote({
                carrierId: enrollment.carrier_id,
              }),
            ]),
            catchError((err: HttpErrorResponse) => {
              const errorMessages = this.mapEnrollmentUpdateErrors(err);

              return of(
                EnrollmentsActions.QuoteViewUpdateEnrollmentCredentialsError({
                  error: errorMessages,
                  enrollment: action.enrollment,
                }),
              );
            }),
          ),
      ),
    ),
  );

  enrollmentUpdatedSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        EnrollmentsActions.UpdateEnrollmentSuccessAction,
        EnrollmentsActions.UpdateEnrollmentAppointmentSuccess,
      ),
      map(() =>
        EnrollmentsActions.UpdateShowEnrollmentAppointmentDialog({
          showDialog: false,
        }),
      ),
    ),
  );

  toggleAppointment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnrollmentsActions.UpdateEnrollmentAppointmentAction),
      tap((action) => {
        const { activeStatus, enrollment } = action;
        this.mixpanelService.track(
          MixpanelEventType.CARRIER_APPOINTMENT_CARRIER_STATUS_CHANGE_REQUESTED,
          {
            carrier: enrollment.carrier_subdomain,
            appointment_type: enrollment.appointment_type || '',
            active: activeStatus,
          },
        );
      }),
      switchMap((action) =>
        this.enrollmentsService
          .requestEnrollmentAppointment(action.enrollment.id)
          .pipe(
            map(() => {
              const { enrollment } = action;
              this.notificationService.success(
                Messages.ENROLLMENT_APPOINTMENT_SUCCESS,
              );
              this.mixpanelService.track(
                MixpanelEventType.CARRIER_APPOINTMENT_CARRIER_TOGGLED,
              );
              this.mixpanelService.track(
                MixpanelEventType.CARRIER_APPOINTMENT_CARRIER_STATUS_CHANGED,
                {
                  carrier: enrollment.carrier_subdomain,
                  active: enrollment.active,
                  appointment_type: enrollment.appointment_type || '',
                  succeeded: true,
                },
              );

              return EnrollmentsActions.UpdateEnrollmentAppointmentSuccess({
                enrollment: action.enrollment,
              });
            }),
            catchError((err: HttpErrorResponse) => {
              if (err.status === 422) {
                const {
                  error: {
                    errors: { enrollment },
                  },
                } = err;
                this.notificationService.error(enrollment[0]);
              } else {
                this.notificationService.error(
                  Messages.ENROLLMENT_APPOINTMENT_FAILURE,
                );
              }
              this.mixpanelService.track(
                MixpanelEventType.CARRIER_APPOINTMENT_CARRIER_STATUS_CHANGED,
                {
                  carrier: action.enrollment.carrier_subdomain,
                  active: action.enrollment.active,
                  appointment_type: action.enrollment.appointment_type || '',
                  succeeded: false,
                },
              );

              return of(
                EnrollmentsActions.UpdateEnrollmentAppointmentFailure(),
              );
            }),
          ),
      ),
    ),
  );

  updateCarrierAttributes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnrollmentsActions.UpdateCarrierAttributes),
      tap((action) => {
        const { enrollment } = action;
        this.mixpanelService.track(
          MixpanelEventType.WELCOME_CARRIER_ATTRIBUTES_UPDATE_REQUESTED,
          {
            carrier: enrollment.carrier_subdomain,
            appointment_type: enrollment.appointment_type || '',
            attributes: enrollment.carrier_attributes.toString(),
          },
        );
      }),
      switchMap((action) =>
        this.enrollmentUpdateService
          .updateEnrollment(
            action.enrollment,
            action.enrollment.active,
            true,
            true,
          )
          .pipe(
            map((enrollment) =>
              EnrollmentsActions.UpdateCarrierAttributesSuccess({
                enrollment,
              }),
            ),
            catchError((err: HttpErrorResponse) => {
              const errorMessages = this.mapEnrollmentUpdateErrors(err);

              return of(
                EnrollmentsActions.UpdateCarrierAttributesError({
                  error: errorMessages,
                  enrollment: action.enrollment,
                }),
              );
            }),
          ),
      ),
    ),
  );

  updateCarrierAttributesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnrollmentsActions.UpdateCarrierAttributesSuccess),
      map(({ enrollment }) => {
        this.mixpanelService.track(
          MixpanelEventType.WELCOME_CARRIER_ATTRIBUTES_UPDATE_SUCCESS,
          {
            carrier: enrollment.carrier_subdomain,
            appointment_type: enrollment.appointment_type || '',
            ...enrollment.carrier_attributes,
          },
        );
        return EnrollmentsActions.UpdateShowEnrollmentAppointmentDialog({
          showDialog: false,
        });
      }),
    ),
  );

  updateCarrierAttributesError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EnrollmentsActions.UpdateCarrierAttributesError),
        tap(({ enrollment, error }) => {
          this.mixpanelService.track(
            MixpanelEventType.WELCOME_CARRIER_ATTRIBUTES_UPDATE_ERROR,
            {
              carrier: enrollment.carrier_subdomain,
              appointment_type: enrollment.appointment_type || '',
              ...enrollment.carrier_attributes,
              error,
            },
          );
        }),
      ),
    { dispatch: false },
  );

  activateDirectEnrollments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnrollmentsActions.ActivateDirectAppointments),
      tap(({ enrollments }) => {
        this.mixpanelService.track(
          MixpanelEventType.WELCOME_DIRECT_APPOINTMENTS_ACTIVATE,
          {
            carriers: enrollments.map(
              (enrollment) => enrollment.carrier_subdomain,
            ),
          },
        );
      }),
      switchMap(({ enrollments }) => {
        if (!enrollments.length) {
          return of(
            EnrollmentsActions.ActivateDirectAppointmentsSuccess({
              enrollments: [],
            }),
          );
        }

        return combineLatest(
          enrollments.map((enrollment) =>
            this.enrollmentsService
              .activateDirectEnrollment(enrollment)
              .pipe(
                catchError((error) =>
                  throwError({ error, failedEnrollment: enrollment }),
                ),
              ),
          ),
        ).pipe(
          map((response: IEnrollment[]) =>
            EnrollmentsActions.ActivateDirectAppointmentsSuccess({
              enrollments: response,
            }),
          ),
          catchError(({ error: httpError, failedEnrollment }) =>
            of(
              EnrollmentsActions.ActivateDirectAppointmentsError({
                error: httpError.error,
                enrollments,
                failedEnrollment,
              }),
            ),
          ),
        );
      }),
    ),
  );

  activateDirectEnrollmentsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EnrollmentsActions.ActivateDirectAppointmentsSuccess),
        tap(({ enrollments }) => {
          this.mixpanelService.track(
            MixpanelEventType.WELCOME_DIRECT_APPOINTMENTS_ACTIVATE_SUCCESS,
            {
              carriers: enrollments.map(
                (enrollment) => enrollment.carrier_subdomain,
              ),
            },
          );
        }),
      ),
    { dispatch: false },
  );

  activateDirectEnrollmentsError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EnrollmentsActions.ActivateDirectAppointmentsError),
        tap(({ enrollments, error, failedEnrollment }) => {
          this.notificationService.error(
            getWelcomeDirectAppointmentActivateErrorMessage(
              failedEnrollment.carrier_name,
            ),
            {
              duration: 9000,
            },
          );
          this.mixpanelService.track(
            MixpanelEventType.WELCOME_DIRECT_APPOINTMENTS_ACTIVATE_ERROR,
            {
              carriers: enrollments.map(
                (enrollment) => enrollment.carrier_subdomain,
              ),
              error: error.message,
            },
          );
        }),
        mergeMap(({ enrollments, failedEnrollment }) =>
          combineLatest(
            enrollments
              .filter((enrollment) => enrollment.id !== failedEnrollment.id)
              .map((enrollment) =>
                this.enrollmentUpdateService.updateEnrollment(
                  enrollment,
                  false,
                  false,
                ),
              ),
          ),
        ),
      ),
    { dispatch: false },
  );

  getAvailableEnrollments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnrollmentsActions.GetAvailableEnrollments),
      concatLatestFrom(() => this.store.pipe(select(selectSignupPlanSku))),
      switchMap(([_action, planSku]) =>
        this.enrollmentsService.getAvailableEnrollments(planSku).pipe(
          map(
            (enrollments) =>
              EnrollmentsActions.GetAvailableEnrollmentsSuccess({
                enrollments,
              }),
            catchError((error) =>
              of(
                EnrollmentsActions.GetAvailableEnrollmentsError({
                  error,
                }),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  requestEnrollmentAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnrollmentsActions.RequestEnrollmentAppointments),
      tap(({ enrollments }) => {
        this.mixpanelService.track(
          MixpanelEventType.WELCOME_SUBAPPOINTMENTS_REQUEST,
          {
            enrollments: enrollments.map(
              (enrollment) => enrollment.carrier_name,
            ),
          },
        );
      }),
      switchMap(({ enrollments }) => {
        if (!enrollments.length) {
          return of(
            EnrollmentsActions.RequestEnrollmentAppointmentsSuccess({
              enrollments: [],
            }),
          );
        }

        const instantApprovalEnrollments = enrollments.filter(
          (enrollment) => enrollment.allow_instant_enrollment,
        );
        const manualApprovalEnrollments = enrollments.filter(
          (enrollment) => !enrollment.allow_instant_enrollment,
        );

        return combineLatest([
          ...manualApprovalEnrollments.map((enrollment) =>
            this.enrollmentsService.requestEnrollmentAppointment(enrollment.id),
          ),
          ...instantApprovalEnrollments.map((enrollment) =>
            this.enrollmentsService.updateEnrollment(
              {
                ...enrollment,
                appointment_type: AppointmentTypes.Sub,
              },
              true,
            ),
          ),
        ]).pipe(
          map(() =>
            EnrollmentsActions.RequestEnrollmentAppointmentsSuccess({
              enrollments,
            }),
          ),
          catchError((error: Error) =>
            of(
              EnrollmentsActions.RequestEnrollmentAppointmentsError({
                error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  requestEnrollmentAppointmentsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EnrollmentsActions.RequestEnrollmentAppointmentsSuccess),
        tap(({ enrollments }) => {
          this.mixpanelService.track(
            MixpanelEventType.WELCOME_SUBAPPOINTMENTS_REQUEST_SUCCESS,
            {
              enrollments: enrollments.map(
                (enrollment) => enrollment.carrier_name,
              ),
            },
          );
        }),
      ),
    { dispatch: false },
  );

  requestEnrollmentAppointmentsError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EnrollmentsActions.RequestEnrollmentAppointmentsError),
        tap(({ error }) => {
          this.notificationService.error(Messages.UPDATE_FAILED);
          this.mixpanelService.track(
            MixpanelEventType.WELCOME_SUBAPPOINTMENTS_REQUEST_ERROR,
            {
              error: error.message,
            },
          );
        }),
      ),
    { dispatch: false },
  );

  navigateToAppointmentsComplianceForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EnrollmentsActions.NavigateToAppointmentsComplianceForm),
        tap(({ enrollmentId }) => {
          this.complianceFormEnrollmentIdService.setCarrierAppointmentEnrollmentId(
            enrollmentId,
          );
          this.router.navigate([
            Features.SETTINGS,
            SettingsSubFeatures.CARRIER_APPOINTMENTS,
            SettingsSubFeatures.COMPLIANCE_FORM,
          ]);
        }),
      ),
    { dispatch: false },
  );

  navigateToComplianceFormFromAppointmentsDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnrollmentsActions.NavigateToComplianceFormFromAppointmentsDialog),
      tap(({ enrollmentId }) => {
        this.complianceFormEnrollmentIdService.setCarrierAppointmentEnrollmentId(
          enrollmentId,
        );
        if (this.router.url.includes(Features.QUOTES)) {
          const url = this.router.serializeUrl(
            this.router.createUrlTree([
              '/settings/carrier-appointments/compliance-form',
            ]),
          );
          window.open(url, '_blank');
        } else {
          this.router.navigate([
            Features.SETTINGS,
            SettingsSubFeatures.CARRIER_APPOINTMENTS,
            SettingsSubFeatures.COMPLIANCE_FORM,
          ]);
        }
      }),
      map(() =>
        EnrollmentsActions.UpdateShowEnrollmentAppointmentDialog({
          showDialog: false,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private mixpanelService: MixpanelService,
    private enrollmentsService: EnrollmentsService,
    private enrollmentUpdateService: EnrollmentUpdateService,
    private notificationService: NotificationService,
    private router: Router,
    private complianceFormEnrollmentIdService: ComplianceFormEnrollmentIdService,
    private store: Store,
  ) {}

  private mapEnrollmentUpdateErrors(
    errorResponse: HttpErrorResponse,
  ): string[] {
    const errorMessages: string[] = [];
    if (errorResponse.status === 422) {
      for (const field in errorResponse.error.errors) {
        if (errorResponse.error.errors.hasOwnProperty(field)) {
          errorMessages.push(...errorResponse.error.errors[field]);
        }
      }
    }

    return errorMessages;
  }
}
