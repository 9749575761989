export interface IApplicationCountResponse {
  new: number;
  in_progress: number;
  completed: number;
}

/**
 * State piece that keeps track of the number of various applications by status
 */
export interface IApplicationCountState {
  /**
   * Whether or not the application count has been retrieved from the backend
   */
  loaded: boolean;
  /**
   * Number of applications that have been transferred from the exchange but not taken over
   */
  new: number;
  /**
   * Number of applications that have not been dispositioned and have not been taken over after being sent by the exchange
   */
  in_progress: number;
  /**
   * Number of applications that have been dispositioned
   */
  dispositioned: number;
  /**
   * Number of applications that have been transferred from the exchange, including taken over apps
   */
  transfers: number;
  /**
   * Number of applications that have been quoted or completed
   */
  completed: number;
  /**
   * Total number of applications
   */
  all: number;
  /**
   * Latest error when fetching the count
   */
  error?: Error;
}

export class ApplicationCountState implements IApplicationCountState {
  loaded = false;
  new = 0;
  transfers = 0;
  all = 0;
  dispositioned = 0;
  in_progress = 0;
  completed = 0;

  constructor(init?: Partial<IApplicationCountState>) {
    Object.assign(this, init);
  }
}
