<app-top-navigation-toolbar
  *ngIf="showTopNav"
  [suppressNavMenu]="(suppressNavMenu$ | async) ?? true"
  [isSideNavOpen]="isSideNavOpen"
  (toggleSideNav)="sidenav.toggle()"
  [isAlertBannerPresent]="isAlertBannerPresent"
></app-top-navigation-toolbar>
<div class="emperor-ui-app-root version">
  RUNNING APP VERSION {{ appVersion }}
</div>
<bp-sdk-root [disableToolbar]="true" enable-lifecycle-events></bp-sdk-root>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    [fixedInViewport]="true"
    [fixedTopGap]="64"
    (openedStart)="isSideNavOpen = true"
    (closedStart)="isSideNavOpen = false"
  >
    <app-navigation-menu></app-navigation-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-alert-banner></app-alert-banner>
    <div
      class="main-content"
      [style.height]="
        showTopNav ? 'calc(100% - ' + (topBarHeight$ | async) + 'px)' : '100%'
      "
      [style.top.px]="showTopNav ? (topBarHeight$ | async) : '0'"
    >
      <ng-container *ngIf="complianceFormChecked$ | async; else loading">
        <router-outlet></router-outlet>
      </ng-container>

      <ng-template #loading>
        <app-loader [isFullScreen]="true"></app-loader>
      </ng-template>
    </div>

    <!-- No Enrollments Warning Dialog -->
    <app-warning-dialog
      *ngIf="showEnrollmentsWarningDialog"
      [data]="noEnrollmentsDialogData"
      (closeDialog)="hideEnrollmentsWarning()"
      (callback)="hideEnrollmentsWarning()"
    ></app-warning-dialog>
  </mat-sidenav-content>
</mat-sidenav-container>
