<mat-toolbar
  *ngFor="let alert of headerAlerts$ | async"
  class="header-alert {{ alert.theme }}"
>
  <div>
    <mat-icon *ngIf="alert.matIcon">{{ alert.matIcon }}</mat-icon>
    <span>{{ alert.message }}</span>
    <button
      mat-button
      *ngFor="let button of alert.buttons"
      [attr.role]="button.role"
      (click)="alertButtonClick(alert, button)"
    >
      {{ button.text }}
    </button>
  </div>
</mat-toolbar>
