import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import {
  MatLegacySnackBarRef as MatSnackBarRef,
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
  LegacySimpleSnackBar as SimpleSnackBar,
} from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarComponent {
  @HostBinding('class.snack-bar')
  readonly snackBarHost = true;

  data: { message: string; action: string };

  constructor(
    public snackBarRef: MatSnackBarRef<SimpleSnackBar>,
    @Inject(MAT_SNACK_BAR_DATA) data: any,
  ) {
    this.data = data;
  }

  get hasAction(): boolean {
    return !!this.data.action;
  }

  action(): void {
    this.snackBarRef.dismissWithAction();
  }
}
