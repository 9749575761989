import { ComplianceFormQuestionGroup } from 'src/app/core/models/compliance-form.model';
import { enrollmentSubdomainCollection } from 'src/app/features/welcome/welcome.model';

export const isMainComplianceFormComplete = (
  completedQuestionGroups: ComplianceFormQuestionGroup[],
): boolean =>
  completedQuestionGroups.includes(ComplianceFormQuestionGroup.MAIN);

export const getIncompleteCarrierQuestionGroups = (
  completedQuestionGroups,
): ComplianceFormQuestionGroup[] =>
  enrollmentSubdomainCollection.filter(
    (enrollmentSubdomainQuestionGroup) =>
      !completedQuestionGroups.includes(enrollmentSubdomainQuestionGroup),
  );
