import { AppointmentTypes } from '@boldpenguin/sdk';
import { Action, createReducer, on } from '@ngrx/store';
import * as EnrollmentsActions from './enrollments.actions';
import EnrollmentsState, {
  AvailableEnrollmentsAdapter,
  EnrollmentsAdapter,
  enrollmentsInitialState,
} from './enrollments.state';

const reducer = createReducer(
  enrollmentsInitialState,
  on(
    EnrollmentsActions.GetAllEnrollments,
    (state): EnrollmentsState => ({
      ...state,
      enrollmentsHaveBeenLoaded: false,
    }),
  ),
  on(EnrollmentsActions.GetAllEnrollmentsSuccess, (state, action) => ({
    ...EnrollmentsAdapter.setAll(action.enrollments, state),
    enrollmentsHaveBeenLoaded: true,
    enrollmentsError: undefined,
  })),
  on(
    EnrollmentsActions.GetAllEnrollmentsError,
    (state, { error }): EnrollmentsState => ({
      ...state,
      enrollmentsHaveBeenLoaded: true,
      enrollmentsError: error,
    }),
  ),
  on(
    EnrollmentsActions.QuoteViewUpdateEnrollmentCredentialsSuccess,
    EnrollmentsActions.UpdateCarrierAttributesSuccess,
    EnrollmentsActions.UpdateEnrollmentSuccessAction,
    (state: EnrollmentsState, { enrollment }) =>
      EnrollmentsAdapter.upsertOne(enrollment, {
        ...state,
      }),
  ),
  on(
    EnrollmentsActions.UpdateEnrollmentAppointmentSuccess,
    (state: EnrollmentsState, { enrollment }) =>
      EnrollmentsAdapter.updateOne(
        {
          id: enrollment.id,
          changes: { appointment_pending: true, active: false },
        },
        { ...state },
      ),
  ),
  on(
    EnrollmentsActions.QuoteViewUpdateEnrollmentCredentialsError,
    EnrollmentsActions.UpdateCarrierAttributesError,
    EnrollmentsActions.UpdateEnrollmentErrorAction,
    (state: EnrollmentsState, { error, enrollment }) =>
      EnrollmentsAdapter.updateOne(
        {
          id: enrollment.id,
          changes: { serverErrorMessages: error },
        },
        { ...state },
      ),
  ),
  on(
    EnrollmentsActions.QuoteViewUpdateEnrollmentCredentialsSuccess,
    EnrollmentsActions.UpdateCarrierAttributesSuccess,
    EnrollmentsActions.UpdateEnrollmentSuccessAction,
    EnrollmentsActions.CancelEnrollmentAttempt,
    (state: EnrollmentsState, { enrollment }) =>
      EnrollmentsAdapter.updateOne(
        {
          id: enrollment.id,
          changes: { serverErrorMessages: [] },
        },
        { ...state },
      ),
  ),
  on(
    EnrollmentsActions.GetAvailableEnrollmentsSuccess,
    (state, { enrollments }) => ({
      ...state,
      availableEnrollments: {
        ...AvailableEnrollmentsAdapter.setAll(
          enrollments,
          state.availableEnrollments,
        ),
      },
    }),
  ),
  on(
    EnrollmentsActions.RequestEnrollmentAppointments,
    (state): EnrollmentsState => ({
      ...state,
      requestingEnrollmentSubappointments: true,
      requestingEnrollmentSubappointmentsError: undefined,
    }),
  ),
  on(
    EnrollmentsActions.RequestEnrollmentAppointmentsSuccess,
    (state: EnrollmentsState, { enrollments }) =>
      EnrollmentsAdapter.updateMany(
        enrollments.map((enrollment) => ({
          id: enrollment.id,
          changes: {
            /*
             * With the addition of instant enrollment we can't assume appointments
             * are in the status of pending after. If the carrier is setup for instant enrollment
             * we should not set the appointment to pending.
             */
            appointment_pending: !enrollment.allow_instant_enrollment,
            active: enrollment.allow_instant_enrollment,
            appointment_type: AppointmentTypes.Sub,
          },
        })),
        {
          ...state,
          requestingEnrollmentSubappointments: false,
          requestingEnrollmentSubappointmentsError: undefined,
        },
      ),
  ),
  on(
    EnrollmentsActions.RequestEnrollmentAppointmentsError,
    (state: EnrollmentsState, { error }): EnrollmentsState => ({
      ...state,
      requestingEnrollmentSubappointments: false,
      requestingEnrollmentSubappointmentsError: error,
    }),
  ),
  on(
    EnrollmentsActions.ActivateDirectAppointments,
    (state: EnrollmentsState): EnrollmentsState => ({
      ...state,
      activatingEnrollmentDirectAppointments: true,
    }),
  ),
  on(
    EnrollmentsActions.ActivateDirectAppointmentsSuccess,
    (state: EnrollmentsState, { enrollments }) =>
      EnrollmentsAdapter.upsertMany(enrollments, {
        ...state,
        activatingEnrollmentDirectAppointments: false,
      }),
  ),
  on(
    EnrollmentsActions.ActivateDirectAppointmentsError,
    (state: EnrollmentsState): EnrollmentsState => ({
      ...state,
      activatingEnrollmentDirectAppointments: false,
    }),
  ),
  on(
    EnrollmentsActions.UpdateShowEnrollmentAppointmentDialog,
    (state: EnrollmentsState, { showDialog }): EnrollmentsState => ({
      ...state,
      showEnrollmentAppointmentDialog: showDialog,
    }),
  ),
);

export const EnrollmentsReducer = (
  state: EnrollmentsState | undefined,
  action: Action,
) => reducer(state, action);
