import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule, TitleStrategy } from '@angular/router';
import { ThemeService } from '../core/services/theme-service/theme.service';
import { ROUTES } from './routes';
import { TerminalTitleStrategy } from './terminal-title-strategy';

const initializeThemeFactory =
  (themeService: ThemeService): (() => void) =>
  // ThemeService.initialize returns a promise, but do not wait for it to resolve
  // as it depends on BpSdkStore being initialized by AppComponent
  () =>
    void themeService.initialize().catch(() => {});

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      scrollPositionRestoration: 'enabled',
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: TerminalTitleStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeThemeFactory,
      multi: true,
      deps: [ThemeService],
    },
  ],
})
export class AppRoutingModule {}
