import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ExchangePermissions } from 'src/app/permissions/permissions';
import { IExchangeStatus } from '../../models/exchange-status.model';
import {
  LoadExchangeStatus,
  UpdateExchangeStatus,
} from '../../store/exchange-status/exchange-status.actions';
import { selectExchangeStatus } from '../../store/exchange-status/exchange-status.selectors';
import { CancelHeaderAlert } from '../../store/header-alerts/header-alerts.actions';
import {
  HeaderAlertButton,
  HeaderAlertButtonRole,
  HeaderAlertId,
  HeaderAlertsService,
  HeaderAlertTheme,
} from '../header-service/header-alerts.service';
import {
  IRouteData,
  PermissionsService,
} from '../permissions/permissions.service';

export interface IExchangeStatusService {
  init(): void;
  getExchangeStatus(): Observable<IExchangeStatus | false>;
  updateExchangeStatus(status: boolean): Observable<IExchangeStatus>;
}

@Injectable({
  providedIn: 'root',
})
export class ExchangeStatusService implements IExchangeStatusService {
  private pausedAlertButtons: HeaderAlertButton[] = [
    {
      text: 'Dismiss',
      role: HeaderAlertButtonRole.CANCEL,
    },
  ];
  private pausedAlert = {
    id: HeaderAlertId.EXCHANGE_PAUSED,
    matIcon: 'info',
    message:
      'Exchange Paused. An Agency Principal must re-activate to receive prospects.',
    theme: HeaderAlertTheme.Gray,
    buttons: this.pausedAlertButtons,
  };
  private pausedAlertActive = false;

  constructor(
    private http: HttpClient,
    private store: Store,
    private headerAlertsService: HeaderAlertsService,
    private router: Router,
    private permissionService: PermissionsService,
  ) {}

  /**
   * Checks the status of the exchange and displays a header alert if paused
   */
  init(): void {
    this.store.dispatch(LoadExchangeStatus());

    const exchangeStatus$ = this.store.pipe(select(selectExchangeStatus));
    const hasPerms$ = this.permissionService
      .hasRoutePermission(ExchangePermissions as IRouteData)
      .pipe(
        tap((permission: boolean) => {
          // Add the activate button to the header alert and change the text if this user has permission
          if (permission) {
            this.pausedAlertButtons.push({
              text: 'Activate',
              role: HeaderAlertButtonRole.CONFIRM,
              clickFunction: () => {
                this.router.navigate(['/exchange/schedule']);
                this.store.dispatch(UpdateExchangeStatus({ status: true }));
              },
            });
            this.pausedAlert.message =
              'Exchange Paused. You must re-activate to receive prospects.';
          }
        }),
      );

    combineLatest([exchangeStatus$, hasPerms$]).subscribe(([status]) => {
      if (status && !status.active) {
        this.headerAlertsService.addHeaderAlert(this.pausedAlert);
        this.pausedAlertActive = true;
      } else if (this.pausedAlertActive) {
        this.store.dispatch(
          CancelHeaderAlert({ headerAlert: this.pausedAlert }),
        );
      }
    });
  }

  /**
   * Gets the current status of the exchange
   *
   * @returns current status of the exchange
   */
  getExchangeStatus(): Observable<IExchangeStatus | false> {
    const url = `${environment.magellanBaseUri}/terminal/partners`;
    // BP-32332 /partners returns false when tenant is not a receive-side partner
    return this.http.get<IExchangeStatus | false>(url);
  }

  /**
   * Updates the exchange status
   *
   * @param status updated status
   * @returns current status of the exchange
   */
  updateExchangeStatus(status: boolean): Observable<IExchangeStatus> {
    const url = `${environment.magellanBaseUri}/terminal/partners`;
    return this.http.put<IExchangeStatus>(url, { partner: { active: status } });
  }
}
