import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import {
  AddHeaderAlert,
  CancelHeaderAlert,
  HeaderAlertButtonClick,
} from '../../store/header-alerts/header-alerts.actions';

export enum HeaderAlertId {
  EXCHANGE_PAUSED = 'exchange_paused',
}

export enum HeaderAlertButtonRole {
  CONFIRM = 'confirm',
  CANCEL = 'cancel',
}

/**
 * A theme the Header Alert should follow. Default is Plain
 */
export enum HeaderAlertTheme {
  /**
   * White BG with black text
   */
  Plain = 'plain',
  /**
   * Primary color BG with white text.
   * Matches the BP Primary color in the app
   */
  Primary = 'primary',
  /**
   * Grey color
   */
  Gray = 'gray',
}

export interface HeaderAlertButton {
  text: string;
  role: HeaderAlertButtonRole;
  clickFunction?: () => void;
}

export interface HeaderAlert {
  id: HeaderAlertId;
  message: string;
  buttons: HeaderAlertButton[];
  beforeUnload?: boolean;
  /**
   * A standardized theme the header can be displayed as
   */
  theme: HeaderAlertTheme;
  /**
   * An option material icon string that can be displayed before the message
   * See: https://material.io/resources/icons/?style=baseline
   */
  matIcon?: string;
  /**
   * Number of milliseconds the alert should display for
   */
  expireAfterMs?: number;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class HeaderAlertsService {
  private buttonClickFunctions: { [actionName: string]: () => any } = {};

  constructor(private store: Store, private actions$: Actions) {
    this.actions$
      .pipe(
        ofType(HeaderAlertButtonClick),
        map(
          ({
            alert,
            button,
          }: {
            alert: HeaderAlert;
            button: HeaderAlertButton;
          }) => {
            const actionIndex = this.buttonActionName(alert, button.role);
            const clickFn = this.buttonClickFunctions[actionIndex];
            if (clickFn) {
              clickFn();
            }
            return undefined;
          },
        ),
        untilDestroyed(this),
      )
      .subscribe();
  }

  addHeaderAlert(alert: HeaderAlert) {
    alert.buttons.forEach((button: HeaderAlertButton) => {
      if (button.clickFunction) {
        this.buttonClickFunctions[this.buttonActionName(alert, button.role)] =
          button.clickFunction;
        delete button.clickFunction;
      }
    });
    const action = AddHeaderAlert({ headerAlert: alert });
    this.store.dispatch(action);

    // Queue up a removal of the header if there is an expiration time set
    if (!!alert.expireAfterMs) {
      setTimeout(() => {
        this.store.dispatch(CancelHeaderAlert({ headerAlert: alert }));
      }, alert.expireAfterMs);
    }
  }

  buttonClick(alert: HeaderAlert, button: HeaderAlertButton) {
    const action = HeaderAlertButtonClick({ alert, button });
    this.store.dispatch(action);
  }

  private buttonActionName(
    alert: HeaderAlert,
    buttonRole: HeaderAlertButtonRole,
  ): string {
    return `${alert.id}_${buttonRole}`;
  }
}
