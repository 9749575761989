<section class="opt-out-container">
  <div class="logo">
    <a [routerLink]="['/']">
      <img src="/assets/images/logo-text-medium.svg" alt="bold penguin logo" />
    </a>
  </div>
  <emperor-card>
    <h2>{{ copy.title }}</h2>
    <div>
      <markdown>{{ copy.body }}</markdown>
    </div>
    <div class="return">
      <emperor-button
        class="emperor-ui-manually-set-size"
        [routerLink]="['/']"
        >{{ copy.cta }}</emperor-button
      >
    </div>
  </emperor-card>
</section>
