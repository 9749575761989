import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ComplianceResponse } from 'src/app/core/models/compliance-form.model';
import {
  ErrorsAndOmissionsDocument,
  IWelcomeStep,
  WelcomeStep,
} from '../welcome.model';
import * as WelcomeActions from './welcome.actions';
import {
  getTrackingSteps,
  getUiSteps,
} from '../utilities/welcome-steps-utilities';

export const welcomeFeatureStateKey = 'welcome';

export interface IWelcomeState extends EntityState<IWelcomeStep> {
  ids: WelcomeStep[];
  currentStepId: WelcomeStep;
  /**
   * Nested steps structure for UI that appear in the sidebar
   */
  sidebarSteps: IWelcomeStep[];
  /**
   * Compliance form data
   */
  complianceFormData?: ComplianceResponse;
  /**
   * If the compliance form data has been loaded
   */
  complianceFormDataLoaded: boolean;
  /**
   * Latest error when fetching the compliance form data
   */
  complianceFormDataError?: Error;
  /**
   * Active E&O document
   */
  errorsAndOmissionsDocument?: ErrorsAndOmissionsDocument;
  /**
   * E&O document is uploading
   */
  errorsAndOmissionsDocumentUploading: boolean;
  /**
   * Latest error when uploading an E&O document
   */
  errorsAndOmissionsDocumentError?: Error;
  complianceFormSaving: boolean;
}

export const welcomeAdapter = createEntityAdapter<IWelcomeStep>({
  selectId: (step) => step.id,
});

export const welcomeInitialState: IWelcomeState =
  welcomeAdapter.getInitialState({
    ids: [],
    currentStepId: WelcomeStep.DIRECT_APPOINTMENTS,
    sidebarSteps: [],
    complianceFormDataLoaded: false,
    errorsAndOmissionsDocumentUploading: false,
    complianceFormSaving: false,
  });

const reducer = createReducer(
  welcomeInitialState,
  on(WelcomeActions.UpdateStepsSuccess, (state: IWelcomeState, { steps }) => ({
    ...welcomeAdapter.setAll(getTrackingSteps(steps), state),
    sidebarSteps: getUiSteps(steps),
  })),
  on(
    WelcomeActions.SetCurrentStep,
    (state: IWelcomeState, { currentStepId }): IWelcomeState => ({
      ...state,
      currentStepId,
    }),
  ),
  on(
    WelcomeActions.UpdateComplianceFormData,
    (state: IWelcomeState, { disableForm }): IWelcomeState => ({
      ...state,
      complianceFormSaving: !!disableForm,
    }),
  ),
  on(
    WelcomeActions.ComplianceFormDataSuccess,
    (state: IWelcomeState, { complianceFormData }): IWelcomeState => ({
      ...state,
      complianceFormData,
      complianceFormDataLoaded: true,
      complianceFormSaving: false,
    }),
  ),
  on(
    WelcomeActions.ComplianceFormDataError,
    (state: IWelcomeState, { error }): IWelcomeState => ({
      ...state,
      complianceFormData: undefined,
      complianceFormDataLoaded: false,
      complianceFormSaving: false,
      complianceFormDataError: error,
    }),
  ),
  on(
    WelcomeActions.UploadEoDocument,
    (state: IWelcomeState): IWelcomeState => ({
      ...state,
      errorsAndOmissionsDocumentUploading: true,
    }),
  ),
  on(
    WelcomeActions.UploadEoDocumentSuccess,
    (state: IWelcomeState, { errorsAndOmissionsDocument }): IWelcomeState => ({
      ...state,
      errorsAndOmissionsDocument,
      errorsAndOmissionsDocumentUploading: false,
      errorsAndOmissionsDocumentError: undefined,
    }),
  ),
  on(
    WelcomeActions.UploadEoDocumentError,
    (state: IWelcomeState, { error }): IWelcomeState => ({
      ...state,
      errorsAndOmissionsDocument: undefined,
      errorsAndOmissionsDocumentUploading: false,
      errorsAndOmissionsDocumentError: error,
    }),
  ),
  on(
    WelcomeActions.CompleteMainComplianceForm,
    (state: IWelcomeState): IWelcomeState => ({
      ...state,
      complianceFormSaving: true,
    }),
  ),
  on(
    WelcomeActions.CompleteMainComplianceFormSuccess,
    (state: IWelcomeState): IWelcomeState => ({
      ...state,
      complianceFormSaving: false,
    }),
  ),
  on(
    WelcomeActions.CompleteMainComplianceFormError,
    (state: IWelcomeState): IWelcomeState => ({
      ...state,
      complianceFormSaving: false,
    }),
  ),
);

export const WelcomeReducer = (
  state: IWelcomeState | undefined,
  action: Action,
) => reducer(state, action);
