import {
  IApplicationForm,
  IApplicationFormIndex,
  IUserInfoTenant,
} from '@boldpenguin/sdk';
import { createAction, props } from '@ngrx/store';
import { IFormFilters } from 'src/app/core/models/quote-dashboard-filters';
import { DashboardNavigationButtons } from '../../../core/models/application-list-nav-buttons.model';
import { IDisposition } from '../models/disposition.model';
import { IPoliciesBoundResponse } from '../models/policies-bound.model';
import { IQuoteArchiveReason } from '../models/quote-archive-reason';

export const CreateApplicationFormAction = createAction(
  '[Quotes] - Create Application Form',
);

export const LoadApplicationFormAction = createAction(
  '[Quotes] - Load Application Form',
  props<{ id: string }>(),
);

export const ApplicationFormLoadingAction = createAction(
  '[Quotes] - Application Form Loading',
  props<{ id: string }>(),
);

export const ApplicationFormLoadErrorAction = createAction(
  '[Quotes] - Application Form Load ERROR',
  props<{ id: string | undefined }>(),
);

export const ApplicationFormCreatedAction = createAction(
  '[Quotes] - Application Form Created',
  props<{ id: string }>(),
);

export const ApplicationFormCreateErrorAction = createAction(
  '[Quotes] - Application Form Create ERROR',
  props<{ errorMessage: string }>(),
);

export const LoadQuotesList = createAction('[Quotes] - Load Quotes List');

export const LoadQuotesListSuccess = createAction(
  '[Quotes] - Load Quotes List Success',
  props<{ quotesList: IApplicationFormIndex[] }>(),
);

export const LoadQuotesListError = createAction(
  '[Quotes] - Load Quotes List Error',
  props<{ error: Error }>(),
);

export const LoadMoreQuotesList = createAction(
  '[Quotes] - Load More Quotes List',
  props<{ page: number }>(),
);

export const LoadMoreQuotesListSuccess = createAction(
  '[Quotes] - Load More Quotes List Success',
  props<{ quotesList: IApplicationFormIndex[] }>(),
);

export const LoadMoreQuotesListError = createAction(
  '[Quotes] - Load More Quotes List Error',
  props<{ error: Error }>(),
);

export const ChangeActiveAppDashboardNav = createAction(
  '[Quotes] - Change Active App Dashboard Nav',
  props<{
    activeNavButton: DashboardNavigationButtons;
    searchFilters: IFormFilters;
  }>(),
);

export const SearchAndFilterQuotesList = createAction(
  '[Quotes] - Search & Filter Quotes List',
  props<{ searchFilters: IFormFilters }>(),
);
export const SelectTenants = createAction(
  '[Quotes] - Tenant filter changed',
  props<{ searchFilters: IFormFilters }>(),
);

export const SearchAndFilterQuotesListSuccess = createAction(
  '[Quotes] - Search & Filter Quotes List Success',
  props<{ quotesList: IApplicationFormIndex[] }>(),
);

export const SearchAndFilterQuotesListError = createAction(
  '[Quotes] - Search & Filter Quotes List Error',
  props<{ error: Error }>(),
);

export const SendApplicationFormToExchange = createAction(
  '[Quotes] - Send Application Form to Exchange',
  props<{ formId: string }>(),
);

export const ShowQuotesViewAction = createAction(
  '[Quotes] - Showing Quotes View',
);

export const HideQuotesViewAction = createAction(
  '[Quotes] - Hiding Quotes View',
);

export const ForceQuotesViewAction = createAction(
  '[Quotes] - Force Showing Quotes View',
  props<{ quoteId?: string }>(),
);

export const QuestionSetSaveStartedAction = createAction(
  '[Quotes] - Question Set Save Started',
);

export const ScrollToFirstInvalidControlAction = createAction(
  '[Quotes] - ScrollToFirstInvalidControlAction',
);

export const SdkAddAdditionalItemAction = createAction(
  '[Quotes] - Add Additional Item to SDK',
);

export const ArchiveApplication = createAction(
  '[Quotes] - Archive Application',
  props<{
    applicationId: string;
    questionId: string;
    answerId: string;
    archiveReason: IQuoteArchiveReason;
  }>(),
);

export const v3ArchiveApplication = createAction(
  '[Quotes] - v3 Archive Application',
  props<{ applicationId: string }>(),
);

export const ArchiveApplicationSuccess = createAction(
  '[Quotes] - Archive Application Success',
  props<{ applicationId: string }>(),
);

export const ArchiveApplicationError = createAction(
  '[Quotes] - Archive Application Error',
);

export const UnarchiveApplication = createAction(
  '[Quotes] - Unarchive Application',
  props<{ applicationId: string; questionId: string; answerId: string }>(),
);

export const v3UnarchiveApplication = createAction(
  '[Quotes] - v3 Unarchive Application',
  props<{ applicationId: string }>(),
);

export const UnarchiveApplicationSuccess = createAction(
  '[Quotes] - Unarchive Application Success',
  props<{ applicationId: string }>(),
);

export const UnarchiveApplicationError = createAction(
  '[Quotes] - Unarchive Application Error',
);

export const FormBodyAnimationStartingAction = createAction(
  '[Quotes] - form body animation starting',
);

export const FormBodyAnimationFinishedAction = createAction(
  '[Quotes] - form body animation finished',
);

export const QuoteRequestAnimationStartingAction = createAction(
  '[Quotes] - quote card animation starting',
);

export const QuoteRequestAnimationFinishedAction = createAction(
  '[Quotes] - quote card animation finished',
);

export const SetCurrentQuoteReviewId = createAction(
  '[Quotes] - Set Current Quote Review Id',
  props<{ quoteId?: string }>(),
);

export const SetCurrentQuoteReviewIdSuccess = createAction(
  '[Quotes] - Set Current Quote Review Id Success',
  props<{ currentQuoteInReviewId: string }>(),
);

export const SetBpSdkQuestionSetButtonDimensions = createAction(
  '[Quotes] - Set bp-sdk-question-set-button Width',
  props<{ width: number; left: number }>(),
);

/**
 * Clones and takes over the provided application form for the currently signed in user
 */
export const TakeoverApplication = createAction(
  '[Quotes] - Takeover Application',
  props<{ appFormId: string }>(),
);

export const TakeoverApplicationSuccess = createAction(
  '[Quotes] - Takeover Application Success',
  props<{ appForm: IApplicationForm }>(),
);

export const TakeoverApplicationError = createAction(
  '[Quotes] - Takeover Application Error',
);

export const NoQuotesSoftFailed = createAction(
  '[Quotes] - No quotes available soft fail',
);

export const ResubmitCarrierQuote = createAction(
  '[Quotes] - Resubmit carrier quote',
  props<{ carrierId: string }>(),
);

export const ResubmitCarrierQuoteSuccess = createAction(
  '[Quotes] - Resubmit carrier quote success',
);

export const ResubmitCarrierQuoteFailed = createAction(
  '[Quotes] - Resubmit carrier quote failed',
  props<{ error: Error }>(),
);

export const GetInProgressApplicationData = createAction(
  '[Quotes] - Get In Progress Application Data',
);

export const GetInProgressApplicationDataSuccess = createAction(
  '[Quotes] - Get In Progress Application Data Success',
  props<{ counts }>(),
);

export const GetInProgressApplicationDataError = createAction(
  '[Quotes] - Get In Progress Application Data Error',
  props<{ error: Error }>(),
);

export const GetPoliciesBound = createAction('[Quotes] - Get Policies Bound');

export const GetPoliciesBoundSuccess = createAction(
  '[Quotes] - Get Policies Bound Success',
  props<{ counts: IPoliciesBoundResponse }>(),
);

export const GetPoliciesBoundError = createAction(
  '[Quotes] - Get Policies Bound Error',
  props<{ error: Error }>(),
);

export const DispositionBound = createAction(
  '[Quotes] - Disposition Bound',
  props<{ appFormId: string; dispositions: IDisposition[] }>(),
);

export const DispositionNotBound = createAction(
  '[Quotes] - Disposition Not Bound',
  props<{ appFormId: string; dispositions: IDisposition[] }>(),
);

export const DispositionBoundSuccess = createAction(
  '[Quotes] - Disposition Bound Success',
  props<{ applicationId: string; dispositions: IDisposition[] }>(),
);

export const DispositionNotBoundSuccess = createAction(
  '[Quotes] - Disposition Not Bound Success',
  props<{ applicationId: string; dispositions: IDisposition[] }>(),
);

export const DispositionError = createAction(
  '[Quotes] - Disposition Error',
  props<{ error: Error }>(),
);

export const OptInCarrier = createAction(
  '[Quotes] - Opt In Carrier',
  props<{
    applicationFormId: string;
    carrierId: string;
    carrierName: string;
    products: string;
  }>(),
);

export const OptInCarrierSuccess = createAction(
  '[Quotes] - Opt In Carrier Success',
  props<{
    applicationFormId: string;
    carrierName: string;
    products: string;
  }>(),
);

export const OptInCarrierError = createAction(
  '[Quotes] - Opt In Carrier Error',
  props<{
    applicationFormId: string;
    carrierName: string;
    products: string;
    error: Error;
  }>(),
);

export const QuestionErrorNavigation = createAction(
  '[Quotes] - Question Error Navigation',
  props<{
    questionSetId: string;
    questionCode: string;
  }>(),
);

export const QuestionErrorFocusFinished = createAction(
  '[Quotes] - Question Error Focus Finished',
);

export const GetOrganizationTenants = createAction(
  '[Quotes] = Get Organization Tenants',
);

export const GetOrganizationTenantsSuccess = createAction(
  '[Quotes] = Get Organization Tenants Success',
  props<{ organizationTenants: IUserInfoTenant[] }>(),
);

export const GetOrganizationTenantsError = createAction(
  '[Quotes] = Get Organization Tenants Error',
  props<{ error: Error }>(),
);

export const DisplayApplicationUploadDialog = createAction(
  '[Quotes] - Display Application Upload',
);

export const HideApplicationUploadDialog = createAction(
  '[Quotes] - Hide Application Upload',
);
