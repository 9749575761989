<div class="home-container">
  <div class="left-col" #left [class.show]="showLeft && leftHasContent">
    <ng-content select="[left]"></ng-content>
  </div>
  <div
    class="spacer"
    *ngIf="showLeft && showRight && leftHasContent && rightHasContent"
  ></div>
  <div class="right-col" [class.show]="showRight && rightHasContent" #right>
    <ng-content select="[right]"></ng-content>
  </div>
</div>
