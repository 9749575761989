import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { LedgerService } from 'src/app/features/billing/services/ledger.service';
import { ComplianceFormService } from '../../services/compliance-form/compliance-form.service';
import {
  MixpanelEventType,
  MixpanelService,
} from '../../services/mixpanel-service/mixpanel.service';
import { Messages } from '../../services/notification-service/notification-messages';
import { NotificationService } from '../../services/notification-service/notification.service';
import * as BankAccountActions from './bank-account.actions';

@Injectable()
export class BankAccountEffects {
  linkBankAccountSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BankAccountActions.LinkBankAccountSuccess),
      switchMap(({ publicToken }) =>
        this.ledgerService.saveBankAccount(publicToken).pipe(
          map(() => BankAccountActions.SaveBankAccountSuccess()),
          catchError((error) =>
            of(BankAccountActions.SaveBankAccountError({ error })),
          ),
        ),
      ),
    ),
  );

  linkMultipleAccountsError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BankAccountActions.LinkMultipleAccountsError),
        tap(({ metadata }) => {
          this.notificationService.error(Messages.ACH_MULTIPLE_ACCOUNTS_ERROR);
          this.mixpanelService.track(
            MixpanelEventType.ACH_MULTIPLE_ACCOUNTS_ERROR,
            {
              institutionName: metadata.institution.name,
              accountsCount: metadata.accounts.length,
            },
          );
        }),
      ),
    { dispatch: false },
  );

  saveBankAccountSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BankAccountActions.SaveBankAccountSuccess),
      tap(() => {
        this.mixpanelService.track(MixpanelEventType.ACH_ACCOUNT_SAVE_SUCCESS);
      }),
      map(() =>
        BankAccountActions.UpdateComplianceFormData({
          data: {
            ach_bank_account_linked: true,
          },
        }),
      ),
    ),
  );

  saveBankAccountError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BankAccountActions.SaveBankAccountError),
        tap(() => {
          this.mixpanelService.track(MixpanelEventType.ACH_ACCOUNT_SAVE_ERROR);
        }),
      ),
    { dispatch: false },
  );

  getBankAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        BankAccountActions.GetBankAccount,
        BankAccountActions.SaveBankAccountSuccess,
      ),
      switchMap(() =>
        this.ledgerService.getBankAccount().pipe(
          map((bankAccount) =>
            BankAccountActions.GetBankAccountSuccess({ bankAccount }),
          ),
          catchError((error) =>
            of(BankAccountActions.GetBankAccountError({ error })),
          ),
        ),
      ),
    ),
  );

  removeBankAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BankAccountActions.RemoveBankAccount),
      tap(() => {
        this.mixpanelService.track(
          MixpanelEventType.ACH_REMOVE_ACCOUNT_CLICKED,
        );
      }),
      switchMap(() =>
        this.ledgerService.removeBankAccount().pipe(
          switchMap(() => [
            BankAccountActions.RemoveBankAccountSuccess(),
            BankAccountActions.UpdateComplianceFormData({
              data: {
                ach_bank_account_linked: false,
              },
            }),
          ]),
          catchError((error) =>
            of(BankAccountActions.RemoveBankAccountError({ error })),
          ),
        ),
      ),
    ),
  );

  updateComplianceFormData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BankAccountActions.UpdateComplianceFormData),
      switchMap(({ data }) =>
        this.complianceFormService.patchComplianceQuestionAnswers(data).pipe(
          map(() => BankAccountActions.UpdateComplianceFormDataSuccess()),
          catchError((error) =>
            of(BankAccountActions.UpdateComplianceFormDataError({ error })),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private complianceFormService: ComplianceFormService,
    private ledgerService: LedgerService,
    private mixpanelService: MixpanelService,
    private notificationService: NotificationService,
  ) {}
}
