import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ExchangeStatusActions from './exchange-status.actions';
import { ExchangeStatusService } from '../../services/exchange-status/exchange-status.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  ExchangeStatus,
  IExchangeStatus,
} from '../../models/exchange-status.model';
import { of } from 'rxjs';
import {
  MixpanelEventType,
  MixpanelService,
} from 'src/app/core/services/mixpanel-service/mixpanel.service';
import { NotificationService } from 'src/app/core/services/notification-service/notification.service';
import { Messages } from 'src/app/core/services/notification-service/notification-messages';

@Injectable()
export class ExchangeStatusEffects {
  loadExchangeStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExchangeStatusActions.LoadExchangeStatus),
      switchMap(() =>
        this.exchangeStatusService.getExchangeStatus().pipe(
          map((status: IExchangeStatus) =>
            ExchangeStatusActions.LoadExchangeStatusSuccess({ status }),
          ),
          catchError((error) =>
            of(
              ExchangeStatusActions.LoadExchangeStatusError({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  updateExchangeStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExchangeStatusActions.UpdateExchangeStatus),
      switchMap((action) =>
        this.exchangeStatusService.updateExchangeStatus(action.status).pipe(
          map((status: IExchangeStatus) => {
            // Send mixpanel event for tracking
            this.mixpanelService.track(
              MixpanelEventType.EXCHANGE_STATUS_UPDATED,
              {
                status: status.active ? ExchangeStatus.On : ExchangeStatus.Off,
              },
            );

            this.notificationService.success(Messages.UPDATE_SUCCESSFUL);

            return ExchangeStatusActions.UpdateExchangeStatusSuccess({
              status,
            });
          }),
          catchError((error) => {
            this.notificationService.error(Messages.UPDATE_FAILED);

            return of(
              ExchangeStatusActions.UpdateExchangeStatusError({
                error,
              }),
            );
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private exchangeStatusService: ExchangeStatusService,
    private mixpanelService: MixpanelService,
    private notificationService: NotificationService,
  ) {}
}
