<emperor-dialog (close)="close()" class="warning-dialog">
  <emperor-dialog-header
    [title]="data.title"
    [color]="EmperorCardHeaderColor.NONE"
  ></emperor-dialog-header>
  <emperor-card-content>
    <p>
      <markdown [data]="data.body"></markdown>
    </p>
  </emperor-card-content>

  <emperor-card-footer [color]="EmperorCardHeaderColor.NONE">
    <emperor-button
      *ngFor="let button of data.actionButtons"
      [type]="EmperorUIButtonTypes.Standard"
      (click)="handleActionButtonClick(button.clickedCallback)"
    >
      {{ button.text }}
    </emperor-button>
  </emperor-card-footer>
</emperor-dialog>
