<ng-container *ngIf="!item.subItems; else panel">
  <ng-template
    *ngIf="!item.isNavButton"
    [ngTemplateOutlet]="navLink"
    [ngTemplateOutletContext]="{ item: item }"
  ></ng-template>
  <ng-template
    *ngIf="item.isNavButton"
    [ngTemplateOutlet]="navButton"
    [ngTemplateOutletContext]="{ item: item }"
  ></ng-template>
</ng-container>

<ng-template #panel>
  <mat-expansion-panel class="nav-menu-panel mat-elevation-z0">
    <mat-expansion-panel-header [attr.data-testid]="getDataTestId(item.text)">
      <mat-panel-title class="nav-menu-panel-title">
        <emperor-icon [name]="item.icon ?? ''" class="nav-icon"></emperor-icon>
        {{ item.text }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container *ngFor="let subItem of item.subItems">
      <ng-template
        *ngIf="!item.isNavButton"
        [ngTemplateOutlet]="navLink"
        [ngTemplateOutletContext]="{ item: subItem }"
      ></ng-template>
      <ng-template
        *ngIf="item.isNavButton"
        [ngTemplateOutlet]="navButton"
        [ngTemplateOutletContext]="{ item: subItem }"
      ></ng-template>
    </ng-container>
  </mat-expansion-panel>
</ng-template>

<ng-template #navButton let-item="item">
  <emperor-nav-button
    class="nav-menu-item"
    [routerLink]="item.url"
    [highlight]="false"
    [stopPropagation]="false"
    [attr.data-intercom-target]="getIntercomTarget(item.text)"
    [attr.data-testid]="getDataTestId(item.text)"
    (click)="onClick(item)"
    [disabled]="item.disabled"
    tabindex="-1"
  >
    <emperor-icon name="{{ item.icon }}" class="nav-icon"></emperor-icon>
    {{ item.text }}
  </emperor-nav-button>
</ng-template>

<ng-template #navLink let-item="item">
  <a
    emperor-nav-link
    class="nav-menu-item"
    [attr.data-intercom-target]="getIntercomTarget(item.text)"
    [attr.data-testid]="getDataTestId(item.text)"
    [routerLink]="item.url"
    [rounded]="false"
    (click)="onClick(item)"
  >
    <emperor-icon [name]="item.icon" class="nav-icon"></emperor-icon>
    {{ item.text }}
  </a>
</ng-template>
