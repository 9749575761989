import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IExchangeStatus } from '../../models/exchange-status.model';

export const LoadExchangeStatus = createAction(
  '[Exchange Status] - Load Exchange Status',
);

export const LoadExchangeStatusSuccess = createAction(
  '[Exchange Status] - Load Exchange Status Success',
  props<{ status: IExchangeStatus | false }>(),
);

export const LoadExchangeStatusError = createAction(
  '[Exchange Status] - Load Exchange Status Error',
  props<{ error: HttpErrorResponse }>(),
);

export const UpdateExchangeStatus = createAction(
  '[Exchange Status] - Toggle Exchange Status',
  props<{ status: boolean }>(),
);

export const UpdateExchangeStatusSuccess = createAction(
  '[Exchange Status] - Toggle Exchange Status Success',
  props<{ status: IExchangeStatus }>(),
);

export const UpdateExchangeStatusError = createAction(
  '[Exchange Status] - Toggle Exchange Status ERROR',
  props<{ error: HttpErrorResponse }>(),
);
