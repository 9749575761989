import {
  ICreateApplicationFormAnswerPool,
  ICreateApplicationFormAnswerValue,
} from '@boldpenguin/sdk';
import { IAcordFormPdf } from '../services/acord-forms.service';
import { EmperorUISelectFormChoice } from '@boldpenguin/emperor-form-fields';

// Enums
export enum FileTypes {
  JSON = 'application/json',
  SAGITTA = 'Sagitta',
}

export enum AlertTooltip {
  RemoveFile = 'Remove File',
  Dismiss = 'Dismiss',
}

export enum UploadFormQuestionCodes {
  FIRST_NAME = 'mqs_first_name',
  LAST_NAME = 'mqs_last_name',
  BUSINESS_NAME = 'mqs_business_name',
  YEAR_ESTABLISHED = 'mqs_year_established',
  PHONE = 'mqs_phone',
  EMAIL = 'mqs_email',
  STREET = 'mqs_street_1',
  STREET2 = 'mqs_street_2',
  CITY = 'mqs_city',
  STATE = 'mqs_state',
  ZIPCODE = 'mqs_zipcode',
  COVERAGE_TYPES = 'CoverageTypes',
  FEIN = 'mqs_fein',
  LEGAL_ENTITY = 'mqs_legal_entity',
  HAS_FEIN = 'mqs_has_fein',
}

export enum YesOrNo {
  YES = 'Yes',
  NO = 'No',
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
}

export enum QuestionPoolCode {
  Location = 'locations',
  Building = 'buildings',
  AdditionalInsureds = 'additional_insureds',
  Vehicles = 'vehicles',
  Drivers = 'drivers',
}

// Interfaces
export interface AcordUiMessage {
  title: string;
  success: boolean;
  subtitle?: string;
  acordPdf?: IAcordFormPdf;
  closeAction?: () => unknown;
}

export interface DocumentUpload {
  filename: string;
  content_type: string;
  data: Record<string, string | string[]>;
}

export interface AppData {
  answer_values: ICreateApplicationFormAnswerValue[];
  answer_pools?: ICreateApplicationFormAnswerPool[];
}

export interface AddressMap {
  [key: string]: {
    short: string;
    long: string;
  };
}

// Hardcode const moved into the model
export const AnsweredByType = 'DocumentUpload';
export const AnsweredBySource = 'Document upload';
export const CoverageLocation = 'NE.POLICIES.EXTRATAGS';
export const AdditionalInterestsLocation = 'ADDITIONAL.INTERESTS';
export const VehicleFlow = 'Year / Make / Model';

// Map Sagitta coverage codes to ours
export const SagittaCoverages = {
  AUTOB: 'AUTOB',
  CGL: 'CGL',
  POLL: 'POLL',
  PROP: 'PROP',
  WORK: 'WORK',
};

export const SagittaLegalEntities = {
  LP: 'Partnership',
  CP: 'C Corporation',
};

export const documentUploadValidTypes = [
  'application/pdf',
  'image/*',
  'text/plain',
  'application/json',
].join(',');

export const stateChoices: EmperorUISelectFormChoice[] = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
].map((state) => ({
  value: state,
  label: state,
  id: state,
}));

export const uploadFormLayout: UploadFormQuestion[][] = [
  [
    {
      code: UploadFormQuestionCodes.FIRST_NAME,
      text: "Policyholder's First Name",
      type: 'text',
    },
    {
      code: UploadFormQuestionCodes.LAST_NAME,
      text: "Policyholder's Last Name",
      type: 'text',
    },
  ],
  [
    { code: UploadFormQuestionCodes.PHONE, text: 'Phone Number', type: 'text' },
    {
      code: UploadFormQuestionCodes.EMAIL,
      text: "Policyholder's email address",
      type: 'text',
    },
  ],
  [
    {
      code: UploadFormQuestionCodes.BUSINESS_NAME,
      text: 'Business Name',
      type: 'text',
    },
  ],
  [
    {
      code: UploadFormQuestionCodes.STREET,
      text: 'Street address',
      type: 'address',
    },
    {
      code: UploadFormQuestionCodes.STREET2,
      text: 'Suite / Apt Number',
      type: 'text',
    },
  ],
  [
    { code: UploadFormQuestionCodes.CITY, text: 'City', type: 'text' },
    { code: UploadFormQuestionCodes.ZIPCODE, text: 'Zipcode', type: 'text' },
    {
      code: UploadFormQuestionCodes.STATE,
      text: 'State',
      type: 'select',
      choices: stateChoices,
    },
  ],
];

// Other
type UploadFormQuestion = {
  code: string;
  text: string;
  type: 'text' | 'select' | 'address';
  choices?: EmperorUISelectFormChoice[];
};

export enum IUploadDialogSource {
  MAIN_MENU = 'Main Menu',
  QUOTES_DASHBOARD_CARD = 'Quotes Dashboard Card',
  QUOTES_HEADER = 'Quotes Header',
}
