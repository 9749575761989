import { createAction, props } from '@ngrx/store';
import {
  CompliancePayload,
  ComplianceResponse,
} from 'src/app/core/models/compliance-form.model';
import {
  IEnrollment,
  IFormControlEnrollment,
} from 'src/app/core/models/enrollments.model';
import { ISelfServiceCallbackParam } from 'src/app/core/services/enrollments-service/enrollments.service';
import { MixpanelEventType } from 'src/app/core/services/mixpanel-service/mixpanel.service';
import { Messages } from 'src/app/core/services/notification-service/notification-messages';
import { W9Submission } from '../models/w9-submission.model';
import {
  ErrorsAndOmissionsDocument,
  IWelcomeStep,
  WelcomeStep,
} from '../welcome.model';

/**
 * Constructing steps, updating current step, submitting step
 */

export const UpdateSteps = createAction(
  '[Welcome] - Update Steps',
  props<{ suppressComplianceForm?: boolean }>(),
);

export const UpdateStepsSuccess = createAction(
  '[Welcome] - Update Steps Success',
  props<{ steps: IWelcomeStep[] }>(),
);

export const SetCurrentStep = createAction(
  '[Welcome] - Set Current Step',
  props<{ currentStepId: WelcomeStep }>(),
);

export const PreviousStep = createAction('[Welcome] - Go To Previous Step');

export const NextStep = createAction('[Welcome] - Go To Next Step');

export const NavigateToDashboard = createAction(
  '[Welcome] - Navigate To Dashboard',
);

export const NavigateToCarrierAppointments = createAction(
  '[Welcome] - Navigate to Carrier Appointments',
);

export const SubmitStep = createAction(
  '[Welcome] - Submit Step',
  props<{ currentStep: IWelcomeStep }>(),
);

/**
 * Step submission handling
 */
export const SkipDirectAppointmentsStep = createAction(
  '[Welcome] - Skip Direct Appointments Step',
);
export const SubmitDirectAppointmentsStep = createAction(
  '[Welcome] - Submit Direct Appointments Step',
);
export const SkipSubAppointmentsStep = createAction(
  '[Welcome] - Skip Sub-Appointments Step',
);
export const SubmitSubAppointmentsStep = createAction(
  '[Welcome] - Submit Sub-Appointments Step',
);

export const SubmitPaymentsStep = createAction(
  '[Welcome] - Submit Payments Step',
);

/**
 * Triggering enrollment actions
 */
export const TriggerDirectAppointmentsActivationWithNoSubs = createAction(
  '[Welcome - Direct Appointments] - Trigger Direct Appointments Activation',
);
export const TriggerDirectAppointmentsActivationAfterSubs = createAction(
  '[Welcome - Sub Appointments] - Trigger Direct Appointments Activation',
);

export const ClearSubAppointmentPreselections = createAction(
  '[Welcome] - Clear Sub-appointments Preselections',
);

export const TriggerSubAppointmentsRequests = createAction(
  '[Welcome] - Trigger Sub-appointments Request',
);

/**
 * Getting/updating/resetting compliance form data
 */
export const GetComplianceFormData = createAction(
  '[Welcome] - Get Compliance Form Data',
);

export const UpdateComplianceFormData = createAction(
  '[Welcome] - Update Compliance Form Data',
  props<{
    data: CompliancePayload;
    disableForm?: boolean;
  }>(),
);

export const ResetComplianceFormData = createAction(
  '[Welcome] - Reset Compliance Form Data',
);

export const ComplianceFormDataSuccess = createAction(
  '[Welcome] - Compliance Form Data Success',
  props<{
    complianceFormData: ComplianceResponse;
    mpEvent?: MixpanelEventType;
  }>(),
);

export const ComplianceFormDataError = createAction(
  '[Welcome] - Compliance Form Data Error',
  props<{ error: Error; message?: Messages; mpEvent?: MixpanelEventType }>(),
);

export const SubmitComplianceQuestionnaireRequests = createAction(
  '[Welcome] - Submit Compliance Questionnaire Requests',
);

/**
 * Direct appointment selections
 */
export const EditDirectAppointmentConnection = createAction(
  '[Welcome] - Edit Direct Appointment Connection',
  props<{ enrollment: IFormControlEnrollment }>(),
);

/**
 * Sub-appointment selections
 */
export const ResetSubAppointmentSelections = createAction(
  '[Carrier Sub-Appointment Compliance Navigation] - Reset Sub-Appointment Selections',
  props<{ enrollmentIds: string[]; data: CompliancePayload }>(),
);

export const UpdateSubAppointmentSelections = createAction(
  '[Welcome] - UpdateSubAppointmentSelections',
  props<{ enrollmentIds: string[] }>(),
);

export const UpdateSubAppointmentSelectionsSuccess = createAction(
  '[Welcome] - UpdateSubAppointmentSelectionsSuccess',
  props<{ enrollments: IEnrollment[] }>(),
);

export const UpdateSubAppointmentSelectionsError = createAction(
  '[Welcome] - UpdateSubAppointmentSelectionsError',
  props<{ error: Error; enrollments: IEnrollment[] }>(),
);

/**
 * Skip default instant enrollment for sub-appointments
 */
export const SkipInstantEnrollmentDefault = createAction(
  '[Welcome] - SkipInstantEnrollmentDefault',
  props<{ enrollmentIds: string[] }>(),
);

export const SkipInstantEnrollmentDefaultSuccess = createAction(
  '[Welcome] - SkipInstantEnrollmentDefaultSuccess',
  props<{ enrollments: IEnrollment[] }>(),
);

export const SkipInstantEnrollmentDefaultError = createAction(
  '[Welcome] - SkipInstantEnrollmentDefaultError',
  props<{ error: Error; enrollments: IEnrollment[] }>(),
);

/**
 * Completing / resetting main compliance form
 */
export const CompleteMainComplianceForm = createAction(
  '[Welcome] - Complete Main Compliance Form',
);

export const CompleteMainComplianceFormSuccess = createAction(
  '[Welcome] - Complete Main Compliance Form Success',
);

export const CompleteMainComplianceFormError = createAction(
  '[Welcome] - Complete Main Compliance Form Error',
  props<{ error: Error }>(),
);

export const ResetComplianceFormCompleted = createAction(
  '[Welcome] - Reset Compliance Form Completed',
);

/**
 * E&O upload
 */
export const UploadEoDocument = createAction(
  '[Welcome] - Upload E&O Document',
  props<{ file: File }>(),
);

export const UploadEoDocumentSuccess = createAction(
  '[Welcome] - Upload E&O Document Success',
  props<{ errorsAndOmissionsDocument: ErrorsAndOmissionsDocument }>(),
);

export const UploadEoDocumentError = createAction(
  '[Welcome] - Upload E&O Document Error',
  props<{ error: Error }>(),
);

/**
 * Submitting W9
 */
export const SubmitW9 = createAction(
  '[Welcome] - Submit W9',
  props<{ w9Submission: W9Submission }>(),
);

export const SkipW9 = createAction('[Welcome] - Skip W9');

export const SubmitW9Success = createAction('[Welcome] - Submit W9 Success');

export const SubmitW9Error = createAction(
  '[Welcome] - Submit W9 Error',
  props<{ error: Error }>(),
);

/** Producer Agreement for sub-appointments */
export const SaveProducerAgreement = createAction(
  '[Welcome] - Save Producer Agreement',
);

export const SaveProducerAgreementSuccess = createAction(
  '[Welcome] - Save Producer Agreement Success',
);

export const SaveProducerAgreementError = createAction(
  '[Welcome] - Save Producer Agreement Error',
);

/**
 * Finishing/resetting FTW
 */
export const FinishWelcomeWizardSuccess = createAction(
  '[Welcome] - Finish Welcome Wizard Success',
);

export const WelcomeSelfServiceRegistrationCallbackAction = createAction(
  '[Welcome] - Hit Enrollment Callback',
  props<{ selfServiceCallback: ISelfServiceCallbackParam }>(),
);
