import { Store, select } from '@ngrx/store';
import { filter, firstValueFrom, map } from 'rxjs';
import { selectLoadedEnrollmentsHaveSubAppointmentsEnabled } from '../core/store/enrollments/enrollments.selectors';
import {
  ApiActionType,
  ApiModelType,
  PermissionModelForRouteAndMenu,
  ProductFeatureType,
  UiActionType,
  UiModelType,
} from './permissions.model';

const hasSubAppointmentsEnabled = (store: Store): Promise<boolean> =>
  firstValueFrom(
    store.pipe(
      select(selectLoadedEnrollmentsHaveSubAppointmentsEnabled),
      filter(({ loaded }) => loaded),
      map(({ hasAvailableSubs }) => hasAvailableSubs),
    ),
  );

export const QuotePermissions: PermissionModelForRouteAndMenu = {
  permissionModel: [ApiModelType.ApplicationForms],
  permissionAction: [ApiActionType.Index, ApiActionType.Show],
};

export const QuoteCreatePermissions: PermissionModelForRouteAndMenu = {
  permissionModel: [ApiModelType.ApplicationForms],
  permissionAction: [ApiActionType.Create],
};

export const UserPermissions: PermissionModelForRouteAndMenu = {
  permissionModel: [ApiModelType.Users],
  permissionAction: [ApiActionType.Index],
};

export const CarrierEnrollmentsPermissions: PermissionModelForRouteAndMenu = {
  permissionModel: [UiModelType.MenuFeatures],
  permissionAction: [UiActionType.Appointments],
};

export const UpdateCarrierEnrollmentPermissions: PermissionModelForRouteAndMenu =
  {
    permissionModel: [ApiModelType.CarrierEnrollments],
    permissionAction: [ApiActionType.Update],
  };

export const AppointmentEnrollmentPermissions: PermissionModelForRouteAndMenu =
  {
    permissionModel: [ApiModelType.CarrierEnrollments],
    permissionAction: [ApiActionType.Appointment],
  };

export const CarrierAppointmentComplianceFormPermissions: PermissionModelForRouteAndMenu =
  {
    permissionModel: [ApiModelType.CarrierEnrollments],
    permissionAction: [ApiActionType.Update, ApiActionType.Appointment],
  };

export const CarrierSettingPermissions: PermissionModelForRouteAndMenu = {
  permissionModel: [ApiModelType.CarrierRankingsByProducts],
  permissionAction: [ApiActionType.Create],
  productFeatureDisabled: [ProductFeatureType.UseRecommendedCarriers],
};

export const CarrierSettingsCoveragePermissions: PermissionModelForRouteAndMenu =
  {
    permissionModel: [ApiModelType.MinimumCoverages],
    permissionAction: [ApiActionType.Create, ApiActionType.Index],
  };

export const BillingPermissions: PermissionModelForRouteAndMenu = {
  permissionModel: [ApiModelType.BillingPortals],
  permissionAction: [ApiActionType.Create],
  productFeatureEnabled: [ProductFeatureType.StripeBilling],
};

export const CommissionsPermissions: PermissionModelForRouteAndMenu = {
  permissionModel: [ApiModelType.Commissions],
  permissionAction: [ApiActionType.Create],
  productFeatureEnabled: [ProductFeatureType.StripeBilling],
  storePermissionCondition: hasSubAppointmentsEnabled,
};

export const PlaidPermissions: PermissionModelForRouteAndMenu = {
  permissionModel: [ApiModelType.Commissions],
  permissionAction: [ApiActionType.Create],
};

export const LegacyBillingPermissions: PermissionModelForRouteAndMenu = {
  permissionModel: [ApiModelType.Invoices],
  permissionAction: [ApiActionType.Index],
  productFeatureDisabled: [ProductFeatureType.StripeBilling],
};

export const LegacyPaymentPlansAndMethodsPermissions: PermissionModelForRouteAndMenu =
  {
    permissionModel: [ApiModelType.Payments],
    permissionAction: [ApiActionType.Index],
    productFeatureDisabled: [ProductFeatureType.StripeBilling],
  };

export const ExchangePermissions: PermissionModelForRouteAndMenu = {
  permissionModel: [ApiModelType.Exchange],
  permissionAction: [ApiActionType.Update],
};

export const ReferralPermissions: PermissionModelForRouteAndMenu = {
  permissionModel: [ApiModelType.Referral],
  permissionAction: [ApiActionType.Create],
};

export const ReportsPermissions: PermissionModelForRouteAndMenu = {
  permissionModel: [ApiModelType.EmbeddedReporting],
  permissionAction: [ApiActionType.Create],
};

export const QuotesReportPermission: PermissionModelForRouteAndMenu = {
  permissionModel: [ApiModelType.EmbeddedReporting],
  permissionAction: [ApiActionType.Create],
};

export const CommissionsReportPermission: PermissionModelForRouteAndMenu = {
  permissionModel: [UiModelType.CommissionsReporting],
  permissionAction: [UiActionType.Commissions],
  storePermissionCondition: hasSubAppointmentsEnabled,
};

export const ExchangeReportPermission: PermissionModelForRouteAndMenu = {
  permissionModel: [ApiModelType.EmbeddedReporting],
  permissionAction: [ApiActionType.Create],
};

export const CQSPermissions: PermissionModelForRouteAndMenu = {
  permissionModel: [ApiModelType.CQSSetting],
  permissionAction: [ApiActionType.Show],
};

// TODO: Replace with goals permissions when backend work is completed
export const GoalsPermissions: PermissionModelForRouteAndMenu = {
  permissionModel: [ApiModelType.Users],
  permissionAction: [ApiActionType.Index],
};

export const AppetitePermissions: PermissionModelForRouteAndMenu = {
  permissionModel: [ApiModelType.Appetite],
  permissionAction: [ApiActionType.Index],
};

export const AppetiteSearchPermissions: PermissionModelForRouteAndMenu = {
  permissionModel: [ApiModelType.Appetite, ApiModelType.BusinessTypeSearch],
  permissionAction: [ApiActionType.Index],
  productFeatureEnabled: [ProductFeatureType.AppetiteSearch],
};

export const RecommendedCarriersIndexBucketPermissions: PermissionModelForRouteAndMenu =
  {
    permissionModel: [ApiModelType.CarrierProductBuckets],
    permissionAction: [ApiActionType.Index],
    productFeatureEnabled: [ProductFeatureType.UseRecommendedCarriers],
  };

export const RecommendedCarriersUpdateBucketPermissions: PermissionModelForRouteAndMenu =
  {
    permissionModel: [ApiModelType.CarrierProductBuckets],
    permissionAction: [ApiActionType.Update],
  };

export const RecommendedCarriersReorderBucketPermissions: PermissionModelForRouteAndMenu =
  {
    permissionModel: [ApiModelType.CarrierProductBuckets],
    permissionAction: [ApiActionType.Reorder],
  };

export const RecommendedCarriersBucketOverride: PermissionModelForRouteAndMenu =
  {
    permissionModel: [ApiModelType.ApplicationForms],
    permissionAction: [ApiActionType.AddNextBucket],
  };

export const AcordFormsPermissions: PermissionModelForRouteAndMenu = {
  permissionModel: [ApiModelType.AcordFormDownload],
  permissionAction: [ApiActionType.Show],
};
