import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { IProductCarrierRanking } from '../../models/product-carrier-ranking';
import { createReducer, on, Action } from '@ngrx/store';
import * as ProductRankingActions from './product-ranking.actions';

export const productRankingReducerKey = 'productRanking';

/**
 * Entity state for the Product Rankings interface
 */
export interface ProductRankingState
  extends EntityState<IProductCarrierRanking> {
  productRankingsHaveBeenLoaded: boolean;
}

export const selectProductCarrierRankingId = (
  pc: IProductCarrierRanking,
): string => pc.product;

export const productRankingAdapter =
  createEntityAdapter<IProductCarrierRanking>({
    selectId: selectProductCarrierRankingId,
  });
export const productRankingInitialState: ProductRankingState =
  productRankingAdapter.getInitialState({
    productRankingsHaveBeenLoaded: false,
  });

const reducer = createReducer(
  productRankingInitialState,
  on(
    ProductRankingActions.LoadProductRankingsForTenant,
    (state: ProductRankingState): ProductRankingState => ({
      ...state,
      productRankingsHaveBeenLoaded: false,
    }),
  ),
  on(
    ProductRankingActions.StoreProductRankingsForTenant,
    (state: ProductRankingState, action) =>
      productRankingAdapter.addMany(action.rankings, {
        ...state,
        productRankingsHaveBeenLoaded: true,
      }),
  ),
  on(
    ProductRankingActions.UpdateProductRankings,
    (state: ProductRankingState, action) => {
      const { rankings } = action;
      return productRankingAdapter.setAll(rankings, state);
    },
  ),
);

export const ProductRankingReducer = (
  state: ProductRankingState | undefined,
  action: Action,
) => reducer(state, action);
