import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IChoice } from '@boldpenguin/sdk';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BusinessTypeService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Gets a list of business types (from partner engine) based on the query
   *
   * @param query term to query for
   * @returns list of business types
   */
  getBusinessTypes(query: string): Observable<IChoice[]> {
    const url = `${environment.apiBaseUri}/business_type_search?filter=${query}`;
    return this.http.get<IChoice[]>(url);
  }
}
