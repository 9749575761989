import { Pipe, PipeTransform } from '@angular/core';
import { ITranslatedMessage } from '@boldpenguin/sdk';

@Pipe({ name: 'quoteError' })
export class QuoteErrorPipe implements PipeTransform {
  transform(translatedMessages: ITranslatedMessage[]): any {
    // For now, just get the first of the translated messages that isn't Unknown
    let errorString = 'Unable to retrieve quote';
    if (translatedMessages?.length > 0) {
      const category = translatedMessages.find(
        (message) => message.code !== '00.00.00.00',
      )?.category;
      errorString = !!category ? category : errorString;
    }

    return errorString;
  }
}
