import { IWarningDialogData } from '../warning-dialog.component';

export const noEnrollmentsDialogData: IWarningDialogData = {
  title: 'No active carrier appointments',
  body: `We’re working on your request. Please note that some sub-appointments only take a few days, while others could take several weeks. If you have any questions please reach out to your Customer Success Manager.`,
  actionButtons: [
    {
      text: 'OK',
      clickedCallback: () => {},
    },
  ],
};
