export enum ComplianceFormQuestionGroup {
  MAIN = 'main',
  MARKEL = 'markel',
  STATE_AUTO = 'stateauto',
  WESTFIELD = 'westfieldcarrier',
}

export interface ComplianceResponse {
  created_at: any;
  data: CompliancePayload;
  id?: string;
  tenant_id: string;
  updated_at: any;
  completed_question_groups: Array<ComplianceFormQuestionGroup>;
}

type CompliancePayloadValue = string | string[] | number | boolean | null;

export type CompliancePayload = {
  [key: string]:
    | CompliancePayloadValue
    | { [key: string]: CompliancePayloadValue };
};
