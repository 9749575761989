import { createSelector } from '@ngrx/store';
import { selectCoreState } from '../core-feature-selector';

export const selectExchangeStatusState = createSelector(
  selectCoreState,
  (state) => state.exchangeStatus,
);

export const selectExchangeStatus = createSelector(
  selectExchangeStatusState,
  (state) => state.exchangeStatus,
);

export const selectExchangeStatusHasBeenLoaded = createSelector(
  selectExchangeStatusState,
  (state) => !!(state.exchangeStatus !== null || state.exchangeStatusError),
);

export const selectExchangeStatusError = createSelector(
  selectExchangeStatusState,
  (state) => state.exchangeStatusError,
);

export const selectHasExchangeStatusError = createSelector(
  selectExchangeStatusError,
  (error) => !!error,
);

export const selectIsReceivePartner = createSelector(
  selectExchangeStatusState,
  selectExchangeStatusHasBeenLoaded,
  selectHasExchangeStatusError,
  (state, loaded, error) => ({
    isReceivePartner: !!state?.exchangeStatus,
    loaded,
    error,
  }),
);
