import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, filter, first, map, of, switchMap, take, tap } from 'rxjs';
import { Features } from 'src/app/features/features';
import { AppointmentEnrollmentPermissions } from 'src/app/permissions/permissions';
import { isOptInQuoteFlowCarrier } from 'src/app/shared/utilities/carrier-utilities';
import { isMainComplianceFormComplete } from 'src/app/shared/utilities/compliance-form-utilities';
import {
  hasAccessPlan,
  hasLegacyPlan,
} from 'src/app/shared/utilities/plan-utilities';
import { selectPlanSku } from '../../services/bp-sdk-store-service/bp-sdk-store.selectors';
import { BpSdkStoreService } from '../../services/bp-sdk-store-service/bp-sdk-store.service';
import { ComplianceFormService } from '../../services/compliance-form/compliance-form.service';
import {
  IRouteData,
  PermissionsService,
} from '../../services/permissions/permissions.service';
import { selectActiveDirectAppointmentEnrollments } from '../enrollments/enrollments.selectors';
import * as ComplianceFormAction from './compliance-form.actions';

export const WELCOME_ROUTE = [`/${Features.WELCOME}`];

@Injectable()
export class ComplianceFormEffects {
  getComplianceForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComplianceFormAction.GetComplianceForm),
      switchMap(() =>
        this.complianceFormService.getComplianceQuestionAnswers().pipe(
          map((complianceForm) =>
            ComplianceFormAction.GetComplianceFormSuccess({ complianceForm }),
          ),
          catchError((error) =>
            of(ComplianceFormAction.GetComplianceFormError({ error })),
          ),
        ),
      ),
    ),
  );

  updateComplianceFormData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComplianceFormAction.UpdateComplianceFormData),
      switchMap(({ data }) =>
        this.complianceFormService.patchComplianceQuestionAnswers(data).pipe(
          map(() => ComplianceFormAction.UpdateComplianceFormDataSuccess()),
          catchError((error) =>
            of(ComplianceFormAction.UpdateComplianceFormDataError({ error })),
          ),
        ),
      ),
    ),
  );

  checkComplianceForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComplianceFormAction.CheckComplianceForm),
      concatLatestFrom(() => [
        this.permissionsService.hasRoutePermission(
          AppointmentEnrollmentPermissions as IRouteData,
        ),
        this.bpSdkStoreService.state$.pipe(select(selectPlanSku)),
        this.store.pipe(
          select(selectActiveDirectAppointmentEnrollments),
          map((directAppointments) =>
            directAppointments.filter(
              (appointment) =>
                // carriers with the label 'OPT_IN_QUOTE_FLOW" are automatically enrolled for new tenants
                !isOptInQuoteFlowCarrier(appointment.carrier_labels),
            ),
          ),
        ),
      ]),
      switchMap(
        ([, hasAppointmentPermissions, planSku, directAppointments]) => {
          if (!hasAppointmentPermissions) {
            return of(false);
          }
          return this.complianceFormService.getComplianceQuestionAnswers().pipe(
            map((complianceResponse) => {
              if (hasAccessPlan(planSku)) {
                return !isMainComplianceFormComplete(
                  complianceResponse.completed_question_groups,
                );
              } else if (!hasAccessPlan(planSku) && !hasLegacyPlan(planSku)) {
                return (
                  directAppointments.length === 0 &&
                  !isMainComplianceFormComplete(
                    complianceResponse.completed_question_groups,
                  )
                );
              } else {
                return false;
              }
            }),
            take(1),
            catchError(() => of(false)),
          );
        },
      ),
      map(
        (isComplianceFormIncomplete) =>
          isComplianceFormIncomplete &&
          window.location.pathname !== WELCOME_ROUTE[0],
      ),
      map((shouldNavToWelcome) =>
        shouldNavToWelcome
          ? ComplianceFormAction.RedirectToWelcome()
          : ComplianceFormAction.CheckComplianceFormSuccess(),
      ),
    ),
  );

  redirectToWelcome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComplianceFormAction.RedirectToWelcome),
      tap(() => this.router.navigate(WELCOME_ROUTE)),
      switchMap(() =>
        this.router.events.pipe(
          filter((event) => event instanceof NavigationEnd),
          first(),
          map(() => ComplianceFormAction.CheckComplianceFormSuccess()),
        ),
      ),
    ),
  );

  constructor(
    private router: Router,
    private actions$: Actions,
    private store: Store,
    private bpSdkStoreService: BpSdkStoreService,
    private complianceFormService: ComplianceFormService,
    private permissionsService: PermissionsService,
  ) {}
}
