import { Store } from '@ngrx/store';

/**
 * API Model Types
 *
 * These map to an API resource
 *
 * A full permission string is configured in Authenticator as:
 * '{Application}:{ApiModelType}:{ApiActionType}'
 *
 * Example: 'Authentication Service:api/terminal/enrollments:index'
 */
export enum ApiModelType {
  AcordFormDownload = 'application_forms/pdf_forms',
  Appetite = 'appetite/appetites',
  ApplicationForms = 'application_forms',
  BillingPortals = 'api/billing_portals',
  BusinessTypeSearch = 'business_type_search',
  CarrierEnrollments = 'api/terminal/enrollments',
  CarrierProductBuckets = 'tenants/carrier_product_buckets',
  CarrierRankingsByProducts = 'carrier_rankings_by_products',
  Commissions = 'api/plaid/link',
  CQSSetting = 'api/terminal/cqs_settings',
  EmbeddedReporting = 'api/terminal/quicksight_embed_url',
  Exchange = 'terminal/partners',
  Invoices = 'api/invoices',
  MinimumCoverages = 'minimum_coverages',
  Payments = 'api/payment_methods',
  Referral = 'api/terminal/referrals',
  Settings = 'settings',
  Users = 'api/terminal/users',
}

/**
 * API Action Types
 *
 * These map to controller actions
 *
 * A full permission string is configured in Authenticator as:
 * '{Application}:{ApiModelType}:{ApiActionType}'
 *
 * Example: 'Authentication Service:api/terminal/enrollments:index'
 */
export enum ApiActionType {
  AddNextBucket = 'add_next_bucket',
  Appointment = 'appointment',
  Create = 'create',
  Edit = 'edit',
  Index = 'index',
  New = 'new',
  Order = 'order',
  Post = 'post',
  Reorder = 'reorder',
  Show = 'show',
  Update = 'update',
}

/**
 * UI-Only Model Types
 *
 * These are configured in Authenticator the same way as API route permissions,
 * but apply strictly to UI features and do not necessarily map to endpoints
 *
 * A full permission string is configured in Authenticator as:
 * '{Application}:{UiModelType}:{UiActionType}'
 *
 * Example: 'Terminal Portal:features/menu:appointments'
 */
export enum UiModelType {
  CommissionsReporting = 'features/reporting',
  MenuFeatures = 'features/menu',
}

/**
 * UI-Only Action Types
 *
 * These correspond to specific components of the UI, such as a single nav menu item
 *
 * A full permission string is configured in Authenticator as:
 * '{Application}:{UiModelType}:{UiActionType}'
 *
 * Example: 'Terminal Portal:features/menu:appointments'
 */
export enum UiActionType {
  Appointments = 'appointments',
  Commissions = 'commissions',
}

/**
 * Features that are flagged in development and not available on prod.
 * Explicit permission is on the environment file.
 */
export enum FeatureType {
  Reports = 'enableReportsNavigationLink',
  Testing = 'unitTestingFeature',
}

/**
 * Feature that is flagged on a tiered product.
 * Verified against the features in state.viewState.features.list
 */
export enum ProductFeatureType {
  AlwaysShowBridgeLink = 'always_show_bridge_link',
  AppetiteSearch = 'agent_appetite_search',
  AppFormSubmitFeedback = 'app-form-submit-feedback',
  BusinessClassAppetite = 'business_class_appetite',
  CarrierDynamicLimits = 'carrier_dynamic_limits',
  HybridAppetite = 'hybrid_appetite',
  LogrocketSessions = 'logrocket_sessions',
  QuotesInAppetiteAppointments = 'quotes_inappetite_appointments',
  ShareableQuotes = 'shareable_quotes',
  StripeBilling = 'terminal_stripe_billing',
  UseQuoteLabels = 'use_quote_labels',
  UseRecommendedCarriers = 'use_recommended_carriers',
  WholesaleWorkflowCTA = 'wholesale_workflow_cta',
  UseDocumentUpload = 'use_document_upload',
  UseComplianceStatus = 'use_compliance_status',
}

export interface PermissionModelForRouteAndMenu {
  permissionModel: ApiModelType[] | UiModelType[];
  permissionAction: ApiActionType[] | UiActionType[];
  featureEnabled?: FeatureType[];
  productFeatureEnabled?: ProductFeatureType[];
  // TODO(BP-29149): this is really difficult to reason about,
  // remove while removing embedded_reporting feature flag
  productFeatureDisabled?: ProductFeatureType[];
  atLeastOneModel?: boolean;
  storePermissionCondition?: (store: Store) => Promise<boolean>;
}
