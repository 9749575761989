export const retentionRatioId = 'retention_ration';
export const carrierOneId = 'carrier_one';
export const carrierOneLossId = 'carrier_one_loss';
export const carrierTwoId = 'carrier_two';
export const carrierTwoLossId = 'carrier_two_trailing_loss';
export const carrierThreeId = 'carrier_three';
export const carrierThreeLossId = 'carrier_three_loss';
export const businessEntityTypeId = 'business_entity_type';
export const principalNpnId = 'principal_npn';
export const hasAgencyLicenseId = 'producer_license_in_agency_name';
export const agencyNameId = 'agency_name';
export const agencyNpnId = 'producer_license_in_agency_name_npn';
export const agencyStatesLicensedId = 'agency_states_licensed';
/**
 * Stored enrollment IDs to activate as direct appointments. Activating direct appointments
 * would prevent users from resuming Welcome flow to finish sub-appointment steps.
 */
export const queuedDirectAppointmentEnrollmentsId =
  'queued_direct_appointment_enrollments';
/**
 * Stored enrollment IDs to activate as sub-appointments. Activating sub-appointments requires
 * compliance steps to be complete, users may resume Welcome flow to finish sub-appointment steps.
 */
export const queuedSubAppointmentEnrollmentsId =
  'queued_sub_appointment_enrollments';
export const skipInstantEnrollmentDefaultForIds =
  'skip_instant_enrollment_default_for_ids';

// State Auto
export enum StateAutoComplianceQuestionKeys {
  OperationStates = 'stateauto_operation_states',
  Bonus = 'stateauto_bonus',
  TopCarrierAppointmentsAndProducts = 'stateauto_carrier_appointments_and_products',
  CarrierTerminations = 'stateauto_carrier_terminations',
  ComplianceIssues = 'stateauto_compliance_issues',
  YearsInBusiness = 'stateauto_years_in_business',
  TotalCommercialPremium = 'stateauto_total_commercial_premium',
  DirectAppointmentCarrierPremiumAndLossRatios = 'stateauto_direct_appointment_carrier_premium_and_loss_ratios',
  Website = 'stateauto_website',
}

// Westfield
export enum WestfieldComplianceQuestionKeys {
  PriorAppointmentRequest = 'westfield_prior_appointment',
  MinimumOneYearExperience = 'westfield_minimum_one_year_experience',
  PhysicalLocation = 'westfield_physical_location',
  OnboardingTraining = 'westfield_onboarding_training',
}

// Markel
export const markelComplianceYearEstablished =
  'markel_compliance_year_established';
export const markelComplianceNumberOfProducers =
  'markel_compliance_number_of_producers';
export const markelComplianceNumberOfRepresentatives =
  'markel_compliance_number_of_representatives';
export const markelComplianceHasBusinessInterest =
  'markel_compliance_has_business_interest';
export const markelComplianceBusinessInterestExplanation =
  'markel_compliance_business_interest_explanation';
export const markelComplianceTotalWholesalerPremium =
  'markel_compliance_total_wholesaler_premium';
export const markelComplianceTotalDirectAppointmentPremium =
  'markel_compliance_total_direct_appointment_premium';
export const markelComplianceTotalManagingAgentPremium =
  'markel_compliance_total_managing_agent_premium';
export const markelComplianceMemberOfAssociation =
  'markel_compliance_member_of_association';
export const markelComplianceInForcePremiumVolume =
  'markel_compliance_in_force_premium_volume';
export const markelCompliancePriorYearPremiumVolume =
  'markel_compliance_prior_year_premium_volume';
export const markelComplianceIndustries = 'markel_compliance_industries';
export const markelComplianceTerminatedWithCarrier =
  'markel_compliance_terminate_with_carrier';
export const markelComplianceCarrierTerminatedWith =
  'markel_compliance_carrier_terminated_with';
export const markelComplianceReasonForTermination =
  'markel_compliance_reason_for_termination';
export const markelComplianceMarkelUtilize = 'markel_compliance_markel_utilize';
export const markelComplianceCommitToWritingMinimum =
  'markel_compliance_commit_to_writing_minimum';
export const markelComplianceTopCarriers = 'markel_compliance_top_carriers';
