import { Params } from '@angular/router';
import {
  ComplianceFormQuestionGroup,
  ComplianceResponse,
} from 'src/app/core/models/compliance-form.model';
import { IEnrollment } from 'src/app/core/models/enrollments.model';
import {
  MixpanelEventType,
  MixpanelService,
} from 'src/app/core/services/mixpanel-service/mixpanel.service';
import {
  hasAccessPlan,
  hasDeprecatedPlan,
} from 'src/app/shared/utilities/plan-utilities';
import {
  IWelcomeStep,
  WelcomeStep,
  agencyInfoStep,
  carrierAdditionalQuestionStep,
  carrierAdditionalQuestionTrackingEvent,
  carrierComplianceSteps,
  endingEOAcknowledgementSteps,
  enrollmentSubdomainCollection,
} from '../welcome.model';

export const getTrackingSteps = (steps): IWelcomeStep[] =>
  steps.reduce((trackingSteps: IWelcomeStep[], step) => {
    const { id, text = '', order = null, children, uiOnly } = step;
    if (!uiOnly) {
      trackingSteps.push({ id, text, order });
    }
    if (children) trackingSteps.push(...getTrackingSteps(children));
    return trackingSteps;
  }, []);

export const getUiSteps = (steps): IWelcomeStep[] =>
  steps.reduce((sidebarSteps: IWelcomeStep[], step) => {
    const { children, trackingOnly } = step;
    if (trackingOnly) {
      return sidebarSteps;
    }
    sidebarSteps.push({
      ...step,
      ...(children ? { children: getUiSteps(children) } : {}),
    });
    return sidebarSteps;
  }, []);

export const addStepOrder = (steps): IWelcomeStep[] => {
  let order = 1;
  const recursiveStepOrder = (recursiveSteps): IWelcomeStep[] =>
    recursiveSteps.map((step) => {
      const { children, uiOnly } = step || {};
      return {
        ...step,
        ...(!uiOnly ? { order: order++ } : {}),
        ...(children ? { children: recursiveStepOrder(children) } : {}),
      };
    });
  return recursiveStepOrder(steps);
};

export const shouldActivateCompletedStep = (
  planCode: string,
  isComplianceFormCompleted: boolean,
  hasActiveDirectAppointmentEnrollments: boolean,
): boolean =>
  isComplianceFormCompleted ||
  (!hasAccessPlan(planCode) && hasActiveDirectAppointmentEnrollments);

export const shouldActivateDirectAppointmentsStep = (
  planCode: string,
  complianceFormData: ComplianceResponse,
  queryParams: Params,
  directAppointmentFormGroupValues: boolean[],
): boolean => {
  if (
    hasAccessPlan(planCode) ||
    (!hasDeprecatedPlan(planCode) &&
      (complianceFormData?.data.has_confirmed_direct_appointments ||
        complianceFormData?.data.has_skipped_direct_appointments))
  ) {
    return false;
  }

  // Handles redirect from self-service registration
  if (queryParams.enrollment_id) {
    return true;
  }

  return (
    directAppointmentFormGroupValues.every((val) => !val) ||
    !complianceFormData?.data.has_confirmed_direct_appointments
  );
};

export const shouldActivateSubAppointmentsStep = (
  complianceFormData: ComplianceResponse | undefined,
): boolean => !complianceFormData?.data.has_confirmed_sub_appointments;

export const addAdditionalComplianceFormStep = (
  enrollments: IEnrollment[],
  carrierAppointmentEnrollmentId: string,
  completedComplianceQuestionSets: Array<string>,
): ComplianceFormQuestionGroup | null => {
  // Find enrollment based on enrollment id params
  const enrollment = enrollments.find(
    (e) => e.id === carrierAppointmentEnrollmentId,
  );

  if (enrollment) {
    // is enrollment in subset of carriers that have addition compliance form questions
    const isAdditionalQuestionCarrier = enrollmentSubdomainCollection.includes(
      enrollment.carrier_subdomain as ComplianceFormQuestionGroup,
    );

    // is carrier question set completed already
    const hasCompletedAdditionalQuestion =
      completedComplianceQuestionSets?.includes(enrollment.carrier_subdomain) ??
      false;

    if (isAdditionalQuestionCarrier && !hasCompletedAdditionalQuestion) {
      return enrollment.carrier_subdomain as ComplianceFormQuestionGroup;
    }
  }

  return null;
};

export const getDirectAppointmentStep = (
  showDirectAppointmentsStep: boolean,
): Array<IWelcomeStep> =>
  showDirectAppointmentsStep
    ? [
        {
          id: WelcomeStep.DIRECT_APPOINTMENTS,
          text: 'Set up your current carriers',
        },
      ]
    : [];

export const getSubAppointmentStep = (
  showSubAppointmentsStep: boolean,
  planSku: string,
): Array<IWelcomeStep> =>
  showSubAppointmentsStep
    ? [
        {
          id: WelcomeStep.SUB_APPOINTMENTS,
          text: hasAccessPlan(planSku)
            ? 'Set up your carrier panel'
            : 'Request appointments with Bold Penguin',
        },
      ]
    : [];

export const getComplianceFormStep = (
  showComplianceFormSteps: boolean,
  additionalCarrierComplianceFormSteps: Array<ComplianceFormQuestionGroup>,
  mixpanelService: MixpanelService,
  hasCompletedMainQuestionSet: boolean,
): Array<IWelcomeStep> => {
  const carrierQuestionSteps = additionalCarrierComplianceFormSteps.map(
    (carrier) => carrierAdditionalQuestionStep.get(carrier) as IWelcomeStep,
  );

  const childSteps: Array<IWelcomeStep> = [
    ...(!hasCompletedMainQuestionSet ? agencyInfoStep : []),
    ...carrierQuestionSteps,
    ...(!hasCompletedMainQuestionSet ? endingEOAcknowledgementSteps : []),
  ];

  const steps: Array<IWelcomeStep> = [
    {
      id: WelcomeStep.COMPLIANCE_FORM,
      text: 'Complete your compliance questionnaire',
      uiOnly: true,
      children: childSteps,
    },
  ];

  additionalCarrierComplianceFormSteps.forEach((carrier) =>
    mixpanelService.track(
      carrierAdditionalQuestionTrackingEvent.get(carrier) as MixpanelEventType,
    ),
  );

  return showComplianceFormSteps ? steps : [];
};

export const getPaymentStep = (
  showPaymentSteps: boolean,
  hasCompletedMainQuestionSet: boolean,
): Array<IWelcomeStep> =>
  !hasCompletedMainQuestionSet && showPaymentSteps
    ? [
        {
          id: WelcomeStep.PAYMENTS,
          text: 'Set up your commission payments',
        },
      ]
    : [];

export const getCompletedStep = (
  isAppointmentsFlow: boolean,
): Array<IWelcomeStep> => [
  {
    id: isAppointmentsFlow
      ? WelcomeStep.APPOINTMENTS_COMPLETED
      : WelcomeStep.COMPLETED,
    trackingOnly: true,
  },
];

export const isCompletingOnlyCarrierComplianceQuestionSet = (
  currentStep: IWelcomeStep,
): boolean =>
  carrierComplianceSteps.includes(currentStep.id) && currentStep?.order === 1;
