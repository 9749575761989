import { createAction, props } from '@ngrx/store';
import {
  CompliancePayload,
  ComplianceResponse,
} from '../../models/compliance-form.model';

/**
 * Loading Compliance Form
 */
export const GetComplianceForm = createAction(
  '[ComplianceForm] - Get Compliance Form',
);

export const GetComplianceFormSuccess = createAction(
  '[ComplianceForm] - Get Compliance Form Success',
  props<{ complianceForm: ComplianceResponse }>(),
);

export const GetComplianceFormError = createAction(
  '[ComplianceForm] - Get Compliance Form Error',
  props<{ error: Error }>(),
);

export const UpdateComplianceFormData = createAction(
  '[ComplianceForm] Update Compliance Form Data',
  props<{ data: CompliancePayload }>(),
);

export const UpdateComplianceFormDataSuccess = createAction(
  '[ComplianceForm] Update Compliance Form Data Success',
);

export const UpdateComplianceFormDataError = createAction(
  '[ComplianceForm] Update Compliance Form Data Error',
  props<{ error: Error }>(),
);

/**
 * Checking Compliance Form
 */
export const CheckComplianceForm = createAction(
  '[ComplianceForm] Check Compliance Form',
);

export const CheckComplianceFormSuccess = createAction(
  '[ComplianceForm] Check Compliance Form Success',
);

export const RedirectToWelcome = createAction(
  '[ComplianceForm] Redirect To Welcome',
);
