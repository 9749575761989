export enum PlanCodes {
  Access = 'access',
  Connect = 'connect',
  ConnectPlus = 'connect_plus',
  Enterprise = 'enterprise',
  Terminal = 'terminal',
  TerminalAnnual = 'terminal_annual',
  TerminalMonthly = 'terminal_monthly',
  ViewOnly = 'viewonly',
  BiBERKTerminalAnnual = 'biberk_terminal_annual',
  BiBERKTerminalMonthly = 'biberk_terminal_monthly',
  AsnoaTerminalAnnual = 'asnoa_terminal_annual',
  AsnoaTerminalMonthly = 'asnoa_terminal_monthly',
  HubTerminalAnnual = 'hub_terminal_annual',
  HubTerminalMonthly = 'hub_terminal_monthly',
  AgencyCollectiveTerminalAnnual = 'agency_collective_terminal_annual',
  AgencyCollectiveTerminalMonthly = 'agency_collective_terminal_monthly',
}
