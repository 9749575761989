import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { LocalStorageService } from 'ngx-webstorage';
import { combineLatest, map, Observable, startWith } from 'rxjs';
import { localStorageKeys } from 'src/app/shared/constants';
import { IEnrollment } from '../../models/enrollments.model';
import { selectAllEnrollments } from '../../store/enrollments/enrollments.selectors';

@Injectable({
  providedIn: 'root',
})
export class ComplianceFormEnrollmentIdService {
  constructor(
    private readonly store: Store,
    private readonly localStorageService: LocalStorageService,
  ) {}

  get getCarrierAppointmentEnrollmentId$(): Observable<string | null> {
    return this.localStorageService
      .observe(localStorageKeys.settingsEnrollmentId)
      .pipe(
        startWith(
          this.localStorageService.retrieve(
            localStorageKeys.settingsEnrollmentId,
          ),
        ),
      );
  }

  get isRequestingCarrierAppointment$(): Observable<boolean> {
    return this.getCarrierAppointmentEnrollmentId$.pipe(
      map((enrollmentId) => !!enrollmentId),
    );
  }

  get getCarrierAppointmentEnrollment$(): Observable<IEnrollment | undefined> {
    return combineLatest([
      this.getCarrierAppointmentEnrollmentId$,
      this.store.pipe(select(selectAllEnrollments)),
    ]).pipe(
      map(([carrierAppointmentEnrollmentId, allEnrollments]) =>
        allEnrollments.find(
          (enrollment) => enrollment.id === carrierAppointmentEnrollmentId,
        ),
      ),
    );
  }

  setCarrierAppointmentEnrollmentId(enrollmentId: string): void {
    this.localStorageService.store(
      localStorageKeys.settingsEnrollmentId,
      enrollmentId,
    );
  }
}
