import { Injectable } from '@angular/core';
import {
  BpSdkStore,
  IPartnerEngineConfig,
  isSdkConfigured,
  isSdkReady,
  isUserAuthenticated,
} from '@boldpenguin/sdk';
import { select } from '@ngrx/store';
import { AnyAction } from 'redux';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, skipUntil } from 'rxjs/operators';

export interface IBpSdkStoreService {
  state$: Observable<BpSdkStore>;
  sdkReadyState$: Observable<BpSdkStore>;
  getApiConfig(): IPartnerEngineConfig;
  getCurrentState(): BpSdkStore;
  dispatch(action: AnyAction);
  init(): void;
}

@Injectable({
  providedIn: 'root',
})
export class BpSdkStoreService implements IBpSdkStoreService {
  private _state$: BehaviorSubject<BpSdkStore | null> =
    new BehaviorSubject<BpSdkStore | null>(null);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  isAuthenticated$ = this.state$.pipe(
    skipUntil(this.state$.pipe(filter(isSdkConfigured))),
    select(isUserAuthenticated),
  );

  public get state$(): Observable<BpSdkStore> {
    return this._state$.pipe(
      filter((state): state is BpSdkStore => state !== null),
    );
  }

  // sdkReadyState$ adds an isSdkReady filter to state$ to prevent emitting state before the sdk is ready
  public get sdkReadyState$(): Observable<BpSdkStore> {
    return this.state$.pipe(filter(isSdkReady));
  }

  init() {
    (window as any).BpSdk.store.subscribe(() => {
      const newState = (window as any).BpSdk.store.getState();
      this._state$.next(newState);
    });

    // seed with the current state
    this._state$.next((window as any).BpSdk.store.getState());
  }

  public getApiConfig(): IPartnerEngineConfig {
    return (window as any).BpSdk.getConfig();
  }

  public getCurrentState(): BpSdkStore {
    return (window as any).BpSdk?.store?.getState() || {};
  }

  // TODO(refactor): fix action typing
  public dispatch(action: AnyAction): void {
    (window as any).BpSdk.store.dispatch(action);
  }
}
