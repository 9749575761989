import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

import { environment } from '../environments/environment';
import { localStorageKeys } from './shared/constants';

declare global {
  interface Window {
    Cypress?: unknown;
  }
}

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  hideReportDialog: boolean;

  private chunkFailedPattern = /Loading chunk [\d]+ failed/;

  constructor(private zone: NgZone) {}

  handleError(e: any) {
    if (environment.production) {
      if (this.chunkFailedPattern.test(e.message)) {
        window.location.reload();
        return;
      }

      const extractedError = this.extractError(e) || 'Handled unknown error';
      console.error(extractedError);
      const isAutomatedTest = !!window?.Cypress;
      const sentryModalDisabled = localStorage.getItem(
        localStorageKeys.disableSentryModal,
      );

      const eventId = this.zone.runOutsideAngular(() =>
        Sentry.captureException(extractedError),
      );

      if (
        environment.isUatEnv &&
        !this.hideReportDialog &&
        !sentryModalDisabled &&
        !isAutomatedTest
      ) {
        this.hideReportDialog = true;
        Sentry.showReportDialog({ eventId });
      }
    }
  }

  // https://github.com/getsentry/sentry-javascript/blob/master/packages/angular/src/errorhandler.ts
  private extractError(error: any): unknown {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error?.ngOriginalError) {
      error = error.ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent && error.error.message) {
        return error.error.message;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // Nothing was extracted, fallback to default error message.
    return null;
  }
}
