<emperor-dialog
  [uiDialogSize]="uiDialogSize"
  class="naics-search-dialog"
  (close)="close()"
>
  <emperor-dialog-header [title]="title"> </emperor-dialog-header>

  <emperor-text-input
    appAutoFocusEmp
    [formControl]="filter"
    [label]="searchLabel"
    [iconPrefix]="searchIcon"
    (keydown.enter)="stopEventPropagation($event)"
  >
    <emperor-icon
      suffix
      aria-label="Clear"
      class="emperor-ui-clear-icon"
      name="remove_circle_2"
      [class.disabled]="!filter.value"
      (click)="clearSearch()"
    ></emperor-icon>
    ></emperor-text-input
  >
  <emperor-card-content [scrollable]="true" class="card-content">
    <ng-container *ngFor="let choiceGroup of groupedChoices">
      <emperor-bp-sdk-business-classification-selector-naics-group-header
        *ngIf="choiceGroup.choices[0]"
        [naicsDescription]="
          choiceGroup.choices[0].national_industry_description || ''
        "
        [naicsExplanation]="choiceGroup.choices[0].explanation || ''"
      >
      </emperor-bp-sdk-business-classification-selector-naics-group-header>
      <emperor-bp-sdk-business-classification-selector-card-content
        *ngFor="let choice of choiceGroup.choices"
        [alias]="choice.alias"
        [confidence]="choice.confidence ?? 0"
        [explanation]="choice.explanation ?? ''"
        [highlights]="choice.highlights ?? []"
        [showConfidence]="false"
        [showDetailedRelevanceChip]="false"
        [showExplanation]="choice.showExplanation ?? false"
        [showNaicsCode]="true"
        [value]="choice.value"
        [isSelected]="choice.value === selectedNaicsDescription"
        [isBestMatch]="false"
        (click)="save(choice, 'search')"
        (keydown.enter)="save(choice, 'search')"
        (keydown.space)="save(choice, 'search')"
      ></emperor-bp-sdk-business-classification-selector-card-content>
    </ng-container>

    <div
      *ngIf="
        groupedChoices.length === 0 &&
        !noResults &&
        !loading &&
        recentChoices.length > 0
      "
    >
      <div class="top-choices-naics-header">
        <div class="top-choices-naics-heading subheader">
          Recent
          <span class="top-choices-naics-visual-divider"></span>
        </div>
      </div>
      <emperor-bp-sdk-business-classification-selector-card-content
        *ngFor="let choice of recentChoices"
        [alias]="choice.alias"
        [value]="choice.value"
        [isSelected]="choice.value === selectedNaicsDescription"
        [showNaicsCode]="true"
        [showConfidence]="false"
        [showDetailedRelevanceChip]="false"
        [isBestMatch]="false"
        (click)="save(choice, 'recent')"
        (keydown.enter)="save(choice, 'recent')"
        (keydown.space)="save(choice, 'recent')"
      ></emperor-bp-sdk-business-classification-selector-card-content>
    </div>

    <div *ngIf="groupedChoices.length === 0 && !noResults && !loading">
      <div class="top-choices-naics-header">
        <div class="top-choices-naics-heading subheader">
          Top 10 most common
          <span class="top-choices-naics-visual-divider"></span>
        </div>
      </div>
      <emperor-bp-sdk-business-classification-selector-card-content
        *ngFor="let choice of topChoices"
        [alias]="choice.alias"
        [value]="choice.value"
        [isSelected]="choice.value === selectedNaicsDescription"
        [showNaicsCode]="true"
        [showConfidence]="false"
        [showDetailedRelevanceChip]="false"
        [isBestMatch]="false"
        (click)="save(choice, 'top-choices')"
        (keydown.enter)="save(choice, 'top-choices')"
        (keydown.space)="save(choice, 'top-choices')"
      ></emperor-bp-sdk-business-classification-selector-card-content>
    </div>
    <ng-container *ngIf="loading">
      <emperor-spinner [diameter]="100" class="loading"></emperor-spinner>
    </ng-container>
    <div class="no-results" *ngIf="noResults">
      No results found for "{{ filter.value }}"
    </div>
  </emperor-card-content>

  <emperor-card-footer class="card-footer">
    <ng-content select="[footer-actions]"></ng-content>
  </emperor-card-footer>
</emperor-dialog>
