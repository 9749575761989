import { createAction, props } from '@ngrx/store';
import { PaymentMethod } from '@stripe/stripe-js';
import { IPromotionCode } from '../../billing/models/coupons';
import { ILegalAgreements } from '../models/legal-agreements.model';
import { MessageDisplay } from '../models/message-display.model';
import { ISignupAgencyInfo } from '../models/signup-agency-info.model';
import { ISignupAgentInfo } from '../models/signup-agent-info.model';
import { ISubscriptionPlanDetails } from '../models/subscription-plan-details.model';

export const signupSetSalesforceParams = createAction(
  '[Signup] Set Salesforce attributes',
  props<{
    accountId: string;
    businessName?: string;
    emailAddress?: string;
    firstName?: string;
    lastName?: string;
    opportunityId: string;
    phoneNumber?: string;
  }>(),
);

export const signupSetDiscount = createAction(
  '[Signup] Set discount',
  props<{ discountCode: string }>(),
);

export const signupSetDiscountSuccess = createAction(
  '[Signup] Set discount success',
  props<{ label: string; promotionCode: string }>(),
);

export const signupSetPlanSku = createAction(
  '[Signup] Set plan sku',
  props<{ planSku: string }>(),
);

export const signupSetPlanSkuSuccess = createAction(
  '[Signup] Set plan sku success',
  props<{ planSku: string; label: string; promotionCode: string }>(),
);

export const signupBackButtonClicked = createAction(
  '[Signup] Signup Next Back Clicked',
  props<{ route: any[] }>(),
);

export const submitAgencyInfo = createAction(
  '[Signup] Signup Agency Info Submitted',
  props<{ agencyInfo: ISignupAgencyInfo }>(),
);

export const submitAgentInfo = createAction(
  '[Signup] Signup Agent Info Submitted',
  props<{ agentInfo: ISignupAgentInfo }>(),
);

export const selectPlanChoice = createAction(
  '[Signup] Signup Plan Selected',
  props<{ plan: ISubscriptionPlanDetails }>(),
);

export const submitPlanChoice = createAction('[Signup] Signup Plan Submitted');

export const setProgressBarPercent = createAction(
  '[Signup] Set Signup Progress Bar Percent',
  props<{ progressBarPercent: number }>(),
);

export const submitPaymentInformation = createAction(
  '[Signup] Signup Payment Submitted',
);

export const loadPromotionCode = createAction(
  '[Signup] Load promotion code',
  props<{ promotionCode: string }>(),
);

export const loadPromotionCodeSuccessful = createAction(
  '[Signup] Promotion code loaded successfully',
  props<{ promotionCode: IPromotionCode }>(),
);

export const loadPromotionCodeFailed = createAction(
  '[Signup] Promotion code failed to load',
  props<{ error: string }>(),
);

export const setPaymentDataInStore = createAction(
  '[Signup] Set Payment method data',
  props<{ paymentMethod: PaymentMethod }>(),
);

export const setMessageDisplay = createAction(
  '[Signup] Set Message to Display',
  props<{ messageDisplay: MessageDisplay }>(),
);

export const submitLegalAgreements = createAction(
  '[Signup] Signup Legal Terms Agreed To',
  props<{ legalAgreements: ILegalAgreements }>(),
);

export const createNewAccount = createAction(
  '[Signup] Completed. Create Account',
);

export const createNewAccountSuccessfulInternal = createAction(
  '[Signup] Account created successfully',
);

export const createNewAccountSuccessful = createAction(
  '[Signup] Account created successfully',
);

export const createNewAccountFailed = createAction(
  '[Signup] Account failed to create',
  props<{ error: string }>(),
);
