<!-- Referral Button -->
<button
  *ngIf="canSeeReferralButton"
  class="menu-button"
  mat-menu-item
  (click)="referAFriendClicked()"
>
  <emperor-icon name="user_share_1"></emperor-icon>
  <span>Refer a friend!</span>
</button>
<!-- Logout Button -->
<button
  class="menu-button logout-button"
  mat-menu-item
  (click)="logoutClicked()"
>
  <emperor-icon name="logout_2"></emperor-icon>
  <span>Logout</span>
</button>
