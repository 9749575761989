import { createAction, props } from '@ngrx/store';
import {
  IAvailableEnrollment,
  IEnrollment,
} from 'src/app/core/models/enrollments.model';

/**
 * Loading The Enrollments
 */
export const GetAllEnrollments = createAction(
  '[Enrollments] - Get All Enrollments',
);

export const GetAllEnrollmentsSuccess = createAction(
  '[Enrollments] - Get All Enrollments Success',
  props<{ enrollments: IEnrollment[] }>(),
);

export const GetAllEnrollmentsError = createAction(
  '[Enrollments] - Get All Enrollments Error',
  props<{ error: Error }>(),
);

/**
 * Update Base Enrollment
 */
export const UpdateEnrollmentAction = createAction(
  '[Enrollments] - Toggle Enrollment',
  props<{ enrollment: IEnrollment; activeStatus: boolean }>(),
);

export const UpdateEnrollmentSuccessAction = createAction(
  '[Enrollments] - Toggle Enrollment Success',
  props<{ enrollment: IEnrollment }>(),
);

export const UpdateEnrollmentErrorAction = createAction(
  '[Enrollments] - Toggle Enrollment ERROR',
  props<{ error: string[]; enrollment: IEnrollment }>(),
);

export const QuoteViewUpdateEnrollmentCredentials = createAction(
  '[Quote View] - Update Enrollment Credentials',
  props<{ enrollment: IEnrollment }>(),
);
export const QuoteViewUpdateEnrollmentCredentialsSuccess = createAction(
  '[Quote View] - Update Enrollment Credentials Success',
  props<{ enrollment: IEnrollment }>(),
);
export const QuoteViewUpdateEnrollmentCredentialsError = createAction(
  '[Quote View] - Update Enrollment Credentials Error',
  props<{ error: string[]; enrollment: IEnrollment }>(),
);

/**
 * Update Carrier Attributes
 */
export const UpdateCarrierAttributes = createAction(
  '[Enrollments] - Update Carrier Attributes',
  props<{ enrollment: IEnrollment }>(),
);

export const UpdateCarrierAttributesSuccess = createAction(
  '[Enrollments] - Update Carrier Attributes Success',
  props<{ enrollment: IEnrollment }>(),
);

export const UpdateCarrierAttributesError = createAction(
  '[Enrollments] - Update Carrier Attributes Error',
  props<{ error: string[]; enrollment: IEnrollment }>(),
);

/**
 * Active Direct Enrollments
 */

export const ActivateDirectAppointments = createAction(
  '[Enrollments] - Activate Direct Enrollments',
  props<{ enrollments: IEnrollment[] }>(),
);
export const ActivateDirectAppointmentsSuccess = createAction(
  '[Enrollments] - Activate Direct Enrollments Success',
  props<{ enrollments: IEnrollment[] }>(),
);
export const ActivateDirectAppointmentsError = createAction(
  '[Enrollments] - Activate Direct Enrollments Error',
  props<{
    error: Error;
    enrollments: IEnrollment[];
    failedEnrollment: IEnrollment;
  }>(),
);

/**
 * Enrollment Appointments Dialog
 */
export const UpdateShowEnrollmentAppointmentDialog = createAction(
  '[Enrollments] - Toggle Enrollment Appointment Dialog',
  props<{ showDialog: boolean }>(),
);

/**
 * Update Enrollment Appointments
 */
export const UpdateEnrollmentAppointmentAction = createAction(
  '[Enrollments] - Toggle Enrollment Appointment',
  props<{ enrollment: IEnrollment; activeStatus: boolean }>(),
);

export const UpdateEnrollmentAppointmentSuccess = createAction(
  '[Enrollments] - Toggle Enrollment Appointment Success',
  props<{ enrollment: IEnrollment }>(),
);

export const UpdateEnrollmentAppointmentFailure = createAction(
  '[Enrollments] - Toggle Enrollment Appointment Failure',
);

export const CancelEnrollmentAttempt = createAction(
  '[Enrollments] - Cancel Attempted Enrollment Appointment Creation',
  props<{ enrollment: IEnrollment }>(),
);

/**
 * Request Enrollment Appointments
 */
export const RequestEnrollmentAppointments = createAction(
  '[Enrollments] - Request Enrollment Appointments',
  props<{ enrollments: IEnrollment[] }>(),
);

export const RequestEnrollmentAppointmentsSuccess = createAction(
  '[Enrollments] - Request Enrollment Appointments Success',
  props<{ enrollments: IEnrollment[] }>(),
);

export const RequestEnrollmentAppointmentsError = createAction(
  '[Enrollments] - Request Enrollment Appointments Error',
  props<{ error: Error }>(),
);

/**
 * Available Enrollments
 */
export const GetAvailableEnrollments = createAction(
  '[Enrollments] Get Available Enrollment Choices',
);

export const GetAvailableEnrollmentsSuccess = createAction(
  '[Enrollments] Get Available Enrollment Choices Success',
  props<{ enrollments: IAvailableEnrollment[] }>(),
);

export const GetAvailableEnrollmentsError = createAction(
  '[Enrollments] Get Available Enrollment Choices Error',
  props<{ error: Error }>(),
);

export const NavigateToAppointmentsComplianceForm = createAction(
  '[Enrollments] Navigate to appointments compliance form',
  props<{ enrollmentId: string }>(),
);

export const NavigateToComplianceFormFromAppointmentsDialog = createAction(
  '[Enrollments] Navigate to appointments compliance form from carrier appointments dialog',
  props<{ enrollmentId: string }>(),
);
