import { IEnrollment } from 'src/app/core/models/enrollments.model';

export enum AppointmentOptionsToShow {
  Sub = 'sub',
  Both = 'both',
  Direct = 'direct',
}

export enum SubmitButtonText {
  FirstTimeWizard = 'CONNECT & CONTINUE',
  DirectAppointment = 'CONFIRM',
  RequestAppointment = 'REQUEST',
}

export interface AppointmentDialogResolution {
  canceled: boolean;
  enrollment?: IEnrollment;
}
