import { IEligibleCarrierStatusInfo } from '../../models/eligible-carrier-status-info';

export enum EligibleCarrierStatus {
  Info = 'info',
  Error = 'error',
  Success = 'success',
  Preferred = 'preferred',
  Warning = 'warning',
  NotOptedIn = 'not-opted-in',
  Incomplete = 'incomplete',
  OffPlatform = 'off-platform',
}

export enum EligibleCarrierStatusDefaultMessages {
  InProgress = 'In progress...',
  NotStarted = 'Not started',
  OutOfAppetite = 'Out of appetite',
  Quoted = 'Quoted',
  Completed = 'Completed',
  Error = 'Error',
  Preferred = 'Preferred carrier',
}

export const CarrierProductStatus: {
  [key: string]: IEligibleCarrierStatusInfo;
} = {
  [EligibleCarrierStatus.Preferred]: {
    statusMessage: EligibleCarrierStatusDefaultMessages.Preferred,
    status: EligibleCarrierStatus.Preferred,
    icon: 'star_circle',
  },
  [EligibleCarrierStatus.Success]: {
    statusMessage: EligibleCarrierStatusDefaultMessages.Quoted,
    status: EligibleCarrierStatus.Success,
    icon: 'check_circle_2_2',
  },
  [EligibleCarrierStatus.Warning]: {
    status: EligibleCarrierStatus.Warning,
    icon: 'interface_alert_circle_1',
  },
  [EligibleCarrierStatus.Info]: {
    statusMessage: EligibleCarrierStatusDefaultMessages.InProgress,
    status: EligibleCarrierStatus.Info,
    icon: 'clock',
  },
  [EligibleCarrierStatus.NotOptedIn]: {
    statusMessage: EligibleCarrierStatusDefaultMessages.InProgress,
    status: EligibleCarrierStatus.NotOptedIn,
    icon: 'clock',
  },
  [EligibleCarrierStatus.Incomplete]: {
    statusMessage: EligibleCarrierStatusDefaultMessages.InProgress,
    status: EligibleCarrierStatus.Incomplete,
    icon: 'clock',
  },
  [EligibleCarrierStatus.OffPlatform]: {
    status: EligibleCarrierStatus.OffPlatform,
    icon: 'login_2',
  },
};
