import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IIconPrefix } from '@boldpenguin/emperor-form-fields';
import {
  EmperorCardHeaderColor,
  EmperorDialogRefService,
  EmperorDialogSize,
} from '@boldpenguin/emperor-presentational';
import { IChoice } from '@boldpenguin/sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { BusinessTypeService } from 'src/app/core/services/business-type/business-type.service';
import { IExcludedNaics } from 'src/app/features/exchange/models/exchange';
import { NaicsExplanationDialogComponent } from '../naics-explanation-dialog/naics-explanation-dialog.component';

export interface IChoiceExt extends IChoice {
  selected?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-multi-naics-search-dialog',
  templateUrl: './multi-naics-search-dialog.component.html',
  styleUrls: ['./multi-naics-search-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiNaicsSearchDialogComponent implements OnInit {
  @Input() selectedNaics: IExcludedNaics[] = [];
  @Input() title: string;
  @Input() searchLabel: string;
  @Input() selectedTitle: string;
  @Output() closed = new EventEmitter<void>();
  @Output() saved = new EventEmitter<any>();

  readonly uiDialogSize = EmperorDialogSize.Large;
  readonly EmperorCardHeaderColor = EmperorCardHeaderColor;
  readonly searchIcon: IIconPrefix = {
    showPrefix: true,
    icon: 'search_1',
  };

  filter = new UntypedFormControl();
  results: IChoiceExt[][];
  noResults: boolean;
  loading = false;

  constructor(
    private businessTypeService: BusinessTypeService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly emperorDialogService: EmperorDialogRefService,
  ) {}

  ngOnInit(): void {
    // Watches the input for changes
    this.filter.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(500), untilDestroyed(this))
      .subscribe((filter: string) => this.search(filter));
  }

  search(filter: string): void {
    if (filter) {
      this.loading = true;
      this.results = [];
      this.cdRef.detectChanges();
      this.businessTypeService
        .getBusinessTypes(filter)
        .pipe(take(1), untilDestroyed(this))
        .subscribe((searchResults: IChoiceExt[]) => {
          this.loading = false;
          this.noResults = !searchResults.length;
          const firstSevenResults = searchResults.slice(0, 7);
          // We need to group the results by code (alias) while keeping the order of relevance
          // Make an array of the naics descriptions for comparing
          const selectionsArr = this.selectedNaics.map((a) => a.description);
          const grouped: IChoiceExt[][] = [];

          firstSevenResults.forEach((result: IChoiceExt) => {
            // If this result's description is already in the selected naics, show it as selected
            if (selectionsArr.some((el) => el === result.value)) {
              result.selected = true;
            }
            const group: IChoiceExt[] = firstSevenResults.filter(
              (r: IChoiceExt) => r.alias === result.alias,
            );
            grouped.push(group);
          });

          this.results = uniqWith<IChoiceExt[]>(grouped, isEqual);
          this.cdRef.detectChanges();
        });
    } else {
      this.clearSearch();
    }
  }

  close() {
    this.closed.emit();
  }
  save(result: IChoiceExt) {
    if (!result.selected) {
      this.saved.emit(result.value);
    }
  }

  clearSearch(): void {
    this.filter.setValue('');
    this.results = [];
    this.noResults = false;
    this.loading = false;
    this.cdRef.detectChanges();
  }

  showExplanationDialog(description: string = '', explanation: string = '') {
    this.emperorDialogService.open(NaicsExplanationDialogComponent, {
      data: {
        naicsDescription: description,
        naicsExplanation: explanation,
      },
    });
  }
}
