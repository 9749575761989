import { CoverageTypesUtils } from '@boldpenguin/emperor-services';
import {
  IAnswer,
  IApplicationFormOwner,
  IApplicationFormQuestionSet,
  ICarrier,
  ICarrierWithProducts,
  IChoice,
  IQuotesState,
  IRealTimeEligibilityCarrier,
  ITranslatedMessage,
  IUser,
  QuestionReferenceType,
  QuoteRequestRequestTypes,
  QuotesState,
} from '@boldpenguin/sdk';
import {
  isGuestUserType,
  isProgrammaticUserType,
  quotesDisplayUser,
} from 'src/app/shared/utilities/exchange-user-utilities';
import {
  CarrierProductStatus,
  EligibleCarrierStatus,
} from '../components/eligibility-panel/eligible-carrier.model';
import { IEligibleCarrierStatusInfo } from '../models/eligible-carrier-status-info';
import { QuoteCardViewModel, SummaryPanelMap } from '../models/quote';
import { IQuoteIncompleteInformationContent } from '../models/quote-information.model';
import { QuoteErrorPipe } from '../pipes/quote/quote-error.pipe';
import { answerDataByCode } from './answer-data-by-code';
import { isAdditionalMarketCarrier } from 'src/app/shared/utilities/carrier-utilities';

export enum QuoteProductBundleNames {
  BUNDLES = 'Bundles',
  ADMITTED = 'Admitted markets',
}

export type GroupedOnlineQuotes = {
  [key: string]: typeof QuotesState;
};

export const QuoteRequestNotOptedIn =
  'not_opted_in' as QuoteRequestRequestTypes;

export const QuoteRequestIncompleteQuestionSet =
  'incomplete_question_set' as QuoteRequestRequestTypes;

const MAX_LENGTH_FOR_INLINE_LABEL = 0;

export const shouldDisplayInlineLabel = (
  label: string,
  code: string,
): boolean => {
  if (answerDataByCode[code]) {
    return !!answerDataByCode[code].displayInlineLabel;
  }

  return (
    !!label && label.length > 0 && label.length < MAX_LENGTH_FOR_INLINE_LABEL
  );
};

export const prepareNaicsCodes = (naics: string[]): string => {
  const naicsCodesString = 'naics_codes[]=';
  return `${naicsCodesString}${naics.join(`&${naicsCodesString}`)}`;
};

export const extractValue = (cc: string, extract: string): string =>
  SummaryPanelMap.find((i) => cc.includes(i.code))?.[extract];

export const extractMulti = (cIds: string[], choices: IChoice[]) =>
  choices.filter((choice) => cIds.indexOf(choice.id) !== -1);

export const extractDeep = (cId: string, choices: IChoice[]): IChoice | null =>
  choices.find((choice: IChoice) => choice.id === cId) ?? null;

export const extractAnswer = (
  answers: IAnswer[],
  targetCode: string | RegExp,
  returnAlias: boolean = false,
  referenceType: QuestionReferenceType | undefined = undefined,
  transformToAbbreviated = false,
) => {
  let currentValue: string | undefined;

  const answer = findAnswerByQuestionAttributes(
    answers,
    targetCode,
    referenceType,
  );
  if (!answer) {
    return currentValue;
  }

  const currentCode = answer.question.code;
  if (extractValue(currentCode, 'multi')) {
    if (transformToAbbreviated) {
      currentValue = extractMulti(answer.choice_ids, answer.question.choices)
        .map((value) => CoverageTypesUtils.getCoverageAbbreviation(value.value))
        .join(', ');
    } else {
      currentValue = extractMulti(answer.choice_ids, answer.question.choices)
        .map((choice) => choice.value)
        .join(', ');
    }
  } else if (extractValue(currentCode, 'deep')) {
    const target = extractDeep(answer.choice_id ?? '', answer.question.choices);
    if (returnAlias) {
      currentValue = (target && target.alias) ?? '';
    } else {
      currentValue = (target && target.value) ?? '';
    }
  } else if (answer.value) {
    currentValue = answer.value;
  }

  return currentValue;
};

/**
 * Find answer by either question reference_type or partial match on
 * question code. Matching by reference_type is a higher confidence
 * match so if given, check all answers first by reference_type before
 * trying a partial match on the question code (lower confidence).
 *
 * @param answers indexed answers
 * @param targetCode partial question code
 * @param referenceType enumerable reference_type, not defined for all questions
 * @returns
 */
export const findAnswerByQuestionAttributes = (
  answers: IAnswer[],
  targetCode: string | RegExp,
  referenceType: QuestionReferenceType | undefined,
): IAnswer | undefined =>
  (referenceType &&
    answers.find((a) => a.question.reference_type === referenceType)) ||
  answers.find((a) => isQuestionCodeTarget(a, targetCode));

// Exclude driver questions.
const isQuestionCodeTarget = (
  answer: IAnswer,
  targetCode: string | RegExp,
): boolean => {
  if (answer.question.code.split('_').includes('driver')) {
    return false;
  }

  if (targetCode instanceof RegExp) {
    return targetCode.test(answer.question.code);
  }

  return answer.question.code.includes(targetCode);
};

export const getQuoteFailureDescription = (
  translatedMessages: ITranslatedMessage[],
): string => {
  // For now, just get the first of the translated messages that isn't Unknown
  let errorString = 'Quoting Issue';

  if (translatedMessages.length > 0) {
    const description = translatedMessages.find(
      (message) => message.code !== '00.00.00.00',
    )?.description;
    errorString = !!description ? description : errorString;
  }

  return errorString;
};

export const getQuoteStatus = (
  quote: IQuotesState,
  hasPreferredQuotesEnabled: boolean,
  isQuestionSetPreferred = false,
): IEligibleCarrierStatusInfo | null => {
  if (!quote.request_status) {
    return null;
  } else if (!quote.online) {
    return CarrierProductStatus[EligibleCarrierStatus.OffPlatform];
  } else if (isQuoteSuccessful(quote)) {
    // If the carrier is marked as preferred, change the success status
    return (isQuotePreferred(quote) || isQuestionSetPreferred) &&
      hasPreferredQuotesEnabled
      ? CarrierProductStatus[EligibleCarrierStatus.Preferred]
      : CarrierProductStatus[EligibleCarrierStatus.Success];
  } else if (isQuoteErrored(quote)) {
    const quoteErrorPipe = new QuoteErrorPipe();
    return {
      ...CarrierProductStatus[EligibleCarrierStatus.Warning],
      statusMessage: quoteErrorPipe.transform(quote.translated_messages),
    };
  } else if (isQuotePending(quote)) {
    return CarrierProductStatus[EligibleCarrierStatus.Info];
  } else if (isQuoteNotOptedIn(quote)) {
    return CarrierProductStatus[EligibleCarrierStatus.NotOptedIn];
  } else if (isQuoteQuestionSetIncomplete(quote)) {
    return CarrierProductStatus[EligibleCarrierStatus.Incomplete];
  }
  return null;
};

export const groupQuotesByProduct = (quotes: IQuotesState[]) => {
  const quoteMapping: { [key: string]: Array<IQuotesState> } = {};
  quotes.forEach((quote) => {
    const productList = !!quote.products
      ? quote.products
          .map((p) => p.name)
          .sort()
          .join(', ')
      : 'Other';

    if (quoteMapping[productList]) {
      quoteMapping[productList].push(quote);
    } else {
      quoteMapping[productList] = [quote];
    }
  });
  return quoteMapping;
};

export const isQuoteSuccessful = (quote: IQuotesState): boolean =>
  !!quote.request_status &&
  !isQuoteErrored(quote) &&
  !isQuotePending(quote) &&
  !isQuoteNotOptedIn(quote) &&
  !isQuoteQuestionSetIncomplete(quote);

export const isQuoteErrored = (quote: IQuotesState): boolean =>
  quote.request_status === QuoteRequestRequestTypes.failed ||
  quote.request_status === QuoteRequestRequestTypes.ineligible ||
  quote.request_status === QuoteRequestRequestTypes.timed_out;

export const isQuotePending = (quote: IQuotesState): boolean =>
  quote.request_status === QuoteRequestRequestTypes.unsent ||
  quote.request_status === QuoteRequestRequestTypes.sent ||
  quote.request_status === QuoteRequestRequestTypes.in_progress;

export const isQuoteNotOptedIn = (quote: IQuotesState): boolean =>
  quote.request_status === QuoteRequestNotOptedIn;

export const isQuoteQuestionSetIncomplete = (quote: IQuotesState): boolean =>
  quote.request_status === QuoteRequestIncompleteQuestionSet;

export const isQuoteIncomplete = (quote: IQuotesState): boolean =>
  isQuoteNotOptedIn(quote) || isQuoteQuestionSetIncomplete(quote);

export const isQuoteOffPlatform = (quote: IQuotesState): boolean =>
  !quote.online && quote.request_status === QuoteRequestRequestTypes.referral;

export const isQuotePreferred = (quote: IQuotesState): boolean =>
  !!quote?.preferred;

// If there's more than one product, it's a bundle
// If there's only one product, it's monoline
export const groupQuotesByBundle = (
  quotes: IQuotesState[],
): GroupedOnlineQuotes =>
  quotes
    .reduce((acc, quote) => {
      if (!isQuoteQuestionSetIncomplete(quote) || quote.products.length === 1) {
        return [...acc, quote];
      }
      // Split incomplete question sets with multiple products
      // into separate 'quotes' for each product, because we don't know
      // if there will be a bundled quote returned until the question set
      // is completed
      const separateQuotes = quote.products.map((product) => ({
        ...quote,
        products: [product],
      }));
      return [...acc, ...separateQuotes];
    }, [])
    .reduce((acc, quote) => {
      const key =
        quote.products && quote.products.length > 1
          ? QuoteProductBundleNames.BUNDLES
          : QuoteProductBundleNames.ADMITTED;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(quote);
      return acc;
    }, {});

export const sortQuotes = (quotes: typeof QuotesState): typeof QuotesState => {
  if (!quotes || quotes.length === 0) {
    return quotes;
  }

  return [...quotes]
    .map((quote) => ({
      ...quote,
      order: quote.order === null ? quotes.length : quote.order,
    }))
    .sort((quote1, quote2) => {
      if (quote1.order === quote2.order) {
        if (
          quote1.carrier.name.toLocaleLowerCase() <
          quote2.carrier.name.toLocaleLowerCase()
        ) {
          return -1;
        }
        if (
          quote1.carrier.name.toLocaleLowerCase() >
          quote2.carrier.name.toLocaleLowerCase()
        ) {
          return 1;
        }
        return 0;
      } else {
        return quote1.order - quote2.order;
      }
    });
};

/*
 * The purpose of the function is to get the missing carriers from the quotable carrier products
 * that in are future buckets and display them inline with the rest of the quotes
 */
export const getFutureBucketCarrierQuotes = (
  quotes: GroupedOnlineQuotes,
  realTimeEligibilityCarriers: IRealTimeEligibilityCarrier[],
  allOfflineQuoteData: IQuotesState[],
): Array<any> => {
  const admittedQuotes = quotes[QuoteProductBundleNames.ADMITTED] || [];
  // Get all the subdomains for the admitted quotes so far
  const quotedCarriers = admittedQuotes.map(
    (carrier_quote) => carrier_quote.carrier.code,
  );

  const offPlatformCarriers = allOfflineQuoteData.map(
    (offPlatformQuote) => offPlatformQuote.carrier.code,
  );

  return realTimeEligibilityCarriers
    .filter(
      (realTimeEligibilityCarrier) =>
        // filter out the quotes that we already have in quotedCarriers
        !quotedCarriers.includes(realTimeEligibilityCarrier.code) &&
        // filter out off-platform because they are displayed in the off-platform section
        !offPlatformCarriers.includes(realTimeEligibilityCarrier.code) &&
        // filter out carriers that have addition market labels
        !isAdditionalMarketCarrier(realTimeEligibilityCarrier.labels),
    )
    .map(
      (realTimeEligibilityCarrier) =>
        ({
          carrier: {
            name: realTimeEligibilityCarrier.name,
            code: realTimeEligibilityCarrier.code,
            logo_url: realTimeEligibilityCarrier.logo_url,
          },
        } as any),
    );
};

/**
 * Gets display value for the owner of an application form.
 * The owner can be an agent
 *
 * @param currentUser IUser - User viewing the application
 * @param owner IApplicationFormOwner - An agent or programmatic user who owns the application form
 * @returns string - Name of the owner
 */
export const getOwnerDisplayName = (
  currentUser: IUser | null,
  owner?: IApplicationFormOwner,
): string => {
  if (!owner || (!owner.first_name && !owner.last_name)) {
    return 'N/A';
  }

  if (owner.id === currentUser?.extra.raw_info.user_id) {
    return 'You';
    // Applications that are transferred from the exchange will be assigned to a "Programmatic User"
  } else if (isProgrammaticUserType(owner) || isGuestUserType(owner)) {
    return quotesDisplayUser;
  } else {
    return `${owner.first_name || ''} ${owner.last_name || ''}`.trim();
  }
};

/**
 * Returns whether a quote view model was transferred from the exchange or not
 *
 * @param quote QuoteCardViewModel
 * @returns boolean
 */
export const isTransferredFromExchange = (quote: QuoteCardViewModel): boolean =>
  isProgrammaticUserType(quote.owner) &&
  quote.tenant_transfer_reason === 'exchange_match';

/**
 * Returns whether a quote view model was transferred to the exchange or not
 *
 * @param quote QuoteCardViewModel
 * @returns boolean
 */
export const isTransferredToExchange = (quote: QuoteCardViewModel): boolean =>
  quote.transferred_to === 'boldpenguin';

/**
 * Maps an eligible carrier to the shape of a quote request
 *
 * @param eligibleCarrier ICarrierWithProducts
 * @returns IQuotesState
 */
export const getEligibleCarrierAsQuoteRequest = (
  eligibleCarrier: ICarrierWithProducts,
  requestStatus: QuoteRequestRequestTypes,
  order: number | null = 0,
): IQuotesState => {
  const quote = {
    active: true,
    annual_price: '',
    application_form_id: '',
    bindable: true,
    calls_to_action: [],
    carrier: {
      code: eligibleCarrier.code,
      id: eligibleCarrier.id,
      logo_url_small: eligibleCarrier.logo_url_small,
      logo_url: eligibleCarrier.logo_url,
      name: eligibleCarrier.name,
      online: eligibleCarrier.online,
      labels: eligibleCarrier.labels,
      opt_in_required: eligibleCarrier.opt_in_required,
      opted_in: eligibleCarrier.opted_in,
    },
    consumer_selected: true,
    coverage: {
      disclaimer: '',
      limit_and_deductible: [],
      limit_and_deductible_default: [],
    },
    created_at: new Date().toISOString(),
    customizable: false,
    downpayment: '',
    effective_date: null,
    expired: false,
    finished_at: new Date().toISOString(),
    focused: false,
    id: eligibleCarrier.id,
    monthly_price: '',
    number_of_installments: 0,
    online: eligibleCarrier.online || false,
    order: order as number,
    owner_id: '',
    permitted_to_bind: true,
    preferred: false,
    price_details: {
      fees: [],
      new_total_premium: '',
      taxes: [],
      disclaimer_html: null,
      premiums: [],
    },
    products: eligibleCarrier.products.map((product) => ({
      from_name: null,
      ...product,
    })),
    quote_number: '',
    recommended: false,
    request_status: requestStatus,
    retrieve_url: '',
    semiannual_price: '',
    selected: false,
    started_at: new Date().toISOString(),
    surcharge: 0,
    tenant_id: '',
    translated_messages: [],
    updated_at: new Date().toISOString(),
    actions: [],
    attachments: [],
    supplier_code: null,
    supplier_name: null,
    supplier_logo_url: null,
    supplier_logo_url_full: null,
  } as IQuotesState;

  return quote;
};

/**
 * Filters out non-admitted carriers when a quote
 * for that carrier/product(s) combination already exists
 *
 * @param quotes Array of IQuotesState
 * @param enrollments Array of enrollments as IQuotesState
 * @returns Returns array of non-admitted carriers that don't have quotes
 */
export const removeOverlappingCarriers = (
  quotes: IQuotesState[],
  enrollments: IQuotesState[],
): IQuotesState[] =>
  enrollments.filter(
    (enrollment) =>
      !quotes.some((quote) => quote.carrier.code === enrollment.carrier.code),
  );

/**
 * Determines if a carrier requires user opt-in
 *
 * @param carrier ICarrier | ICarrierProducts
 * @returns Returns true if opt_in_required is true, else false
 */
export const requiresOptIn = (
  carrier?: ICarrier | ICarrierWithProducts,
): boolean => (carrier?.opt_in_required && !carrier?.opted_in) ?? false;

/**
 * Determines if the pending eligible carrier should be displayed
 *
 * @param carrier Eligible carrier for the application form
 * @param questionSets Question sets for the application form
 *
 * @returns Returns true if the carrier requires opt-in and its question set
 * does not exist on the application form
 */
export const showPreOptInEligibleCarrier = (
  carrier: ICarrier,
  questionSets: IApplicationFormQuestionSet[],
) => {
  const hasCarrierQuestionSet = questionSets.some(
    (qs) => qs.question_set.tenant_id === carrier?.id,
  );
  return requiresOptIn(carrier) && !hasCarrierQuestionSet;
};

/**
 * Determines if the application form came from Storefront
 *
 * @param quote QuoteCardViewModel
 * @returns boolean
 */
export const isStorefront = (quote: QuoteCardViewModel): boolean =>
  quote.applicationSource?.source === 'storefront';

/**
 * Constructs body copy and optional disclaimer text
 * to display in quote card
 *
 * @param quote IQuotesState
 * @returns Returns IQuoteIncompleteInformationContent
 */
export const getQuoteAlertContent = (
  quote: IQuotesState,
): IQuoteIncompleteInformationContent => {
  if (isQuoteNotOptedIn(quote)) {
    return {
      body: `${quote.carrier.name} is a wholesale brokerage licensed in all 50 states that provides quotes from E&S carriers. Get an instant non-admitted market quote by answering a few additional questions.`,
      disclaimer:
        'By clicking "Complete Questions" user acknowledges they are subject to any and all state rules and regulations governing access to the non-admitted  market.',
      italicizeDisclaimer: true,
    };
  } else if (isQuoteQuestionSetIncomplete(quote)) {
    return {
      body: "We're unable to provide you with a quote since the carrier questions are incomplete.",
      disclaimer:
        'By clicking below, you can return to the application to complete the questions and submit a request for a quote.',
    };
  }
  return { body: '' };
};
