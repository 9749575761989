<emperor-dialog-header
  title="Refer a friend"
  [color]="EmperorCardHeaderColor.NONE"
></emperor-dialog-header>

<emperor-card-content class="terms-and-conditions-form">
  <p>Fill out the information below, and we’ll take it from there.</p>
  <div class="form">
    <form [formGroup]="referralForm">
      <!-- Agency Name -->
      <mat-form-field appearance="outline">
        <mat-label>Agency name</mat-label>
        <input matInput formControlName="agencyName" />
        <mat-error>
          {{
            referralForm.get('agencyName')
              | fcErr : { name: 'Agency name', maxlength: 255 }
          }}
        </mat-error>
      </mat-form-field>

      <!-- Contact Name -->
      <mat-form-field appearance="outline">
        <mat-label>Contact name</mat-label>
        <input matInput formControlName="contactName" />
        <mat-error>
          {{
            referralForm.get('contactName')
              | fcErr : { name: 'Contact name', maxlength: 255 }
          }}
        </mat-error>
      </mat-form-field>

      <!-- Phone -->
      <mat-form-field appearance="outline">
        <mat-label>Contact phone</mat-label>
        <input matInput appPhoneNumber maxlength="14" formControlName="phone" />
        <mat-error>
          {{
            referralForm.get('phone')
              | fcErr : { name: 'Phone number', minlength: 10, maxlength: 10 }
          }}
        </mat-error>
      </mat-form-field>

      <!-- Email -->
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" />
        <mat-error>
          {{ referralForm.get('email') | fcErr : { name: 'Email' } }}
        </mat-error>
      </mat-form-field>
    </form>
  </div>
</emperor-card-content>

<emperor-card-footer [color]="EmperorCardHeaderColor.NONE">
  <emperor-button
    emperorDialogClose
    [uiButtonType]="EmperorUIButtonTypes.Text"
    class="text-uppercase"
  >
    Cancel
  </emperor-button>
  <emperor-button
    (click)="onSubmit()"
    [disabled]="referralForm.invalid"
    [uiButtonType]="EmperorUIButtonTypes.Standard"
    class="text-uppercase"
  >
    Refer
  </emperor-button>
</emperor-card-footer>
