import { Features } from '../features/features';
import { ConfirmationComponent } from '../shared/components/confirmation/confirmation.component';
import { Routes } from '@angular/router';

export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'quotes',
    pathMatch: 'full',
  },
  {
    path: 'login',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: Features.QUOTES,
    loadChildren: () =>
      import('../features/quotes/quotes.module').then((m) => m.QuotesModule),
  },
  {
    path: Features.AUTH,
    loadChildren: () =>
      import('../features/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: Features.SETTINGS,
    loadChildren: () =>
      import('../features/settings/settings.module').then(
        (m) => m.SettingsModule,
      ),
  },
  {
    path: Features.BILLING,
    loadChildren: () =>
      import('../features/billing/billing.module').then((m) => m.BillingModule),
  },
  {
    path: Features.COMMISSIONS,
    loadChildren: () =>
      import('../features/commissions/commissions.module').then(
        (m) => m.CommissionsModule,
      ),
  },
  {
    path: Features.SIGNUP,
    loadChildren: () =>
      import('../features/signup/signup.module').then((m) => m.SignupModule),
  },
  {
    path: Features.EXCHANGE,
    loadChildren: () =>
      import('../features/exchange/exchange.module').then(
        (m) => m.ExchangeModule,
      ),
  },
  {
    path: Features.REPORTS,
    loadChildren: () =>
      import('../features/reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: Features.WELCOME,
    loadChildren: () =>
      import('../features/welcome/welcome.module').then((m) => m.WelcomeModule),
  },
  {
    path: Features.APPETITE_SEARCH,
    loadChildren: () =>
      import('../features/appetite-search/appetite-search.module').then(
        (m) => m.AppetiteSearchModule,
      ),
  },
  {
    path: Features.CONFIRM,
    component: ConfirmationComponent,
    title: 'Confirmation',
  },
  {
    path: 'reports',
    redirectTo: `${Features.REPORTS}`,
  },
  {
    path: 'application_forms/:id',
    redirectTo: 'quotes/form/:id',
  },
  {
    path: 'application_forms/:id/latest',
    redirectTo: 'quotes/form/:id',
  },
  {
    path: '**',
    redirectTo: 'auth',
  },
];
