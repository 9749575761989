<mat-toolbar
  class="toolbar"
  [class.impersonating]="loggedInUserIsImpersonating"
  [ngStyle]="{
    'box-shadow': isAlertBannerPresent ? 'none' : '0 2px 6px rgb(0 0 0 / 30%)'
  }"
>
  <button
    *ngIf="!suppressNavMenu; else logo"
    class="sidenav-button"
    mat-button
    data-testid="main-nav-button"
    [attr.data-intercom-target]="intercomLabels.NAV_MENU"
    (click)="sideNavClicked()"
  >
    <div class="mat-button-layout">
      <img [src]="logoPath$ | async" class="header-logo" />
      <span class="sidenav-label button-text-1">menu</span>
      <emperor-icon
        class="sidenav-icon"
        [name]="isSideNavOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
      ></emperor-icon>
    </div>
  </button>
  <ng-template #logo>
    <img [src]="logoPath$ | async" class="header-logo loading" />
  </ng-template>

  <div class="spacer">
    <div data-testid="impersonating" *ngIf="loggedInUserIsImpersonating">
      Impersonating {{ nameOfUserBeingImpersonated$ | async }}
    </div>
  </div>
  <div class="spacer">
    <div *ngIf="loggedInUserIsImpersonating">
      <emperor-link-button [href]="JiraCreateBugPage" target="_blank">
        <span class="create-bug-text">Create Bug</span>
      </emperor-link-button>
    </div>
  </div>
  <div class="right-menu-content">
    <app-profile-menu class="profile-menu-container"></app-profile-menu>
    <div *ngIf="intercomEnabled$ | async" class="intercom-container">
      <button
        id="intercom-launcher"
        mat-button
        title="Need help?"
        aria-label="Chat for help"
        (click)="intercomActive ? onHideIntercom() : onShowIntercom()"
        [ngClass]="{ active: intercomActive }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          aria-describedby="chat-icon-desc"
          width="25"
          height="24"
          fill="#fff"
          fill-rule="nonzero"
          xmlns:v="https://vecta.io/nano"
        >
          <desc id="chat-icon-desc">speech bubble icon</desc>
          <path
            d="M15.998 16v2H8.66l-2.67 2v-2h-3V9h6V7h-6a2 2 0 0 0-2 2v9c0 1.1.897 2 2 2h1v4l5.333-4h6.66c1.1 0 2-.9 2-2v-2h-2 .015zm1.992-2a7.01 7.01 0 0 1-7-7 7.01 7.01 0 0 1 7-7 7.01 7.01 0 0 1 7 7 7.01 7.01 0 0 1-7 7zm0-12c-2.76 0-5 2.24-5 5a5.01 5.01 0 0 0 5 5c2.75 0 5-2.25 5-5a5.01 5.01 0 0 0-5-5zm-1 5h2v4h-2zm1-3a1 1 0 1 0 0 2 1 1 0 1 0 0-2z"
          />
        </svg>
      </button>
    </div>
  </div>
</mat-toolbar>
