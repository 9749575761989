import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  EmperorDialogRefService,
  EmperorDialogSize,
  EmperorUIButtonTypes,
} from '@boldpenguin/emperor-presentational';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Messages } from 'src/app/core/services/notification-service/notification-messages';
import { NotificationService } from 'src/app/core/services/notification-service/notification.service';
import { ProspectDetails } from 'src/app/features/quotes/models/quote';
import { ExchangeTransferService } from 'src/app/features/quotes/services/exchange-transfer.service';

@UntilDestroy()
@Component({
  selector: 'app-send-to-exchange-dialog',
  templateUrl: './send-to-exchange-dialog.component.html',
  styleUrls: ['./send-to-exchange-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendToExchangeDialogComponent implements OnInit {
  @Input() prospect: ProspectDetails | undefined;
  @Input() tenantName: string;
  @Output() closed = new EventEmitter();

  transferring = false;
  transferSuccess: boolean;
  referWarmTransfer: boolean;
  disclaimerAccepted: boolean;
  errorMessage: string;

  readonly EmperorDialogSize = EmperorDialogSize;
  readonly EmperorUIButtonTypes = EmperorUIButtonTypes;

  readonly errorMap = [
    {
      errorMessage: 'Validation failed: cannot clone application form twice',
      displayMessage:
        'This application has already been referred to the exchange.',
    },
    {
      errorMessage:
        'Failed to clone application form: {:phone_number=>["is not unique since initial creation"]}',
      displayMessage:
        'The phone number used on this application has already been used on another application in the exchange.',
    },
  ];

  readonly cicPhoneNumberMap = {
    geico: '4439513321',
  };

  constructor(
    private cdRef: ChangeDetectorRef,
    private emperorDialogRefService: EmperorDialogRefService,
    private exchangeTransferService: ExchangeTransferService,
    private notificationService: NotificationService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    // Warm transfer referral message is for Geico only
    const geicoTenantName = new RegExp('geico', 'i');
    this.referWarmTransfer = geicoTenantName.test(this.tenantName);
  }

  /**
   * Transfers an application to the BP Exchange
   */
  sendToExchange(): void {
    this.transferring = true;
    this.exchangeTransferService
      .transferToExchange()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.transferSuccess = true;
          this.transferring = false;
          this.cdRef.detectChanges();
        },
        error: (err) => {
          const message = this.errorMap.find(
            (error) => error.errorMessage === err.error.error,
          );
          if (message) {
            this.errorMessage = message.displayMessage;
          }
          this.transferSuccess = false;
          this.transferring = false;
          this.cdRef.detectChanges();
        },
      });
  }

  onClose(): void {
    this.closed.emit();
    if (this.transferSuccess) {
      this.goToQuotes();
    }
  }

  /**
   * Navigates to the quotes landing page (Done view)
   */
  goToQuotes(): void {
    // Don't show the message if doing a warm transfer
    if (!this.referWarmTransfer) {
      const message = `${this.prospect?.firstName} ${this.prospect?.lastName} can expect a phone call from Bold Penguin within the next 30 minutes`;
      this.notificationService.success(message as Messages, { duration: 5000 });
    }
    this.router.navigate(['/quotes'], { queryParams: { navButton: 'Done' } });
  }
}
