import { createSelector } from '@ngrx/store';

import { HeaderAlert } from '../../services/header-service/header-alerts.service';
import { selectCoreState } from '../core-feature-selector';
import HeaderAlertsState from './header-alerts.state';

const selectHeaderAlertManagement = createSelector(
  selectCoreState,
  (state) => state.headerAlerts,
);

export const selectAllHeaderAlerts = createSelector(
  selectHeaderAlertManagement,
  (headerAlertsState: HeaderAlertsState) =>
    Object.values(headerAlertsState || {}) as HeaderAlert[],
);

export const selectNumberOfHeaderAlerts = createSelector(
  selectHeaderAlertManagement,
  (headerAlertsState: HeaderAlertsState) =>
    Object.values(headerAlertsState || {}).length,
);

export const selectBeforeUnloadHeaderAlerts = createSelector(
  selectHeaderAlertManagement,
  (headerAlertsState: HeaderAlertsState) =>
    (Object.values(headerAlertsState || {}) as HeaderAlert[]).filter(
      (alert) => !!alert.beforeUnload,
    ),
);
