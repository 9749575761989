<emperor-dialog class="send-to-exchange-dialog" (close)="onClose()">
  <emperor-card-content class="dialog-body">
    <ng-container
      *ngIf="transferSuccess === undefined || transferSuccess === false"
    >
      <h3 *ngIf="transferSuccess === undefined">Refer to Bold Penguin</h3>
      <h3 *ngIf="transferSuccess === false" class="error">
        <emperor-icon name="remove_circle_2"></emperor-icon>
        <span>There was an error with your referral</span>
      </h3>
      <p *ngIf="!errorMessage; else displayError">
        By clicking continue, you are electing to pass this customer to the Bold
        Penguin Exchange. Bold Penguin will look for an agent or broker who can
        facilitate their insurance needs.
      </p>
      <ng-template #displayError>
        <p class="error-message">{{ errorMessage }}</p>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="transferSuccess === true">
      <h3 class="success">
        <emperor-icon name="check_circle_2_2"></emperor-icon>
        <span>Successful Referral to Exchange</span>
      </h3>
    </ng-container>
    <div class="prospect-info">
      <div class="prospect-name">
        {{ prospect?.firstName }}
        {{ prospect?.lastName }}
      </div>
      <div class="agency-name">{{ prospect?.businessName }}</div>
      <p>{{ prospect?.email }}</p>
      <p>{{ prospect?.phone ?? '' | phoneNumber }}</p>
    </div>

    <section class="disclaimer">
      <header class="disclaimer-header">Attestation:</header>
      <div class="disclaimer-text">
        <p id="disclaimer">
          You authorize me to share your information with Bold Penguin's
          Commercial Insurance Center, who will contact you at the number you
          provided me, including using automated technology or text messages, to
          assist you with your commercial insurance needs.
        </p>
        <p>
          You understand your consent is not required to obtain any good or
          service and to be connected with sellers that can fit your needs
          without provided consent.
        </p>
        <p>May I check this box on your behalf?</p>
      </div>
      <mat-checkbox
        [(ngModel)]="disclaimerAccepted"
        color="primary"
        aria-describedby="disclaimer"
      >
        I attest I have read the above to the consumer.
      </mat-checkbox>
    </section>

    <ng-container *ngIf="!transferSuccess && !errorMessage">
      <div class="warning">
        <emperor-icon name="interface_alert_circle_1"></emperor-icon>
        <div>
          <p>This action cannot be undone.</p>
          <p>
            Would you like to transfer {{ prospect?.firstName }}
            {{ prospect?.lastName }} to the Bold Penguin Exchange?
          </p>
        </div>
      </div>

      <p id="attestation">
        By clicking continue, you attest your customer verbally authorized you
        to consent on the disclosure on their behalf.
      </p>
      <div class="buttons">
        <emperor-button
          emperorDialogClose
          class="emperor-ui-manually-set-size"
          [uiButtonType]="EmperorUIButtonTypes.Stroked"
          [disabled]="transferring"
        >
          CANCEL
        </emperor-button>
        <emperor-button
          class="emperor-ui-manually-set-size"
          [disabled]="transferring || !disclaimerAccepted"
          aria-describedby="attestation"
          (click)="sendToExchange()"
        >
          <span *ngIf="transferring; else finished">
            <emperor-spinner [diameter]="30"></emperor-spinner>
          </span>
          <ng-template #finished>
            <span *ngIf="transferSuccess === undefined">CONTINUE</span>
            <span *ngIf="transferSuccess === false">TRY AGAIN</span>
          </ng-template>
        </emperor-button>
      </div>
    </ng-container>
    <ng-container *ngIf="transferSuccess === true">
      <p *ngIf="!referWarmTransfer; else warmTransferScript">
        {{ prospect?.firstName }}
        {{ prospect?.lastName }} can expect a phone call from Bold Penguin
        within the next 30 minutes.
      </p>

      <ng-template #warmTransferScript>
        <p>
          The customer's information has been shared with Commercial Insurance
          Center (CIC). Please initiate a warm transfer for the customer to the
          CIC Customer Service Advocate by dialing the number below. You must
          provide the customer's number
          <b>{{ prospect?.phone ?? '' | phoneNumber }}</b> when prompted.
        </p>
        <div class="prospect-info">
          <p>Commercial Insurance Center (CIC) Number:</p>
          <p>{{ cicPhoneNumberMap.geico | phoneNumber }}</p>
        </div>
      </ng-template>

      <div class="buttons">
        <emperor-button
          emperorDialogClose
          class="emperor-ui-manually-set-size"
          [uiButtonType]="EmperorUIButtonTypes.Standard"
          (click)="goToQuotes()"
        >
          DONE
        </emperor-button>
      </div>
    </ng-container>
    <ng-container *ngIf="errorMessage">
      <div class="buttons">
        <emperor-button
          emperorDialogClose
          class="emperor-ui-manually-set-size"
          [uiButtonType]="EmperorUIButtonTypes.Standard"
        >
          DONE
        </emperor-button>
      </div>
    </ng-container>
  </emperor-card-content>
</emperor-dialog>
