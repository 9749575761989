export enum Messages {
  NO_INVITE = `Please follow the invite link you received from your administrator. If you haven't received one, please reach out.`,
  DEFAULT = 'Contact your administrator for help.',
  RESET_TOKEN_EXPIRED = 'Your link is expired, please check your email for the latest version.',
  RESET_SUCCESS = 'Your password has successfully been reset. Please hold tight while we log you in.',
  EXPLORE_EXCHANGE_SUBMIT_SUCCESS = 'Thank you! We will reach out to you soon!',
  EXPLORE_EXCHANGE_SUBMIT_FAILED = `We're sorry, there was an issue submitting your request, please try again later.`,
  ERROR = `We're sorry but there was a server error, please try again.`,
  STATUS_UPDATE_SUCCESS = 'Carrier status successfully updated.',
  STATUS_UPDATE_ERROR = 'There was a problem while trying to update status. Please try again.',
  SIGNUP_ERROR = 'We are not able to create your account at this time.  One of our Customer Success Managers will reach out to you shortly or you can contact support@boldpenguin.com',
  PRODUCT_RANKINGS_UPDATE_SUCCESS = 'Product rankings have successfully been updated',
  PRODUCT_RANKINGS_UPDATE_ERROR = 'There was an error updating product rankings, please reload the page and try again.',
  PROBLEM_LOADING_USER_DATA = 'There was a problem loading agent data. Please refresh the page to try again.',
  PROBLEM_UPDATING_USER_DATA = 'There was a problem saving User edits. Please try again.',
  USER_UPDATED = 'User edits saved successfully.',
  PROBLEM_ADDING_USER = 'There was a problem adding the User. Please try again.',
  USER_ADDED = 'New User saved successfully.',
  INVITE_RESENT = 'User invite has been resent.',
  PROBLEM_SENDING_INVITE = 'There was a problem sending the invite. Please try again.',
  USER_DEACTIVATED = 'User has been deactivated.',
  PROBLEM_DEACTIVATING_USER = 'There was a problem deactivating the User. Please try again.',
  COVERAGE_LEVEL_LIMITS_UPDATE_SUCCESS = 'Coverage level limit has successfully been updated',
  COVERAGE_LEVEL_LIMITS_UPDATE_ERROR = 'There was an error updating the coverage level limit, please reload the page and try again.',
  ARCHIVE_QUOTE_SUCCESS = `Your quote was successfully archived!`,
  ARCHIVE_QUOTE_ERROR = `There was an error archiving your quote, please refresh the page and try again.`,
  UNARCHIVE_QUOTE_SUCCESS = `Your quote was successfully returned to the inbox!`,
  UNARCHIVE_QUOTE_ERROR = `There was an error unarchiving your quote, please refresh the page and try again.`,
  USER_MSG = `You don't have permission for the page you were trying to access. You cannot access this page. Your agency principal can update your permissions.`,
  CREATE_PAYMENT_METHOD_SUCCESS = 'Payment method successfully added',
  CREATE_FIRST_PAYMENT_METHOD_SUCCESS = 'Payment method successfully added. Please refresh your browser',
  UPDATE_PAYMENT_METHOD_SUCCESS = 'Payment method successfully updated',
  UPDATE_DEFAULT_PAYMENT_METHOD_SUCCESS = 'Payment method successfully updated as default',
  REMOVE_PAYMENT_METHOD_SUCCESS = 'Payment method successfully removed',
  REMOVE_PAYMENT_METHOD_ERROR = 'There was a problem removing this payment method',
  COPY_TO_CLIPBOARD = `Successfully copied to clipboard.`,
  ONBOARDING_PAYMENT_METHOD_FAILURE = 'There was a problem saving your payment method',
  COMPLIANCE_FORM_SUCCESSFULLY_SUBMITTED = `Your compliance form was successfully submitted.`,
  COMPLIANCE_FORM_COMPLETED = `Your compliance form has already been submitted.`,
  COMPLIANCE_FORM_FAILURE = `Your compliance form was not successfully submitted at this time. Please try again later.`,
  ENROLLMENT_APPOINTMENT_SUCCESS = `Your enrollment appointment was successfully submitted.`,
  ENROLLMENT_APPOINTMENT_FAILURE = `Your enrollment appointment was not successfully submitted. Please try again later`,
  REGISTER_SELF_SERVICE_ENROLLMENT_FAIL = 'Failed to register for self service enrollment.',
  SELF_SERVICE_ENROLLMENT_CALLBACK_FAIL = 'Failed to update the carrier appointment information.',
  ALREADY_SELF_SERVICE_REGISTERED = `It looks like you're already registered with this carrier. Please contact support@boldpenguin.com to line up your accounts.`,
  REFERRAL_SENT = 'Referral sent',
  REFERRAL_ERROR = 'There was an error sending your referral, please try again',
  UPDATE_SUCCESSFUL = 'Update successfully saved',
  UPDATE_FAILED = 'Update failed',
  GENERIC_ERROR_CONTACT_SUPPORT = 'There was a problem completing your request. Please try again or contact support@boldpenguin.com.',
  NEW_VERSION_AVAILABLE = 'New version available',
  APP_TAKEOVER_ERROR = 'Reassigning the quote failed. Please try again or contact support',
  BIND_OFFLINE_QUOTE_FAILED = `We were unable to connect to this carrier. Please try again. If you're still experiencing issues, reach out to support@boldpenguin.com or go directly to the carrier to finish quoting.`,
  RESUBMIT_QUOTE_FAILED = 'Resubmitting the quote failed. Please try again or contact support',
  APPLICATIONS_REPORT_FAILED = 'Applications failed to load, please retry. If this problem continues please contact support@boldpenguin.com',
  CARRIERS_REPORT_FAILED = 'Carriers failed to load, please retry. If this problem continues please contact support@boldpenguin.com',
  PRODUCERS_REPORT_FAILED = 'Producers failed to load, please retry. If this problem continues please contact support@boldpenguin.com',
  ACORD_REMOVE_FILE_FAILED = 'There was an error removing the file. Please refresh and try again. If the issue continues, please contact support@boldpenguin.com.',
  ACORD_QUOTE_START_FAILED = 'Quote start failed. Please contact support@boldpenguin.com.',
  ACORD_UNEXPECTED_ERROR = 'There was an unexpected error. Please refresh and try again. If the issue persists, contact support@boldpenguin.com.',
  ACORD_DOWNLOAD_START = 'Generating ACORD forms...',
  DISPOSITION_BOUND_SUCCESS = 'Congratulations! Policy has been successfully bound.',
  DISPOSITION_NOT_BOUND_SUCCESS = 'Application has been marked as complete.',
  APPLICATION_FORM_LOAD_ERROR = 'There was a problem completing your request. Please try again or contact support.',
  APPLICATIONS_GOAL_SAVE_SUCCESS = 'Applications goal successfully updated.',
  APPLICATIONS_GOAL_SAVE_ERROR = 'There was a problem completing your request. Please try again or contact support.',
  CQS_SETTINGS_SUCCESS = 'Your information has been successfully saved',
  WELCOME_COMPLIANCE_FORM_GET_ERROR = 'There was a problem fetching the data. Please try again or contact support.',
  WELCOME_COMPLIANCE_FORM_SAVE_ERROR = 'There was a problem completing your request. Please try again or contact support.',
  WELCOME_UPDATE_SUB_APPOINTMENT_SELECTIONS_ERROR = 'There was a problem completing your request. Please try again or contact support.',
  ACH_MULTIPLE_ACCOUNTS_ERROR = 'It looks like multiple bank accounts were added, please add only one bank account.',
  APP_FORM_MISSING_ADDRESS_COMPONENTS = 'There was a problem completing your request. Please enter your missing address information.',
  COVERAGE_SUGGESTION_ADD_SUCCESS = 'The requested coverage was successfully added.',
  COVERAGE_SUGGESTION_ADD_ERROR = 'There was a problem while trying to add the requested coverage. Please try again.',
  ADDITIONAL_COVERAGE_LOADING = 'Please wait...',
  CARRIER_RANKING_SUCCESS = 'Carrier rankings successfully updated.',
  CARRIER_RANKING_BUCKET_SUCCESS = 'Tier successfully updated.',
  CARRIER_RANKING_BUCKET_CREATED_SUCCESS = 'Tier successfully created.',
  RECOMMENDED_CARRIERS_MANUAL_UNLOCK = 'There are no additional carriers to continue with at this time.',
  RECOMMENDED_CARRIERS_MANUAL_UNLOCK_ERROR = 'There was a problem completing your request. Please try again or contact support.',
  RECOMMENDED_CARRIERS_MANUAL_UNLOCK_LOADING = 'Please wait for additional questions to display.',
  RECOMMENDED_CARRIERS_MANUAL_UNLOCK_SUCCESS = 'Please continue with the application.',
}
