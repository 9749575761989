<emperor-dialog-header title="Learn about our Exchange"></emperor-dialog-header>
<emperor-card-content class="card-content">
  <p>
    Bold Penguin operates the largest commercial insurance exchange connecting
    businesses, agents, and carriers to the right quote in record time.
  </p>
  <p>
    If this is a small business you are unable to quote via your panel, you can
    sell it to the Exchange. Bold Penguin will match the prospect to another
    agency and pay you a percentage of the matching fee.
  </p>
  <p>
    If you would like to know more about integrating the Exchange with your
    Terminal, contact a Customer Success Manager at
    <emperor-link-button
      [uiButtonType]="EmperorUIButtonTypes.Link"
      [href]="supportEmail"
      >support@boldpenguin.com</emperor-link-button
    >.
  </p>
</emperor-card-content>
