import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { RouterModule } from '@angular/router';
import { FormFieldsModule } from '@boldpenguin/emperor-form-fields';
import { IconsModule } from '@boldpenguin/emperor-icons';
import {
  EmperorAlertModule,
  EmperorButtonModule,
  EmperorCardModule,
  EmperorDialogModule,
  EmperorProgressBarModule,
  EmperorSpinnerModule,
  EmperorTooltipModule,
} from '@boldpenguin/emperor-presentational';
import { SDKWrapperModule } from '@boldpenguin/emperor-sdk-wrapper';
import { IMaskModule } from 'angular-imask';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MarkdownModule } from 'ngx-markdown';
import { HeaderAlertsService } from '../core/services/header-service/header-alerts.service';
import { COMPONENTS } from './components/components';
import { PdfDialogComponent } from './components/pdf-dialog/pdf-dialog.component';
import { DIRECTIVES } from './directive/directives';
import { MaterialModule } from './modules/material.module';
import { PIPES } from './pipes/pipes';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    IMaskModule,
    MarkdownModule,
    ReactiveFormsModule,
    FormFieldsModule,
    IconsModule,
    EmperorAlertModule,
    EmperorButtonModule,
    EmperorCardModule,
    EmperorDialogModule,
    EmperorSpinnerModule,
    EmperorTooltipModule,
    SDKWrapperModule,
    PdfViewerModule,
    EmperorProgressBarModule,
  ],
  declarations: [...PIPES, ...COMPONENTS, ...DIRECTIVES, PdfDialogComponent],
  exports: [...PIPES, ...COMPONENTS, ...DIRECTIVES],
  providers: [HeaderAlertsService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
