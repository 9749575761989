import { BillingSubFeatures } from 'src/app/features/billing/models/sub-features';
import { Features } from 'src/app/features/features';
import { ReportsSubFeatures } from 'src/app/features/reports/models/sub-features';
import { SettingsSubFeatures } from 'src/app/features/settings/models/sub-features';
import {
  AppetiteSearchPermissions,
  BillingPermissions,
  CQSPermissions,
  CarrierEnrollmentsPermissions,
  CarrierSettingPermissions,
  CommissionsPermissions,
  CommissionsReportPermission,
  ExchangePermissions,
  ExchangeReportPermission,
  LegacyBillingPermissions,
  LegacyPaymentPlansAndMethodsPermissions,
  QuoteCreatePermissions,
  QuotesReportPermission,
  RecommendedCarriersIndexBucketPermissions,
  ReportsPermissions,
  UserPermissions,
} from 'src/app/permissions/permissions';
import {
  PermissionModelForRouteAndMenu,
  ProductFeatureType,
} from 'src/app/permissions/permissions.model';
import * as QuotesActions from '../../features/quotes/store/quotes.actions';
import { ActionCreator } from '@ngrx/store/src/models';
import {
  MixpanelEventProperties,
  MixpanelEventType,
} from 'src/app/core/services/mixpanel-service/mixpanel.service';
import { IUploadDialogSource } from 'src/app/features/quotes/models/upload-document-model';

// Note: For permissions, a model refers to a resource string, and an
//       action refers to an operation the user could perform on
//       the model (index, create, etc)
interface IMenuItem extends Partial<PermissionModelForRouteAndMenu> {
  url: string[] | null;
  text: string;
  subscriptionRequired?: boolean;
  icon?: string;
  disabled?: boolean;
  subItems?: IMenuItem[];
  isNavButton?: boolean;
  action?: ActionCreator<string, any>;
  mixpanelCallback?: {
    event: MixpanelEventType;
    props?: MixpanelEventProperties;
  };
}

const allMainMenuItems: IMenuItem[] = [
  {
    text: 'Home',
    url: [Features.QUOTES],
    icon: 'file_shield',
  },

  {
    text: 'Create New Application',
    url: [Features.QUOTES, 'start'],
    icon: 'file_add_2',
    subscriptionRequired: true,
    isNavButton: true,
    ...QuoteCreatePermissions,
    productFeatureDisabled: [ProductFeatureType.UseDocumentUpload],
  },
  {
    text: 'New Application',
    url: null,
    icon: 'file_add_2',
    subscriptionRequired: true,
    ...QuoteCreatePermissions,
    productFeatureEnabled: [ProductFeatureType.UseDocumentUpload],
    subItems: [
      {
        text: 'Upload Document',
        url: null,
        action: QuotesActions.DisplayApplicationUploadDialog,
        mixpanelCallback: {
          event: MixpanelEventType.DOCUMENT_UPLOAD_START,
          props: { source: IUploadDialogSource.MAIN_MENU },
        },
      },
      {
        text: 'Create New Application',
        isNavButton: true,
        url: [Features.QUOTES, 'start'],
      },
    ],
  },
  {
    text: 'Client Quote-Start',
    url: [Features.SETTINGS, SettingsSubFeatures.CLIENT_QUOTE_START],
    icon: 'user_upload',
    ...CQSPermissions,
  },
  {
    text: 'Appetite Search',
    url: [Features.APPETITE_SEARCH],
    icon: 'file_view_2',
    ...AppetiteSearchPermissions,
  },
  {
    text: 'Exchange',
    url: [Features.EXCHANGE],
    icon: 'store',
    ...ExchangePermissions,
  },
  {
    text: 'Insights',
    url: [Features.REPORTS],
    icon: 'file_statistic_new_1',
    ...ReportsPermissions,
    subItems: [
      {
        text: 'Quotes',
        url: [Features.REPORTS, ReportsSubFeatures.QUOTES],
        ...QuotesReportPermission,
      },
      {
        text: 'Commissions',
        url: [Features.REPORTS, ReportsSubFeatures.COMMISSIONS],
        ...CommissionsReportPermission,
      },
      {
        text: 'Exchange',
        url: [Features.REPORTS, ReportsSubFeatures.EXCHANGE],
        ...ExchangeReportPermission,
      },
    ],
  },
];

const allSettingsMenuItems: IMenuItem[] = [
  {
    text: 'User Management',
    url: [Features.SETTINGS, SettingsSubFeatures.USERS],
    icon: 'user_group',
    ...UserPermissions,
  },
  {
    text: 'Appointments',
    url: [Features.SETTINGS, SettingsSubFeatures.CARRIER_APPOINTMENTS],
    icon: 'calendar_timeout',
    ...CarrierEnrollmentsPermissions,
  },
  {
    text: 'Carrier Settings',
    url: [Features.SETTINGS, SettingsSubFeatures.CARRIER_SETTINGS],
    icon: 'shield_settings',
    ...CarrierSettingPermissions,
  },
  {
    text: 'Carrier Settings',
    url: [Features.SETTINGS, SettingsSubFeatures.RECOMMENDED_CARRIERS],
    icon: 'shield_settings',
    ...RecommendedCarriersIndexBucketPermissions,
  },
  {
    text: 'Billing',
    url: [Features.BILLING],
    icon: 'receipt_3',
    ...LegacyBillingPermissions,
    subItems: [
      {
        text: 'Invoices',
        url: [Features.BILLING, BillingSubFeatures.INVOICES],
        ...LegacyBillingPermissions,
      },
      {
        text: 'Payments & Plans',
        url: [Features.BILLING, BillingSubFeatures.PAYMENTS_AND_PLANS],
        ...LegacyPaymentPlansAndMethodsPermissions,
      },
      {
        text: 'Commissions Table',
        url: [Features.BILLING, BillingSubFeatures.COMMISSIONS],
        ...LegacyBillingPermissions,
      },
    ],
  },
  {
    text: 'Billing',
    url: [Features.BILLING],
    icon: 'receipt_3',
    ...BillingPermissions,
  },
  {
    text: 'Commissions',
    url: [Features.COMMISSIONS],
    icon: 'commissions',
    ...CommissionsPermissions,
  },
];

export { IMenuItem, allMainMenuItems, allSettingsMenuItems };
