import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { EmperorDialogRefService } from '@boldpenguin/emperor-presentational';
import { createAction, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BpSdkCoreService } from 'src/app/core/services/bp-sdk-core-service/bp-sdk-core.service';
import {
  selectTenantName,
  selectUserEmail,
  selectUserFirstName,
  selectUserLastName,
} from 'src/app/core/services/bp-sdk-store-service/bp-sdk-store.selectors';
import { BpSdkStoreService } from 'src/app/core/services/bp-sdk-store-service/bp-sdk-store.service';
import { IntercomInternalLabels } from 'src/app/core/services/intercom-service/intercom.service';
import {
  MixpanelEventType,
  MixpanelService,
} from 'src/app/core/services/mixpanel-service/mixpanel.service';
import {
  IRouteData,
  PermissionsService,
} from 'src/app/core/services/permissions/permissions.service';
import { ReferralPermissions } from 'src/app/permissions/permissions';
import { ReferralDialogComponent } from 'src/app/shared/components/referral-dialog/referral-dialog.component';
import { BaseMenuComponent } from '../base-menu/base-menu';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent
  extends BaseMenuComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('menuTrigger') trigger: MatMenuTrigger;

  intercomLabels = IntercomInternalLabels; // For setting the data-intercom-target attribute

  agentFirstName$: Observable<string>;
  agentLastName$: Observable<string>;
  agentEmail$: Observable<string>;
  tenantName$: Observable<string>;
  canSeeReferralButton$: Observable<boolean>;

  constructor(
    private bpSdkCoreService: BpSdkCoreService,
    private bpSdkStoreService: BpSdkStoreService,
    private mixpanelService: MixpanelService,
    private permissionService: PermissionsService,
    private dialogService: EmperorDialogRefService,
  ) {
    super();
  }

  get menuArrowIcon() {
    return this._menuExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
  }

  ngOnInit() {
    this.agentFirstName$ = this.bpSdkStoreService.state$.pipe(
      select(selectUserFirstName),
      map((nameOrNull) => nameOrNull ?? ''),
    );

    this.agentLastName$ = this.bpSdkStoreService.state$.pipe(
      select(selectUserLastName),
      map((nameOrNull) => nameOrNull ?? ''),
    );

    this.agentEmail$ = this.bpSdkStoreService.state$.pipe(
      select(selectUserEmail),
      map((nameOrNull) => nameOrNull ?? ''),
    );

    this.tenantName$ = this.bpSdkStoreService.state$.pipe(
      select(selectTenantName),
      map((nameOrNull) => nameOrNull ?? ''),
    );

    this.canSeeReferralButton$ = this.permissionService.hasRoutePermission(
      ReferralPermissions as IRouteData,
    );
  }

  logout() {
    this.mixpanelService.track(MixpanelEventType.LOGGED_OUT);
    this.mixpanelService.reset();
    this.bpSdkCoreService.logout();
  }

  referAFriend() {
    this.mixpanelService.track(MixpanelEventType.REFER_A_FRIEND_OPENED);
    this.dialogService.open(ReferralDialogComponent);
  }
}

// This is just temporary - will be removed in a future ticket
export const CreateApplicationFormAction = createAction(
  '[Quotes] - Create Application Form',
);
