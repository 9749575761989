import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-year',
  templateUrl: './year.component.html',
  styleUrls: ['./year.component.scss'],
})
export class YearComponent implements OnInit {
  year = '2021';

  ngOnInit() {
    this.year = `${new Date().getFullYear()}`;
  }
}
