import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getPartnerEngineConfig } from '@boldpenguin/sdk';
import { environment } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Observable,
  Subject,
  catchError,
  exhaustMap,
  from,
  take,
  throwError,
} from 'rxjs';
import { BpSdkCoreService } from 'src/app/core/services/bp-sdk-core-service/bp-sdk-core.service';
import { BpSdkStoreService } from 'src/app/core/services/bp-sdk-store-service/bp-sdk-store.service';

@UntilDestroy()
@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
  private readonly logoutOnce = new Subject<void>();

  private safeHostExp = environment.production
    ? /\.boldpenguin\.com$/
    : /\.boldpenguin\.com$|localhost$/;

  constructor(
    private bpSdkCoreService: BpSdkCoreService,
    private bpSdkStoreService: BpSdkStoreService,
  ) {
    this.setupLogoutSubscription();
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let url;
    try {
      url = new URL(req.url);
    } catch (error) {} // silently handle invalid urls (e.g. /path/to/download.pdf)

    if (!url || !this.safeHostExp.test(url.hostname)) {
      return next.handle(req);
    }

    // note: if both token and cookie are set, token will take precendence
    const { token } = getPartnerEngineConfig(
      this.bpSdkStoreService.getCurrentState(),
    );
    const updateOptions: {
      withCredentials: boolean;
      setHeaders?: { [header: string]: string };
    } = {
      withCredentials: true,
    };
    if (token) {
      updateOptions.setHeaders = { Authorization: `Bearer ${token}` };
    }
    const authReq = req.clone(updateOptions);

    return next.handle(authReq).pipe(
      catchError((err: any, event$: Observable<HttpEvent<any>>) => {
        if (err) {
          if (err.status === 401) {
            this.logoutOnce.next();
            return event$;
          }
          return throwError(() => err);
        }
        return event$;
      }),
    );
  }

  private setupLogoutSubscription() {
    this.logoutOnce
      .asObservable()
      .pipe(
        take(1),
        exhaustMap(() => from(this.bpSdkCoreService.logout())),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
