import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IntercomInternalLabels } from 'src/app/core/services/intercom-service/intercom.service';

@Component({
  selector: 'app-internal-page-header',
  templateUrl: './internal-page-header.component.html',
  styleUrls: ['./internal-page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternalPageHeaderComponent {
  @Input() heading: string;
  @Input() icon: string;
  @Input() large: boolean;

  readonly intercomLabels = IntercomInternalLabels;
}
