import { AppointmentTypes, ICarrierAttributes } from '@boldpenguin/sdk';
import isEqual from 'lodash/isEqual';
import {
  IAvailableEnrollment,
  IFormControlEnrollment,
  IEnrollment,
} from '../models/enrollments.model';

/**
 * Determines if an enrollment carrier has producer code information saved
 *
 * @param enrollment IEnrollment
 * @returns Returns true if there are enrollment attributes and all have values, returns false if there are no attributes or some/all don't have values
 */
export const hasCompletedEnrollmentAttributes = (
  enrollment: IEnrollment,
): boolean => {
  const enrollmentAttributes = enrollment.enrollment_attributes;
  const enrollmentAttributeKeys = Object.keys(enrollmentAttributes);
  return (
    !!enrollmentAttributeKeys.length &&
    enrollmentAttributeKeys.every((key) => !!enrollmentAttributes[key].value)
  );
};

/**
 * Determines if an enrollment carrier has completed self service registration
 *
 * @param enrollment IEnrollment
 * @returns Returns true if the self service registration has been completed, returns false if the carrier does not support self service registration of if registration has not been completed
 */
export const hasCompletedSelfServiceRegistration = (
  enrollment: IEnrollment,
): boolean => !!enrollment.self_service_registration && !!enrollment.completed;

/**
 * Determines if an enrollment is queued to activate a direct appointment
 * with a carrier requiring no attributes
 */
export const hasQueuedDirectAppointment = (
  id: string,
  carrierAttributes: ICarrierAttributes,
  pendingDirectAppointmentEnrollmentIds,
): boolean =>
  Object.keys(carrierAttributes).length === 0 &&
  pendingDirectAppointmentEnrollmentIds.includes(id);

/**
 * Filters enrollments by available appointment type
 *
 * @param enrollments Array of enrollments
 * @param appointmentType Appointment type to filter by
 * @returns Returns true if has available appointment type, returns false if not
 */
export const filterByAvailableAppointmentType = (
  enrollments: (IAvailableEnrollment | IEnrollment)[],
  appointmentType: AppointmentTypes,
): (IAvailableEnrollment | IEnrollment)[] =>
  enrollments.filter((d) =>
    d.available_appointment_types?.includes(appointmentType),
  );

/**
 * Determines if enrollment should be queued to have active direct appointment
 *
 * @param enrollment Enrollment object
 * @returns Returns true if enrolment has direct available appointment type and all enrollment attributes have values, returns false if not
 */
export const isQueuedToActivateDirectEnrollment = (
  enrollment: IEnrollment,
): boolean =>
  enrollment.available_appointment_types.includes(AppointmentTypes.Direct) &&
  hasCompletedEnrollmentAttributes(enrollment);

/**
 * Determines if an enrollment has an active direct appointment
 *
 * @param enrollment Enrollment object
 * @returns Returns true if appointment type is direct and active is true, returns false if not
 */
export const isActiveDirectAppointmentEnrollment = (
  enrollment: IEnrollment,
): boolean =>
  enrollment.active && enrollment.appointment_type === AppointmentTypes.Direct;

/**
 * Determines if an enrollment has an active sub-appointment
 *
 * @param enrollment Enrollment object
 * @returns Returns true if appointment type is sub and active is true, returns false if not
 */
export const isActiveSubAppointmentEnrollment = (
  enrollment: IEnrollment,
): boolean =>
  enrollment.active && enrollment.appointment_type === AppointmentTypes.Sub;

/**
 * Maps an enrollment to the shape of the carrier on a FormControl
 *
 * @param enrollment IEnrollment
 * @returns Returns object with reduced set enrollment attributes
 */
export const getFormControlCarrier = (
  enrollment: IEnrollment,
): IFormControlEnrollment => ({
  name: enrollment.carrier_name,
  carrier_id: enrollment.id,
  carrier_attributes: enrollment.carrier_attributes,
  carrier_subdomain: enrollment.carrier_subdomain,
  carrier_labels: enrollment.carrier_labels,
  logo_url: enrollment.logo_path,
  available_appointment_types: enrollment.available_appointment_types,
  products: enrollment.carrier_products || [],
  preselected: enrollment.preselected,
  active: enrollment.active,
  self_service_registration: enrollment.self_service_registration,
  allow_instant_enrollment: enrollment.allow_instant_enrollment,
  carrier_online: enrollment.carrier_online,
});

/**
 * Converts and array of enrollments into an
 * array of the enrollments' id and carrier_attributes properties
 *
 * @param enrollments IEnrollment[]
 * @returns Array of enrollment ids and carrier attributes
 */
export const mapToCarrierAttributes = (
  enrollments: IEnrollment[],
): {
  id: string;
  carrier_attributes: ICarrierAttributes;
  completed: boolean;
}[] =>
  enrollments.map((e) => ({
    id: e.id,
    carrier_attributes: e.carrier_attributes,
    completed: e.completed,
  }));

/**
 * Finds enrollments whose carrier attributes have not changed
 *
 * @param previousEnrollments IEnrollment[]
 * @param currentEnrollments IEnrollment[]
 * @returns Returns array of enrollments that have modified carrier attributes
 */
export const findChangedEnrollments = (
  previousEnrollments: IEnrollment[],
  currentEnrollments: IEnrollment[],
): IEnrollment[] =>
  currentEnrollments.filter(
    (curr) =>
      !previousEnrollments.find((prev) =>
        isEqual(
          {
            id: curr.id,
            carrier_attributes: curr.carrier_attributes,
            completed: curr.completed,
          },
          {
            id: prev.id,
            carrier_attributes: prev.carrier_attributes,
            completed: prev.completed,
          },
        ),
      ),
  );

/**
 * Converts an enrollment's server error messages, replaced if necessary based on a list of overrides
 *
 * @param errors An enrollment's server errors
 * @param carrierSubdomain The enrollment's subdomain
 * @returns An array of error messages that have been replaced with overrides if necessary
 */
export const getUserFacingServerErrors = (
  errors: string[] | undefined,
  carrierSubdomain: string,
): string[] | undefined => {
  if (!errors) {
    return;
  }

  if (carrierSubdomain === 'homesite') {
    return [...errors].map((error) => {
      if (error.toLowerCase().includes('unknown producer code')) {
        return 'MSA needs to approve your producer code before quoting. Please contact support@boldpenguin.com.';
      }
      return error;
    });
  }

  return errors;
};
