import { PlanCodes } from 'src/app/shared/models/plan-codes';
import { PriceSkus } from 'src/app/shared/models/price-skus';

export enum PlanNames {
  Access = 'Access',
  Connect = 'Connect',
  ConnectPlus = 'Connect+',
  Enterprise = 'Enterprise',
  // Names are only used on the plan UI component, so
  // we don't need one for each code
  Terminal = 'Bold Penguin Terminal',
  Annual = 'Annual',
  Monthly = 'Monthly',
}

export enum BillingPeriod {
  Annually = 'annually',
  Monthly = 'monthly',
}

interface IPricingDetails {
  pricePerPeriod: number;
  additionalSavings?: string;
  billingPeriod: BillingPeriod;
}

export enum DisplayPricePer {
  Month = 'month',
  Year = 'year',
}

export interface ISubscriptionPlanDetails {
  name: PlanNames;
  priceSku: PriceSkus;
  pricingFormula: (numAgents?: number) => number | null;
  pricePerPeriod: number | null;
  pricePerAdditionalAgent: number | null;
  planDescription: string;
  /** Displayed on the plan selection signup step */
  planSelectionFeatures: string[];
  /** Displayed on the payment signup step  */
  paymentPageFeatures: string[];
  perAgentFeature?: string;
  dueOnSignup: boolean;
  basePlanSku?: string;
  terminalPricingData?: IPricingDetails;
  /** Displayed to customer as "per month" or "per year" during signup */
  displayPricePer: DisplayPricePer;
}

export interface IMonthlyRateWithDiscount {
  isDiscounted: boolean;
  rates: Map<PriceSkus, number>;
}

export const terminalPricingAnnual2024PlansDefaults: ISubscriptionPlanDetails =
  {
    name: PlanNames.Annual,
    priceSku: PriceSkus.TerminalAnnual2024,
    pricingFormula: () => 1200,
    pricePerPeriod: 1200,
    pricePerAdditionalAgent: null,
    planDescription:
      'This cost-effective plan saves you money as you immediately save time in the quote and bind process.',
    paymentPageFeatures: [
      'Quote direct and sub-appointments across a broad carrier panel',
      'Carrier Appetite Search',
      'Interactive Insights',
      'Up to 10 Agents included',
    ],
    planSelectionFeatures: [
      'Quote direct and sub-appointments across a broad carrier panel',
      'Carrier Appetite Search',
      'Interactive Insights',
      'Flat annual pricing and up to 10 Agents included',
    ],
    dueOnSignup: true,
    basePlanSku: 'terminal',
    displayPricePer: DisplayPricePer.Year,
    terminalPricingData: {
      pricePerPeriod: 1200,
      billingPeriod: BillingPeriod.Annually,
    },
  };

export const discountedPricingAnnualPlanDefaults: ISubscriptionPlanDetails = {
  name: PlanNames.Annual,
  priceSku: PriceSkus.TerminalAnnual,
  pricingFormula: () => 12 * 199,
  pricePerPeriod: 12 * 199,
  pricePerAdditionalAgent: 39,
  planDescription:
    'Our most cost-effective plan saves you money as you immediately save time in the quote and bind process.',
  planSelectionFeatures: [
    'Access to direct and sub-appointments and quote most efficiently',
    'Up to 3 agents included',
  ],
  paymentPageFeatures: [
    'Access to direct and sub-appointments and quote most efficiently',
    'Up to 3 agents included',
  ],
  perAgentFeature: 'per additional agent per month, billed annually & prorated',
  dueOnSignup: true,
  basePlanSku: PlanCodes.Terminal,
  displayPricePer: DisplayPricePer.Month,
  terminalPricingData: {
    pricePerPeriod: 199,
    billingPeriod: BillingPeriod.Annually,
    additionalSavings: '$600',
  },
};

export const discountedPricingMonthlyPlanDefaults: ISubscriptionPlanDetails = {
  ...discountedPricingAnnualPlanDefaults,
  name: PlanNames.Monthly,
  priceSku: PriceSkus.TerminalMonthly,
  pricingFormula: () => 249,
  pricePerPeriod: 249,
  pricePerAdditionalAgent: 49,
  planDescription:
    'The same great features as the Annual subscription, but billed on a monthly basis. Quote and bind seamlessly on day one!',
  planSelectionFeatures: [
    'Access to direct and sub-appointments and quote most efficiently',
    'Up to 3 agents included',
  ],
  paymentPageFeatures: [
    'Access to direct and sub-appointments and quote most efficiently',
    'Up to 3 agents included',
  ],
  perAgentFeature: 'per additional agent per month, billed monthly',
  displayPricePer: DisplayPricePer.Month,
  terminalPricingData: {
    pricePerPeriod: 249,
    billingPeriod: BillingPeriod.Monthly,
  },
};

export const agencyNetworkSubscriptionPlans: ISubscriptionPlanDetails[] = [
  discountedPricingAnnualPlanDefaults,
  discountedPricingMonthlyPlanDefaults,
];

export const defaultSubscriptionPlans: ISubscriptionPlanDetails[] = [
  terminalPricingAnnual2024PlansDefaults,
];

export const allSubscriptionPlans = [
  ...agencyNetworkSubscriptionPlans,
  ...defaultSubscriptionPlans,
];
