<div
  class="internal-page-header header"
  role="navigation"
  [class.large]="large"
>
  <div
    class="left-header-items"
    [attr.data-intercom-target]="intercomLabels.INTERNAL_PAGE_HEADING"
  >
    <div *ngIf="icon" class="header-navigation-icon">
      <emperor-icon [name]="icon" class="header-nav-icon-pic"></emperor-icon>
    </div>
    <h1 class="mat-subtitle-1">{{ heading }}</h1>
  </div>
  <!-- TODO: Set up content projection if the center/right areas will be dynamic in the future,
  i.e. <ng-content select="[centerItems]"></ng-content> -->
  <div class="center-header-items"></div>
  <div class="right-header-items">
    <ng-content select="[rightItems]"></ng-content>
  </div>
</div>
