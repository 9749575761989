import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppointmentTypes, ICarrier } from '@boldpenguin/sdk';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IAvailableEnrollment,
  IEnrollmentAppointment,
  IEnrollment,
} from '../../models/enrollments.model';

export interface IEnrollmentsService {
  getEnrollments(): Observable<IEnrollment[]>;
  updateEnrollment(
    enrollment: IEnrollment,
    activate: boolean,
  ): Observable<IEnrollment>;
  registerSelfServiceEnrollment(enrollment: IEnrollment): Observable<{
    signup_redirect_url?: string;
    already_self_registered?: boolean;
  }>;
  selfServiceRegistrationCallback(
    params: ISelfServiceCallbackParam,
  ): Observable<ISelfServiceCallbackResponse>;
}

export interface ISelfServiceCallbackParam {
  enrollment_id: string;
  query_parameters: any;
}

export interface ISelfServiceCallbackResponse {
  enrollment: IEnrollment;
  carrier: ICarrier;
}

@Injectable({
  providedIn: 'root',
})
export class EnrollmentsService implements IEnrollmentsService {
  constructor(private http: HttpClient) {}

  getEnrollments(): Observable<IEnrollment[]> {
    const url = `${environment.apiAuthUri}/api/terminal/enrollments`;
    return this.http
      .get<IEnrollment[]>(url)
      .pipe(
        map((enrollments) =>
          this.cleanUpEnrollmentProducerFieldData(enrollments),
        ),
      );
  }

  updateEnrollment(
    enrollment: IEnrollment,
    activate: boolean,
    forceValidation: boolean = false,
  ): Observable<IEnrollment> {
    const updatedEnrollment = {
      enrollment: {
        ...enrollment.carrier_attributes,
        appointment_type: !!enrollment.appointment_type
          ? enrollment.appointment_type
          : null,
        active: activate,
        always_validate:
          forceValidation &&
          enrollment.appointment_type !== AppointmentTypes.Sub,
      },
    };

    const url = `${environment.apiAuthUri}/api/terminal/enrollments/${enrollment.id}`;
    return this.http.put<IEnrollment>(url, updatedEnrollment);
  }

  requestEnrollmentAppointment(
    enrollmentId: string,
  ): Observable<IEnrollmentAppointment> {
    const url = `${environment.apiAuthUri}/api/terminal/enrollments/${enrollmentId}/appointment`;
    return this.http.post<IEnrollmentAppointment>(url, {});
  }

  registerSelfServiceEnrollment(enrollment: IEnrollment): Observable<{
    signup_redirect_url?: string;
    already_self_registered?: boolean;
  }> {
    const url = `${environment.apiAuthUri}/api/terminal/enrollments/${enrollment.id}/register`;
    return this.http.get(url);
  }

  selfServiceRegistrationCallback(
    params: ISelfServiceCallbackParam,
  ): Observable<ISelfServiceCallbackResponse> {
    const url = `${environment.apiAuthUri}/api/terminal/enrollments/${params.enrollment_id}/callback`;
    return this.http.post<ISelfServiceCallbackResponse>(
      url,
      params.query_parameters,
    );
  }

  cleanUpEnrollmentProducerFieldData(
    enrollments: IEnrollment[],
  ): IEnrollment[] {
    return enrollments.map((enrollment) => {
      // Cleans up the producer_fields a bit as they come back with two fields even when no other data exists
      enrollment.producer_schema.producer_fields =
        enrollment.producer_schema.producer_fields
          .filter((producerField) => {
            const keys = Object.keys(producerField).filter(
              (key) => key !== 'id' && key !== '_destroy',
            );
            return keys.length > 0;
          })
          .map((producerField) => {
            delete producerField._destroy;
            delete producerField.id;
            return producerField;
          });
      return enrollment;
    });
  }

  getAvailableEnrollments(
    planSku = 'terminal',
  ): Observable<IAvailableEnrollment[]> {
    const url = `${environment.apiAuthUri}/api/terminal/registrations/available_enrollments`;
    return this.http.get<any[]>(url, {
      params: { client_id: environment.clientId, plan_sku: planSku },
    });
  }

  activateDirectEnrollment(enrollment: IEnrollment): Observable<IEnrollment> {
    const url = `${environment.apiAuthUri}/api/terminal/enrollments/${enrollment.id}`;
    return this.http.patch<IEnrollment>(url, {
      ...enrollment,
      appointment_type: AppointmentTypes.Direct,
      active: true,
    });
  }
}
