import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  HeaderAlert,
  HeaderAlertButton,
  HeaderAlertsService,
} from 'src/app/core/services/header-service/header-alerts.service';
import { selectAllHeaderAlerts } from 'src/app/core/store/header-alerts/header-alerts.selectors';

@Component({
  selector: 'app-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertBannerComponent implements OnInit {
  headerAlerts$: Observable<HeaderAlert[]>;

  constructor(
    private store: Store,
    private headerAlertsSrv: HeaderAlertsService,
  ) {}

  ngOnInit(): void {
    this.headerAlerts$ = this.store.pipe(select(selectAllHeaderAlerts));
  }

  alertButtonClick(alert: HeaderAlert, button: HeaderAlertButton): void {
    this.headerAlertsSrv.buttonClick(alert, button);
  }
}
