import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit, AfterViewInit {
  @ViewChild('left') leftRef: ElementRef;
  @ViewChild('right') rightRef: ElementRef;
  @Input() showLeft = true;
  @Input() showRight = true;

  leftHasContent = true;
  rightHasContent = true;

  constructor(private router: Router, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        debounceTime(1),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.checkForNgContent();
      });
  }

  ngAfterViewInit() {
    this.checkForNgContent();
  }

  checkForNgContent() {
    this.leftHasContent =
      this.leftRef.nativeElement &&
      Array.from(this.leftRef.nativeElement.children).filter(
        (n: { localName: string }) => n.localName !== 'router-outlet',
      ).length > 0;

    this.rightHasContent =
      this.rightRef.nativeElement &&
      Array.from(this.rightRef.nativeElement.children).filter(
        (n: { localName: string }) => n.localName !== 'router-outlet',
      ).length > 0;
    this.cdRef.detectChanges();
  }
}
