import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-profile-panel',
  templateUrl: './profile-panel.component.html',
  styleUrls: ['./profile-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePanelComponent {
  @Input() agentFirstName: string;
  @Input() agentLastName: string;
  @Input() agentEmail: string;
  @Input() canSeeReferralButton: boolean;
  @Output() referAFriend = new EventEmitter();
  @Output() logout = new EventEmitter();

  referAFriendClicked() {
    this.referAFriend.emit();
  }

  logoutClicked() {
    this.logout.emit();
  }
}
