<div class="compat-banner" *ngIf="isMobileSized && !isDismissed">
  <!-- Mobile Text -->
  <div>This application is not optimized for mobile viewing.</div>

  <div class="dismiss">
    <emperor-button (click)="dismiss()" [uiButtonType]="emperorTextButton">
      DISMISS
    </emperor-button>
  </div>
</div>
