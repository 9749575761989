import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as mixpanel from 'mixpanel-browser';
import { LocalStorageService } from 'ngx-webstorage';
import { localStorageKeys } from 'src/app/shared/constants';
import { environment } from '../../../../environments/environment';

declare global {
  interface Window {
    Cypress?: unknown;
  }
}

// Map used for values to mixpanel events. Be careful changing the values here,
// as mixpanel uses them as unique event identifiers. Changing them may result in
// mixpanel funnels / dashboards changing. If a phrasing change is needed, consider
// using custom properties inside mixpanel
export enum MixpanelEventType {
  // App
  TERMINAL_LOADED = 'App.TerminalSiteLoaded',
  TERMINAL_APP_FAILED_TO_INIT = 'App.TerminalInitFailed',
  SESSION_EXPIRED = 'App.SessionExpired',
  LOGGED_OUT = 'App.LoggedOutSuccessfully',
  REDIRECTED_TO_LOGIN = 'App.RedirectedToLogin',
  PAGE_LOADED = 'App.PageLoaded',
  REFER_A_FRIEND_OPENED = 'App.ReferAFriendOpened',
  REFER_A_FRIEND_SENT_REFERRAL = 'App.ReferAFriendSentReferral',
  REFER_A_FRIEND_CLOSED = 'App.ReferAFriendClosed',
  SHOW_UPDATE_MODAL = 'App.SWUpdateModalDisplayed',

  // Compatibility Banner
  COMPATIBILITY_BANNER_DISMISSED = 'CompatibilityBanner.Dismissed',

  // Auth Dashboard
  AUTH_DASH_BUTTON_CLICKED = 'AuthDashboard.ButtonClicked',

  // Signup.component
  SIGNUP_PAYMENT = 'Signup.PaymentMethod',
  SIGNUP_PAYMENT_PAGE_LOADED = 'Signup.PaymentPageLoaded',
  SIGNUP_PAYMENT_STRIPE_ERROR = 'Signup.PaymentStripeError',
  SIGNUP_PAYMENT_COMPLETE_PAYMENT_CTA = 'Signup.PaymentCompletePaymentCTAClicked',
  SIGNUP_PAYMENT_PRICE_TYPE_TOGGLED = 'Signup.PaymentPriceTypeToggled',
  SIGNUP_SUCCESSFUL = 'Signup.Successful',
  SIGNUP_FAILED = 'Signup.Failed',
  SIGNUP_WELCOME = 'Signup.Welcome',
  SIGNUP_BACK = 'Signup.Back',
  SIGNUP_AGENT = 'Signup.AgentInformation',
  SIGNUP_ABOUT = 'Signup.TellUsAboutYourself',
  SIGNUP_AGENT_INFO_LOADED = 'Signup.AgentInfoLoaded',
  SIGNUP_AGENT_INFO_FORM_UPDATED = 'Signup.AgentInfoFormUpdated',
  SIGNUP_AGENCY_INFO_LOADED = 'Signup.AgencyInfoLoaded',
  SIGNUP_AGENCY_INFO_FORM_UPDATED = 'Signup.AgencyInfoFormUpdated',
  SIGNUP_DIRECT_APPOINTMENTS_LOGO_ERROR = 'Signup.DirectAppointmentLogoError',
  SIGNUP_PLAN_SELECTED = 'Signup.PlanSelected',
  SIGNUP_PLAN_DROPDOWN_OPENED = 'Signup.PlanCarriersDropdownClicked',
  SIGNUP_PLAN_SUBMITTED = 'Signup.PlanSubmitted',
  SIGNUP_PLAN_LOADED = 'Signup.PlanLoaded',
  SIGNUP_PAYMENT_TERMINAL_TERMS_OF_SERVICE_DIALOG_OPENED = 'Signup.PaymentTermsAndConditionsDialogOpened',
  SIGNUP_PAYMENT_CUSTOMER_TERMS_OF_SERVICE_DIALOG_OPENED = 'Signup.PaymentCustomerTermsOfServiceDialogOpened',
  SIGNUP_PAYMENT_PRODUCER_AGREEMENT_DIALOG_OPENED = 'Signup.PaymentProducerAgreementDialogOpened',
  SIGNUP_PAYMENT_CUSTOMER_TERMS_OF_SERVICE_DOWNLOADED = 'Signup.PaymentCustomerTermsOfServiceDownloaded',
  SIGNUP_PAYMENT_TERMINAL_TERMS_OF_SERVICE_DOWNLOADED = 'Signup.PaymentTerminalTermsAndConditionsDownloaded',
  SIGNUP_PAYMENT_PRODUCER_AGREEMENT_DOWNLOADED = 'Signup.PaymentProducerAgreementDownloaded',
  SIGNUP_PAYMENT_LEGAL_SUBMITTED = 'Signup.PaymentLegalAgreementsSubmitted',
  SIGNUP_PAYMENT_LEGAL_REP_CLICKED = 'Signup.PaymentLegalRepresentationClicked',
  SIGNUP_PAYMENT_LEGAL_TOC_CLICKED = 'Signup.PaymentTermsAndConditionsClicked',
  SIGNUP_PAYMENT_LEGAL_PA_CLICKED = 'Signup.PaymentProducerAgreementClicked',

  // Promotion codes
  PROMOTION_CODE_SUCCESSFUL = 'PromotionCode.Successful',
  PROMOTION_CODE_FAILED = 'PromotionCode.Failed',

  // DEPRECATED - Reskin Remove
  // Signup - Choose Subscription Plan
  CHOOSE_PLAN_STARTING_VALUES = 'ChoosePlan.InitializedWithStartingValues',
  CHOOSE_PLAN_SUBMITTED = 'ChoosePlan.Submitted',

  // Subscription Plan Card
  SUBSCRIPTION_CARD_SELECTED = 'SubscriptionCard.Selected',

  // ACH (Commission Direct Deposit)
  ACH_ACCOUNT_SAVE_SUCCESS = 'CommissionPayout.ACHAccountSaved',
  ACH_ACCOUNT_SAVE_ERROR = 'CommissionPayout.ACHAccountError',
  ACH_REMOVE_ACCOUNT_CLICKED = 'CommissionPayout.ACHRemoveAccountClicked',
  ACH_MULTIPLE_ACCOUNTS_ERROR = 'CommissionPayout.ACHMultipleAccountsError',

  // Commissions
  COMMISSIONS_ADD_PAYMENT_METHOD = 'Commissions.AddPaymentMethodClicked',

  // Login.component
  LOGIN_AUTH_ERROR = 'Login.LoginError',

  // SAML Login
  SAML_LOADED = 'SamlLogin.SamlLoaded',
  SAML_SUBMITTED = 'SamlLogin.SamlSubmitted',

  // WELCOME WIZARD
  WELCOME_PREVIOUS_STEP = 'FirstTimeUseWizard.PreviousStep',
  WELCOME_NEXT_STEP = 'FirstTimeUseWizard.NextStep',
  WELCOME_GO_TO_DASHBOARD = 'FirstTimeUseWizard.GoToDashboard',
  WELCOME_GO_TO_CARRIER_APPOINTMENTS = 'FirstTimeUseWizard.GoToCarrierAppointments',
  WELCOME_GET_COMPLIANCE_FORM_DATA = 'FirstTimeUseWizard.GetComplianceFormData',
  WELCOME_GET_COMPLIANCE_FORM_DATA_SUCCESS = 'FirstTimeUseWizard.GetComplianceFormDataSuccess',
  WELCOME_GET_COMPLIANCE_FORM_DATA_ERROR = 'FirstTimeUseWizard.GetComplianceFormDataError',
  WELCOME_UPDATE_COMPLIANCE_FORM_DATA = 'FirstTimeUseWizard.UpdateComplianceFormData',
  WELCOME_UPDATE_COMPLIANCE_FORM_DATA_SUCCESS = 'FirstTimeUseWizard.UpdateComplianceFormDataSuccess',
  WELCOME_UPDATE_COMPLIANCE_FORM_DATA_ERROR = 'FirstTimeUseWizard.UpdateComplianceFormDataError',
  WELCOME_RESET_COMPLIANCE_FORM_DATA = 'FirstTimeUseWizard.ResetComplianceFormData',
  WELCOME_RESET_COMPLIANCE_FORM_DATA_SUCCESS = 'FirstTimeUseWizard.ResetComplianceFormDataSuccess',
  WELCOME_RESET_COMPLIANCE_FORM_DATA_ERROR = 'FirstTimeUseWizard.ResetComplianceFormDataError',
  WELCOME_DIRECT_APPOINTMENT_SELECTED = 'FirstTimeUseWizard.DirectAppointmentChoiceSelected',
  WELCOME_DIRECT_APPOINTMENT_LOGO_ERROR = 'FirstTimeUseWizard.DirectAppointmentLogoError',
  WELCOME_DIRECT_APPOINTMENT_MODAL_OPENED = 'FirstTimeUseWizard.DirectAppointmentModalOpened',
  WELCOME_DIRECT_APPOINTMENT_DIALOG_LOGO_ERROR = 'FirstTimeUseWizard.DirectAppointmentDialogLogoError',
  WELCOME_CARRIER_ATTRIBUTES_UPDATE_REQUESTED = 'FirstTimeUseWizard.CarrierAttributesUpdateRequested',
  WELCOME_CARRIER_ATTRIBUTES_UPDATE_SUCCESS = 'FirstTimeUseWizard.CarrierAttributesUpdateSuccess',
  WELCOME_CARRIER_ATTRIBUTES_UPDATE_ERROR = 'FirstTimeUseWizard.CarrierAttributesUpdateError',
  WELCOME_DIRECT_APPOINTMENTS_ACTIVATE = 'FirstTimeUseWizard.DirectAppointmentsActivate',
  WELCOME_DIRECT_APPOINTMENTS_ACTIVATE_SUCCESS = 'FirstTimeUseWizard.DirectAppointmentsActivateSuccess',
  WELCOME_DIRECT_APPOINTMENTS_ACTIVATE_ERROR = 'FirstTimeUseWizard.DirectAppointmentsActivateError',
  WELCOME_DIRECT_APPOINTMENTS_SKIP = 'FirstTimeUseWizard.DirectAppointmentsSkip',
  WELCOME_SUB_APPOINTMENT_LOGO_ERROR = 'FirstTimeUseWizard.SubAppointmentLogoError',
  WELCOME_SUB_APPOINTMENT_SELECTED = 'FirstTimeUseWizard.SubAppointmentChoiceSelected',
  WELCOME_APPOINTMENTS_SELECTED = 'FirstTimeUseWizard.AppointmentChoiceSelected',
  WELCOME_DIRECT_APPOINTMENTS_QUEUED = 'FirstTimeUseWizard.DirectAppointmentChoiceQueued',
  WELCOME_SUB_APPOINTMENTS_UPDATE = 'FirstTimeUseWizard.AppointmentChoiceUpdate',
  WELCOME_SUB_APPOINTMENTS_UPDATE_SUCCESS = 'FirstTimeUseWizard.AppointmentChoiceUpdateSuccess',
  WELCOME_SUB_APPOINTMENTS_UPDATE_ERROR = 'FirstTimeUseWizard.AppointmentChoiceUpdateError',
  WELCOME_APPOINTMENTS_RESET = 'FirstTimeUseWizard.AppointmentChoiceReset',
  WELCOME_ACKNOWLEDGEMENT_CHANGED = 'FirstTimeUseWizard.AcknowledgmentChanged',
  WELCOME_ACKNOWLEDGEMENT_PA_DOWNLOAD = 'FirstTimeUseWizard.AcknowledgementProducerAgreementDownload',
  WELCOME_ACKNOWLEDGEMENT_PA_DIALOG_OPENED = 'FirstTimeUseWizard.AcknowledgementProducerAgreementDialogOpened',
  WELCOME_ACKNOWLEDGEMENT_PA_CLICKED = 'FirstTimeUseWizard.AcknowledgementProducerAgreementClicked',
  WELCOME_AGENCY_INFO_QUESTION_UPDATED = 'FirstTimeUseWizard.AgencyInfoQuestionUpdated',
  WELCOME_PAYMENT_QUESTION_UPDATED = 'FirstTimeUseWizard.PaymentQuestionUpdated',
  WELCOME_UPLOAD_EO_DOCUMENT = 'FirstTimeUseWizard.UploadEoDocument',
  WELCOME_UPLOAD_EO_DOCUMENT_SUCCESS = 'FirstTimeUseWizard.UploadEoDocumentSuccess',
  WELCOME_UPLOAD_EO_DOCUMENT_ERROR = 'FirstTimeUseWizard.UploadEoDocumentError',
  WELCOME_COMPLIANCE_FORM_LOCKED = 'FirstTimeUseWizard.ComplianceFormLocked',
  WELCOME_SUBAPPOINTMENTS_REQUEST = 'FirstTimeUseWizard.SubAppointmentsRequest',
  WELCOME_SUBAPPOINTMENTS_REQUEST_SUCCESS = 'FirstTimeUseWizard.SubAppointmentsRequestSuccess',
  WELCOME_SUBAPPOINTMENTS_REQUEST_ERROR = 'FirstTimeUseWizard.SubAppointmentsRequestError',
  WELCOME_SUBAPPOINTMENTS_SKIP = 'FirstTimeUseWizard.SubAppointmentsSkip',
  WELCOME_W9_SUBMITTING = 'FirstTimeUseWizard.W9Submitting',
  WELCOME_W9_SUBMITTED = 'FirstTimeUseWizard.W9Submitted',
  WELCOME_W9_ERROR = 'FirstTimeUseWizard.W9Error',
  WELCOME_STATEAUTO_QUESTIONS_LOADED = 'FirstTimeUseWizard.StateAutoQuestionLoaded',
  WELCOME_STATEAUTO_QUESTIONS_ACTIVE = 'FirstTimeUseWizard.StateAutoQuestionActive',
  WELCOME_STATEAUTO_QUESTION_UPDATED = 'FirstTimeUseWizard.StateAutoQuestionUpdated',

  // Carrier Specific Compliance Questions
  COMPLIANCE_MARKEL_QUESTIONS_LOADED = 'ComplianceMarkelQuestionsLoaded',
  COMPLIANCE_MARKEL_QUESTIONS_ACTIVE = 'ComplianceMarkelQuestionsActive',
  COMPLIANCE_MARKEL_QUESTIONS_UPDATED = 'ComplianceMarkelQuestionsUpdated',
  // Carrier Specific Compliance Questions
  COMPLIANCE_WESTFIELD_QUESTIONS_LOADED = 'ComplianceWestfieldQuestionsLoaded',
  COMPLIANCE_WESTFIELD_QUESTIONS_ACTIVE = 'ComplianceWestfieldQuestionsActive',
  COMPLIANCE_WESTFIELD_QUESTIONS_UPDATED = 'ComplianceWestfieldQuestionsUpdated',

  // Dashboard
  DASHBOARD_MORE_ACTIONS_MENU = 'Dashboard.MoreActionsMenu',

  // SDK Form fields
  APPLICATION_FORM_VALUE_UPDATED = 'AppForm.ValueUpdated',
  APPLICATION_FORM_PREFILLED_VALUE_UPDATED = 'AppForm.PrefilledValueUpdated',
  APPLICATION_FORM_ANSWER_SAVED = 'AppForm.AnswerSaved',
  APPLICATION_FORM_MISSING_ADDRESS_COMPONENTS = 'AppForm.MissingAddressComponents',

  // SDK Question Set
  QUESTION_SET_SAVED = 'AppForm.QuestionSetSaved',
  QUESTION_SET_LOADED = 'AppForm.QuestionSetLoaded',
  QUESTION_SET_TOGGLE_NAVIGATION_DISABLED = 'AppForm.QuestionSetToggleNavigationDisabled',

  // Toolbar Navigation
  TOP_TOOLBAR_NAVIGATE_STARTED = 'TopToolbar.NavigateStarted',

  // Quotes
  QUOTE_EXIT = 'AppForm.Quote.ExitButtonClicked',
  QUOTE_SELL = 'AppForm.Quote.SellToExchangeButtonClicked',
  QUOTE_SUBMIT_FEEDBACK = 'AppForm.Quote.SubmitFeedbackButtonClicked',
  QUOTE_SHAREABLE_QUOTE_OPENED = 'AppForm.Quote.ShareableQuotesOpened',
  QUOTE_SHAREABLE_QUOTE_CLOSED = 'AppForm.Quote.ShareableQuotesClosed',

  // Business class selector
  BUSINESS_CLASS_SELECTOR_SEARCH_CLEARED = 'AppForm.BCS.SearchCleared',
  BUSINESS_CLASS_SELECTOR_SHOW_HIDE_CLICKED = 'AppForm.BCS.ShowHideClicked',

  // Quote Card
  QUOTE_CARD_EDIT = 'QuoteCard.Edit',
  QUOTE_CARD_QUICK_INFO = 'QuoteCard.QuickInfo',
  QUOTE_CARD_MORE_INFO = 'QuoteCard.MoreInfo',
  QUOTE_CARD_OVERFLOW_MENU = 'QuoteCard.OverflowMenu',
  QUOTE_CARD_ACORD_PDF_DOWNLOAD = 'QuoteCard.AcordPdfDownload',
  QUOTE_CARD_REASSIGN = 'QuoteCard.Reassign',
  QUOTE_CARD_MARK_DONE = 'QuoteCard.MarkDone',

  // Quote Link
  QUOTE_LINK_START = 'QuoteLink.Start',

  // Quote List
  QUOTE_LIST_CREATE_NEW_APP = 'QuoteList.CreateNewApplicationForm',
  QUOTE_LIST_CREATE_NEW_APP_SUCCESS = 'QuoteList.CreateNewApplicationFormSuccess',
  QUOTE_LIST_CREATE_NEW_FAILED = 'QuoteList.CreateNewApplicationFormFailed',
  QUOTE_LIST_LOAD_APP_SUCCESS = 'QuoteList.LoadApplicationFormSuccess',
  QUOTE_LIST_LOAD_APP_FAILED = 'QuoteList.LoadApplicationFormFailed',
  QUOTE_LIST_FILTER_BY_STATUS = 'QuoteList.FilterByStatus',
  QUOTE_LIST_FILTER_BY_TIME = 'QuoteList.FilterByTime',
  QUOTE_LIST_FILTER_BY_ASSIGNEE = 'QuoteList.FilterByAssignee',
  QUOTE_LIST_SIDENAV_OPENED = 'QuoteList.SideNavOpened',
  QUOTE_LIST_SIDENAV_BUTTON_CLICKED = 'QuoteList.SideNavButtonClicked',
  QUOTE_LIST_VIEW_IN_PROGRESS_APPS = 'QuoteList.ViewInProgressApps',
  QUOTE_LIST_SEARCH = 'QuoteList.Search',

  // Carrier Settings
  CARRIER_RANKER_UPDATED = 'CarrierRanker.RankingsUpdated',
  COVERAGE_LEVEL_LIMITS_UPDATED = 'CoverageLevelLimits.Updated',

  // Carrier Settings / Recommended Carrier
  RECOMMENDED_CARRIERS_LOADED = 'RecommendedCarriers.Loaded',
  RECOMMENDED_CARRIERS_BUCKET_MARKED_EXCLUSIVE = 'RecommendedCarriers.BucketMarkedExclusive',
  RECOMMENDED_CARRIERS_BUCKET_UNMARKED_EXCLUSIVE = 'RecommendedCarriers.BucketUnMarkedExclusive',
  RECOMMENDED_CARRIERS_CARRIER_MOVED_TO_BUCKET = 'RecommendedCarriers.CarrierMovedToBucket',
  RECOMMENDED_CARRIERS_CARRIER_MOVED_IN_BUCKET = 'RecommendedCarriers.CarrierMovedInBucket',
  RECOMMENDED_CARRIERS_BUCKET_MOVED = 'RecommendedCarriers.BucketMoved',
  RECOMMENDED_CARRIERS_BUCKET_CREATED = 'RecommendedCarriers.BucketCreated',

  // Takeover Component
  TAKEOVER_ACTIVATED = 'Takeover.Activated',

  // Quote Wrapper
  QUOTE_WRAPPER_QUOTED = 'QuoteWrapper.Quoted',

  // SentToExchangeDialog
  APPSTATE_DIALOG_DISMISSED = 'AppStateDialog.Dismissed',
  APPSTATE_DIALOG_RETURN_TO_DASH = 'AppStateDialog.ReturnToDashboard',
  APPSTATE_DIALOG_OPENED = 'AppStateDialog.Opened',

  // Archive Quote Dialog
  ARCHIVE_QUOTE_DIALOG_OPENED = 'ArchiveQuoteDialog.Opened',
  ARCHIVE_QUOTE_DIALOG_DISMISSED = 'ArchiveQuoteDialog.Dismissed',
  ARCHIVE_QUOTE_DIALOG_ARCHIVE_SELECTED = 'ArchiveQuoteDialog.ArchiveSelected',
  ARCHIVE_QUOTE_DIALOG_UNARCHIVE_SELECTED = 'ArchiveQuoteDialog.UnarchiveSelected',

  // User List
  USER_MANAGEMENT_ADD_USER = 'UserManagement.UserAdded',
  USER_MANAGEMENT_EDIT_USER = 'UserManagement.UserEdited',
  USER_MANAGEMENT_DEACTIVATE = 'UserManagement.UserDeactivated',
  USER_MANAGEMENT_USER_INVITE_RESENT = 'UserManagement.UserInviteResent',
  USER_MANAGEMENT_SUBSCRIPTION_WARNING_MODAL_DISPLAYED = 'UserManagement.SubscriptionWarningModal.Displayed',
  USER_MANAGEMENT_SUBSCRIPTION_WARNING_MODAL_CANCELED = 'UserManagement.SubscriptionWarningModal.Canceled',
  USER_MANAGEMENT_SUBSCRIPTION_WARNING_MODAL_CONFIRMED = 'UserManagement.SubscriptionWarningModal.Confirmed',
  USER_MANAGEMENT_USER_ACCOUNT_TYPE_CHANGED = 'UserManagement.UserAccountType.Changed',
  USER_MANAGEMENT_OVERFLOW_MENU_OPENED = 'UserManagement.OverflowMenu.Opened',
  USER_MANAGEMENT_OVERFLOW_MENU_EDIT_USER_CLICKED = 'UserManagement.OverflowMenu.EditUser.Clicked',
  USER_MANAGEMENT_OVERFLOW_MENU_RESEND_INVITE_CLICKED = 'UserManagement.OverflowMenu.ResendInvite.Clicked',
  USER_MANAGEMENT_OVERFLOW_MENU_ACTIVATION_CHANGE_CLICKED = 'UserManagement.OverflowMenu.ActivationChange.Clicked',
  USER_MANAGEMENT_ACCOUNT_TYPE_TOOLTIP_CLICKED = 'UserManagement.AccountTypeToolTip.Clicked',

  // RTE Sidebar
  RTE_ACORD_DOWNLOADED = 'RteSidebar.AcordDownloaded',
  RTE_ACORD_DOWNLOADED_SUCCESS = 'RteSidebar.AcordDownloadedSuccess',
  RTE_ACORD_DOWNLOADED_ERROR = 'RteSidebar.AcordDownloadedError',
  RTE_CROSS_SELL_SUGGESTION_DISPLAYED = 'RteSidebar.CrossSellSuggestionDisplayed',
  RTE_CROSS_SELL_SUGGESTION_DISMISSED = 'RteSidebar.CrossSellSuggestionDismissed',
  RTE_CROSS_SELL_SUGGESTION_CLICKED = 'RteSidebar.CrossSellSuggestionClicked',
  RTE_CROSS_SELL_SUGGESTION_ADDED = 'RteSidebar.CrossSellSuggestionAdded',
  RTE_CROSS_SELL_SUGGESTION_FAILED_TO_ADD = 'RteSidebar.CrossSellSuggestionFailedToAdd',
  RTE_CROSS_SELL_SUGGESTION_FORWARD_CLICK = 'RteSidebar.CrossSellSuggestionForwardClick',
  RTE_CROSS_SELL_SUGGESTION_BACKWARD_CLICK = 'RteSidebar.CrossSellSuggestionBackwardClick',
  RTE_CROSS_SELL_SUGGESTION_MEATBALL_CLICK = 'RteSidebar.CrossSellSuggestionMeatballClick',
  RTE_GO_TO_QUOTE = 'RteSidebar.GoToQuote',
  RTE_GO_TO_QUOTE_FROM_CARRIER = 'RteSidebar.GoToQuoteFromCarrier',
  RTE_NAVIGATION_COMPLETE_CARRIER_QUESTION_SET = 'RteSidebar.NavigationCompleteCarrierQuestionSet',

  // Summary Panel
  SUMMARY_PANEL_TOGGLE_OPEN = 'SummaryPanel.ToggleOpenState',

  // Additional Coverage Options
  ADDITIONAL_COVERAGE_SHOW_COVERAGES = 'AdditionalCoverage.ShowCoverages',
  ADDITIONAL_COVERAGE_HIDE_COVERAGES = 'AdditionalCoverage.HideCoverages',
  ADDITIONAL_COVERAGE_ADD_COVERAGE = 'AdditionalCoverage.AddCoverage',
  ADDITIONAL_COVERAGE_DIALOG_CANCEL = 'AdditionalCoverage.Dialog.Cancel',
  ADDITIONAL_COVERAGE_DIALOG_CONTINUE = 'AdditionalCoverage.Dialog.Continue',
  ADDITIONAL_COVERAGE_AVAILABLE_PRODUCTS = 'AdditionalCoverage.AvailableProducts',
  ADDITIONAL_COVERAGE_PRODUCTS_SHOWN = 'AdditionalCoverage.ProductsShown',

  // Carrier Appointments
  CARRIER_APPOINTMENT_CARRIER_TOGGLED = 'CarrierAppointment.CarrierToggled',
  CARRIER_APPOINTMENT_CARRIER_STATUS_CHANGE_REQUESTED = 'CarrierAppointment.CarrierAppointmentCarrierStatusChangeRequested',
  CARRIER_APPOINTMENT_CARRIER_STATUS_CHANGED = 'CarrierAppointment.CarrierAppointmentCarrierStatusChanged',
  CARRIER_APPOINTMENT_CARRIER_STATUS_CHANGE_FAILED = 'CarrierAppointment.CarrierAppointmentCarrierStatusChangeFailed',
  CARRIER_APPOINTMENT_DIALOG_LOGO_ERROR = 'CarrierAppointment.DirectAppointmentDialogLogoError',
  CARRIER_APPOINTMENT_DIALOG_FAILED_TO_SHOW = 'CarrierAppointment.DialogFailedToShow',

  // Guards
  PERMISSIONS_DENIED = 'PermissionsGuard.UserRedirectedAndShownSnackbar',
  COMPLIANCE_FORM_DENIED = 'ComplianceFormGuard.UserRedirectedAndShownSnackbar',

  // Quote errors
  QUOTE_SOFT_FAIL = 'AppForm.Quote.SoftFail',
  QUOTE_UPDATE_CREDENTIALS_OPENED = 'AppForm.Quote.Unauthorized.UpdateCredentials.Opened',
  QUOTE_UPDATE_CREDENTIALS_SUBMITTED = 'AppForm.Quote.Unauthorized.UpdateCredentials.Submitted',
  QUOTE_ERROR_QUESTION_LINK_CLICKED = 'AppForm.Quote.ErrorQuestionLinkClicked',

  // Quotes
  REACHED_QUOTE_PRESENTATION = 'QuotePresentation.Shown',
  QUOTE_PRESENTATION_QUOTES_LOADED = 'QuotePresentation.QuotesLoaded',
  QUOTE_PRESENTATION_QUOTE_VIEWED = 'QuotePresentation.QuoteViewed',
  QUOTE_PRESENTATION_QUOTE_CTA = 'QuotePresentation.QuoteCTA',
  QUOTE_PRESENTATION_QUOTE_CTA_ERROR = 'QuotePresentation.QuoteCTAError',
  QUOTE_PRESENTATION_OPT_IN_CTA = 'QuotePresentation.OptInCTA',
  QUOTE_PRESENTATION_OPT_IN_SUCCESS = 'QuotePresentation.OptInSuccess',
  QUOTE_PRESENTATION_OPT_IN_ERROR = 'QuotePresentation.OptInError',
  QUOTE_PRESENTATION_GO_TO_INCOMPLETE_CARRIER_QUESTION_SET = 'QuotePresentation.GoToIncompleteCarrierQuestionSet',

  // Quotes - custom errors
  QUOTE_CUSTOM_ERROR_VIEWED = 'AppForm.Quote.CustomErrorViewed',

  // Exchange
  EXCHANGE_STATUS_UPDATED = 'Exchange.StatusUpdated',
  EXCHANGE_INFORMATION = 'Exchange.Information',
  EXCHANGE_TEAM_UPDATED = 'Exchange.TeamUpdated',
  EXCHANGE_LICENSED_STATES_UPDATED = 'Exchange.Team.LicensedStatesUpdated',
  EXCHANGE_CAMPAIGN_UPDATED = 'Exchange.CampaignUpdated',

  // Quote Sidebar
  QUOTE_SIDEBAR_SEND_TO_EXCHANGE = 'QuoteSidebar.Quote.SendToExchange',
  QUOTE_SIDEBAR_ACORD_PDF_DOWNLOAD = 'QuoteSidebar.Quote.AcordPdfDownload',
  QUOTE_SIDEBAR_ACORD_PDF_DOWNLOAD_SUCCESS = 'QuoteSidebar.Quote.AcordPdfDownloadSuccess',
  QUOTE_SIDEBAR_ACORD_PDF_DOWNLOAD_ERROR = 'QuoteSidebar.Quote.AcordPdfDownloadError',

  // ACORD Modal
  ACORD_MODAL_SHOWN = 'AcordModal.Shown',
  ACORD_MODAL_CREATE_ACORD_SUCCESS = 'AcordModal.CreateAcordSuccess',
  ACORD_MODAL_CREATE_ACORD_ERROR = 'AcordModal.CreateAcordError',
  ACORD_MODAL_VIEW_ACORD = 'AcordModal.ViewAcord',
  ACORD_MODAL_DOWNLOAD_ACORD = 'AcordModal.DownloadAcord',

  // ACORD Upload
  ACORD_DIALOG_OPENED = 'ACORD.DialogOpened',
  ACORD_SUBMISSION_STARTED = 'ACORD.SubmissionStarted',
  ACORD_PDF_UPLOAD_SUCCESS = 'ACORD.PdfUploadSuccess',
  ACORD_PDF_UPLOAD_FAIL = 'ACORD.PdfUploadFail',
  ACORD_PDF_UPLOAD_REMOVED = 'ACORD.PdfUploadRemoved',
  ACORD_SUBMISSION_CANCELED = 'ACORD.SubmissionCanceled',
  ACORD_QUOTE_STARTED = 'ACORD.QuoteStarted',
  ACORD_QUOTE_START_FAIL = 'ACORD.QuoteStartFail',

  // Takeover modal
  TAKEOVER_MODAL_SHOWN = 'TakeoverModal.Shown',
  TAKEOVER_MODAL_CANCELED = 'TakeoverModal.Canceled',
  TAKEOVER_MODAL_PREVIEW = 'TakeoverModal.Preview',
  TAKEOVER_MODAL_ASSIGN_TO_ME = 'TakeoverModal.AssignToMe',

  // Reports
  REPORT_FILTER_DATE_CHANGED = 'Reports.Filters.Date.Changed',
  REPORT_FILTER_NAME_CHANGED = 'Reports.Filters.Name.Changed',
  REPORT_APPLICATION_REFERENCE_ID_CLICKED = 'Reports.Applications.ReferenceId.Clicked',
  REPORT_FILTER_CHANGED = 'Reports.Filters.Changed',

  // Reports - Quicksight
  REPORTS_QUICKSIGHT_INSTANTIATED = 'Reports.Quicksight.Instantiated',
  REPORTS_QUICKSIGHT_EMPTY_STATE_VIEWED = 'Reports.Quicksight.EmptyStateViewed',
  REPORTS_QUICKSIGHT_COMMISSIONS_NAVIGATED = 'Reports.Quicksight.NavigatedFromCommissions',

  // Disposition
  DISPOSITION_MODAL_MARKED_DONE = 'DispositionModal.MarkedDone',
  DISPOSITION_MODAL_CANCELED = 'DispositionModal.Canceled',
  DISPOSITION_MODAL_CLOSED = 'DispositionModal.Closed',
  DISPOSITION_SUCCESS = 'Disposition.Success',

  // CQS
  CQS_ACTIVATE = 'CQS.Activated',
  CQS_DEACTIVATE = 'CQS.Deactivated',
  CQS_CONTACT_UPDATE = 'CQS.ContactUpdated',
  CQS_THEME_UPDATE = 'CQS.ThemeUpdated',
  CQS_BUSINESS_HOURS_UPDATE = 'CQS.BusinessHoursUpdated',

  // Appetite Search
  APPETITE_SEARCH_BUSINESS_CLASS_DIALOG_OPENED = 'AppetiteSearch.BusinessClassDialog.Opened',
  APPETITE_SEARCH_BUSINESS_CLASS_DIALOG_DISMISSED = 'AppetiteSearch.BusinessClassDialog.Dismissed',
  APPETITE_SEARCH_BUSINESS_CLASS_DIALOG_SAVED = 'AppetiteSearch.BusinessClassDialog.Saved',
  APPETITE_SEARCH_COVERAGE_DIALOG_OPENED = 'AppetiteSearch.CoverageDialog.Opened',
  APPETITE_SEARCH_COVERAGE_DIALOG_DISMISSED = 'AppetiteSearch.CoverageDialog.Dismissed',
  APPETITE_SEARCH_COVERAGE_DIALOG_SAVED = 'AppetiteSearch.CoverageDialog.Saved',
  APPETITE_SEARCH_STATE_DIALOG_OPENED = 'AppetiteSearch.StateDialog.Opened',
  APPETITE_SEARCH_STATE_DIALOG_DISMISSED = 'AppetiteSearch.StateDialog.Dismissed',
  APPETITE_SEARCH_STATE_DIALOG_SAVED = 'AppetiteSearch.StateDialog.Saved',
  APPETITE_SEARCH_SUBMITTED = 'AppetiteSearch.SearchSubmitted',
  APPETITE_SEARCH_RESULTS_SUCCESS = 'AppetiteSearch.SearchResultsSuccess',
  APPETITE_SEARCH_RESULTS_ERROR = 'AppetiteSearch.SearchResultsError',
  APPETITE_SEARCH_PROCEED_SUBMITTED = 'AppetiteSearch.ProceedWithApplicationSubmitted',
  APPETITE_SEARCH_PROCEED_SUCCESS = 'AppetiteSearch.ProceedWithApplicationSuccess',
  APPETITE_SEARCH_PROCEED_ERROR = 'AppetiteSearch.ProceedWithApplicationError',
  APPETITE_SEARCH_GO_TO_CARRIER_WEBSITE = 'AppetiteSearch.GoToCarrierWebsite',
  APPETITE_SEARCH_CONNECT_TO_CARRIER = 'AppetiteSearch.ConnectToCarrier',

  // RTE v2
  REAL_TIME_ELIGIBILITY_PANEL_V2_LOADED = 'RealTimeEligibilityPanelV2.Loaded',

  // Dynamic Limits
  DYNAMIC_LIMITS_OPENED = 'DynamicLimits.Opened',
  DYNAMIC_LIMITS_CANCELED = 'DynamicLimits.Canceled',
  DYNAMIC_LIMITS_CONFIRMED = 'DynamicLimits.Confirmed',

  // Recommended Carriers
  RECOMMENDED_CARRIERS_HOW_TO_USE = 'Recommended_Carriers.HowToUse',

  // Component usage tracking
  COMPONENT_TRACKING_INCREMENT = 'ComponentTracking.Increment',

  // Google Address Validation
  ADDRESS_VALIDATION_VERDICT = 'AddressValidation.Verdict',
  ADDRESS_VALIDATION_VALID_ON_FIRST_ATTEMPT = 'AddressValidation.ValidatedAddressOnFirstAttempt',
  ADDRESS_VALIDATION_CTA_CLICKED = 'AddressValidation.CTAClicked',
  ADDRESS_VALIDATION_CLOSE_CLICKED = 'AddressValidation.CloseClicked',
  ADDRESS_VALIDATION_DIALOG_OPENED = 'AddressValidation.DialogOpened',
  ADDRESS_VALIDATION_ERROR = 'AddressValidation.Error',

  //Application document upload
  DOCUMENT_UPLOAD_START = 'DocumentUpload.Start',
  DOCUMENT_UPLOAD_CANCEL = 'DocumentUpload.Cancel',
  DOCUMENT_UPLOAD_DOCUMENT_SELECTED = 'DocumentUpload.DocumentSelected',
  DOCUMENT_UPLOAD_DOCUMENT_UPLOAD_FAIL = 'DocumentUpload.DocumentUploadFail',
  DOCUMENT_UPLOAD_START_APPLICATION = 'DocumentUpload.StartApplication',
  DOCUMENT_UPLOAD_START_APPLICATION_SUCCESS = 'DocumentUpload.StartApplicationSuccess',
  DOCUMENT_UPLOAD_START_APPLICATION_FAIL = 'DocumentUpload.StartApplicationFail',
}

type MixpanelEventPropertyBasicType = string | number | boolean | null;
type MixpanelEventPropertyType =
  | MixpanelEventPropertyBasicType
  | Array<MixpanelEventPropertyBasicType>;
export interface MixpanelEventProperties {
  [key: string]: MixpanelEventPropertyType;
}

export interface MixpanelUserProfileProperties {
  $email?: string;
  $first_name?: string;
  $last_name?: string;
  tenant_id?: string;
  tenant_name?: string;
  completed_steps?: Array<string>;
  plan_sku?: string;
}

export interface IMixpanelService {
  init(): void;
  identify(userToken: string): void;
  track(eventId: MixpanelEventType, properties: MixpanelEventProperties): void;
  registerSuperProperties(properties: MixpanelEventProperties): void;
  setUserProfileProperties(properties: MixpanelUserProfileProperties): void;
  addNewSuperProperties(newProperties: MixpanelEventProperties): void;
  unsetSuperProperty(property: string): void;
  reset(): void;
  trackFormSubmissionEvents(
    formGroup: UntypedFormGroup,
    formName: string,
    mixpanelEvent: MixpanelEventType,
  ): void;
}

@Injectable({
  providedIn: 'root',
})
export class MixpanelService implements IMixpanelService {
  optOut = false;
  logToConsole = false;
  initialized = false;
  savedMixpanelProperties: MixpanelEventProperties;

  constructor(private localStorageService: LocalStorageService) {}

  /**
   * Initialize the mixpanel library. Should only be called once,
   * and must be called before any events are tracked
   *
   */
  init(): void {
    if (
      !!localStorage &&
      localStorage.getItem(localStorageKeys.disableMixpanel)
    ) {
      this.optOut = true;
    }

    if (
      environment.logMixpanelEvents ||
      (!!localStorage &&
        !!localStorage.getItem(localStorageKeys.logMixpanelEvents))
    ) {
      this.logToConsole = true;
    }

    if (!!window?.Cypress) {
      // we're running automated tests, so don't send MP events
      this.optOut = true;
    }

    if (!environment.sendMixpanelEvents) {
      this.optOut = true;
    }

    mixpanel.init(environment.mixpanelProjectKey, {
      cross_subdomain_cookie: false,
    });
    this.initialized = true;
  }

  /**
   * Called once we are able to uniquely identify the user
   *
   * @param userToken A string that uniquely identifies the user
   */
  identify(userToken: string): void {
    if (this.optOut) return;
    mixpanel.identify(userToken);
  }

  /**
   *
   * @param id Unique identifier for the action
   * @param properties (optional) an object of key-value pairs. The keys must
   *                   be strings, and the values must be a support data type
   *                   see https://help.mixpanel.com/hc/en-us/articles/115004547063
   *                   for more details
   */
  track(
    eventId: MixpanelEventType,
    properties: MixpanelEventProperties = {},
  ): void {
    if (this.logToConsole) {
      console.log(`MIXPANEL EVENT: ${eventId}: `, {
        ...this.savedMixpanelProperties,
        ...properties,
      });
    }

    if (this.optOut) return;

    if (
      !this.localStorageService.retrieve(localStorageKeys.impersonated) &&
      this.initialized
    ) {
      mixpanel.track(eventId, properties);
    }
  }

  /**
   * Registers a set of super properties with mixpanel. Super properties are properties
   * that are sent on every event.
   */
  registerSuperProperties(properties: MixpanelEventProperties): void {
    this.savedMixpanelProperties = properties;

    if (this.optOut) return;

    mixpanel.register(properties);
  }

  /**
   * Registers a new set of super properties with mixpanel. Super properties are properties
   * that are sent on every event.
   */
  addNewSuperProperties(newProperties: MixpanelEventProperties): void {
    // Note: don't need to consider OptOut here because we're not calling mixpanel directly

    const allProperties = { ...this.savedMixpanelProperties, ...newProperties };
    this.registerSuperProperties(allProperties);
  }

  /**
   * Unsets one super property with mixpanel. Super properties are properties
   * that are sent on every event.
   */
  unsetSuperProperty(property: string): void {
    delete this.savedMixpanelProperties[property];

    if (this.optOut) return;
    mixpanel.unregister(property);
  }

  /**
   * Sets user profile properties. User profiles represent a set of information about the user generating events
   * These properties are associated with the user, not events, and as such are allowed to change over time (for example, if the user
   * changes their last name, then a call to setUserProfileProperties with a new last name will update their profile
   */
  setUserProfileProperties(properties: MixpanelUserProfileProperties): void {
    if (this.optOut) return;

    mixpanel.people.set(properties);
  }

  /**
   * Reset all mixpanel information (including cookie-related things like the
   * distinct_id). This should only be called when a user explicitly indicates they
   * will not be using the system any longer (i.e. they explicitly log out)
   */
  reset(): void {
    mixpanel.reset();
  }

  /**
   * Helper function to track events related to form submission
   *
   * @param formGroup The form group to track submission information about
   * @param formName String name to use for the form to differentiate it
   * @param mixpanelEvent The event name to use when sending to mixpanel
   */
  trackFormSubmissionEvents(
    formGroup: UntypedFormGroup,
    formName: string,
    mixpanelEvent: MixpanelEventType,
  ): void {
    // Note: don't need to consider OptOut here because we're not calling mixpanel directly

    const formControls = formGroup.controls;
    const formErrors: { [formControlName: string]: string[] } = {};
    Object.keys(formControls).forEach((controlName) => {
      const formControl = formControls[controlName];
      // Null check
      if (formControl.errors) {
        formErrors[`${formName}.${controlName}`] = Object.keys(
          formControl.errors,
        );
      }
    });
    this.track(mixpanelEvent, { valid: formGroup.valid, ...formErrors });
  }
}
