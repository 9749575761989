import { createReducer, on } from '@ngrx/store';
import * as ComplianceFormActions from './compliance-form.actions';
import {
  complianceFormInitialState,
  ComplianceFormState,
} from './compliance-form.state';

export const ComplianceFormReducer = createReducer(
  complianceFormInitialState,
  on(
    ComplianceFormActions.GetComplianceForm,
    (state: ComplianceFormState): ComplianceFormState => ({
      ...state,
      isLoaded: false,
    }),
  ),
  on(
    ComplianceFormActions.GetComplianceFormSuccess,
    (state: ComplianceFormState, { complianceForm }): ComplianceFormState => ({
      ...state,
      isLoaded: true,
      complianceForm,
    }),
  ),
  on(
    ComplianceFormActions.GetComplianceFormError,
    (state: ComplianceFormState): ComplianceFormState => ({
      ...state,
      isLoaded: true,
    }),
  ),
  on(
    ComplianceFormActions.CheckComplianceFormSuccess,
    (state: ComplianceFormState): ComplianceFormState => ({
      ...state,
      isChecked: true,
    }),
  ),
);
