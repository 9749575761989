export interface FormControlExtras {
  name: string;
  min?: number;
  max?: number;
  minlength?: number;
  maxlength?: number;
  matDatepickerMin?: string;
  matDatepickerMax?: string;
  pattern?: string;
}

export type FormControlValidators =
  | 'min'
  | 'max'
  | 'required'
  | 'requiredTrue'
  | 'email'
  | 'minlength'
  | 'maxlength'
  | 'pattern'
  | 'nullValidator'
  | 'mask'
  | 'matDatepickerMin'
  | 'matDatepickerMax'
  | 'matDatepickerParse';

export const FormControlErrors = {
  required: 'is required',
  email: 'is invalid, please enter a valid email address',
  max: 'can be at most',
  min: 'must be at least',
  maxlength: 'has a max character length limit of',
  minlength: 'has a minimum character length of',
  pattern: 'is invalid',
  mask: 'is invalid',
  selected: 'requires a valid selection',
  matDatepickerMin: 'needs to be at least',
  matDatepickerMax: `shouldn't be after`,
  matDatepickerParse: 'is an invalid date',
};
