import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import {
  EmperorCardHeaderColor,
  EmperorDialogSize,
  EmperorUIButtonTypes,
} from '@boldpenguin/emperor-presentational';
import { AppointmentTypes } from '@boldpenguin/sdk';
import { Store, select } from '@ngrx/store';
import { Observable, filter, map, take, tap } from 'rxjs';
import {
  IEnrollmentAttribute,
  IEnrollmentAttributes,
  IEnrollment,
} from 'src/app/core/models/enrollments.model';
import { CarrierMessageService } from 'src/app/core/services/carrier-message-service/carrier-message.service';
import { MixpanelEventType } from 'src/app/core/services/mixpanel-service/mixpanel.service';
import {
  CancelEnrollmentAttempt,
  NavigateToComplianceFormFromAppointmentsDialog,
  UpdateCarrierAttributes,
  UpdateEnrollmentAction,
  UpdateEnrollmentAppointmentAction,
  UpdateShowEnrollmentAppointmentDialog,
} from 'src/app/core/store/enrollments/enrollments.actions';
import {
  selectEnrollmentById,
  selectEnrollmentServerErrorsById,
} from 'src/app/core/store/enrollments/enrollments.selectors';
import {
  DirectAppointmentButtonOverrides,
  DirectAppointmentDialogOverridesMap,
} from 'src/app/features/settings/components/carrier-appointment/appointment-dialog/appointment-dialog-overrides.model';
import {
  AppointmentDialogResolution,
  AppointmentOptionsToShow,
  SubmitButtonText,
} from '../../models/appointment-dialog.model';
import { CarrierMessage } from '../../models/carrier-messages';
import { IFormControlMetadata } from '../../models/forms.model';
import {
  buildDirectAppointmentForm,
  toHumanReadable,
} from '../../utilities/direct-appointment-utilities';

@Component({
  selector: 'app-carrier-appointment-dialog',
  templateUrl: './carrier-appointment-dialog.component.html',
  styleUrls: ['./carrier-appointment-dialog.component.scss'],
})
export class CarrierAppointmentDialogComponent implements OnInit {
  @Input() enrollmentId: string;
  @Input() isFirstTimeWizard: boolean;
  @Input() isComplianceFormCompleted?: boolean;
  @Input() appointmentOptionsToShow?: AppointmentOptionsToShow;
  @Input() incompleteQuestionGroups?: string[];
  @Output() cancel: EventEmitter<AppointmentDialogResolution> =
    new EventEmitter();
  enrollment$: Observable<IEnrollment | undefined>;
  serverErrorMessages$: Observable<{
    errors: string[] | undefined;
    hasErrors: boolean;
  }>;
  hasServerErrorMessages$: Observable<boolean>;
  appointmentOptions = AppointmentOptionsToShow;
  form = new UntypedFormGroup({});
  formControlMetadata: IFormControlMetadata[] = [];
  appointmentType = new FormControl<AppointmentTypes>(AppointmentTypes.Direct, {
    nonNullable: true,
  });
  directOverrides = DirectAppointmentDialogOverridesMap;
  logErrorEvent: MixpanelEventType;
  carrierAlerts$: Observable<CarrierMessage[]>;

  readonly EmperorUIButtonTypes = EmperorUIButtonTypes;
  readonly EmperorDialogSize = EmperorDialogSize;
  readonly EmperorCardHeaderColor = EmperorCardHeaderColor;
  readonly appointmentTypeChoices = [
    {
      id: AppointmentTypes.Direct,
      value: AppointmentTypes.Direct,
      label: 'Direct appointment',
    },
    {
      id: AppointmentTypes.Sub,
      value: AppointmentTypes.Sub,
      label: 'Request through Bold Penguin',
    },
  ];

  constructor(
    private store: Store,
    private carrierMessageService: CarrierMessageService,
  ) {}

  ngOnInit(): void {
    this.logErrorEvent = this.isFirstTimeWizard
      ? MixpanelEventType.WELCOME_DIRECT_APPOINTMENT_DIALOG_LOGO_ERROR
      : MixpanelEventType.CARRIER_APPOINTMENT_DIALOG_LOGO_ERROR;

    this.setUpServerErrors();

    this.enrollment$ = this.store.pipe(
      select(selectEnrollmentById(this.enrollmentId)),
      take(1),
      filter(Boolean),
      tap((enrollment: IEnrollment) => {
        if (this.appointmentOptionsToShow !== AppointmentOptionsToShow.Sub) {
          this.form = buildDirectAppointmentForm(enrollment);
          this.formControlMetadata = this.getFormControlMetadata(
            enrollment.enrollment_attributes,
          );
        } else {
          this.appointmentType.setValue(AppointmentTypes.Sub);
        }
      }),
    );

    this.carrierAlerts$ = this.enrollment$.pipe(
      filter(Boolean),
      map((enrollment) =>
        this.carrierMessageService.getSubAppointmentMessagesForCarrier(
          enrollment.carrier_subdomain,
        ),
      ),
    );
  }

  getSubmitText(enrollment: IEnrollment): string {
    if (this.isFirstTimeWizard) {
      return SubmitButtonText.FirstTimeWizard;
    } else if (this.isDirectAppointment()) {
      return (
        this.getDirectOverrideButtonValue(
          DirectAppointmentButtonOverrides.SubmitText,
          enrollment,
        ) ?? SubmitButtonText.DirectAppointment
      );
    }
    return SubmitButtonText.RequestAppointment;
  }

  isDirectAppointment(): boolean {
    return this.appointmentType.value === AppointmentTypes.Direct;
  }

  isSubmitDisabled(enrollment): boolean {
    if (!this.isDirectAppointment()) {
      return this.appointmentOptionsToShow === AppointmentOptionsToShow.Direct;
    }
    return (
      this.getDirectOverrideButtonValue(
        DirectAppointmentButtonOverrides.DisableSubmit,
        enrollment,
      ) ?? this.form.invalid
    );
  }

  onSubmitClick(enrollment: IEnrollment): void {
    if (
      this.appointmentType.value === AppointmentTypes.Sub &&
      this.appointmentOptionsToShow !== AppointmentOptionsToShow.Direct
    ) {
      this.requestSubAppointment(enrollment);
    } else if (
      this.appointmentType.value === AppointmentTypes.Direct &&
      !this.isSubmitDisabled(enrollment)
    ) {
      if (this.isFirstTimeWizard) {
        this.updateCarrierAttributes(enrollment);
      } else {
        this.updateEnrollment(enrollment);
      }
    }
  }

  handleSubmitSubAppointment(enrollment: IEnrollment) {
    this.submitSubAppointment(enrollment);
  }

  handleCancelDialog(): void {
    this.cancel.emit();
    this.store.dispatch(
      UpdateShowEnrollmentAppointmentDialog({ showDialog: false }),
    );
  }

  handleCancelComplianceFormDialog(enrollment: IEnrollment) {
    this.store.dispatch(CancelEnrollmentAttempt({ enrollment }));
  }

  trackMessages(i: number, carrierMessage: CarrierMessage): string {
    return carrierMessage.message;
  }

  private setUpServerErrors() {
    this.serverErrorMessages$ = this.store.pipe(
      select(selectEnrollmentServerErrorsById(this.enrollmentId)),
    );
  }

  private updateEnrollment(enrollment: IEnrollment): void {
    this.store.dispatch(
      UpdateEnrollmentAction({
        enrollment: {
          ...enrollment,
          appointment_type: AppointmentTypes.Direct,
          carrier_attributes: this.form.value,
        },
        activeStatus: true,
      }),
    );
  }

  private updateCarrierAttributes(enrollment: IEnrollment): void {
    this.store.dispatch(
      UpdateCarrierAttributes({
        enrollment: {
          ...enrollment,
          carrier_attributes: this.form.value,
        },
      }),
    );
  }

  private requestSubAppointment(enrollment: IEnrollment) {
    if (
      !this.isFirstTimeWizard &&
      (!this.isComplianceFormCompleted ||
        this.incompleteQuestionGroups?.includes(enrollment.carrier_subdomain))
    ) {
      this.store.dispatch(
        NavigateToComplianceFormFromAppointmentsDialog({
          enrollmentId: enrollment.id,
        }),
      );
    } else {
      this.submitSubAppointment({
        ...enrollment,
        appointment_type: AppointmentTypes.Sub,
      });
    }
  }

  private submitSubAppointment(enrollment: IEnrollment) {
    if (enrollment.allow_instant_enrollment) {
      this.store.dispatch(
        UpdateEnrollmentAction({
          enrollment: { ...enrollment, appointment_type: AppointmentTypes.Sub },
          activeStatus: true,
        }),
      );
    } else {
      this.store.dispatch(
        UpdateEnrollmentAppointmentAction({
          enrollment: { ...enrollment, appointment_type: AppointmentTypes.Sub },
          activeStatus: true,
        }),
      );
    }
  }

  private getFormControlMetadata(
    enrollmentAttributes: IEnrollmentAttributes,
  ): IFormControlMetadata[] {
    return Object.keys(enrollmentAttributes).map((key) => ({
      formControlName: key,
      label: toHumanReadable(key),
      hint: enrollmentAttributes[key].help_text,
      errorText: enrollmentAttributes[key].error_text,
      inputType: this.isPasswordInput(key, enrollmentAttributes[key])
        ? 'password'
        : 'text',
    }));
  }

  private isPasswordInput(
    key: string,
    enrollmentAttributes: IEnrollmentAttribute,
  ): boolean {
    return Object.values(enrollmentAttributes)
      .map(String)
      .concat(key)
      .some((value: string) => value.toLowerCase().includes('password'));
  }

  private getDirectOverrideButtonValue(
    buttonType: DirectAppointmentButtonOverrides,
    enrollment: IEnrollment,
  ): any | null {
    return (
      this.directOverrides[enrollment.carrier_subdomain]?.dialogActionButtons[
        buttonType
      ] ?? null
    );
  }
}
