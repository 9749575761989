import { ICarrier } from '@boldpenguin/sdk';
import { createAction, props } from '@ngrx/store';
import { ISelfServiceCallbackParam } from 'src/app/core/services/enrollments-service/enrollments.service';
import { IEnrollment } from '../../models/enrollments.model';

export const SelfServiceRegistrationCallbackAction = createAction(
  '[Core] - Hit Enrollment Callback',
  props<{ selfServiceCallback: ISelfServiceCallbackParam }>(),
);

export const SelfServiceRegistrationCallbackSuccessAction = createAction(
  '[Core] - Hit Enrollment Callback Success',
  props<{ enrollment: IEnrollment; carrier: ICarrier }>(),
);

export const SelfServiceRegistrationCallbackFailedAction = createAction(
  '[Core] - Hit Enrollment Callback Fail',
  props<{ error: any }>(),
);

export const RegisterSelfServiceEnrollmentAction = createAction(
  '[Core] - Register Self Service Enrollment',
  props<{ enrollment: IEnrollment }>(),
);

export const RegisterSelfServiceEnrollmentRedirect = createAction(
  '[Core] - Register Self Service Enrollment Redirect',
  props<{ url: string }>(),
);

export const RegisterSelfServiceEnrollmentFail = createAction(
  '[Core] - Register Self Service Enrollment Failed',
  props<{ enrollment: IEnrollment; error: any }>(),
);
