import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getFormId } from '@boldpenguin/sdk';
import { environment } from '@environments/environment';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { selectTenantId } from 'src/app/core/services/bp-sdk-store-service/bp-sdk-store.selectors';
import { BpSdkStoreService } from 'src/app/core/services/bp-sdk-store-service/bp-sdk-store.service';
import {
  MixpanelEventType,
  MixpanelService,
} from 'src/app/core/services/mixpanel-service/mixpanel.service';

export interface ISendToExchangeResult {
  id: string;
  redirect_url: string | null;
  reference_id: string;
}

@Injectable()
export class ExchangeTransferService {
  constructor(
    private bpSdkStoreService: BpSdkStoreService,
    private mixpanelService: MixpanelService,
    private http: HttpClient,
  ) {}

  /**
   * Transfers an application to the Bold Penguin Exchange
   *
   * @returns transfer result
   */
  transferToExchange(): Observable<ISendToExchangeResult> {
    this.mixpanelService.track(MixpanelEventType.QUOTE_SELL, {
      routeChange: 'quotes',
    });

    // The current application id
    const formId = getFormId(this.bpSdkStoreService.getCurrentState());

    // Using an null tenant_id will clone to the BP tenant
    const postData = {
      application_form: {
        tenant_id: null,
      },
    };

    return this.bpSdkStoreService.state$.pipe(
      select(selectTenantId),
      switchMap((tenantId) => {
        const url = `${environment.apiBaseUri}/tenants/${tenantId}/application_forms/${formId}/clones`;
        return this.http.post<ISendToExchangeResult>(url, postData);
      }),
    );
  }
}
