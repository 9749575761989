import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  template: '',
})
export abstract class BaseMenuComponent implements AfterViewInit, OnDestroy {
  trigger: MatMenuTrigger;
  protected _menuExpanded = false;
  private _subscriptions: Subscription[] = [];
  constructor() {}

  ngAfterViewInit() {
    this._subscriptions.push(
      this.trigger.menuOpened.subscribe(() => {
        this._menuExpanded = true;
      }),
    );

    this._subscriptions.push(
      this.trigger.menuClosed.subscribe(() => {
        this._menuExpanded = false;
      }),
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
