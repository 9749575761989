import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import {
  agencyNameId,
  agencyNpnId,
  agencyStatesLicensedId,
  businessEntityTypeId,
  carrierOneId,
  carrierOneLossId,
  carrierThreeId,
  carrierThreeLossId,
  carrierTwoId,
  carrierTwoLossId,
  hasAgencyLicenseId,
  principalNpnId,
  retentionRatioId,
  WestfieldComplianceQuestionKeys,
} from 'src/app/shared/models/shared-compliance-form-questions';
import { isMainComplianceFormComplete } from 'src/app/shared/utilities/compliance-form-utilities';
import {
  ComplianceFormQuestionGroup,
  CompliancePayload,
  ComplianceResponse,
} from '../../models/compliance-form.model';

@Injectable({
  providedIn: 'root',
})
export class ComplianceFormService {
  url = `${environment.apiAuthUri}/api/terminal/compliance_form`;

  constructor(private http: HttpClient) {}

  getComplianceQuestionAnswers(): Observable<ComplianceResponse> {
    return this.http.get<ComplianceResponse>(this.url);
  }

  patchComplianceQuestionAnswers(
    data: CompliancePayload,
  ): Observable<ComplianceResponse> {
    return this.http.patch<ComplianceResponse>(this.url, {
      data,
    });
  }

  resetComplianceQuestionAnswers(): Observable<ComplianceResponse> {
    return this.patchComplianceQuestionAnswers(
      this.resetComplianceFormPayload(),
    );
  }

  updateCompletedQuestionGroups(
    completedCarrierComplianceQuestions: Array<ComplianceFormQuestionGroup> = [],
  ): Observable<ComplianceResponse> {
    const completedQuestionGroups = completedCarrierComplianceQuestions;

    if (!isMainComplianceFormComplete(completedQuestionGroups)) {
      completedQuestionGroups.push(ComplianceFormQuestionGroup.MAIN);
    }

    return this.http.patch<ComplianceResponse>(this.url, {
      completed_question_groups: completedQuestionGroups,
    });
  }

  resetCompletedQuestionGroups(): Observable<ComplianceResponse> {
    return this.http.patch<ComplianceResponse>(this.url, {
      completed_question_groups: [],
    });
  }

  private resetComplianceFormPayload(): CompliancePayload {
    return {
      // Agency info
      annual_small_business_premium: '',
      lines_of_business: [],
      [retentionRatioId]: '',
      [principalNpnId]: '',
      principal_agent_name: '',
      principal_states_licensed: '',
      [hasAgencyLicenseId]: true,
      [agencyNameId]: '',
      [agencyNpnId]: '',
      [agencyStatesLicensedId]: [],
      [carrierOneId]: '',
      [carrierOneLossId]: '',
      [carrierTwoId]: '',
      [carrierTwoLossId]: '',
      [carrierThreeId]: '',
      [carrierThreeLossId]: '',
      // E&O
      errors_and_omissions: '',
      errors_and_omissions_document: null,
      errors_and_omissions_document_error: false,
      // Acknowledgement
      acknowledgement_checked: false,
      // Payments
      [businessEntityTypeId]: '',
      llc_type: '',
      tax_id_type: '',
      tax_id: '',
      w9_checked: false,
      has_confirmed_sub_appointments: false,
      queued_sub_appointment_enrollments: [],
      completed_question_groups: [],
      // Westfield
      [WestfieldComplianceQuestionKeys.PriorAppointmentRequest]: false,
      [WestfieldComplianceQuestionKeys.MinimumOneYearExperience]: false,
      [WestfieldComplianceQuestionKeys.PhysicalLocation]: false,
      [WestfieldComplianceQuestionKeys.OnboardingTraining]: false,
    };
  }
}
