<emperor-dialog
  [uiDialogSize]="uiDialogSize"
  class="naics-search-dialog"
  (close)="close()"
>
  <emperor-dialog-header [title]="title"></emperor-dialog-header>

  <emperor-text-input
    appAutoFocusEmp
    [formControl]="filter"
    [label]="searchLabel"
    [iconPrefix]="searchIcon"
  >
    <emperor-icon
      suffix
      aria-label="Clear"
      class="emperor-ui-clear-icon"
      name="remove_circle_2"
      [class.disabled]="!filter.value"
      (click)="clearSearch()"
    ></emperor-icon>
    ></emperor-text-input
  >
  <emperor-card-content [scrollable]="true" class="card-content">
    <div class="no-results" *ngIf="noResults; else showResults">
      No results found for "{{ filter.value }}"
    </div>
  </emperor-card-content>
</emperor-dialog>

<!-- TEMPLATES -->
<ng-template #showResults>
  <div class="results" *ngIf="results && results.length; else searchMessage">
    <ng-container *ngFor="let group of results">
      <div class="result-header">
        <div class="heading subheader" *ngIf="group[0]">
          <span class="description">{{
            group[0].national_industry_description
          }}</span>
          <emperor-icon
            name="interface_information_1"
            aria-label="Button that toggles naics explanation dialog"
            tabindex="0"
            (click)="
              showExplanationDialog(
                group[0].national_industry_description,
                group[0].explanation
              )
            "
          >
          </emperor-icon>

          <span class="visual-divider"></span>
        </div>
      </div>

      <ul class="naics-list">
        <li
          tabindex="0"
          *ngFor="let result of group"
          (click)="save(result)"
          (keyup.enter)="save(result)"
          [class.selected]="result.selected"
        >
          <div *ngIf="result.selected" class="selected">
            {{ selectedTitle }}
          </div>
          <div class="description">{{ result.value }}</div>
          <div class="code">Code {{ result.alias }}</div>
        </li>
      </ul>
    </ng-container>
  </div>
  <ng-container *ngIf="loading">
    <emperor-spinner [diameter]="100" class="loading"></emperor-spinner>
  </ng-container>
</ng-template>

<ng-template #searchMessage>
  <div class="search-message" *ngIf="!loading">
    Search for a business type
  </div></ng-template
>
