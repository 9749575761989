import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EmperorUISelectFormChoice } from '@boldpenguin/emperor-form-fields';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ComplianceFormQuestionGroup } from 'src/app/core/models/compliance-form.model';
import {
  MixpanelEventType,
  MixpanelService,
} from 'src/app/core/services/mixpanel-service/mixpanel.service';
import { selectLogoPathForEnrollmentBySubdomain } from 'src/app/core/store/enrollments/enrollments.selectors';
import { WestfieldComplianceQuestionKeys } from 'src/app/shared/models/shared-compliance-form-questions';

@UntilDestroy()
@Component({
  selector: 'app-westfield-compliance-questions',
  templateUrl: './westfield-compliance-questions.component.html',
  styleUrls: ['./westfield-compliance-questions.component.scss'],
})
export class WestfieldComplianceQuestionsComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;

  carrierLogo$: Observable<string>;

  readonly westfieldComplianceChoices: EmperorUISelectFormChoice[] = [
    {
      id: 'true',
      value: true,
      label: 'Yes',
    },
    {
      id: 'false',
      value: false,
      label: 'No',
    },
  ];

  constructor(private store: Store, private mixpanelService: MixpanelService) {}

  get priorAppointmentRequestControl(): UntypedFormControl {
    return this.formGroup.get(
      WestfieldComplianceQuestionKeys.PriorAppointmentRequest,
    ) as UntypedFormControl;
  }

  get minimumOneYearExperienceControl(): UntypedFormControl {
    return this.formGroup.get(
      WestfieldComplianceQuestionKeys.MinimumOneYearExperience,
    ) as UntypedFormControl;
  }

  get onboardingTrainingControl(): UntypedFormControl {
    return this.formGroup.get(
      WestfieldComplianceQuestionKeys.OnboardingTraining,
    ) as UntypedFormControl;
  }

  get physicalLocationControl(): UntypedFormControl {
    return this.formGroup.get(
      WestfieldComplianceQuestionKeys.PhysicalLocation,
    ) as UntypedFormControl;
  }

  ngOnInit(): void {
    this.mixpanelService.track(
      MixpanelEventType.COMPLIANCE_WESTFIELD_QUESTIONS_LOADED,
    );
    this.carrierLogo$ = this.store.pipe(
      select(
        selectLogoPathForEnrollmentBySubdomain(
          ComplianceFormQuestionGroup.WESTFIELD,
        ),
      ),
    );
  }
}
