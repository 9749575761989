import { createAction, props } from '@ngrx/store';
import { IProductCarrierRanking } from '../../models/product-carrier-ranking';

export const LoadProductRankingsForTenant = createAction(
  '[Product Rankings] - Load Product Rankings',
);

export const LoadProductRankingsForTenantError = createAction(
  '[Product Rankings] - Load Product Rankings Error',
  props<{ error: Error }>(),
);

export const StoreProductRankingsForTenant = createAction(
  '[Product Rankings] - Store Product Rankings',
  props<{ rankings: IProductCarrierRanking[] }>(),
);

export const UpdateProductRankings = createAction(
  '[Product Rankings] - Update Product Rankings',
  props<{ rankings: IProductCarrierRanking[] }>(),
);

export const UpdateProductSuccess = createAction(
  '[Product Rankings] - Update Product Rankings Success',
);

export const UpdateProductRankingsError = createAction(
  '[Product Rankings] - Update Product Rankings Error',
  props<{ error: Error }>(),
);
