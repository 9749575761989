import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DashboardNavigationButtons } from 'src/app/core/models/application-list-nav-buttons.model';
import {
  FormFilterAppStates,
  FormFilters,
  NavButtonFilterMap,
} from '../../models/quote-dashboard-filters';
import { ApplicationFormSearchService } from '../../services/application-form-search/application-form-search.service';
import * as ApplicationCountActions from './application-count.actions';
import startOfMonth from 'date-fns/startOfMonth';
import formatISO from 'date-fns/formatISO';

@Injectable()
export class ApplicationCountEffects {
  /**
   * This effect listens for an explicit call to GetApplicationCounts as well as some other
   * actions that happen on the quotes dashboard that change application states around
   */
  getApplicationCounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationCountActions.GetApplicationCounts),
      map(() => ApplicationCountActions.GetApplicationCountsInternal()),
    ),
  );

  getApplicationCountsInternal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationCountActions.GetApplicationCountsInternal),
      switchMap(() => {
        const newFilters = NavButtonFilterMap[DashboardNavigationButtons.New];
        const openFilters = NavButtonFilterMap[DashboardNavigationButtons.Open];
        const searchFiltersNew = new FormFilters({ ...newFilters, q: null });
        const searchFiltersOpen = new FormFilters({
          ...openFilters,
          q: null,
        });
        const searchFiltersCompleted = new FormFilters({
          app_state: [
            FormFilterAppStates.Quoted,
            FormFilterAppStates.Completed,
          ],
          app_created_at_gt: formatISO(startOfMonth(new Date())),
          page: 1,
          q: null,
        });
        return combineLatest([
          this.applicationFormSearchService.searchAndFilterApplicationsForTotalCount(
            {
              ...searchFiltersNew,
              page: 1,
            },
          ),
          this.applicationFormSearchService.searchAndFilterApplicationsForTotalCount(
            {
              ...searchFiltersOpen,
              page: 1,
            },
          ),
          this.applicationFormSearchService.searchAndFilterApplicationsForTotalCount(
            searchFiltersCompleted,
          ),
        ]).pipe(
          map(([newFilterCount, openFilterCount, completedFilterCount]) =>
            ApplicationCountActions.GetApplicationCountsSuccess({
              counts: {
                in_progress: openFilterCount,
                new: newFilterCount,
                completed: completedFilterCount,
              },
            }),
          ),
          catchError((error) =>
            of(ApplicationCountActions.GetApplicationCountsError({ error })),
          ),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private applicationFormSearchService: ApplicationFormSearchService,
  ) {}
}
