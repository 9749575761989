export const localStorageKeys = {
  appetiteRecentStates: 'appetite-search-recent-states',
  disableLogRocket: '__terminal_disable_logRocket__',
  disableMixpanel: '__terminal_disable_mixpanel__',
  disablePlaidRedirect: '__PLAID_REDIRECT_DISABLED__',
  disableSdkCustomElements: '__BP_SDK_DISABLE_CUSTOM_ELEMENTS__',
  disableSentryModal: '__terminal_disable_sentry_modal__',
  impersonated: 'impersonated',
  linkTokenResponse: 'link_token_response',
  logMixpanelEvents: '__AGENT_TERMINAL_LOG_MIXPANEL_EVENTS_TO_CONSOLE__',
  naicsRecentChoices: 'naics-search-dialog-recent-choices',
  sdkDebugging: '__AGENT_TERMINAL_SDK_DEBUGGING__',
  settingsEnrollmentId: 'settings_enrollment_id',
  theme: 'terminal_theme',
} as const;

export const externalUrls = {
  ExchangeMarketingPage:
    'https://www.boldpenguin.com/core-pages/request-a-demo',
  JiraCreateBugPage:
    'https://boldpenguin.atlassian.net/servicedesk/customer/portal/7',
};
