import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IIconPrefix } from '@boldpenguin/emperor-form-fields';
import {
  EmperorCardHeaderColor,
  EmperorDialogSize,
} from '@boldpenguin/emperor-presentational';
import { IChoice, IGroupedChoice } from '@boldpenguin/sdk';
import { buildChoiceGroup } from '@boldpenguin/sdk';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LocalStorageService } from 'ngx-webstorage';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { BusinessTypeService } from 'src/app/core/services/business-type/business-type.service';
import { localStorageKeys } from '../../constants';
import { NaicsDialogChoice } from './../../models/naics-dialog-choice';
import { topChoices } from './top-naics-dialog-choices';

@UntilDestroy()
@Component({
  selector: 'app-naics-search-dialog',
  templateUrl: './naics-search-dialog.component.html',
  styleUrls: ['./naics-search-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NaicsSearchDialogComponent implements OnInit {
  @Input() selectedNaicsDescription: string | undefined;
  @Input() title: string;
  @Input() searchLabel: string;
  @Input() selectedTitle: string;
  @Output() closed = new EventEmitter<void>();
  @Output() saved = new EventEmitter<{ description: string; source: string }>();

  readonly uiDialogSize = EmperorDialogSize.Large;
  readonly EmperorCardHeaderColor = EmperorCardHeaderColor;
  readonly searchIcon: IIconPrefix = {
    showPrefix: true,
    icon: 'search_1',
  };
  groupedChoices: IGroupedChoice[] = [];
  recentChoices: NaicsDialogChoice[] = [];
  filter = new UntypedFormControl();
  noResults: boolean;
  loading = false;

  readonly topChoices = topChoices;

  private readonly maxRecentChoices = 3;

  constructor(
    private readonly businessTypeService: BusinessTypeService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.recentChoices =
      this.localStorageService.retrieve(localStorageKeys.naicsRecentChoices) ||
      [];

    // Watches the input for changes
    this.filter.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(500), untilDestroyed(this))
      .subscribe((filter: string) => this.search(filter));

    if (this.selectedNaicsDescription) {
      this.loading = true;
      this.filter.setValue(this.selectedNaicsDescription);
    }
  }

  search(filter: string): void {
    if (filter) {
      this.loading = true;
      this.groupedChoices = [];
      this.cdRef.detectChanges();
      this.businessTypeService
        .getBusinessTypes(filter)
        .pipe(take(1), untilDestroyed(this))
        .subscribe((searchResults: IChoice[]) => {
          this.loading = false;
          this.noResults = !searchResults.length;
          this.groupedChoices = this.calculateGroupedChoices(
            searchResults.slice(0, 7),
          );

          this.cdRef.detectChanges();
        });
    } else {
      this.clearSearch();
    }
  }

  close() {
    this.closed.emit();
  }

  save(result: IChoice | NaicsDialogChoice, source: string) {
    if (this.selectedNaicsDescription !== result.value) {
      this.saved.emit({ description: result.value, source });
      this.updateRecentChoices(result);
    }
  }

  clearSearch(): void {
    this.filter.setValue('');
    this.groupedChoices = [];
    this.noResults = false;
    this.loading = false;
    this.cdRef.detectChanges();
  }

  calculateGroupedChoices(choices: IChoice[] = []): IGroupedChoice[] {
    const sortedChoices: IChoice[] = [...choices].sort(
      (a, b) => (b.score || 0) - (a.score || 0),
    );

    return buildChoiceGroup(sortedChoices);
  }

  stopEventPropagation(event: Event): void {
    event.stopPropagation();
  }

  private updateRecentChoices({
    alias,
    value,
  }: IChoice | NaicsDialogChoice): void {
    if (this.recentChoices.find((choice) => choice.value === value)) {
      return;
    }

    if (this.recentChoices.length >= this.maxRecentChoices) {
      this.recentChoices.pop();
    }

    this.recentChoices.unshift({ alias, value });

    this.localStorageService.store(
      localStorageKeys.naicsRecentChoices,
      this.recentChoices,
    );
  }
}
