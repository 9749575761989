import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
  HeaderAlert,
  HeaderAlertButton,
} from 'src/app/core/services/header-service/header-alerts.service';

import { HeaderAlertButtonRole } from '../../services/header-service/header-alerts.service';
import * as HeaderAlertsActions from './header-alerts.actions';

@Injectable()
export class HeaderAlertsEffects {
  buttonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeaderAlertsActions.HeaderAlertButtonClick),
      map(
        ({
          alert,
          button,
        }: {
          alert: HeaderAlert;
          button: HeaderAlertButton;
        }) => {
          if (button.role === HeaderAlertButtonRole.CONFIRM) {
            return HeaderAlertsActions.ConfirmHeaderAlert({
              headerAlert: alert,
            });
          } else if (button.role === HeaderAlertButtonRole.CANCEL) {
            return HeaderAlertsActions.CancelHeaderAlert({
              headerAlert: alert,
            });
          }
          return HeaderAlertsActions.ConfirmHeaderAlert({ headerAlert: alert });
        },
      ),
    ),
  );

  constructor(private actions$: Actions) {}
}
