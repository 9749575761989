export enum Features {
  APPETITE_SEARCH = 'appetite-search',
  AUTH = 'auth',
  BILLING = 'billing',
  CONFIRM = 'confirm',
  COMMISSIONS = 'commissions',
  EXCHANGE = 'exchange',
  QUOTES = 'quotes',
  REPORTS = 'insights',
  SIGNUP = 'signup',
  SETTINGS = 'settings',
  WELCOME = 'welcome',
}
