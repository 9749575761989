import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PriceSkus } from 'src/app/shared/models/price-skus';
import { ICalendlyOptions } from '../models/calendly-options.model';
import {
  allSubscriptionPlans,
  BillingPeriod,
  IMonthlyRateWithDiscount,
} from '../models/subscription-plan-details.model';
import { INewIAUser } from '../services/registration-service/registration.service';
import { signupFeatureKey, SignupState } from './signup.reducer';

export const selectSignupState =
  createFeatureSelector<SignupState>(signupFeatureKey);

export const selectPreferredPartnershipLabel = createSelector(
  selectSignupState,
  (state) => state.preferredPartnershipLabel,
);

export const selectAgencyInfo = createSelector(
  selectSignupState,
  (state) => state.agencyInfo,
);

export const selectSignatureCheckboxLabel = createSelector(
  selectSignupState,
  (state) =>
    `I attest, acknowledge and agree that my checking of this box constitutes the legal equivalent of my manual signature and represent and warrant that I have the requisite power and authority to sign on behalf of and bind ${
      state.agencyInfo?.legalEntityName ?? 'the company'
    } to the terms of these agreements.`,
);

export const selectAgentInfo = createSelector(
  selectSignupState,
  (state) => state.agentInfo,
);

export const selectProgressBarPercent = createSelector(
  selectSignupState,
  (state) => state.progressBarPercent,
);

export const selectChosenPlan = createSelector(
  selectSignupState,
  (state) => state.plan,
);

export const selectPricePerAdditionalAgent = createSelector(
  selectSignupState,
  ({ promotionCode, plan }) => {
    const pricePerAdditionalAgent = plan?.pricePerAdditionalAgent;
    if (!pricePerAdditionalAgent) {
      return null;
    }

    const coupon = promotionCode?.coupon;
    let discount = 0;
    if (coupon?.percent_off) {
      discount = (coupon.percent_off / 100) * pricePerAdditionalAgent;
    }

    return pricePerAdditionalAgent > discount
      ? pricePerAdditionalAgent - discount
      : 0;
  },
);

export const selectAllPlansPricePerAdditionalAgent = createSelector(
  selectSignupState,
  ({ promotionCode }) => {
    const rate =
      promotionCode?.coupon?.amount_off ??
      promotionCode?.coupon?.percent_off ??
      0;
    const discount = (rate > 100 ? 100 : rate) / 100;

    const prices = new Map<PriceSkus, number>();
    allSubscriptionPlans.forEach((plan) =>
      prices.set(
        plan.priceSku,
        (plan.pricePerAdditionalAgent ?? 0) * (1 - discount),
      ),
    );

    return prices;
  },
);

export const selectSubtotalPrice = createSelector(
  selectSignupState,
  ({ plan }: SignupState) => plan?.pricePerPeriod,
);

export const selectAllPlansDiscountedMonthlyRates = createSelector(
  selectSignupState,
  ({ promotionCode }: SignupState): IMonthlyRateWithDiscount => {
    const rate =
      promotionCode?.coupon?.amount_off ??
      promotionCode?.coupon?.percent_off ??
      0;
    const discount = (rate > 100 ? 100 : rate) / 100;
    const ratesWithDiscounts = { isDiscounted: !!discount, rates: new Map() };
    allSubscriptionPlans.forEach((plan) =>
      ratesWithDiscounts.rates.set(
        plan.priceSku,
        (plan.terminalPricingData?.pricePerPeriod ?? 0) * (1 - discount),
      ),
    );
    return ratesWithDiscounts;
  },
);

export const selectDiscountAmount = createSelector(
  selectSignupState,
  selectSubtotalPrice,
  ({ promotionCode }: SignupState, subtotal) => {
    if (!subtotal) {
      return null;
    }

    let discount = 0;
    const coupon = promotionCode?.coupon;
    if (coupon?.amount_off) {
      discount = coupon.amount_off / 100;
    } else if (coupon?.percent_off) {
      discount = (coupon.percent_off / 100) * subtotal;
    }
    return subtotal > discount ? discount : subtotal;
  },
);

export const selectTotalPrice = createSelector(
  selectSubtotalPrice,
  selectDiscountAmount,
  (subtotal, discount) => {
    if (!subtotal || discount == null) {
      return null;
    }
    return subtotal > discount ? subtotal - discount : 0;
  },
);

export const selectMonthlyCost = createSelector(
  selectTotalPrice,
  selectChosenPlan,
  (total, plan) =>
    total && plan?.terminalPricingData?.billingPeriod === BillingPeriod.Annually
      ? total / 12
      : total,
);

export const selectPromotionCode = createSelector(
  selectSignupState,
  ({ promotionCode }: SignupState) => promotionCode,
);

export const selectPromotionCodeLoadingHappening = createSelector(
  selectSignupState,
  ({ promotionCodeLoadingHappening }: SignupState) =>
    promotionCodeLoadingHappening,
);

export const selectMessageToDisplay = createSelector(
  selectSignupState,
  (state) => state.messageDisplay,
);

export const selectLegalAgreements = createSelector(
  selectSignupState,
  (state) => state.legalAgreements,
);

export const selectSignupPlanSku = createSelector(
  selectSignupState,
  (signupState) =>
    signupState.planSku ||
    signupState.plan?.basePlanSku ||
    signupState.plan?.priceSku,
);

export const selectInfoForRegistration = createSelector(
  selectSignupState,
  selectSignupPlanSku,
  (signupState, planCode) => {
    //If the plan is a 'terminal' plan, then we are going to send
    //plan + price instead of just plan
    const priceCode = !!signupState.plan?.basePlanSku
      ? signupState.plan?.priceSku
      : undefined;
    const registrationInfo: INewIAUser = {
      user: {
        first_name: signupState.agentInfo?.firstName ?? '',
        last_name: signupState.agentInfo?.lastName ?? '',
        email: signupState.agentInfo?.email ?? '',
        phone: signupState.agencyInfo?.phone ?? '',
      },
      tenant_name: signupState.agencyInfo?.agencyName ?? '',
      plan: planCode,
      price: priceCode,
      accept_agreements: signupState.legalAgreements?.transform(),
      payment_id: signupState.payment?.id,
      promotion_code: signupState.promotionCode?.id,
      legal_entity_name: signupState.agencyInfo?.legalEntityName,
      street1: signupState.agencyInfo?.street1,
      street2: signupState.agencyInfo?.street2,
      city: signupState.agencyInfo?.city,
      state: signupState.agencyInfo?.state,
      zipcode: signupState.agencyInfo?.zipcode,
      salesforce_account_id: signupState.salesforce?.accountId,
      salesforce_opportunity_id: signupState.salesforce?.opportunityId,
    };

    return registrationInfo;
  },
);

export const selectCalendlyOptions = createSelector(
  selectSignupState,
  (signupState): ICalendlyOptions => {
    const prefill = {
      name: `${signupState.agentInfo?.firstName || ''} ${
        signupState.agentInfo?.lastName || ''
      }`.trim(),
      email: signupState.agentInfo?.email || '',
    };

    let utm;
    if (signupState.salesforce?.accountId) {
      utm = {
        salesforceUuid: signupState.salesforce.accountId,
      };
    }

    return {
      prefill,
      utm,
    };
  },
);
