import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileInputService {
  hasAcceptedFileType(file: File, fileType: string): boolean {
    let isAccepted = false;
    if (this.isWildCardFileType(fileType)) {
      isAccepted = true;
    } else if (this.isFileExtensionFormat(fileType)) {
      isAccepted = this.hasFileExtension(file, fileType);
    } else if (this.isMimeFileTypeFormat(fileType)) {
      isAccepted = this.hasMimeFileType(file, fileType);
    }

    return isAccepted;
  }

  isWildCardFileType(fileType: string): boolean {
    return fileType === '*';
  }

  /**
   * This check is a simple validation for a file extension .{extension}, but does not validate
   * whether it is a real file type
   */
  isFileExtensionFormat(fileType: string): boolean {
    const sanitizedType = fileType?.trim() ?? '';
    return sanitizedType.length > 1 && sanitizedType.startsWith('.');
  }

  hasFileExtension(file: File, extension: string): boolean {
    return file.name.toLowerCase().endsWith(extension.toLowerCase());
  }

  /**
   * This check is a simple validation for the MIME format {id}/{type}, but does not validate
   * against real types
   * MIME types are defined {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers | here}
   */
  isMimeFileTypeFormat(fileType: string): boolean {
    const parts = fileType?.split('/') ?? [];
    return parts.length === 2 && parts.every((part) => part.trim().length > 0);
  }

  hasMimeFileType(file: File, fileType: string): boolean {
    const fileTypeParts = fileType.split('/');
    const isWildCardType = fileTypeParts[1] === '*';

    return isWildCardType
      ? this.isWildCardMimeFileTypeMatch(file, fileType)
      : this.isExactMimeFileTypeMatch(file, fileType);
  }

  /**
   * @description
   * Creates a FileList from a list of Files. This is useful when you want to set the value
   * of an HTML File Input programmatically (i.e. if the files came from a drag and drop situation)
   * and were not directly added to the <input> element
   */
  /* istanbul ignore next - JSDOM has not implemented DataTransfer, and it cannot be easily mocked */
  convertFilesToFileList(files: File[]): FileList {
    const dataTransfer = new DataTransfer();
    files.forEach((file) => dataTransfer.items.add(file));

    return dataTransfer.files;
  }

  private isExactMimeFileTypeMatch(file: File, fileType: string): boolean {
    return file.type === fileType;
  }

  private isWildCardMimeFileTypeMatch(file: File, fileType: string): boolean {
    const allowedFileTypeParts = fileType.split('/');
    const fileTypeParts = file.type.split('/');

    return allowedFileTypeParts[0] === fileTypeParts[0];
  }
}
