import { Injectable } from '@angular/core';
import { selectUser, getViewStateFeatures, IUser } from '@boldpenguin/sdk';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import {
  FeatureType,
  PermissionModelForRouteAndMenu,
  ProductFeatureType,
} from 'src/app/permissions/permissions.model';
import { environment } from 'src/environments/environment';
import { BpSdkCoreService } from '../bp-sdk-core-service/bp-sdk-core.service';
import { BpSdkStoreService } from '../bp-sdk-store-service/bp-sdk-store.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService implements IPermissionsService {
  constructor(
    private bpSdkCoreService: BpSdkCoreService,
    private bpSdkStoreService: BpSdkStoreService,
    private store: Store,
  ) {}

  hasRoutePermission(data: IRouteData): Observable<boolean> {
    return this.bpSdkStoreService.sdkReadyState$.pipe(
      select(selectUser),
      filter((user) => !!user?.extra?.raw_info?.permissions),
      map((user: IUser) => this.userHasPermissions(user, data)),
      first(),
    );
  }

  hasRequiredSubscriptionStatus(
    isSubscriptionRequired: boolean,
  ): Observable<boolean> {
    return this.bpSdkStoreService.sdkReadyState$.pipe(
      select(selectUser),
      filter((user) => !!user?.extra?.raw_info?.sub_status),
      map(
        (user) =>
          !isSubscriptionRequired ||
          user?.extra?.raw_info?.sub_status === 'active',
      ),
      first(),
    );
  }

  hasFeatureEnabled(data: IRouteData): Observable<boolean> {
    if (data.featureEnabled) {
      return of(this.checkIfFeatureIsAllowedOnEnvironment(data.featureEnabled));
    } else if (data.productFeatureEnabled) {
      return this.bpSdkStoreService.state$.pipe(
        select(getViewStateFeatures),
        filter((features) => !!features.isLoaded),
        map((features) =>
          this.productFeatureIsEnabled(
            features.list as ProductFeatureType[],
            data.productFeatureEnabled,
          ),
        ),
        first(),
      );
    }
    return of(true);
  }

  hasFeatureDisabled(data: IRouteData): Observable<boolean> {
    if (data.productFeatureDisabled) {
      return this.bpSdkStoreService.state$.pipe(
        select(getViewStateFeatures),
        filter((features) => !!features.isLoaded),
        map((features) =>
          this.productFeatureIsDisabled(
            features.list as ProductFeatureType[],
            data.productFeatureDisabled,
          ),
        ),
        first(),
      );
    }
    return of(true);
  }

  featureMeetsStoreCondition(data: IRouteData): Promise<boolean> {
    return data.storePermissionCondition
      ? data.storePermissionCondition(this.store)
      : Promise.resolve(true);
  }

  private userHasPermissions(user: IUser, data: IRouteData): boolean {
    const { permissionModel, permissionAction } = data;
    const noPermissionRequired = !permissionModel;

    if (noPermissionRequired) {
      return noPermissionRequired;
    }

    const pm = permissionModel ?? [];
    const pa = permissionAction ?? [];

    const hasModelPerms: boolean[] = [];

    pm.forEach((model) => {
      let hasPermissionsForAllActions = true;

      pa.forEach((action) => {
        hasPermissionsForAllActions =
          hasPermissionsForAllActions &&
          this.bpSdkCoreService.hasPermission({
            user,
            model,
            action,
          });
      });

      hasModelPerms.push(hasPermissionsForAllActions);
    });

    return data.atLeastOneModel
      ? hasModelPerms.some(Boolean)
      : hasModelPerms.every(Boolean);
  }

  // The thinking here is that there will be some overlap between enabled features and
  // features needed to render a component.
  // You need to satisfy every feature needed to render a route
  // not every feature that is on your account
  private productFeatureIsEnabled(
    features: ProductFeatureType[],
    productFeatures: ProductFeatureType[] | undefined,
  ): boolean {
    return (
      !!productFeatures &&
      productFeatures.every((feature) => !!features.find((f) => f === feature))
    );
  }

  /**
   * Given a list `productFeatures` that should be disabled, confirm no disabled
   * product features are found in list `features` of enabled feature flags.
   */
  private productFeatureIsDisabled(
    features: ProductFeatureType[],
    productFeatures: ProductFeatureType[] | undefined,
  ): boolean {
    return (
      !!productFeatures &&
      !productFeatures.some((feature) => !!features.find((f) => f === feature))
    );
  }

  private checkIfFeatureIsAllowedOnEnvironment(
    features: FeatureType[],
  ): boolean {
    return features.every((feature) => environment[feature] === true);
  }
}

export interface IPermissionsService {
  hasRoutePermission(routeData: IRouteData): Observable<boolean>;
  hasFeatureDisabled(data: IRouteData): Observable<boolean>;
  hasFeatureEnabled(data: IRouteData): Observable<boolean>;
  featureMeetsStoreCondition(data: IRouteData): Promise<boolean>;
  hasRequiredSubscriptionStatus(subRequired: boolean): Observable<boolean>;
}

export interface IRouteData extends Partial<PermissionModelForRouteAndMenu> {
  text?: string;
}
