import { Action, createReducer, on } from '@ngrx/store';
import * as EnrollmentSelfServiceRegistrationActions from './enrollment-self-service-registration.actions';
import EnrollmentSelfServiceRegistrationState from './enrollment-self-service-registration.state';

export const enrollmentSelfServiceRegistrationStateKey =
  'enrollmentSelfServiceRegistration';

export const enrollmentSelfServiceRegistrationInitialState: EnrollmentSelfServiceRegistrationState =
  {
    selfServiceRegistrationFailed: false,
    callbackEnrollmentAndCarrier: undefined,
  };

const reducer = createReducer(
  enrollmentSelfServiceRegistrationInitialState,
  on(
    EnrollmentSelfServiceRegistrationActions.SelfServiceRegistrationCallbackSuccessAction,
    (
      state: EnrollmentSelfServiceRegistrationState,
      { carrier, enrollment },
    ): EnrollmentSelfServiceRegistrationState => ({
      ...state,
      selfServiceRegistrationFailed: false,
      callbackEnrollmentAndCarrier: { carrier, enrollment },
    }),
  ),
  on(
    EnrollmentSelfServiceRegistrationActions.SelfServiceRegistrationCallbackFailedAction,
    (
      state: EnrollmentSelfServiceRegistrationState,
    ): EnrollmentSelfServiceRegistrationState => ({
      ...state,
      selfServiceRegistrationFailed: true,
    }),
  ),
);

export const EnrollmentSelfServiceRegistrationReducer = (
  state: EnrollmentSelfServiceRegistrationState | undefined,
  action: Action,
) => reducer(state, action);
