import { Action, createReducer, on } from '@ngrx/store';
import * as RootActions from './root.actions';
import RootState, { initializeState } from './root.state';

export const initialState = initializeState();

const reducer = createReducer(
  initialState,
  on(
    RootActions.SetSubdomain,
    (state: RootState, payload): RootState => ({
      ...state,
      customSubdomain: payload.subdomain,
    }),
  ),
);

export const RootReducer = (state: RootState | undefined, action: Action) =>
  reducer(state, action);
