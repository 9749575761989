import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  EmperorCardHeaderColor,
  EmperorDialogSize,
  EmperorUIButtonTypes,
} from '@boldpenguin/emperor-presentational';
import { CoverageTypesUtils } from '@boldpenguin/emperor-services';
import { Store, select } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { selectEnrollmentProducts } from '../../../core/store/enrollments/enrollments.selectors';

interface CoverageEntry {
  name: string;
  icon: string;
  abbreviation: string;
}

@Component({
  selector: 'app-coverage-selector-dialog',
  templateUrl: './coverage-selector-dialog.component.html',
  styleUrls: ['./coverage-selector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverageSelectorDialogComponent implements OnInit {
  @Input() title: string;
  @Input() selectedCoverage: string;
  @Output() closed = new EventEmitter<void>();
  @Output() saved = new EventEmitter<string>();

  coverageDisplayList$: Observable<CoverageEntry[]>;

  readonly uiDialogSize = EmperorDialogSize.Large;
  readonly EmperorUIButtonTypes = EmperorUIButtonTypes;
  readonly EmperorCardHeaderColor = EmperorCardHeaderColor;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.coverageDisplayList$ = this.store.pipe(
      select(selectEnrollmentProducts),
      // eslint-disable-next-line @ngrx/avoid-mapping-selectors
      map((products: string[]) =>
        products.map((product: string) => {
          const abbreviation =
            CoverageTypesUtils.getCoverageAbbreviation(product);
          const icon = CoverageTypesUtils.getCoverageIcon(abbreviation);
          return {
            name: product,
            icon,
            abbreviation,
          };
        }),
      ),
    );
  }

  closeModal(): void {
    this.closed.emit();
  }

  save(coverageAbbreviation: string): void {
    this.saved.emit(coverageAbbreviation);
  }
}
