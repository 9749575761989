import { Injectable } from '@angular/core';
import { AnalyticsTrackingEventName as EmperorAnalyticsTrackingEventName } from '@boldpenguin/emperor-sdk-wrapper';
import {
  AnalyticsTrackingEventName as SdkAnalyticsTrackingEventName,
  IAnalyticsTrackEventDetail,
} from '@boldpenguin/sdk';
import {
  MixpanelEventProperties,
  MixpanelEventType,
  MixpanelService,
} from '../mixpanel-service/mixpanel.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private mixpanelEventMap = {
    [EmperorAnalyticsTrackingEventName.ModifyPrefilledValue]:
      MixpanelEventType.APPLICATION_FORM_PREFILLED_VALUE_UPDATED,
    [SdkAnalyticsTrackingEventName.QuestionSetSaved]:
      MixpanelEventType.QUESTION_SET_SAVED,
    [SdkAnalyticsTrackingEventName.AddressValidation_ValidatedOnFirstAttempt]:
      MixpanelEventType.ADDRESS_VALIDATION_VALID_ON_FIRST_ATTEMPT,
    [SdkAnalyticsTrackingEventName.AddressValidation_CTAClicked]:
      MixpanelEventType.ADDRESS_VALIDATION_CTA_CLICKED,
    [SdkAnalyticsTrackingEventName.AddressValidation_Verdict]:
      MixpanelEventType.ADDRESS_VALIDATION_VERDICT,
    [EmperorAnalyticsTrackingEventName.AddressValidation_CloseDialogClicked]:
      MixpanelEventType.ADDRESS_VALIDATION_CLOSE_CLICKED,
    [EmperorAnalyticsTrackingEventName.AddressValidation_DialogOpened]:
      MixpanelEventType.ADDRESS_VALIDATION_DIALOG_OPENED,
    [SdkAnalyticsTrackingEventName.AddressValidation_ValidationError]:
      MixpanelEventType.ADDRESS_VALIDATION_ERROR,
  };

  constructor(private mixpanelService: MixpanelService) {}

  /**
   * Handle the bpAnalyticsTrack event
   */
  handleBpAnalyticsTrackEvent(
    event: CustomEvent<IAnalyticsTrackEventDetail>,
  ): void {
    switch (event.detail?.eventName) {
      default:
        if (this.mixpanelEventMap[event.detail?.eventName]) {
          this.mixpanelService.track(
            this.mixpanelEventMap[event.detail?.eventName],
            {
              ...(event.detail?.eventPayload as MixpanelEventProperties),
            },
          );
        }
        break;
    }
  }

  handleSdkEvent(eventName: string, event: CustomEvent) {
    switch (eventName) {
      case 'saveAnswer':
        if ((event.detail?.new_value ?? null) !== null) {
          this.mixpanelService.track(
            MixpanelEventType.APPLICATION_FORM_ANSWER_SAVED,
            {
              answerId: event.detail?.answer?.id ?? 'unknown',
              applicationFormId:
                event.detail?.answer?.application_form_id ?? 'unknown',
              questionCode: event.detail?.answer?.question?.code ?? 'unknown',
            },
          );
        }
        break;
      default:
        break;
    }
  }
}
