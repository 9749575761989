import { HttpErrorResponse } from '@angular/common/http';
import { IExchangeStatus } from '../../models/exchange-status.model';

export default interface ExchangeStatusState {
  exchangeStatus: IExchangeStatus | false | null;
  exchangeStatusError: HttpErrorResponse | null;
}

export const initializeState = (): ExchangeStatusState => ({
  exchangeStatus: null,
  exchangeStatusError: null,
});
