import { createReducer, on } from '@ngrx/store';
import {
  ApplicationCountState,
  IApplicationCountState,
} from '../../models/application-count-state.model';
import * as ApplicationCountActions from './application-count.actions';

export const applicationCountStateKey = 'applicationCount';

export const initialState: IApplicationCountState = new ApplicationCountState();

export const ApplicationCountReducer = createReducer(
  initialState,
  on(
    ApplicationCountActions.GetApplicationCountsSuccess,
    (state: IApplicationCountState, { counts }): IApplicationCountState => ({
      ...state,
      ...counts,
      error: undefined,
      loaded: true,
    }),
  ),
  on(
    ApplicationCountActions.GetApplicationCountsError,
    (state: IApplicationCountState, { error }): IApplicationCountState => ({
      ...state,
      error,
    }),
  ),
);
