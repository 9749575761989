import { Pipe, PipeTransform } from '@angular/core';
import { CoverageTypesUtils } from '@boldpenguin/emperor-services';

@Pipe({
  name: 'coverageAbbr',
})
export class CoveragesAbbreviationPipe implements PipeTransform {
  transform(coverages: { name: string }[] | string): string {
    const covs: { name: string }[] =
      typeof coverages === 'string' ? [{ name: coverages }] : coverages;
    return transformCoveragesIntoAbbreviated(covs);
  }
}

export const transformCoveragesIntoAbbreviated = (
  coverages: { name: string }[],
): string =>
  coverages
    .map((product) => CoverageTypesUtils.getCoverageAbbreviation(product.name))
    .sort()
    .join(', ');
