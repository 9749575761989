import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import {
  MixpanelEventType,
  MixpanelService,
} from 'src/app/core/services/mixpanel-service/mixpanel.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent {
  @Input() src: string;
  @Input() caption: string;
  @Input() alt = '';
  @Input() mixpanelEventType: MixpanelEventType =
    MixpanelEventType.SIGNUP_DIRECT_APPOINTMENTS_LOGO_ERROR;

  displayCaption = false;
  constructor(private mixpanelService: MixpanelService) {}

  onError(): void {
    this.displayCaption = !!this.caption;
    this.trackEvent();
  }

  trackEvent(): void {
    const errorEvent = this.mixpanelEventType;
    const properties = { url: this.src };

    this.mixpanelService.track(errorEvent, properties);
  }
}
