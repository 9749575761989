import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs';
import { IntercomInternalLabels } from 'src/app/core/services/intercom-service/intercom.service';
import {
  MixpanelEventType,
  MixpanelService,
} from 'src/app/core/services/mixpanel-service/mixpanel.service';
import { selectUrl } from 'src/app/root-store/state.router';
import { IMenuItem } from '../../models/menus';

@UntilDestroy()
@Component({
  selector: 'app-navigation-menu-item',
  templateUrl: './navigation-menu-item.component.html',
  styleUrls: ['./navigation-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationMenuItemComponent
  implements AfterViewInit, AfterContentInit
{
  @ViewChild(MatExpansionPanel) matPanel: MatExpansionPanel;
  @Input() item: IMenuItem;
  @Output() itemClicked = new EventEmitter<string[] | null>();

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly cdRef: ChangeDetectorRef,
    private mixpanelService: MixpanelService,
  ) {}

  ngAfterContentInit() {
    /**
     * Programmatically open and close panels as user navigates.
     * Using a template binding to MatExpansionPanel `[expanded]`
     * sometimes left the panel open when it should close.
     */
    if (this.item.subItems) {
      this.store
        .pipe(
          select(selectUrl),
          filter(Boolean),
          map((url: string) => this.isSubItemSelected(url)),
          // adding distinctUntilChanged() left the panel open sometimes
          untilDestroyed(this),
        )
        .subscribe((shouldExpandPanel) => {
          if (!this.matPanel) return;
          if (shouldExpandPanel && !this.matPanel.expanded) {
            this.matPanel.open();
          } else if (!shouldExpandPanel && this.matPanel.expanded) {
            this.matPanel.close();
          }
        });
    }
  }

  ngAfterViewInit() {
    /**
     * Open the panel programmatically, for example on page refresh.
     */
    if (
      this.item.subItems &&
      this.isSubItemSelected(this.router.routerState.snapshot.url)
    ) {
      this.matPanel.open();
      this.cdRef.detectChanges();
    }
  }

  onClick(item: IMenuItem) {
    if (item?.action) {
      this.store.dispatch(item.action());
    }

    if (item.url && item.url.length > 0) {
      this.mixpanelService.track(
        MixpanelEventType.TOP_TOOLBAR_NAVIGATE_STARTED,
        { url: item.url },
      );
    } else {
      //we want to close the menu even if there is no page change
      this.matPanel.close();
      this.cdRef.detectChanges();
    }

    if (item.mixpanelCallback) {
      this.mixpanelService.track(
        item.mixpanelCallback.event,
        item.mixpanelCallback.props,
      );
    }
  }

  getIntercomTarget(text: string) {
    return `${IntercomInternalLabels.NAV_MENU_CATEGORY}: ${text}`;
  }

  getDataTestId(text: string) {
    return `nav-menu-item-${text.toLowerCase().replace(' ', '-')}`;
  }

  private isSubItemSelected(currentUrl: string) {
    return this.item.url && currentUrl.slice(1).startsWith(this.item.url[0]);
  }
}
