import { AfterContentInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAutoFocusEmp]',
})
export class AutoFocusEmperorFormDirective implements AfterContentInit {
  constructor(private el: ElementRef) {}

  ngAfterContentInit() {
    setTimeout(() => {
      this.el.nativeElement?.querySelector('input')?.focus();
    }, 500);
  }
}
