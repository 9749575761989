<div [ngClass]="{ 'file-drop-zone': true, fileover: fileOver }">
  <input
    aria-label="File Upload"
    type="file"
    #fileDropRef
    id="fileDropRef"
    (change)="handleFilesChanged($event)"
    (click)="clearInputValue()"
    [multiple]="multiple"
    [accept]="accept"
  />

  <emperor-icon name="file_add_2"></emperor-icon>

  <emperor-progress-bar
    *ngIf="loading"
    [indeterminate]="true"
    class="progress-bar"
  ></emperor-progress-bar>

  <div class="file-list">
    <ng-template #noFiles>
      Drag and drop or <label for="fileDropRef">browse</label> your files
    </ng-template>

    <ng-container *ngIf="uploadedFiles.length > 0; else noFiles">
      <p *ngFor="let file of uploadedFiles">
        {{ file.name }}
      </p>
    </ng-container>

    <div class="subtext" *ngIf="subtext">
      {{ subtext }}
    </div>
  </div>
</div>
