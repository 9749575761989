import {
  CarrierGroupedMessages,
  CarrierMessage,
} from '../models/carrier-messages';

export const groupByCarrier = (
  messages: CarrierMessage[],
): CarrierGroupedMessages => {
  const grouped: CarrierGroupedMessages = {};
  messages.forEach((message) => {
    if (!grouped[message.carrierSubdomain]) {
      grouped[message.carrierSubdomain] = [];
    }
    grouped[message.carrierSubdomain].push(message);
  });
  return grouped;
};
