import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, UntypedFormArray } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, shareReplay } from 'rxjs';
import { IAvailableEnrollment } from 'src/app/core/models/enrollments.model';
import { CarrierMessageService } from 'src/app/core/services/carrier-message-service/carrier-message.service';
import {
  MixpanelEventType,
  MixpanelService,
} from 'src/app/core/services/mixpanel-service/mixpanel.service';
import { CarrierGroupedMessages } from '../../models/carrier-messages';
import { trackByCarrierId } from '../../utilities/carrier-appointment-utilities';

@UntilDestroy()
@Component({
  selector: 'app-carrier-appointments-choices',
  templateUrl: './carrier-appointments-choices.component.html',
  styleUrls: ['./carrier-appointments-choices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CarrierAppointmentsChoicesComponent implements OnInit {
  @Input() loaded = false;
  @Input() parentForm: UntypedFormArray;
  @Input() controls: AbstractControl[];
  @Input() selectedMixpanelEvent: MixpanelEventType;
  @Input() logoErrorMixpanelEvent: MixpanelEventType;

  readonly trackByCarrierId = trackByCarrierId;
  carrierMessages$: Observable<CarrierGroupedMessages>;

  constructor(
    private mixpanel: MixpanelService,
    private carrierMessageService: CarrierMessageService,
  ) {}

  ngOnInit(): void {
    this.carrierMessages$ = this.carrierMessageService
      .getApplicableSubAppointmentMessages$()
      .pipe(
        shareReplay({ bufferSize: 1, refCount: false }),
        untilDestroyed(this),
      );
  }

  trackCheckboxClick(carrier: IAvailableEnrollment) {
    this.mixpanel.track(this.selectedMixpanelEvent, {
      carrier: carrier.name,
    });
  }
}
