import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { BpSdkStoreService } from 'src/app/core/services/bp-sdk-store-service/bp-sdk-store.service';
import {
  CarrierGroupedMessages,
  CarrierMessage,
} from 'src/app/shared/models/carrier-messages';
import { usStates } from 'src/app/shared/models/us-states-model';
import { BusinessEntityType } from '../../../features/welcome/models/tax-classification-choices';
import { selectComplianceFormBusinessEntityType } from '../../../features/welcome/store/welcome.selectors';
import { groupByCarrier } from 'src/app/shared/utilities/carrier-message-utilities';

@Injectable({
  providedIn: 'root',
})
export class CarrierMessageService {
  private subAppointmentMessages: CarrierMessage[] = [
    {
      type: 'universal',
      carrierSubdomain: 'biberk',
      message:
        'When you request a biBERK sub-appointment, Bold Penguin and biBERK will work together to establish a new code. If you currently have an appointment through another wholesaler, your prior code will be closed and a new code will be opened with Bold Penguin. This does not impact renewals for active policies with the prior wholesaler.',
    },
    {
      type: 'businessEntityExclusion',
      carrierSubdomain: 'berkleycarrier',
      exclusions: [BusinessEntityType.SoleProp],
      message:
        'Berkley Management Protection will not approve requests for agencies with a tax class of Single Member LLC or Sole Proprietorship.',
    },
    {
      type: 'stateExclusion',
      carrierSubdomain: 'berkleycarrier',
      exclusions: [usStates.AK, usStates.LA],
      message:
        'Please note that Berkley Management Protection will ask Agencies located in AK or LA for documentation of min. $100k in Management Liability opportunities in other states.',
    },
    {
      type: 'stateExclusion',
      carrierSubdomain: 'uticafirst',
      exclusions: [
        usStates.AL,
        usStates.AK,
        usStates.AZ,
        usStates.AR,
        usStates.CA,
        usStates.CO,
        usStates.DE,
        usStates.DC,
        usStates.GA,
        usStates.HI,
        usStates.ID,
        usStates.IL,
        usStates.IN,
        usStates.IA,
        usStates.KS,
        usStates.KY,
        usStates.LA,
        usStates.ME,
        usStates.MI,
        usStates.MN,
        usStates.MS,
        usStates.MO,
        usStates.MT,
        usStates.NE,
        usStates.NV,
        usStates.NH,
        usStates.NM,
        usStates.NY,
        usStates.NC,
        usStates.ND,
        usStates.OK,
        usStates.OR,
        usStates.RI,
        usStates.SC,
        usStates.SD,
        usStates.TN,
        usStates.TX,
        usStates.UT,
        usStates.VT,
        usStates.WA,
        usStates.WV,
        usStates.WI,
        usStates.WY,
      ],
      message:
        'Please note that Utica First will only approve requests for agencies located in one of the following states: CT, FL, MA, MD, NJ, OH, PA, and VA',
    },
    {
      type: 'stateExclusion',
      carrierSubdomain: 'westfieldcarrier',
      exclusions: [
        usStates.AL,
        usStates.AK,
        usStates.AR,
        usStates.CA,
        usStates.CT,
        usStates.DC,
        usStates.FL,
        usStates.HI,
        usStates.ID,
        usStates.KS,
        usStates.LA,
        usStates.ME,
        usStates.MA,
        usStates.MN,
        usStates.MS,
        usStates.MO,
        usStates.MT,
        usStates.NE,
        usStates.NV,
        usStates.NH,
        usStates.NJ,
        usStates.NY,
        usStates.ND,
        usStates.OK,
        usStates.OR,
        usStates.RI,
        usStates.SD,
        usStates.TX,
        usStates.UT,
        usStates.VT,
        usStates.WA,
        usStates.WY,
      ],
      message:
        'Please note that Westfield will only approve requests for agencies located in one of the following states: <br> AZ, CO, DE, GA, IL, IN, IA, KY, MD, MI, NM, NC, OH, PA, SC, TN, VA, WV, WI',
    },
    {
      type: 'businessEntityExclusion',
      carrierSubdomain: 'cna',
      exclusions: [BusinessEntityType.SoleProp],
      message:
        'CNA will not approve requests for agencies with a tax class of Single Member LLC or Sole Proprietorship.',
    },
    {
      type: 'stateExclusion',
      carrierSubdomain: 'cna',
      exclusions: [usStates.OK, usStates.FL, usStates.TX, usStates.NY],
      message:
        'Please note that CNA will not approve requests for agencies located in OK, FL, TX or certain areas of NY.',
    },
  ];

  constructor(
    private store: Store,
    private bpSdkStoreService: BpSdkStoreService,
  ) {}

  /**
   * @returns all messages for a given carrier
   */
  getSubAppointmentMessagesForCarrier(
    carrierSubdomain: string,
  ): CarrierMessage[] {
    return this.subAppointmentMessages.filter(
      (message) => message.carrierSubdomain === carrierSubdomain,
    );
  }

  /**
   * @returns applicable messages given the exclusions for all carriers, grouped by carrier
   * @remark this requires data from the welcome slice of state
   */
  getApplicableSubAppointmentMessages$(): Observable<CarrierGroupedMessages> {
    return this.getBusinessEntityExclusionMessages$().pipe(
      map((businessEntityExclusions) =>
        groupByCarrier([
          ...businessEntityExclusions,
          ...this.getUniversalMessages(),
          ...this.getTenantLocationCarrierExclusionMessages(),
        ]),
      ),
    );
  }

  /**
   * @returns business entity related messages for all carriers
   */
  getBusinessEntityExclusionMessages$(): Observable<CarrierMessage[]> {
    return this.store.pipe(
      select(selectComplianceFormBusinessEntityType),
      map((businessEntityType: BusinessEntityType) =>
        this.subAppointmentMessages.filter(
          (message) =>
            message.type === 'businessEntityExclusion' &&
            message.exclusions.includes(businessEntityType),
        ),
      ),
    );
  }

  private getUniversalMessages(): CarrierMessage[] {
    return this.subAppointmentMessages.filter(
      (message) => message.type === 'universal',
    );
  }

  private getTenantLocationCarrierExclusionMessages(): CarrierMessage[] {
    const tenantState =
      this.bpSdkStoreService.getCurrentState().auth.tenantState;
    return this.subAppointmentMessages.filter(
      (message) =>
        message.type === 'stateExclusion' &&
        message.exclusions.includes(tenantState),
    );
  }
}
