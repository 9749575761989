import { Pipe, PipeTransform } from '@angular/core';

/**
 *
 *
 * @param value Initial value.
 * @param options Which include slice start & end.
 *                If we should add an Ellipsis.
 *                Summands which is everything that should be added to the initial value if any.
 * @example
 * ```
 *
 * Example input =
 *  {{
 *         agent.firstName --> 'Long First Name'
 *           | truncate
 *             : {
 *                 start: 0,
 *                 end: 21,
 *                 ellipsis: true,
 *                 summands: [agent.lastName --> 'Long Last Name']
 *               }
 *       }}
 *
 *   Example output = 'Long First Name Long...'
 * }
 * ```
 *
 */
@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string | null, options: ITruncateOptions): string {
    let fmtVal: string = value ?? '';
    const { start, end, ellipsis, summands } = options;

    const summandsNoNulls = summands.map((s) => s ?? '');

    if (!value) {
      return '';
    }

    if (!!summandsNoNulls.length) {
      fmtVal = `${value} ${summandsNoNulls.join()}`;
    }

    fmtVal = this.sliceStr(fmtVal, start, end);
    fmtVal = this.addEllipsis(fmtVal, ellipsis, end);

    return fmtVal;
  }

  private sliceStr(str: string, start: number, end: number): string {
    return str.length > end ? str.slice(start, end) : str;
  }

  private addEllipsis(str: string, ellipsis: boolean, end: number): string {
    if (str.length >= end) {
      return ellipsis ? `${str.trim()}...` : str.trim();
    }
    return str;
  }
}

interface ITruncateOptions {
  start: number;
  end: number;
  ellipsis: boolean;
  summands: Array<string | null>;
}
