import { Injectable, NgZone } from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarConfig as MatSnackBarConfig,
} from '@angular/material/legacy-snack-bar';
import { SnackBarComponent } from '../../../shared/components/snack-bar/snack-bar.component';
import { DynamicMessage } from './dynamic-notification-messages';
import { Messages } from './notification-messages';

export interface INotificationService {
  default(message: Messages, config: NotificationConfig): void;
  info(message: Messages, config: NotificationConfig): void;
  success(message: Messages, config: NotificationConfig): void;
  warn(message: Messages, config: NotificationConfig): void;
  error(message: Messages, config: NotificationConfig): void;
}

export interface NotificationConfig {
  duration?: number;
  horizontalPosition?: 'start' | 'center' | 'end' | 'left' | 'right';
  verticalPosition?: 'top' | 'bottom';
  panelClass?: string;
  actionText?: string;
}
@Injectable({
  providedIn: 'root',
})
export class NotificationService implements INotificationService {
  private defaultConfig: MatSnackBarConfig = {
    duration: 2000,
    horizontalPosition: 'center',
    verticalPosition: 'top',
  };
  constructor(
    private readonly zone: NgZone,
    private readonly snackBar: MatSnackBar,
  ) {}

  default(message: Messages | DynamicMessage, config: NotificationConfig = {}) {
    return this.notify('default-notification-overlay', message, config);
  }

  info(message: Messages | DynamicMessage, config: NotificationConfig = {}) {
    return this.notify('info-notification-overlay', message, config);
  }

  success(message: Messages | DynamicMessage, config: NotificationConfig = {}) {
    return this.notify('success-notification-overlay', message, config);
  }

  warn(message: Messages | DynamicMessage, config: NotificationConfig = {}) {
    return this.notify('warning-notification-overlay', message, {
      duration: 2500,
      ...config,
    });
  }

  error(message: Messages | DynamicMessage, config: NotificationConfig = {}) {
    return this.notify('error-notification-overlay', message, {
      duration: 3000,
      ...config,
    });
  }

  private notify(
    notificationClass: string,
    message: Messages | DynamicMessage,
    config: NotificationConfig,
  ) {
    const overrideConfig: MatSnackBarConfig =
      this.toMatSnackBarConfigObject(config);

    const panelClass = [notificationClass];

    return this.show(
      message,
      {
        ...this.defaultConfig,
        panelClass,
        ...overrideConfig,
      },
      config.actionText,
    );
  }

  private toMatSnackBarConfigObject(
    config: NotificationConfig,
  ): Record<string, unknown> {
    // currently we export config properties in the same format that
    // MatSnackBar expects, so this is effectively a no-op. If we
    // ever want to move away from MatSnackBarConfig for our notifications,
    // this can map properties
    const snackBarConf = { ...config };
    delete snackBarConf.actionText;
    return snackBarConf;
  }

  private show(
    message: Messages | DynamicMessage,
    configuration: MatSnackBarConfig,
    actionText?: string,
  ) {
    return this.zone.run(() => {
      const action = !!actionText ? actionText : undefined;

      return this.snackBar.openFromComponent(SnackBarComponent, {
        ...configuration,
        data: { message, action },
      });
    });
  }
}
