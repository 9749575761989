import {
  ChangeDetectionStrategy,
  Component,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  EmperorCardHeaderColor,
  EmperorDialogConfig,
  EmperorUIButtonTypes,
} from '@boldpenguin/emperor-presentational';

@Component({
  selector: 'app-naics-explanation-dialog',
  templateUrl: './naics-explanation-dialog.component.html',
  styleUrls: ['./naics-explanation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NaicsExplanationDialogComponent {
  readonly EmperorUIButtonTypes = EmperorUIButtonTypes;
  readonly EmperorCardHeaderColor = EmperorCardHeaderColor;

  constructor(
    private sanitizer: DomSanitizer,
    public config: EmperorDialogConfig,
  ) {}

  sanitizedExplanation(): string {
    return (
      this.sanitizer.sanitize(
        SecurityContext.HTML,
        this.config.data?.naicsExplanation,
      ) ?? ''
    );
  }
}
