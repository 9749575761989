import { Action, createReducer, on } from '@ngrx/store';
import * as ExchangeStatusActions from './exchange-status.actions';
import ExchangeStatusState, { initializeState } from './exchange-status.state';

export const exchangeStatusStateKey = 'exchangeStatus';

export const initialState = initializeState();

const reducer = createReducer(
  initialState,
  on(
    ExchangeStatusActions.LoadExchangeStatusSuccess,
    (state: ExchangeStatusState, { status }): ExchangeStatusState => ({
      ...state,
      exchangeStatus: status,
    }),
  ),
  on(
    ExchangeStatusActions.UpdateExchangeStatusSuccess,
    (state: ExchangeStatusState, { status }): ExchangeStatusState => ({
      ...state,
      exchangeStatus: status,
      exchangeStatusError: null,
    }),
  ),
  on(
    ExchangeStatusActions.LoadExchangeStatusError,
    ExchangeStatusActions.UpdateExchangeStatusError,
    (state: ExchangeStatusState, { error }): ExchangeStatusState => ({
      ...state,
      exchangeStatus: null,
      exchangeStatusError: error,
    }),
  ),
);

export const ExchangeStatusReducer = (
  state: ExchangeStatusState | undefined,
  action: Action,
) => reducer(state, action);
