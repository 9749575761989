import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IEnrollment } from 'src/app/core/models/enrollments.model';
import { EnrollmentsService } from '../enrollments-service/enrollments.service';
import {
  MixpanelEventType,
  MixpanelService,
} from '../mixpanel-service/mixpanel.service';
import { Messages } from '../notification-service/notification-messages';
import { NotificationService } from '../notification-service/notification.service';

@Injectable({
  providedIn: 'root',
})
export class EnrollmentUpdateService {
  constructor(
    private mixPanelService: MixpanelService,
    private enrollmentsService: EnrollmentsService,
    private notificationService: NotificationService,
  ) {}

  updateEnrollment(
    enrollment: IEnrollment,
    activate: boolean,
    displayMessage: boolean = true,
    forceValidation: boolean = false,
  ): Observable<IEnrollment> {
    return this.enrollmentsService
      .updateEnrollment(enrollment, activate, forceValidation)
      .pipe(
        tap((updatedEnrollment) => {
          this.trackUpdateStatus(updatedEnrollment, true);
          if (displayMessage) {
            this.displayUpdateSuccessMessage(enrollment.carrier_name);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this.trackUpdateStatus(enrollment, false);
          if (displayMessage) {
            this.displayUpdateErrorMessage();
          }

          return throwError(error);
        }),
      );
  }

  private trackUpdateStatus(enrollment: IEnrollment, succeeded: boolean): void {
    this.mixPanelService.track(
      MixpanelEventType.CARRIER_APPOINTMENT_CARRIER_STATUS_CHANGED,
      {
        carrier: enrollment.carrier_subdomain,
        active: enrollment.active,
        appointment_type: enrollment.appointment_type || '',
        succeeded,
      },
    );
  }

  private displayUpdateSuccessMessage(carrierName: string): void {
    this.notificationService.success(
      `${carrierName} has been successfully updated.`,
      {
        horizontalPosition: 'center',
      },
    );
  }

  private displayUpdateErrorMessage(): void {
    this.notificationService.error(Messages.STATUS_UPDATE_ERROR, {
      horizontalPosition: 'center',
    });
  }
}
