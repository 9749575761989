import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { IEnrollment } from 'src/app/core/models/enrollments.model';
import { PhoneNumberPipe } from '../pipes/phone-number/phone.pipe';

export const toHumanReadable = (name: string): string => {
  const words = name.match(/[A-Za-z][a-z]*/g) || [];
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');
};

export const extractFCPlaceholder = (
  enrollment: IEnrollment,
  attributeName: string,
): string => enrollment.enrollment_attributes[attributeName].placeholder;

export const extractFCHelpText = (
  enrollment: IEnrollment,
  attributeName: string,
): string => enrollment.enrollment_attributes[attributeName].help_text;

export const extractFCErrorMessage = (
  enrollment: IEnrollment,
  attributeName: string,
): string => enrollment.enrollment_attributes[attributeName].error_text;

// TODO since the regex is coming from the API directly, we can probably trust it and use `eval` to get a regular expression object.
// Currently I'm worried that there will be more flux, so if you're here and the regular expression validations are stable, use `eval`
// instead of this approach.
const prepareRegex = (regex: string): RegExp => {
  const lastSlashIdx = regex.lastIndexOf('/');
  const options = regex.slice(lastSlashIdx + 1);
  const expression = regex.slice(1, lastSlashIdx);
  return new RegExp(expression, options);
};

export const buildDirectAppointmentForm = (
  enrollment: IEnrollment,
): UntypedFormGroup => {
  const phonePipe = new PhoneNumberPipe();
  const fields = enrollment.enrollment_attributes;
  const form = new UntypedFormGroup({});

  Object.keys(fields).forEach((attributeName) => {
    const fcValue = attributeName.toLowerCase().includes('phone')
      ? phonePipe.transform(fields[attributeName].value)
      : fields[attributeName].value;

    const patternValidator = fields[attributeName].regex && [
      Validators.pattern(prepareRegex(fields[attributeName].regex)),
      Validators.required,
    ];
    const validators = patternValidator || [Validators.required];
    const control = new UntypedFormControl(fcValue, validators);
    form.addControl(attributeName, control);
  });

  return form;
};
