import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { IconsModule } from '@boldpenguin/emperor-icons';
import { PresentationalModule } from '@boldpenguin/emperor-presentational';
import { PhoneNumberPipe } from '../shared/pipes/phone-number/phone.pipe';
import { SharedModule } from '../shared/shared.module';
import { AlertBannerComponent } from './components/alert-banner/alert-banner.component';
import { NavigationMenuItemComponent } from './components/navigation-menu-item/navigation-menu-item.component';
import { NavigationMenuComponent } from './components/navigation-menu/navigation-menu.component';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';
import { ProfilePanelComponent } from './components/profile-menu/profile-panel/profile-panel.component';
import { TopNavigationToolbarComponent } from './components/top-navigation-toolbar/top-navigation-toolbar.component';

@NgModule({
  declarations: [
    TopNavigationToolbarComponent,
    NavigationMenuComponent,
    ProfileMenuComponent,
    ProfilePanelComponent,
    AlertBannerComponent,
    NavigationMenuItemComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatExpansionModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    IconsModule,
    PresentationalModule,
  ],
  exports: [
    TopNavigationToolbarComponent,
    NavigationMenuComponent,
    AlertBannerComponent,
  ],
  providers: [PhoneNumberPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NavigationModule {}
