import { Action, createReducer, on } from '@ngrx/store';
import { PaymentMethod } from '@stripe/stripe-js';
import { IPromotionCode } from '../../billing/models/coupons';
import { ILegalAgreements } from '../models/legal-agreements.model';
import { MessageDisplay } from '../models/message-display.model';
import { ISalesforceAttributes } from '../models/salesforce.model';
import { ISignupAgencyInfo } from '../models/signup-agency-info.model';
import { ISignupAgentInfo } from '../models/signup-agent-info.model';
import { ISubscriptionPlanDetails } from '../models/subscription-plan-details.model';
import * as SignupActions from './signup.actions';

export const signupFeatureKey = 'signup';

export interface SignupState {
  // Signup State for creating an account
  agencyInfo?: Partial<ISignupAgencyInfo>;
  agentInfo?: ISignupAgentInfo;
  plan?: ISubscriptionPlanDetails;
  planSku?: string;
  preferredPartnershipLabel?: string;
  promotionCode?: IPromotionCode;
  legalAgreements?: ILegalAgreements;
  payment?: PaymentMethod;
  salesforce?: ISalesforceAttributes;

  // Extra State for signing up
  progressBarPercent: number;
  promotionCodeLoadingHappening: boolean;
  messageDisplay?: MessageDisplay;
}

export const signupInitialState: SignupState = {
  progressBarPercent: 10,
  promotionCodeLoadingHappening: false,
};
const reducer = createReducer(
  signupInitialState,
  on(
    SignupActions.signupSetSalesforceParams,
    (
      state,
      {
        accountId,
        businessName,
        emailAddress,
        firstName,
        lastName,
        opportunityId,
        phoneNumber,
      },
    ): SignupState => ({
      ...state,
      agencyInfo: {
        ...(state.agencyInfo || {}),
        agencyName: businessName || '',
        phone: phoneNumber || '',
      },
      agentInfo: {
        ...(state.agentInfo || {}),
        firstName: firstName || '',
        lastName: lastName || '',
        email: emailAddress || '',
      },
      salesforce: {
        accountId,
        opportunityId,
      },
    }),
  ),
  on(
    SignupActions.signupSetDiscountSuccess,
    (state, { label }): SignupState => ({
      ...state,
      preferredPartnershipLabel: label,
    }),
  ),
  on(
    SignupActions.signupSetPlanSkuSuccess,
    (state, { planSku, label }): SignupState => ({
      ...state,
      planSku,
      preferredPartnershipLabel: label,
    }),
  ),
  on(
    SignupActions.submitAgencyInfo,
    (state, { agencyInfo }): SignupState => ({
      ...state,
      agencyInfo,
    }),
  ),
  on(
    SignupActions.submitAgentInfo,
    (state, { agentInfo }): SignupState => ({
      ...state,
      agentInfo,
    }),
  ),
  on(
    SignupActions.selectPlanChoice,
    (state, { plan }): SignupState => ({
      ...state,
      plan,
    }),
  ),
  on(
    SignupActions.setProgressBarPercent,
    (state, { progressBarPercent }): SignupState => ({
      ...state,
      progressBarPercent,
    }),
  ),
  on(
    SignupActions.loadPromotionCode,
    (state): SignupState => ({
      ...state,
      promotionCodeLoadingHappening: true,
    }),
  ),
  on(
    SignupActions.loadPromotionCodeSuccessful,
    (state, { promotionCode }): SignupState => ({
      ...state,
      promotionCodeLoadingHappening: false,
      promotionCode,
    }),
  ),
  on(
    SignupActions.loadPromotionCodeFailed,
    (state): SignupState => ({
      ...state,
      promotionCodeLoadingHappening: false,
      preferredPartnershipLabel: undefined,
      planSku: undefined,
    }),
  ),
  on(
    SignupActions.setMessageDisplay,
    (state, { messageDisplay }): SignupState => ({
      ...state,
      messageDisplay,
    }),
  ),
  on(
    SignupActions.submitLegalAgreements,
    (state, { legalAgreements }): SignupState => ({
      ...state,
      legalAgreements,
    }),
  ),
  on(
    SignupActions.setPaymentDataInStore,
    (state, { paymentMethod }): SignupState => ({
      ...state,
      payment: paymentMethod,
    }),
  ),
);

export const SignupReducer = (state: SignupState, action: Action) =>
  reducer(state, action);
