import { createAction, createActionGroup, props } from '@ngrx/store';

export const AppFormTrackingActions = createActionGroup({
  source: 'Application Form',
  events: {
    'Increment Button Clicked': props<{
      source: string;
      description: string;
    }>(),
  },
});

export const ExchangeDailyLimitIncrement = createAction(
  '[Exchange Daily Limit] Increment Button Clicked',
  props<{
    source: string;
    description: string;
  }>(),
);

export const ExchangeConcurrencyLimitIncrement = createAction(
  '[Exchange] Increment Button Clicked',
  props<{
    source: string;
    description: string;
  }>(),
);
