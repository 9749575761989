import { Action, createReducer, on } from '@ngrx/store';

import { HeaderAlert } from '../../services/header-service/header-alerts.service';
import * as HeaderAlertsActions from './header-alerts.actions';
import HeaderAlertsState, { initializeState } from './header-alerts.state';

export const headerAlertStateKey = 'headerAlerts';

export const initialState = initializeState();

const removeAlertCreator = (
  state: HeaderAlertsState,
  payload: { headerAlert: HeaderAlert },
) => {
  const newState = Object.assign({}, state);
  if (state[payload.headerAlert.id]) {
    delete newState[payload.headerAlert.id];
  }
  return newState;
};

const reducer = createReducer(
  initialState,
  on(
    HeaderAlertsActions.AddHeaderAlert,
    (state: HeaderAlertsState, payload): HeaderAlertsState => ({
      ...state,
      [payload.headerAlert.id]: payload.headerAlert,
    }),
  ),
  on(HeaderAlertsActions.CancelHeaderAlert, removeAlertCreator),
  on(HeaderAlertsActions.ConfirmHeaderAlert, removeAlertCreator),
);

export const HeaderAlertsReducer = (
  state: HeaderAlertsState | undefined,
  action: Action,
) => reducer(state, action);
