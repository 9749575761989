import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { IReferral } from './referral.model';

export interface IReferralService {
  referAgency(referralData: {
    name: string;
    contact: string;
    email: string;
    phone: string;
  }): Observable<IReferral>;
}

@Injectable({
  providedIn: 'root',
})
export class ReferralService implements IReferralService {
  constructor(private http: HttpClient) {}

  public referAgency({
    name,
    contact,
    email,
    phone,
  }: {
    name: string;
    contact: string;
    email: string;
    phone: string;
  }): Observable<IReferral> {
    const url = `${environment.apiAuthUri}/api/terminal/referrals`;
    return this.http.post<IReferral>(url, { name, contact, email, phone });
  }
}
