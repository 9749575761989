import {
  IApplicationFormCreatedBy,
  IApplicationFormOwner,
  IApplicationFormQuickView,
  IApplicationSourcePayload,
  IQuotesState,
  ITenant,
} from '@boldpenguin/sdk';
import { startOfDay, subDays, subWeeks } from 'date-fns';
import { IEligibleCarrierProduct } from './carrier-products-response.model';
import { IQuoteArchiveReason } from './quote-archive-reason';
import {
  EmperorUIAlertType,
  QuotePresentationLabel,
} from '@boldpenguin/emperor-presentational';

export interface AgentDetails {
  firstName: string;
  lastName: string;
  name: string;
  title: string;
}

export enum QuoteListViews {
  /**
   * Viewing the Active/Inbox of the Quotes List
   */
  Inbox = 'Inbox',
  /**
   * Viewing archived quotes
   */
  Archive = 'Archive',
}

export interface ProspectDetails {
  businessClass: string;
  businessName: string;
  city: string;
  coverages: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phone: string;
  state: string;
}

export interface QuoteCardViewModel {
  id: string;
  ownerId: string;
  owner: IApplicationFormOwner;
  firstName?: string;
  lastName?: string;
  businessName?: string;
  coverages?: Array<string>;
  phone?: string;
  state?: string;
  city?: string;
  classOfBusiness?: string;
  naic?: string;
  email?: string;
  status: string;
  applicationSource?: IApplicationSourcePayload | undefined;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  createdBy: IApplicationFormCreatedBy;
  referredBy?: IApplicationFormOwner | null;
  referenceId: string;
  archiveQuestion?: {
    archiveReason: IQuoteArchiveReason | null;
    answerId: string;
    questionId: string;
  };
  percentage?: number;
  quick_view?: IApplicationFormQuickView;
  tenant_transfer_reason: string | null;
  eligible_carriers_products?: IEligibleCarrierProduct[];
  transferred_to?: string | null;
  tenant: ITenant;
}

export enum QuoteQuestionCodes {
  BusinessName = 'business_name',
  ArchiveReason = 'app_archive_reason',
  FirstName = 'first_name',
  LastName = 'last_name',
  CoverageTypes = 'coverage_type',
  Phone = 'phone',
  Email = 'email',
  City = 'city',
  State = 'state',
  Naics = 'naics',
  Address = 'address',
  EffectiveDate = 'coverage_start_date',
  TransferReason = 'tenant_transfer_reason',
  SendToBoldPenguin = 'send_to_bold_penguin',
  LegalEntity = 'legal_entity',
  /**
   * Used to send the quote to the exchange
   */
  SendToExchange = 'send_to_bold_penguin',
  Pathpoint = 'pathpoint',
  ReducedCob = 'reduced_cob',
}

export type AppFormState =
  | 'incomplete'
  | 'in_progress'
  | 'completed'
  | 'quoted';

export const SummaryPanelMap = [
  { code: QuoteQuestionCodes.Naics, target: 'business_class', deep: true },
  { code: QuoteQuestionCodes.ReducedCob, target: 'business_class', deep: true },
  { code: QuoteQuestionCodes.City, target: 'city' },
  { code: QuoteQuestionCodes.CoverageTypes, target: 'coverages', multi: true },
  { code: QuoteQuestionCodes.FirstName, target: 'firstName' },
  { code: QuoteQuestionCodes.LastName, target: 'lastName' },
  { code: QuoteQuestionCodes.Phone, target: 'phone' },
  { code: QuoteQuestionCodes.State, target: 'state', deep: true },
  { code: QuoteQuestionCodes.BusinessName, target: 'businessName' },
  { code: QuoteQuestionCodes.Email, target: 'email' },
  { code: QuoteQuestionCodes.LegalEntity, target: 'legal_entity', deep: true },
];

/* eslint-disable @typescript-eslint/naming-convention */
export const FiltersTimeMap = {
  'All Time': null,
  Today: startOfDay(new Date()).toISOString(),
  'Past 7 days': startOfDay(subWeeks(new Date(), 1)).toISOString(),
  'Past 30 days': startOfDay(subDays(new Date(), 30)).toISOString(),
};

/**
 * @deprecated use FormFilterGenericAppOwners, can be removed after legacy dashboard is removed
 */
export enum AvailableAgents {
  All = 'All Agents',
  Me = 'Assigned To Me',
  Not = 'Not Assigned To Me',
}

export enum QuoteFormAnimationState {
  NotStarted = 'NotStarted',
  Animating = 'Animating',
  Completed = 'Completed',
}

export type QuoteRequestGroup = { [key: string]: Array<IQuotesState> };

export enum QuoteButtonText {
  ContinueToCarrier = 'CONTINUE TO CARRIER',
  ContinueToQuote = 'CONTINUE TO QUOTE',
  ContinueToBind = 'CONTINUE TO BIND',
  ReviewWithCarrier = 'REVIEW WITH CARRIER',
  NextSteps = 'NEXT STEPS',
}

export enum QuoteText {
  ReadyToBind = 'Bindable quote',
  AdditionalQuestions = 'Carrier may require additional questions',
}

export const ReadyToBindQuotePresentation: QuotePresentationLabel = {
  icon: 'shield_check',
  text: QuoteText.ReadyToBind,
  iconAlertType: EmperorUIAlertType.Success,
};

export const RateIndicationQuotePresentation: QuotePresentationLabel = {
  icon: 'interface_information_1',
  text: 'Rate indication',
  tooltip: QuoteText.AdditionalQuestions,
};
