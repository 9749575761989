import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  FormControlErrors,
  FormControlExtras,
  FormControlValidators,
} from './fc-err.models';

/**
 *
 *
 * @param formControl The formControl we want to check errors against.
 * @param formControlExtras The name, specific dynamic error length related to error message.
 * @param defaultErrorMessage The default message to display as error, overrides any other error FC has.
 * @example
 * ```
 *
 * @Component({
 * selector: 'app-example',
 *   template: `
 *      <mat-error>
 *        {{ exampleFormControl | fcErr: {name: 'Example', minlength: 8, maxlength: 8} }}
 *      </mat-error>
 * `
 * })
 * export class ExampleComponent {
 *   exampleFormControl = new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(8)])
 *   exampleError = new fcErr(exampleFormControl, {name: 'Example', minlength: 8, maxlength: 8})
 *
 *   constructor(fcErr: FcErrPipe) {}
 *
 *   Example output = 'Example has a minimum character length of 8'
 * }
 * ```
 *
 */

@Pipe({
  name: 'fcErr',
  pure: false,
})
export class FcErrPipe implements PipeTransform {
  transform(
    formControl: AbstractControl | null,
    formControlExtras: FormControlExtras,
    defaultErrorMessage?: string,
  ): string | null {
    if (!formControl) {
      return null;
    }

    return this.getFormControlError(
      formControl,
      formControlExtras,
      defaultErrorMessage,
    );
  }

  private getFormControlError(
    formControl: AbstractControl,
    formControlExtras: FormControlExtras,
    defaultErrorMessage?: string,
  ): string | null {
    let error: string | null = null;
    const formControlHasErrors =
      !!this.getFormControlErrors(formControl).length;

    if (formControlHasErrors) {
      if (defaultErrorMessage) {
        error = defaultErrorMessage;
      } else {
        error = this.formatErrorMessage(formControl, formControlExtras);
      }
    }

    return error;
  }

  private formatErrorMessage(
    formControl: AbstractControl,
    formControlExtras: FormControlExtras,
  ): string {
    const errorMessages: Array<string> = [];
    const errors = this.getFormControlErrors(formControl);
    let baseDynamicErrorMsg: string;
    let dynamicErrorMessage: string;
    let standardErrorMessage: string;
    let hasDynamicErrorMessage: boolean;
    const dynamicErrorMessages = [
      'min',
      'max',
      'minlength',
      'maxlength',
      'matDatepickerMin',
      'matDatepickerMax',
      'pattern',
    ];

    errors.forEach((error: FormControlValidators) => {
      standardErrorMessage = FormControlErrors[error];
      hasDynamicErrorMessage = dynamicErrorMessages.includes(error);
      baseDynamicErrorMsg = `This field ${standardErrorMessage}`;
      dynamicErrorMessage = `${baseDynamicErrorMsg} ${
        formControlExtras[error] || ''
      }`;

      if (hasDynamicErrorMessage) {
        errorMessages.push(dynamicErrorMessage);
      } else {
        errorMessages.push(baseDynamicErrorMsg);
      }
    });

    return errorMessages.length > 0 ? errorMessages[0] : '';
  }

  private getFormControlErrors(formControl: AbstractControl): Array<string> {
    return !!formControl.errors ? Object.keys(formControl.errors) : [];
  }
}
