import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { browserTracingIntegration, init, setTag } from '@sentry/angular-ivy';

import packageInfo from '../package.json';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { applyPolyfills, defineWebComponents } from '@boldpenguin/sdk';

if (environment.production) {
  enableProdMode();

  init({
    dsn: 'https://e5c0db5cfd0d42dbbc2a9b00d640d019@sentry.io/5177967',
    environment: environment.envTag,
    release: `terminal@${packageInfo.version}`,
    integrations: [
      browserTracingIntegration({
        instrumentNavigation: environment.sentryInstrumentNavigation,
        instrumentPageLoad: environment.sentryInstrumentPageLoad,
        markBackgroundSpan: environment.sentryMarkBackgroundSpans,
        enableLongTask: environment.sentryEnableLongTask,
      }),
    ],
    tracesSampleRate: environment.sentryTracesSampleRate,
    tracePropagationTargets: [/^(.+)\.boldpenguin\.com(?:\/.*)?$/],
    beforeSend: (event, hint) => {
      const message = (hint.originalException as any)?.message;
      // Automated tests navigate quickly, and canceled fetch requests throw an error
      if (event.tags?.e2e && message && /Failed to fetch/.test(message)) {
        return null;
      }
      return event;
    },
  });

  // Add Sentry tag to filter by e2e tests
  setTag('e2e', String(!!window?.Cypress));
}

applyPolyfills().then(() => {
  defineWebComponents(window);
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
