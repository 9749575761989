import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

enum ConfirmationCampaigns {
  PathpointOptOut = 'pathpoint_opt_out',
  Default = 'default',
}

const ConfirmationCampaignsCopy = {
  [ConfirmationCampaigns.PathpointOptOut]: {
    title: 'Pathpoint Opt-Out',
    body: `Thank you! You won't be enrolled in Pathpoint. If you have any questions,
    please reach out to your Customer Success Manager or
    <a href="mailto:support@boldpenguin.com">support@boldpenguin.com</a>.`,
    cta: 'Return To Terminal',
  },
  [ConfirmationCampaigns.Default]: {
    title: 'Return to Terminal',
    body: ``,
    cta: 'Return To Terminal',
  },
};

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationComponent implements OnInit {
  copy = ConfirmationCampaignsCopy[ConfirmationCampaigns.Default];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const forParam =
      this.route.snapshot.queryParams.for ?? ConfirmationCampaigns.Default;

    this.copy = ConfirmationCampaignsCopy[forParam];
  }
}
